import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CuratorGroupModalEmptySearchResults.scss';

const CuratorGroupModalEmptySearchResults = props => {
  return (
    <div className='curator-group-modal-empty-search-results'>
      <div className='header'>No Curators Found</div>
      <div className='subheader'>
        We could not find a creator with the search term "{props.searchVal}" in your shopping circles or any circles that you have favorited. To
        discover and add new curators or circles, go to the{' '}
        <Link onClick={props.closeModal} to={`/shop/circles`}>
          curator discovery page.
        </Link>
      </div>
    </div>
  );
};

CuratorGroupModalEmptySearchResults.propTypes = {
  searchVal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default CuratorGroupModalEmptySearchResults;
