import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import pluralize from 'pluralize';
import './ShopResultsShowMoreOptions.scss';

import { getDepartmentById, getIndustryById } from '../../../Helpers/shop_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';
import { scrollToTop } from '../../../Helpers/helpers';

const ShopResultsShowMoreOptions = props => {
  const { shop } = props;
  const { activeCategory, activeIndustry, activeDepartment, activeBrands, activeCurator, activeCuratorGroup } = props.activeRefinementObjects;
  const catalogLabel = activeCategory?.name || activeDepartment?.name;
  let refinementPills = [];

  // Always allow them to search for more curators on discover
  refinementPills.push({
    label: 'Discover More Curators',
    onClick: () => {
      const Industry_id = activeCategory
        ? getDepartmentById(shop, activeCategory.Department_id).Industry_id
        : activeDepartment?.Industry_id || activeIndustry?.id;
      const Department_id = activeCategory ? getDepartmentById(shop, activeCategory.Department_id).id : activeDepartment?.id;

      scrollToTop();
      props.setDiscoverFilters({ Department_id, Industry_id });
      props.history.push('/shop/circles');
    }
  });

  // If we are on the category level, allow them to unrefine to the department level
  if (activeCategory) {
    const department = getDepartmentById(shop, activeCategory.Department_id);
    refinementPills.push({
      label: `Show all ${department.name}`,
      unrefine: () => {
        scrollToTop();
        props.applyUnrefinement({ label: 'Category', unrefinement: { Category_id: null, Department_id: department.id } });
      }
    });
  }

  // If we are on the department level, allow them to unrefine to the industry level
  const inCuratedShopLowerLevelThanIndustry = (activeCategory || activeDepartment) && (activeCurator || activeCuratorGroup);
  if (inCuratedShopLowerLevelThanIndustry) {
    const department = activeDepartment || getDepartmentById(shop, activeCategory.Department_id);
    const industry = getIndustryById(shop, department.Industry_id);
    refinementPills.push({
      label: `Show all ${industry.name}`,
      unrefine: () => {
        scrollToTop();
        props.applyUnrefinement({
          labels: ['Category', 'Department', 'Industry'],
          unrefinement: { Category_id: null, Department_id: null, Industry_id: industry.id }
        });
      }
    });
  }

  // If we have a brand selected, allow them to unrefine to show all brands
  if (activeBrands?.length) {
    refinementPills.push({
      label: catalogLabel ? `Show all brands in ${catalogLabel}` : 'Show all brands',
      unrefine: () => {
        scrollToTop();
        props.applyUnrefinement({ label: 'Brand', unrefinement: { AllBrand_id: null } });
      }
    });
  }

  // Show a random few options from the next level of product refinements, use state to maintain randomization order
  const [nextRefinementObjectsShuffled, setNextRefinementObjectsShuffled] = React.useState([]);
  const nextRefinementLevel = props.productRefinements[0];
  const nextRefinementObjects = nextRefinementLevel?.items || [];
  const refinementReshuffleHash = nextRefinementObjects[0]
    ? catalogLabel + JSON.stringify(nextRefinementObjects[0].refinement || {}) + nextRefinementObjects[0].label
    : null;
  React.useEffect(() => {
    if (!refinementReshuffleHash) setNextRefinementObjectsShuffled([]);
    else {
      const activeRefinementValues = props.activeProductRefinements.map(o => o.displayValue);
      const potentialRefinements = nextRefinementObjects.filter(o => !activeRefinementValues.includes(o.label));
      setNextRefinementObjectsShuffled(_.shuffle(potentialRefinements).slice(0, 5));
    }
  }, [refinementReshuffleHash]);

  nextRefinementObjectsShuffled.forEach(o => {
    refinementPills.push({
      label: `Shop ${getPrettyNumber(o.count)} ${pluralize(o.label, o.count)}`,
      refine: () => {
        scrollToTop();
        props.applyRefinement({ label: nextRefinementLevel.label, refinement: o.refinement });
      }
    });
  });

  return (
    <div className='shop-results-show-more-options-container'>
      <div className='show-more-header'>Looking for more?</div>
      <div className='refinement-pills'>
        {refinementPills.map(data => {
          const select = data.onClick || data.refine || data.unrefine;
          return (
            <div onClick={select} key={data.label} className='refinement-pill'>
              <div className='refinement-label'>{data.label}</div>
              {!!data.count && <div className='refinement-count'>{data.count}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ShopResultsShowMoreOptions.propTypes = {
  shop: PropTypes.object.isRequired,

  applyUnrefinement: PropTypes.func.isRequired,
  applyRefinement: PropTypes.func.isRequired,
  setDiscoverFilters: PropTypes.func.isRequired,

  activeRefinementObjects: PropTypes.object.isRequired,
  productRefinements: PropTypes.array.isRequired
};

export default withRouter(ShopResultsShowMoreOptions);
