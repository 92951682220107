import React from 'react';
import MetaTags from 'react-meta-tags';

import { getCoverImageForGroup } from '../../../Helpers/shop_helpers';
import { getNameWithS } from '../../../Helpers/user_helpers';

const ShopMetaTags = props => {
  const { activeCurator, activeCuratorGroup, activeIndustry, activeDepartment, activeCategory, activeBrands } = props?.activeRefinementObjects || {};

  // Get parts for more specific titles and descriptions
  const hierarchyName = activeCategory?.name || activeDepartment?.name || activeIndustry?.name;
  const brandFilterName = activeBrands?.map(b => b.name).join(', ');
  const hierarchyPart = hierarchyName ? ` in ${hierarchyName}` : '';
  const brandPart = brandFilterName ? ` featuring ${brandFilterName}` : '';

  let title, ogTitle, description, ogDescription, ogImage;
  if (activeCurator) {
    // Single curator scenario
    const nameWithS = getNameWithS(activeCurator);
    title = `${nameWithS.full} Must-Have Picks${hierarchyPart}${brandPart} | ShopMy`;
    ogTitle = title;
    description = `Explore ${nameWithS.full} handpicked favorites${hierarchyPart}${brandPart}.`;
    ogDescription = description;
    ogImage = activeCurator.image;
  } else if (activeCuratorGroup) {
    const nameWithS = getNameWithS({ name: activeCuratorGroup.title });
    title = `${nameWithS.full} Handpicked Recommendations${hierarchyPart}${brandPart} | ShopMy`;
    ogTitle = title;
    description = `Don't miss out on ${activeCuratorGroup.title} top product recommendations${hierarchyPart}${brandPart}. Shop curated picks from multiple experts.`;
    ogDescription = description;
    ogImage = getCoverImageForGroup(activeCuratorGroup);
  } else {
    title = `Top-Rated Finds${hierarchyPart}${brandPart} by Leading Curators | ShopMy`;
    ogTitle = title;
    description = `Discover the best products${hierarchyPart}${brandPart}, carefully curated by our top experts. Shop now.`;
    ogDescription = description;
  }

  return (
    <MetaTags>
      {title && <title>{title}</title>}
      {ogTitle && <meta property='og:title' content={ogTitle} />}
      {description && <meta name='description' content={description} />}
      {ogDescription && <meta property='og:description' content={ogDescription} />}
      {ogImage && <meta property='og:image' content={ogImage} />}
    </MetaTags>
  );
};

export default ShopMetaTags;
