import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './OpportunityListingAdvancedSettings.scss';

import { opportunityMetricFocusOptions, opportunityConstants } from '../../../Helpers/opportunity_helpers';
import { convertToNumber } from '../../../Helpers/formatting';

import Tooltip from '../../General/Tooltip';

const OpportunityListingAdvancedSettings = props => {
  const { opportunity, maxBudget, daysToReviewUponCompletion, acceptableDomains, successMetrics, updateField } = props;

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleCollapsed = () => setIsCollapsed(!isCollapsed);

  const isMetricSelected = metric => successMetrics.split(',').includes(metric.value);

  const resultsInReview = opportunity.results?.filter(result => result.isInReview) || [];

  const settings = [
    {
      title: 'Max Budget',
      description:
        'Set a maximum budget for this opportunity. Once you hit this budget, no more creators will be able to accept the opportunity and you will not be able to send any more requests.',
      input: {
        value: maxBudget ? `$${commaNumber(maxBudget)}` : '',
        placeholder: '$50,000',
        updateVal: val => updateField('maxBudget', convertToNumber(val))
      }
    },
    {
      title: 'Days To Review Upon Completion',
      description:
        'If you have extended review requirements you can set this to give yourself time to confirm the content. We heavily recommend against using a review window longer than 7 days.',
      input: {
        value: daysToReviewUponCompletion,
        disabled: !!resultsInReview.length,
        disabledMessage: `You have ${resultsInReview.length} request${
          resultsInReview.length === 1 ? '' : 's'
        } in review and cannot change the duration until ${resultsInReview.length === 1 ? 'it' : 'they'} are completed.`,
        placeholder: '3 days',
        updateVal: val => updateField('daysToReviewUponCompletion', convertToNumber(val))
      }
    },
    {
      title: 'Acceptable Domains',
      description:
        'If you have specific domains (website.com) you want to allow for linking, you can specify them here. If left blank, all domains will be allowed. Separate them with commas.',
      input: {
        value: acceptableDomains,
        className: 'wide',
        placeholder: 'domain.com',
        hasErrorMessage: acceptableDomains.includes('/')
          ? 'Please enter only the domain name without the protocol or path (example: sephora.com)'
          : null,
        updateVal: val => {
          updateField('acceptableDomains', val.split(' ').join(''));
        }
      }
    },
    {
      title: 'Success Metrics',
      description:
        'If you have specific metrics you want to track for this opportunity, you can specify them here. We will show this to the talent to help them understand what you are looking for.',
      multiselect: {
        options: opportunityMetricFocusOptions,
        isOptionSelected: isMetricSelected,
        toggle: metric => {
          const metrics = successMetrics.split(',');
          const newMetrics = isMetricSelected(metric) ? metrics.filter(m => m !== metric.value) : [...metrics, metric.value];
          updateField('successMetrics', newMetrics.join(','));
        }
      }
    },
    {
      title: 'Links That Count',
      description:
        'By default, all links generated by creators will count towards the total number of links they are allowed to send. If you want to exclude certain links from counting towards this total, you can specify them here.',
      select: {
        options: [
          { value: { onlyCountQuickLinks: false }, display: 'All Links' },
          { value: { onlyCountQuickLinks: true }, display: 'Quick Links Only' }
        ],
        isOptionSelected: option => !!option.value.onlyCountQuickLinks === !!opportunity.onlyCountQuickLinks,
        toggle: option => updateField('onlyCountQuickLinks', option.value.onlyCountQuickLinks)
      }
    },
    {
      title: 'Orders That Count',
      description:
        'By default, orders must be driven by a link. If you want to also track orders that are driven by a code, you can enable this option.',
      select: {
        options: [
          { value: { allowCodeOnlyOrders: false }, display: 'Link Only' },
          { value: { allowCodeOnlyOrders: true }, display: 'Links + Codes' }
        ],
        isOptionSelected: option => !!option.value.allowCodeOnlyOrders === !!opportunity.allowCodeOnlyOrders,
        toggle: option => updateField('allowCodeOnlyOrders', option.value.allowCodeOnlyOrders)
      }
    },
    {
      title: 'Instagram Stories That Count',
      description: `By default, we count all frames. If you want to change this, please select a new option below.`,
      select: {
        options: [
          { value: { overrideMinutesBetweenStoriesToCountAsOne: 0 }, display: 'No Minimum Duration, count all frames' },
          { value: { overrideMinutesBetweenStoriesToCountAsOne: 5 }, display: '5 Minutes' },
          { value: { overrideMinutesBetweenStoriesToCountAsOne: 30 }, display: '30 Minutes' },
          { value: { overrideMinutesBetweenStoriesToCountAsOne: null }, display: '1 Hour' }
        ],
        isOptionSelected: option => {
          return option.value.overrideMinutesBetweenStoriesToCountAsOne === opportunity.overrideMinutesBetweenStoriesToCountAsOne;
        },
        toggle: option => updateField('overrideMinutesBetweenStoriesToCountAsOne', option.value.overrideMinutesBetweenStoriesToCountAsOne)
      }
    }
  ];

  return (
    <div className={cn('opportunity-listing-section listing-advanced-settings-container', { collapsed: isCollapsed })}>
      <div className='opportunity-listing-section-title-container' onClick={toggleCollapsed}>
        <div className='opportunity-listing-section-title'>Advanced Settings</div>
        <div className='toggle-collapsed'>
          <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
        </div>
      </div>
      {!isCollapsed && (
        <div className='settings-sections'>
          {settings.map((setting, idx) => {
            const { title, description, input, multiselect, select } = setting;
            const selectOrMultiselect = multiselect || select;
            return (
              <div key={idx} className='settings-section'>
                <div className='settings-section-title-container'>
                  <div className='settings-section-title'>{title}</div>
                </div>
                {description && <div className='settings-section-description'>{description}</div>}
                <div className='settings-section-input'>
                  {input && (
                    <Tooltip message={input.disabled && input.disabledMessage}>
                      <input
                        disabled={input.disabled}
                        className={cn(input.className || '', { error: !!input.hasErrorMessage })}
                        placeholder={input.placeholder}
                        value={input.value}
                        onChange={e => input.updateVal(e.target.value)}
                      />
                      {input.hasErrorMessage && <div className='error-message'>{input.hasErrorMessage}</div>}
                    </Tooltip>
                  )}
                  {selectOrMultiselect && (
                    <div className='select-options'>
                      {selectOrMultiselect.options.map((option, index) => {
                        const isSelected = selectOrMultiselect.isOptionSelected(option);
                        const toggle = () => selectOrMultiselect.toggle(option);
                        return (
                          <div
                            className={cn('option-container', {
                              selected: isSelected
                            })}
                            onClick={toggle}
                            key={index}
                          >
                            <div className='checkbox'>
                              <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className='display'>{option.display}</div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

OpportunityListingAdvancedSettings.propTypes = {
  opportunity: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  maxBudget: PropTypes.string.isRequired,
  daysToReviewUponCompletion: PropTypes.string.isRequired,
  acceptableDomains: PropTypes.string.isRequired,
  successMetrics: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired
};

export default OpportunityListingAdvancedSettings;
