import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import moment from 'moment';
import './ShopCollectionResult.scss';

import { getPinsForCuratorCollection } from '../../../APIClient/shop';

import { getShortPinLink } from '../../../Helpers/attribution_helpers';
import { getUserId } from '../../../Helpers/user_helpers';
import { getPrettyTimeAgoFromNow } from '../../../Helpers/formatting';
import { getLinkToProductPageViaId, getLinkToShopForUsername, getActiveCuratorGroup } from '../../../Helpers/shop_helpers';

import SmartImage from '../../General/SmartImage';

const ShopCollectionResult = props => {
  const { previewType, collection } = props;
  const { preview_pins, total_num_pins } = collection;

  const linkToCollection = `/shop/collections/${collection.id}`;
  const linkToCuratorShop = getLinkToShopForUsername(collection.User_username, { tab: 'collections' });
  const showCollectionUserName = !!getActiveCuratorGroup(props.shop)?.id;

  // Allow them to show more pins
  const [fetchingAllPins, setFetchingAllPins] = React.useState(false);
  const [allPins, setAllPins] = React.useState([]);
  const [numVisiblePins, setNumVisiblePins] = React.useState(8);
  const hasMorePins = total_num_pins > numVisiblePins;
  const showMorePins = async () => {
    if (fetchingAllPins) return;
    !allPins.length && (await fetchAllPins());
    setNumVisiblePins(numVisiblePins + 12);
  };

  // Decide visible pins
  const visiblePins = allPins.length
    ? allPins.slice(0, collection.image ? numVisiblePins - 1 : numVisiblePins)
    : preview_pins.slice(0, collection.image ? numVisiblePins - 1 : numVisiblePins);

  const fetchAllPins = async () => {
    setFetchingAllPins(true);
    try {
      const resp = await getPinsForCuratorCollection(collection.id);
      setAllPins(resp.pins);
    } catch (e) {
      console.error(e);
    }
    setFetchingAllPins(false);
  };

  // Smooth Animation of Images
  const [postImageLoaded, setPostImageLoaded] = React.useState(false);
  const onPostImageLoad = () => {
    setTimeout(() => setPostImageLoaded(true), 100);
  };

  // Get metadata
  let metadata = [];
  const isMyCollection = collection.User_id === getUserId(props.user);
  if (collection.User_name && showCollectionUserName) metadata.push(collection.User_name);
  metadata.push(`${total_num_pins} Product${total_num_pins !== 1 ? 's' : ''}`);

  const updatedThisWeek = moment(collection.lastUpdated).isAfter(moment().subtract(7, 'days'));
  updatedThisWeek && metadata.push(`Updated ${getPrettyTimeAgoFromNow(collection.lastUpdated, { longForm: true })}`);

  // if (['quick-shop', 'shelves', 'gift_guides'].includes(previewType)) {
  //   return (
  //     <div className='shop-collection-result quick-shop'>
  //       <div className='collection-result-header-container'>
  //         <div className='result-data'>
  //           {collection.User_image && showCollectionUserName && (
  //             <Link to={linkToCuratorShop} className='image-container'>
  //               <img src={collection.User_image} alt={collection.User_name} />
  //             </Link>
  //           )}
  //           <div className='metadata-container'>
  //             <h3 className='title'>{collection.name}</h3>
  //             <h4 className='subtitle'>{metadata.join(' • ')}</h4>
  //           </div>
  //         </div>
  //         <div className='result-actions'>
  //           <Link to={linkToCollection} className='action'>
  //             View Full Collection
  //           </Link>
  //         </div>
  //       </div>
  //       <div className='preview-pins'>
  //         {collection.image && (
  //           <Link to={linkToCollection} className='collection-image-container'>
  //             <img src={collection.image} alt={collection.name} />
  //           </Link>
  //         )}
  //         {visiblePins.slice(0, collection.image ? numVisiblePins - 1 : numVisiblePins).map(pin => {
  //           const linkTo = pin.Product_id
  //             ? getLinkToProductPageViaId(pin.Product_id, {
  //                 User_id: collection.User_id,
  //                 Pin_id: pin.id
  //               })
  //             : getShortPinLink(pin);

  //           const wrapInLink = el => {
  //             if (pin.Product_id) {
  //               return (
  //                 <Link key={pin.id} to={linkTo} className='preview-pin'>
  //                   {el}
  //                 </Link>
  //               );
  //             } else {
  //               return (
  //                 <a key={pin.id} href={linkTo} target='_blank' rel='noopener noreferrer' className='preview-pin' key={pin.id}>
  //                   {el}
  //                 </a>
  //               );
  //             }
  //           };

  //           return wrapInLink(
  //             <>
  //               <div className='image-container'>
  //                 <img src={pin.image} alt={pin.name} />
  //               </div>
  //               <div className='data'>
  //                 {pin.Product_brand && <div className='brand'>{pin.Product_brand}</div>}
  //                 <div className='title'>{pin.title}</div>
  //                 {pin.Product_category && <div className='category'>{pin.Product_category}</div>}
  //               </div>
  //             </>
  //           );
  //         })}
  //       </div>
  //       {hasMorePins && (
  //         <div className='show-more-container'>
  //           <div className={cn('show-more', { fetching: fetchingAllPins })} onClick={showMorePins}>
  //             {fetchingAllPins ? 'Getting more...' : 'Show more'}
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // }

  const additionalClasses = cn(`column-${props.columnIdx}`, { 'image-loaded': postImageLoaded });
  if (['post', 'post_vertical', 'post_horizontal', 'posts'].includes(previewType)) {
    const previewImage = collection.image || collection.preview_pins[0]?.image;
    return (
      <div className={cn('shop-collection-result post', collection.Section_previewType, { 'has-image': !!previewImage })}>
        <Link to={`/shop/collections/${collection.id}`}>
          <>
            <div to={`/shop/collections/${collection.id}`} className={cn('image-container', additionalClasses)}>
              <img onLoad={onPostImageLoad} className={cn('cover-img', additionalClasses)} src={previewImage} alt={collection.name} />
            </div>
            <div className={cn('data-container', additionalClasses)}>
              {showCollectionUserName && <div className='title'>{collection.User_name}</div>}
              <h4 className='subtitle'>{getPrettyTimeAgoFromNow(collection.createdAt, { longForm: true })}</h4>
            </div>
          </>
        </Link>
      </div>
    );
  }

  if (['shelf', 'large_preview', 'editorial', 'quick-shop', 'shelves', 'gift_guides'].includes(previewType)) {
    return (
      <Link to={linkToCollection} className='shop-collection-result shelf'>
        <div className='preview-pins'>
          {visiblePins.slice(0, 5).map((pin, idx) => {
            const fadeInDelay = idx * 100;
            return (
              <div key={pin.id} className='preview-pin'>
                <div className='image-container'>
                  <SmartImage fadeInDelay={fadeInDelay} src={pin.image} alt={pin.name} />
                </div>
              </div>
            );
          })}
        </div>
        <div className='collection-result-body-container'>
          <div className='result-data'>
            {showCollectionUserName && collection.User_image && !isMyCollection && (
              <Link to={linkToCuratorShop} className='image-container'>
                <img src={collection.User_image} alt={collection.User_name} />
              </Link>
            )}
            <div className='metadata-container'>
              <h3 className='title'>{collection.name}</h3>
              <h4 className='subtitle'>{metadata.join(' • ')}</h4>
            </div>
          </div>
          <div className='go-to-collection'>
            View Full Collection
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className='shop-collection-preview-container'>
      <h1>Unknown Type: {previewType}</h1>
    </div>
  );
};

ShopCollectionResult.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  columnIdx: PropTypes.number.isRequired,
  previewType: PropTypes.string.isRequired,
  collection: PropTypes.object.isRequired
};

export default ShopCollectionResult;
