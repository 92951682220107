import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './ProductMainSection.scss';

import SortableList from '../General/SortableList';
import ProductHeart from '../Shop/Elements/ProductHeart';

import { updateProductImage } from '../../APIClient/products';
import { deleteUserProductByProductId, createUserProductByProductId } from '../../Actions/ShopActions';

import {
  getLinkToBrandPageViaId,
  getLinkToCategoryPageViaId,
  getAttributableCuratorId,
  getAttributableCuratorGroupId
} from '../../Helpers/shop_helpers';
import { getPrettyTimeAgoFromNow } from '../../Helpers/formatting';
import { addVariablesToShopAffiliateLink } from '../../Helpers/attribution_helpers';
import { getGMTTime } from '../../Helpers/formatting';

const ProductMainSection = props => {
  const { shop, user, product, isFetchingFull } = props;
  const { category, brand, description } = product || {};
  const links = product?.links || [];
  const other_images = _.orderBy(product?.images || [], ['isCover', 'sortOrderRank'], ['desc', 'asc']);

  // Use ID it in React so we can update the state
  const [featuredProductImageId, setFeaturedProductImageId] = React.useState(null);
  const featuredProductImage = other_images.find(p => p.id === featuredProductImageId) || other_images[0];
  const setFeaturedProductImage = productImage => setFeaturedProductImageId(productImage.id);
  React.useEffect(() => {
    if (!isFetchingFull && !featuredProductImage) setFeaturedProductImageId(other_images[0]);
  }, [isFetchingFull]);

  // Description (brand names can have periods so need to cast it to a variable)
  const sentences = description?.replaceAll(brand?.name, 'BRANDNAME')?.split('. ') || [];
  const firstSentence = (sentences[0] + '. ').replace('BRANDNAME', brand?.name);
  const restOfDescription = description?.replace(firstSentence, '').replace('BRANDNAME', brand?.name);

  // Handle Primary Selection
  const dropSort = async (productImage, updates) => {
    await updateProductImage(productImage, { ...updates, isManuallyOrdered: true });
    await props.syncProduct();
  };

  const hideProductImage = async productImage => {
    const curIndex = other_images.findIndex(p => p.id === productImage.id);
    await updateProductImage(productImage, { isHidden: true });
    await props.syncProduct();
    setFeaturedProductImage(other_images[curIndex + 1] || other_images[curIndex - 1]);
    window.ALERT.success(`Image removed successfully.`);
  };

  // Allow Editing
  const [isEditing, setIsEditing] = React.useState(false);
  const toggleEditing = () => setIsEditing(!isEditing);

  // Handle sort dropping
  const [isEditingOrder, setIsEditingOrder] = React.useState(false);
  const toggleEditOrder = () => setIsEditingOrder(!isEditingOrder);
  const getOtherImageCard = (productImage, additionalProps = {}) => {
    const { rearrangeHandle } = additionalProps;
    const { image, id, style, isCover, isHidden } = productImage;
    const isFeatured = id === featuredProductImage.id;
    const select = () => setFeaturedProductImage(productImage);
    return (
      <div className={cn('product-main-section-other-image-container item', style, { featured: isFeatured, hidden: isHidden })} key={productImage.id}>
        <div className='actions'>{rearrangeHandle}</div>
        <img
          onClick={select}
          loading={isCover ? 'eager' : 'lazy'}
          className={cn('other-image', { featured: isFeatured })}
          src={image}
          alt={product?.title}
        />
      </div>
    );
  };

  // Handle Image Issues
  const [brokenImages, setBrokenImages] = React.useState([]);
  const imageBroke = image => setBrokenImages([...brokenImages, image]);
  const visibleOtherImages = other_images.filter(p => !brokenImages.includes(p.image));

  // Admin Actions
  let adminActions = [];
  if (isEditing) {
    adminActions.push(
      {
        display: 'Delete Image',
        extraClass: 'delete',
        action: () => hideProductImage(featuredProductImage),
        isHidden: isEditingOrder
      },
      {
        display: 'Change Image Style',
        action: async () => {
          await updateProductImage(featuredProductImage, {
            style: featuredProductImage.style === 'cover' ? 'contain' : 'cover'
          });
          await props.syncProduct();
        },
        isHidden: isEditingOrder
      },
      {
        display: 'Make Primary Image',
        action: async () => {
          await updateProductImage(featuredProductImage, { isCover: true, isManuallyOrdered: true, sortOrderRank: getGMTTime() * -1 });
          await props.syncProduct();
        }
      },
      {
        display: isEditingOrder ? 'Done Reordering' : 'Reorder',
        extraClass: 'reorder',
        action: toggleEditOrder
      },
      {
        display: 'Done Editing',
        action: toggleEditing,
        isHidden: isEditingOrder
      }
    );
  } else {
    adminActions.push({
      display: 'Edit',
      action: toggleEditing
    });
  }
  adminActions = adminActions.filter(action => !action.isHidden);

  // Beautiful Image Loading
  const [primaryImageLoaded, setPrimaryImageLoaded] = React.useState(false);
  const onPrimaryImageLoad = () => {
    setTimeout(() => {
      setPrimaryImageLoaded(true);
    }, 250); // Smooth Loading (time with CSS classes below)
  };

  // Reset animation on product ID change
  const prevProductId = React.useRef(product?.id); // To handle hot reload issues
  React.useEffect(() => {
    if (product?.id !== prevProductId.current) setPrimaryImageLoaded(false);
    prevProductId.current = product?.id;
  }, [product?.id]);

  const additionalClasses = cn({ fetching: isFetchingFull }, featuredProductImage?.style || '');
  return (
    <div className='product-main-section-container'>
      <div className='product-data-outer-container'>
        <div className='product-data-inner-container'>
          <div className={cn('main', additionalClasses)}>
            {isFetchingFull ? (
              <>
                <div className='metadata loading' />
                <div className='brand loading' />
                <div className='title loading' />
              </>
            ) : (
              <>
                <div className='metadata'>
                  <Link to={getLinkToBrandPageViaId(brand?.id)} className='clickable fade-in first'>
                    {brand?.name || product?.brand}
                  </Link>
                  {category && <span className='separator fade-in first'>•</span>}
                  {category && (
                    <Link to={getLinkToCategoryPageViaId(category.id)} className='clickable fade-in first'>
                      {category.name}
                    </Link>
                  )}
                </div>
                <h1 onClick={() => window.__ADMIN_CONTROL_MODE__ && props.syncProduct()} className='title fade-in second'>
                  {product?.title}
                </h1>
                {!!product?.circleCuratorCount && (
                  <div className='my-circle fade-in second' onClick={props.scrollToMyCircle}>
                    Favorited by {product.circleCuratorCount} in your circles
                  </div>
                )}
              </>
            )}
            {product && !isFetchingFull && (
              <div className='favorite-icon fade-in second'>
                <ProductHeart
                  size={24}
                  product={product}
                  user={user}
                  attribution={{
                    Curator_id: getAttributableCuratorId(shop),
                    CuratorGroup_id: getAttributableCuratorGroupId(shop)
                  }}
                />
              </div>
            )}
          </div>
          {isFetchingFull ? (
            <div className='body'>
              <div className='description emphasize loading' />
              <div className='description normal loading' />
            </div>
          ) : (
            description && (
              <h2 className='body'>
                <div className={cn('description emphasize fade-in third')}>{firstSentence}</div>
                <div className={cn('description normal fade-in fourth')}>{restOfDescription}</div>
              </h2>
            )
          )}
          {isFetchingFull ? (
            <div className='links-and-favorite loading' />
          ) : (
            <div className='links-and-favorite fade-in fifth'>
              {links?.map((link, index) => {
                const { merchant, affiliate_link, createdAt } = link;
                const adjLink = addVariablesToShopAffiliateLink(affiliate_link || link.link, { product, shop, user });
                return (
                  <>
                    <a className='link' key={link} href={adjLink} target='_blank' rel='noopener noreferrer'>
                      {merchant ? `Shop now at ${merchant.name}` : 'Shop now'}
                      <div className='time-ago'>Link from {getPrettyTimeAgoFromNow(createdAt, { longForm: true })}</div>
                    </a>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={cn('product-images-container', additionalClasses)}>
        <div className={cn('primary-image-container', additionalClasses)}>
          {featuredProductImage && (
            <img
              onLoad={onPrimaryImageLoad}
              className={cn('primary-image', { loaded: primaryImageLoaded }, featuredProductImage?.style)}
              loading='eager'
              src={featuredProductImage.image}
              alt={product?.title}
            />
          )}
          {window.__ADMIN_CONTROL_MODE__ && (
            <div className='admin-controls'>
              {adminActions.map(({ display, action, extraClass }) => (
                <div key={display} className={cn('control-btn', extraClass)} onClick={action}>
                  {display}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='other-images'>
          {isEditingOrder ? (
            <SortableList
              isEditing
              containerClassName='other-images'
              useSortableHandleDefault
              axis='x'
              items={other_images}
              updateItem={dropSort}
              getCard={getOtherImageCard}
            />
          ) : isFetchingFull ? (
            _.range(4).map(index => (
              <div className='product-main-section-other-image-container loading' key={index}>
                <div className='other-image loading' />
              </div>
            ))
          ) : (
            visibleOtherImages.map((productImage, index) => {
              const { image, style, id } = productImage;
              const isFeatured = id === featuredProductImage?.id;
              const select = () => setFeaturedProductImage(productImage);
              return (
                <div className={cn('product-main-section-other-image-container', { featured: isFeatured })} key={id}>
                  <img
                    onClick={select}
                    loading={index <= 6 ? 'eager' : 'lazy'}
                    className={cn('other-image', style, { featured: isFeatured })}
                    onLoad={e => (e.target.naturalWidth < 250 || e.target.naturalHeight < 250) && imageBroke(image)}
                    onError={e => imageBroke(image)}
                    src={image}
                    alt={product?.title}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

ProductMainSection.propTypes = {
  // From Outside
  product: PropTypes.object,
  isFetchingFull: PropTypes.bool.isRequired,
  syncProduct: PropTypes.func.isRequired,
  scrollToMyCircle: PropTypes.func.isRequired,
  scrollToCurators: PropTypes.func.isRequired,

  // From Inside
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  deleteUserProductByProductId: PropTypes.func.isRequired,
  createUserProductByProductId: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  deleteUserProductByProductId,
  createUserProductByProductId
})(ProductMainSection);
