import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './Talent.scss';

import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';

import { isBrand, isShopper, isLoggedIn, getBrandId } from '../../Helpers/user_helpers';
import {
  getTalent,
  getTalentActionEnumDetails,
  getTalentStatusEnumDetails,
  getVisibleTalentActionEnum,
  isFetchingInitialTalent,
  isTopTalent
} from '../../Helpers/talent_helpers';
import { setUrlParam, removeUrlParam, getUrlParam } from '../../Helpers/helpers';
import { getUIKeyValueTalent, isAdminControlMode } from '../../Helpers/ui_helpers';
import { getGiftingRequestsForLookbookId } from '../../Helpers/brand_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import usePageLoadEvent from '../../CustomHooks/usePageLoadEvent';

import { updateRequest } from '../../Actions/UserActions';
import { augmentBrandLists, updateBrandList, deleteBrandList } from '../../Actions/BrandActions';
import { createSamplesRequest, setCustomCode, setCustomCommissionRate, updateCode } from '../../Actions/AnalyticsActions';
import { getTalent as syncTalent, syncTalentResult } from '../../Actions/TalentActions';
import { getRecommendedArtists as syncRecommendedArtists, deleteRecommendedArtist } from '../../Actions/RecommendationActions';
import { updateChat } from '../../Actions/ChatActions';
import {
  openChatOverlay,
  openArtistModal,
  setUIKeyValue,
  openBulkTalentModal,
  openCodesModal,
  openRequestModal,
  openFulfillmentModal
} from '../../Actions/UIActions';

import TalentSidebar from '../../Components/Talent/TalentSidebar';
import TalentResults from '../../Components/Talent/TalentResults';
import TalentControls from '../../Components/Talent/TalentControls';

import Loader from '../../Components/Loader/Loader';

const Discover = props => {
  const { ui, user, talent, analytics, openBulkTalentModal } = props;
  const Brand_id = getBrandId(user);
  const isFetchingInitial = isFetchingInitialTalent(talent);
  const showTopTalentOnly = !!getUIKeyValueTalent(ui, 'showTopTalentOnly');
  const showBottomTalentOnly = !!getUIKeyValueTalent(ui, 'showBottomTalentOnly') && isAdminControlMode(ui);

  const [showTalentDetails, setShowTalentDetails] = useState(false);
  const [curSearchVal, setCurSearchVal] = useState(getUrlParam('query') || '');
  const [sortOrder, setSortOrder] = useState(getUrlParam('order') || null);
  const [sortDirection, setSortDirection] = useState(getUrlParam('direction') || 'desc');
  const [curActionEnum, setCurActionEnum] = useState(getUrlParam('action') || null);
  const [curStatusEnum, setCurStatusEnum] = useState(getUrlParam('status') || null);
  const [curListId, setCurListId] = useState(getUrlParam('listid') ? parseInt(getUrlParam('listid')) : null);
  const [curLookbookId, setCurLookbookId] = useState(getUrlParam('lookbookid') ? parseInt(getUrlParam('lookbookid')) : null);

  useEffect(() => {
    Brand_id && window.__ADD_EVENT__('Talent - Page View', { user });
  }, [Brand_id]);

  // Adjust URL parameters to save state on navigation changes
  useEffect(() => {
    curActionEnum !== null ? setUrlParam('action', curActionEnum) : removeUrlParam('action'); // if not default, save it
    curStatusEnum !== null ? setUrlParam('status', curStatusEnum) : removeUrlParam('status'); // if not default, save it
    curListId !== null ? setUrlParam('listid', curListId) : removeUrlParam('listid'); // if not default, save it
    curLookbookId !== null ? setUrlParam('lookbookid', curLookbookId) : removeUrlParam('lookbookid'); // if not default, save it
    sortOrder !== null ? setUrlParam('order', sortOrder) : removeUrlParam('order'); // if not default, save it
    sortDirection !== 'desc' ? setUrlParam('direction', sortDirection) : removeUrlParam('direction'); // if not default, save it
  }, [curActionEnum, curStatusEnum, curListId, curLookbookId, sortOrder, sortDirection]);

  // Eventing
  usePageLoadEvent('Talent - View Page');

  if (!isLoggedIn(user)) return <RequiresLoginPanel />;
  if (isShopper(user)) return <RequiresNonShopperLoginPanel />;
  if (!isBrand(user)) return <RequiresBrandLoginPanel />;

  // Get the requests based on the filter state
  const results = getTalent(talent);

  // Helpers to improve performance of lookups within the filtering
  const selectedLookbookUserIdsSet = new Set(_.map(curLookbookId ? getGiftingRequestsForLookbookId(analytics, curLookbookId) : [], 'User_id'));

  let filteredResults = _.filter(results, result => {
    // Check that it passes search
    if (curSearchVal) {
      const partsOfSearch = curSearchVal.split(' ');
      if (partsOfSearch.some(part => !`${result.name}-${result.username}`.toLowerCase().includes(part.toLowerCase()))) return false;
    }

    // Check that it passes action filters
    if (curActionEnum) {
      const aed = getTalentActionEnumDetails(curActionEnum);
      if (aed.talentFilterForceOverride && !aed.talentFilterForceOverride(result)) return false;
      if (!aed.talentFilterForceOverride && !result.actionEnums?.includes(curActionEnum)) return false;
    }

    // Check that it passes status filters or additional allowable filter
    if (curStatusEnum) {
      const sed = getTalentStatusEnumDetails(curStatusEnum);
      if (sed.talentFilterForceOverride && !sed.talentFilterForceOverride(result)) return false;
      if (!sed.talentFilterForceOverride && !result.statusEnums?.includes(curStatusEnum)) return false;
    }

    // Check that it passes list filters
    if (curListId) {
      const isInRecommendedList = result.recommendation?.BrandList_id === curListId;
      const isInBrandList = !!result.listUsers?.find(l => l.BrandList_id === curListId);
      if (!isInRecommendedList && !isInBrandList) return false;
    }

    if (curLookbookId) {
      return selectedLookbookUserIdsSet.has(result.id);
    }

    // If we are not filtered to a list, and have the talent filter on, ensure we only show top talent
    if (!curListId && !curLookbookId && !curSearchVal && showTopTalentOnly && !isTopTalent(result)) return false;
    if (!curListId && !curLookbookId && !curSearchVal && showBottomTalentOnly && isTopTalent(result)) return false;

    // Ensure we only show admin controls to admins
    const hasNonAdminAction = result.statusEnums.find(ae => !getTalentStatusEnumDetails(ae)?.isAdmin);
    const onlyAdminActions = !hasNonAdminAction;
    if (onlyAdminActions && !isAdminControlMode(ui)) return false;
    return true;
  });

  // Sort them
  filteredResults = sortOrder
    ? _.orderBy(filteredResults, sortOrder, sortDirection)
    : _.orderBy(
        filteredResults,
        [
          r => {
            // If filtering by list, order by score
            if (curListId || curLookbookId) return r.score * -1;

            // TODO: contract recommendations commented out for now
            // if (curActionEnum === 'RESPOND_TO_COLLABORATION_PROPOSAL') {
            //   return (r.contract.accept_confidence ?? 0) * -1;
            // }

            // Sort by whether there is action required
            const visibleAE = getVisibleTalentActionEnum({ actionEnums: r.actionEnums, curActionEnum, curStatusEnum });
            const aed = getTalentActionEnumDetails(visibleAE);
            if (aed.showAlert) return aed.rank;
            if (aed.hasAction) return aed.rank + 1e3;
            return aed.rank + 1e4;
          },
          r => r.score
        ],
        ['asc', 'desc']
      );

  const updateSearchVal = newVal => {
    setCurSearchVal(newVal);
    setCurActionEnum(null);
    setCurStatusEnum(null);
    setCurListId(null);
    setCurLookbookId(null);
  };

  const clearFiltersAndSearch = () => {
    setCurSearchVal('');
    setCurActionEnum(null);
    setCurStatusEnum(null);
    setCurListId(null);
    setCurLookbookId(null);
  };

  return (
    <>
      <div className='talent-outer-container'>
        {insertMetaTags({
          title: 'My Talent',
          description: '',
          image: ''
        })}
        {isFetchingInitial ? (
          <div className='talent-inner-container'>
            <div className='talent-loading-container'>
              <Loader size={120} />
            </div>
          </div>
        ) : (
          <>
            <div className={cn('talent-inner-container', { 'with-details': showTalentDetails })}>
              <TalentControls
                ui={ui}
                user={user}
                showTalentDetails={showTalentDetails}
                setShowTalentDetails={setShowTalentDetails}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                curSearchVal={curSearchVal}
                updateSearchVal={updateSearchVal}
                setUIKeyValue={props.setUIKeyValue}
                showTopTalentOnly={showTopTalentOnly}
                showBottomTalentOnly={showBottomTalentOnly}
              />
              <div className={cn('talent-left-container', { 'with-details': showTalentDetails })}>
                <TalentSidebar
                  ui={ui}
                  user={user}
                  results={results}
                  talent={talent}
                  analytics={analytics}
                  curActionEnum={curActionEnum}
                  curStatusEnum={curStatusEnum}
                  curListId={curListId}
                  curLookbookId={curLookbookId}
                  setCurActionEnum={setCurActionEnum}
                  setCurStatusEnum={setCurStatusEnum}
                  openBulkTalentModal={openBulkTalentModal}
                  openRequestModal={props.openRequestModal}
                  setCurListId={setCurListId}
                  setCurLookbookId={setCurLookbookId}
                  deleteBrandList={props.deleteBrandList}
                  updateBrandList={props.updateBrandList}
                  syncTalent={props.syncTalent}
                  showTopTalentOnly={showTopTalentOnly}
                  showBottomTalentOnly={showBottomTalentOnly}
                />
              </div>
              <div className={cn('talent-main-container', { 'with-details': showTalentDetails })}>
                <TalentResults
                  ui={ui}
                  user={user}
                  results={results}
                  filteredResults={filteredResults}
                  analytics={analytics}
                  talent={talent}
                  curListId={curListId}
                  curLookbookId={curLookbookId}
                  curActionEnum={curActionEnum}
                  curStatusEnum={curStatusEnum}
                  augmentBrandLists={props.augmentBrandLists}
                  syncTalentResult={props.syncTalentResult}
                  showTalentDetails={showTalentDetails}
                  openArtistModal={props.openArtistModal}
                  createSamplesRequest={props.createSamplesRequest}
                  updateRequest={props.updateRequest}
                  setCustomCode={props.setCustomCode}
                  updateCode={props.updateCode}
                  setCustomCommissionRate={props.setCustomCommissionRate}
                  updateChat={props.updateChat}
                  openChatOverlay={props.openChatOverlay}
                  openCodesModal={props.openCodesModal}
                  openRequestModal={props.openRequestModal}
                  openFulfillmentModal={props.openFulfillmentModal}
                  curSearchVal={curSearchVal}
                  clearFiltersAndSearch={clearFiltersAndSearch}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

Discover.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  syncTalent: PropTypes.func.isRequired,
  syncTalentResult: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,

  // Lists
  augmentBrandLists: PropTypes.func.isRequired,
  deleteBrandList: PropTypes.func.isRequired,
  updateBrandList: PropTypes.func.isRequired,

  // Recommendations
  syncRecommendedArtists: PropTypes.func.isRequired,
  deleteRecommendedArtist: PropTypes.func.isRequired,

  // Gifting
  createSamplesRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,

  // Codes
  setCustomCode: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,

  // Rates
  setCustomCommissionRate: PropTypes.func.isRequired,

  // Chat
  updateChat: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,

  // Action Modals
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,

  // General Help
  setUIKeyValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics, talent } = state;
  return { user, ui, analytics, talent };
};

export default connect(mapStateToProps, {
  syncTalent,
  syncTalentResult,
  syncRecommendedArtists,
  deleteRecommendedArtist,
  augmentBrandLists,
  updateBrandList,
  deleteBrandList,
  createSamplesRequest,
  updateRequest,
  setCustomCode,
  updateCode,
  setCustomCommissionRate,
  openArtistModal,
  setUIKeyValue,
  updateChat,
  openChatOverlay,
  openCodesModal,
  openRequestModal,
  openFulfillmentModal,
  openBulkTalentModal
})(Discover);
