import React from 'react';
import PropTypes from 'prop-types';
import './BrandCatalogAnnouncement.scss';

import ArrowButton from '../../General/Buttons/ArrowButton';

const BrandCatalogAnnouncement = props => {
  const pressActionOne = () => props.closeModal();
  const pressActionTwo = () => props.openChat(`Could we schedule a demo of SKU-Based Commission Rates?`);

  return (
    <div className='catalog-announcement-outer-container'>
      <div className='main-panel'>
        <div className='badge'>Now on ShopMy</div>
        <div className='title'>SKU-Based Commission Rates</div>
        <div className='body'>
          Take control of creator incentives with custom commission rates for individual products, helping you focus attention on key items — whether
          it's for a product launch, special collection, or limited-time promotion.
          <br />
          <br />
          Easily manage commission rates for specific products (or groups of products), apply site-wide rates, or offer creator-specific rates — all
          from one seamless dashboard. With this added flexibility, you can guide creators toward the products that matter most, incentivize them to
          promote your products, and run more focused, efficient campaigns with precision.
        </div>
        <div className='continue-btn-container'>
          <ArrowButton displayText='Explore Now' onClick={pressActionOne} />
          <ArrowButton displayText='Chat with Us' onlyText onClick={pressActionTwo} />
        </div>
      </div>
      <img src='https://static.shopmy.us/Website/Affiliates_PopUp.png' alt='brand-catalog' />
    </div>
  );
};

BrandCatalogAnnouncement.propTypes = {
  announcement: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired
};

export default BrandCatalogAnnouncement;
