import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Latest.scss';

import { getUserId, isLoggedIn, isShopper } from '../../Helpers/user_helpers';
import { isAdminControlMode, getUIKeyValueLatest } from '../../Helpers/ui_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import { openBrandModal, setUIKeyValue } from '../../Actions/UIActions';
import { syncLatest, setActiveTab, setActiveTag } from '../../Actions/LatestActions';

import LatestHeader from '../../Components/Latest/LatestHeader';
import LatestSections from '../../Components/Latest/LatestSections';
import Loader from '../../Components/Loader/Loader';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';

const Latest = props => {
  const { user, ui, latest } = props;

  // Data Fetching
  const User_id = getUserId(user);
  const showHidden = isAdminControlMode(ui);
  const syncNewsletter = async () => {
    await props.syncLatest({ showHidden });
  };
  useEffect(() => {
    syncNewsletter();
  }, [User_id, showHidden]);

  // New Badging Management
  const [lastViewedContent] = React.useState(getUIKeyValueLatest(ui, 'lastViewedContent'));
  useEffect(() => {
    props.setUIKeyValue('lastViewedContent', new Date(), 'latest');
    window.__ADD_EVENT__('Latest - View Page');
  }, []);

  if (!isLoggedIn(user)) return <RequiresLoginPanel />;
  if (isShopper(user)) return <RequiresNonShopperLoginPanel />;

  return (
    <div className='latest-outer-container'>
      {insertMetaTags({
        title: 'Latest on ShopMy',
        description: '',
        image: ''
      })}
      <div className='latest-inner-container'>
        <LatestHeader syncNewsletter={syncNewsletter} ui={ui} latest={latest} setActiveTab={props.setActiveTab} setActiveTag={props.setActiveTag} />
        {latest.newsletter ? (
          <LatestSections latest={latest} user={user} openBrandModal={props.openBrandModal} lastViewedContent={lastViewedContent} />
        ) : (
          <div className='loading-sections'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

Latest.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  latest: PropTypes.object.isRequired,
  syncLatest: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setUIKeyValue: PropTypes.func.isRequired,
  openBrandModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, latest } = state;
  return { user, ui, latest };
};

export default connect(mapStateToProps, {
  openBrandModal,
  syncLatest,
  setActiveTab,
  setActiveTag,
  setUIKeyValue
})(Latest);
