import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import './Catalog.scss';

import { getAllShopifyIntegrations, isBrand } from '../../Helpers/user_helpers';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';

import AnnouncementModal from '../../Components/Announcements/AnnouncementModal';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import CatalogControlBar from '../../Components/Catalog/CatalogControlBar';
import CatalogVariants from '../../Components/Catalog/CatalogVariants';
import CatalogProductModal from '../../Components/Catalog/CatalogProductModal';
import CatalogGroups from '../../Components/Catalog/CatalogGroups';
import CatalogDataLoader from '../../Components/Catalog/CatalogDataLoader';
import CatalogCommissionRates from '../../Components/Catalog/CatalogCommissionRates';
import { addCustomAffiliateRate, updateCustomAffiliateRate, deleteCustomAffiliateRate } from '../../Actions/AnalyticsActions';
import { updateBrandSettings } from '../../Actions/BrandActions';
import { getBrandCustomAffiliateRatesMap } from '../../Helpers/brand_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { isAffiliateOnlyPartnerBrand } from '../../Helpers/subscription_helpers';

const Catalog = props => {
  const {
    ui,
    user,
    analytics,
    isLoadingVariants,
    curVariants,
    curVariantsPage,
    setCurVariantsPage,
    numVariantsPages,
    numTotalVariants,
    catalogGroups,
    isLoadingCatalogGroups,
    fetchCatalogGroups,
    canSupportCustomRates,
    isCatalogSyncStatusInitialized,
    isSyncingCatalogGroups,
    isSyncingCatalogProducts,
    startCatalogSyncTask,
    controlBarSearchTerm,
    setControlBarSearchTerm,
    controlBarActiveFilters,
    setControlBarFilterValue
  } = props;

  const history = useHistory();
  const curTab = useParams()?.tab || props.tab || 'products';
  const { catalogProductModalVariant } = ui;
  const isCatalogProductModalVisible = !!catalogProductModalVariant;
  const announcement = getAnnouncementByType(user, 'BRAND_CATALOG');

  const handleTabChange = tab => {
    history.push(`/catalog/${tab}`);
  };

  const handleVariantsPageChange = newPageNum => {
    if (newPageNum === curVariantsPage) return;
    setCurVariantsPage(newPageNum);
    window.scrollTo(0, 0);
  };

  const navigateToCatalogGroupProducts = catalogGroup => {
    history.push(`/catalog/products`);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setControlBarFilterValue('CatalogGroup', catalogGroup);
    }, 0);
  };

  // Ensure logged in
  if (!isBrand(user)) return <RequiresBrandLoginPanel />;

  const isFetchingAnalytics = analytics?.brandAnalytics?.isFetchingAnalytics;
  const { catalogGroupRatesMap } = getBrandCustomAffiliateRatesMap(analytics);

  const saveGroupCustomAffiliateRate = async (catalogGroupId, rate) => {
    const existingRate = catalogGroupRatesMap[catalogGroupId];

    if (!rate && existingRate) {
      await props.deleteCustomAffiliateRate(existingRate);
      return;
    } else if (!rate && !existingRate) {
      return;
    }

    const numericalRate = parseFloat(rate);
    const rateFloor = isAdminControlMode(ui) ? 0 : isAffiliateOnlyPartnerBrand(user) ? 10 : 5;
    if (_.isNaN(numericalRate) || numericalRate < rateFloor || numericalRate > 100) {
      window.ALERT.error(`Please enter a numerical rate between ${rateFloor} and 100.`);
      return;
    }

    const needsSave = numericalRate !== existingRate?.rate;
    if (!needsSave) return;

    const data = {
      id: existingRate?.id,
      CatalogGroup_id: catalogGroupId,
      rate: numericalRate
    };

    if (existingRate?.id) {
      await props.updateCustomAffiliateRate(existingRate, data);
    } else {
      await props.addCustomAffiliateRate(data);
    }
  };

  const isSyncRunning = isSyncingCatalogGroups || isSyncingCatalogProducts;
  const isSyncUnavailable = !isCatalogSyncStatusInitialized;

  const brandShopifyIntegrations = getAllShopifyIntegrations(user);
  const shouldShowVariantDomains = brandShopifyIntegrations.filter(integration => integration.allowProductSyncing).length > 1;

  const triggerSyncCatalog = () => {
    if (isSyncRunning) {
      return window.ALERT.info('Your catalog is currently being synced. Please wait for it to complete.');
    } else if (isSyncUnavailable) {
      return;
    }
    const catalogSyncTaskType = curTab === 'groups' ? 'sync_catalog_groups' : 'sync_catalog_products';
    startCatalogSyncTask(catalogSyncTaskType);
  };

  return (
    <div className='catalog-outer-container'>
      {insertMetaTags({
        title: 'Your Product Catalog',
        description: '',
        image: ''
      })}
      <div className='catalog-inner-container'>
        <div className='catalog-header'>{'Your Product Catalog'}</div>
        <div className='catalog-sync-container'>
          {!isSyncUnavailable && (
            <div className={cn('catalog-sync-btn', { syncing: isSyncRunning })} onClick={triggerSyncCatalog}>
              {isSyncRunning
                ? isSyncingCatalogGroups
                  ? 'Syncing Groups'
                  : 'Syncing Products'
                : curTab === 'groups'
                ? 'Sync Groups'
                : 'Sync Products'}
              <FontAwesomeIcon icon={faArrowsRotate} />
            </div>
          )}
        </div>
        <CatalogControlBar
          curTab={curTab}
          setCurTab={handleTabChange}
          canSupportCustomRates={canSupportCustomRates}
          controlBarSearchTerm={controlBarSearchTerm}
          setControlBarSearchTerm={setControlBarSearchTerm}
          catalogGroups={catalogGroups}
          controlBarActiveFilters={controlBarActiveFilters}
          setControlBarFilterValue={setControlBarFilterValue}
          user={user}
          ui={ui}
          updateBrandSettings={props.updateBrandSettings}
        />
        <div className='catalog-content'>
          {curTab === 'products' && (
            <CatalogVariants
              isLoadingVariants={isLoadingVariants}
              isLoadingAnalytics={isFetchingAnalytics}
              variants={curVariants}
              curVariantsPage={curVariantsPage}
              handleVariantsPageChange={handleVariantsPageChange}
              numVariantsPages={numVariantsPages}
              shouldShowVariantDomains={shouldShowVariantDomains}
            />
          )}
          {curTab === 'groups' && (
            <CatalogGroups
              catalogGroups={catalogGroups}
              isLoadingCatalogGroups={isLoadingCatalogGroups}
              isLoadingAnalytics={isFetchingAnalytics}
              fetchCatalogGroups={fetchCatalogGroups}
              canSupportCustomRates={canSupportCustomRates}
              catalogGroupRatesMap={catalogGroupRatesMap}
              saveGroupCustomAffiliateRate={saveGroupCustomAffiliateRate}
              controlBarSearchTerm={controlBarSearchTerm}
              navigateToCatalogGroupProducts={navigateToCatalogGroupProducts}
            />
          )}
          {curTab === 'rates' && (
            <CatalogCommissionRates
              isLoadingAnalytics={isFetchingAnalytics}
              numTotalVariants={numTotalVariants}
              catalogGroups={catalogGroups}
              saveGroupCustomAffiliateRate={saveGroupCustomAffiliateRate}
              handleTabChange={handleTabChange}
              controlBarSearchTerm={controlBarSearchTerm}
              navigateToCatalogGroupProducts={navigateToCatalogGroupProducts}
              canSupportCustomRates={canSupportCustomRates}
            />
          )}
        </div>
      </div>
      {announcement && <AnnouncementModal announcement={announcement} />}
      {isCatalogProductModalVisible && (
        <CatalogProductModal variant={catalogProductModalVariant} shouldShowVariantDomains={shouldShowVariantDomains} />
      )}
    </div>
  );
};

Catalog.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isLoadingVariants: PropTypes.bool.isRequired,
  curVariants: PropTypes.array.isRequired,
  curVariantsPage: PropTypes.number.isRequired,
  setCurVariantsPage: PropTypes.func.isRequired,
  numVariantsPages: PropTypes.number.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  isLoadingCatalogGroups: PropTypes.bool.isRequired,
  fetchCatalogGroups: PropTypes.func.isRequired,
  canSupportCustomRates: PropTypes.bool.isRequired,
  isCatalogSyncStatusInitialized: PropTypes.bool.isRequired,
  isSyncingCatalogGroups: PropTypes.bool,
  isSyncingCatalogProducts: PropTypes.bool,
  startCatalogSyncTask: PropTypes.func.isRequired,
  controlBarSearchTerm: PropTypes.string.isRequired,
  setControlBarSearchTerm: PropTypes.func.isRequired,
  controlBarActiveFilters: PropTypes.object.isRequired,
  setControlBarFilterValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics } = state;
  return { user, ui, analytics };
};

export default CatalogDataLoader(
  connect(mapStateToProps, {
    addCustomAffiliateRate,
    updateCustomAffiliateRate,
    deleteCustomAffiliateRate,
    updateBrandSettings
  })(Catalog)
);
