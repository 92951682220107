import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { isPublicPage, isEmbedPage, isBeautyShopPage, isHomePage, isPromotePage, isReferralPage, getRootSMSUrl } from '../../Helpers/helpers';
import { isLoggedIn } from '../../Helpers/user_helpers';
import { addEvent } from '../../APIClient/events';

import facebookIcon from '../../static/images/social_icons/facebook_dark.png';
import youtubeIcon from '../../static/images/social_icons/youtube_dark.png';
import instagramIcon from '../../static/images/social_icons/instagram_dark.png';

import EmailPanel from '../HomeOld/EmailPanel';

import './FooterOld-SL.scss';
import './FooterOld-PRO.scss';
import './FooterOld-SMS.scss';

class FooterOld extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    setJoinedMailingList: PropTypes.func.isRequired,
    clickApply: PropTypes.func
  };

  getCurPath = () => _.get(this.props.history, ['location', 'pathname']);

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  generateLink = (name, destination) => {
    const { to, href, isNew } = destination || {};
    const newIcon = <div className='new-badge'>NEW</div>;
    return to ? (
      <li>
        <Link onClick={this.scrollTop} className='footer-panel-link' to={`/${to}`}>
          {name}
        </Link>
      </li>
    ) : (
      <li>
        <a className='footer-panel-link' rel='noopener nofollow' href={href}>
          {name}
          {isNew && newIcon}
        </a>
      </li>
    );
  };

  clickSMS = () => {
    addEvent('Footer - Click To Navigate to ShopMy');
  };

  clickApply = () => {
    addEvent('Footer - Click To Apply');
    window.location.href = `${getRootSMSUrl()}/home?auth=apply`;
  };

  clickLogin = () => {
    window.location.href = `${getRootSMSUrl()}/home?auth=login`;
  };

  clickTerms = () => {
    window.location.href = 'https://static.shopmy.us/Shoplist_TOS.pdf';
  };

  clickPrivacy = () => {
    window.location.href = 'https://static.shopmy.us/Privacy_Policy.pdf';
  };

  render() {
    const { user } = this.props;
    const isEmbed = isEmbedPage();
    if (isEmbed) return null;
    const isBeautyShop = isBeautyShopPage();
    const isHome = isHomePage();
    const isPromote = isPromotePage();
    const isReferral = isReferralPage();
    const showDisclaimer = isBeautyShop;
    return window.__IS_PRO__ ? (
      <div
        className={classnames('footer-old-outer-shell', {
          home: isHome,
          promote: isPromote,
          referral: isReferral,
          'has-disclaimer': showDisclaimer
        })}
      >
        <div className='footer-outer-container'>
          <a onClick={this.clickSMS} target='_blank' rel='noopener noreferrer' href={`${getRootSMSUrl()}/home`} className='footer-home-link'>
            <div className='footer-public-logo-header'>from the team at</div>
            <div className='footer-logo'>shop my</div>
          </a>
          <div className='footer-btns'>
            <div onClick={this.clickTerms} className='footer-btn'>
              Terms
            </div>
            <div onClick={this.clickPrivacy} className='footer-btn'>
              Privacy
            </div>
            {!isLoggedIn(user) && (
              <div onClick={this.clickApply} className='footer-btn'>
                Build Your Own Shop
              </div>
            )}
            {!isLoggedIn(user) && (
              <div onClick={this.clickLogin} className='footer-btn'>
                Login
              </div>
            )}
            <a href='https://guide.shopmy.us' className='footer-btn'>
              Shop Guide
            </a>
          </div>
        </div>
      </div>
    ) : isPublicPage() ? (
      <div className='footer-outer-shell public'>
        <div className='footer-outer-container public'>
          <Link itemProp='url' to='/home' className='footer-home-link'>
            <div className='footer-public-logo-header'>powered by</div>
            <div className='footer-logo'>shop my</div>
          </Link>
        </div>
      </div>
    ) : (
      <div
        className={classnames('footer-outer-shell', {
          promote: isPromote,
          referral: isReferral,
          'has-disclaimer': showDisclaimer
        })}
      >
        {showDisclaimer && (
          <div className='footer-disclaimer-container'>
            <div className='footer-disclaimer'>
              The products featured here may contain affiliate links, meaning we or the artist may earn a commission on products purchased through
              links to retailer sites.
            </div>
          </div>
        )}
        <div className='footer-outer-container'>
          <div itemScope className='footer-inner-container'>
            <div className='footer-main-body wide'>
              <div className='footer-left-panel panel'>
                <div className='footer-panel-header'>EXPLORE</div>
                <ul>
                  {this.generateLink('All Experts', { to: 'shops' })}
                  {this.generateLink('Product Rankings', { to: 'shop' })}
                  {this.generateLink('Blog', { to: 'blog' })}
                  {this.generateLink('Make a Shop', {
                    href: `${getRootSMSUrl()}/home?auth=apply`,
                    isNew: true
                  })}
                </ul>
              </div>
              <div className='footer-middle-panel panel'>
                <div className='footer-panel-header'>ABOUT</div>
                <ul>
                  {this.generateLink('About Us', { to: 'about' })}
                  {this.generateLink('Contact Us', { to: 'contact' })}
                  {this.generateLink('Brand Partnerships', { to: 'brand-partners' })}
                  {this.generateLink('Terms', { to: 'terms' })}
                  {this.generateLink('Privacy', { to: 'terms' })}
                  {this.generateLink('Join Our Team', { to: 'careers' })}
                </ul>
              </div>
              <div className='footer-right-panel panel'>
                <div className='footer-panel-header'>STAY IN TOUCH</div>
                <div className='footer-panel-message'>
                  Be the first to be updated on
                  <br />
                  news from Shop My.
                </div>
                <EmailPanel ui={this.props.ui} setJoinedMailingList={this.props.setJoinedMailingList} inFooter />
                <div className='footer-panel-social-links'>
                  <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/shopmyshelfus'>
                    <img alt='Instagram Icon' src={instagramIcon} />
                  </a>
                  <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/channel/UCtmQnJpZ1pPyWI2OonhUJ7A'>
                    <img alt='Youtube Icon' src={youtubeIcon} />
                  </a>
                  <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/shopmyshelf'>
                    <img alt='Instagram Icon' src={facebookIcon} />
                  </a>
                </div>
              </div>
            </div>
            <Link onClick={this.scrollTop} to='/home' replace={this.getCurPath().includes('/home')} className='footer-brand-container'>
              <div className='footer-logo'>shop my</div>
              <div className='footer-copyright'>© Copyright {moment().format('YYYY')} Shop My Shelf, Inc. All Rights Reserved.</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FooterOld);
