import APIClient from './index';
import _ from 'lodash';

export const getProduct = (Product_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/v2/Products/${Product_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getSimilarProducts = (Product_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/v2/Products/${Product_id}/similar?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateProductImage = (productImage, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ProductImages/${productImage.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
