import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLock, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './MoreOptionsMenuNew.scss';

import { logoutUser } from '../../../Actions/UserActions';
import { openAuthModal, toggleNewShopMode } from '../../../Actions/UIActions';

import {
  getBrand,
  getProfileFromObject,
  isLoggedIn,
  isBrand,
  isShopper,
  isManager,
  isCreator,
  getUsername,
  getNewRequests,
  getContracts,
  getNewContractProposals,
  getUnreadChatNotifications,
  getIncompleteConsultResults,
  getOutstandingOpportunityRequests,
  getNumUnpaidInvoicesPastDue
} from '../../../Helpers/user_helpers';
import { hasUnlockedNewShopExperience } from '../../../Helpers/tier_helpers';
import { CREATOR_HOME_NAV, BRAND_HOME_NAV, SHOP_HOME_NAV } from '../../../Helpers/home_helpers';
import { hasPermission } from '../../../Helpers/manager_helpers';
import { isSubscribedToFeatureOrAdmin } from '../../../Helpers/subscription_helpers';

import Tooltip from '../../General/Tooltip';
import ProfilePhoto from '../../Profile/ProfilePhoto';

const MoreOptionsMenu = props => {
  const { manager, user } = props;

  // Get Relevant User Data
  const newChatNotifications = getUnreadChatNotifications(user);
  const newRequests = getNewRequests(user);
  const userContracts = getContracts(user);
  const newContractProposals = getNewContractProposals(user);
  const newConsultResults = getIncompleteConsultResults(user);
  const newOpportunityRequests = getOutstandingOpportunityRequests(user);
  const numUnpaidInvoicesPastDue = getNumUnpaidInvoicesPastDue(user);
  const totalTalentBrandNotifications = isBrand(user) ? newRequests.length + newContractProposals.length : 0;

  // Basic Responses
  const defaultDisabledManagerAccess = 'You do not have permission to access this page';
  const defaultBrandUnsubscribeMessage = 'You cannot access this page with your current package.';

  // Link Object Style
  const getLink = (title, to, extras = {}) => ({ title, to, ...extras });
  const getLinkWithClick = (title, onClick, extras = {}) => ({ title, onClick, ...extras });
  const getExternalLink = (title, href, extras = {}) => ({ title, href, ...extras });

  // Create Groups
  let navGroups = [];

  // For Logged Out Users
  if (!isLoggedIn(user)) {
    navGroups = [
      {
        title: 'Products For Creators',
        items: CREATOR_HOME_NAV.map(item => getLink(item.headerNav || item.header, item.to))
      },
      {
        title: 'Products For Brands',
        items: BRAND_HOME_NAV.map(item => getLink(item.headerNav || item.header, item.to))
      },
      {
        title: 'For Shoppers',
        items: SHOP_HOME_NAV.map(item => getLink(item.headerNav || item.header, item.to))
      },
      {
        title: 'General',
        items: [getLink('About Us', '/about'), getLink('Blog', '/blog'), getLink('Careers', '/careers'), getLink('Contact Us', '/contact')]
      },
      {
        actions: [
          { title: 'Apply', onClick: () => props.openAuthModal('apply-select-type'), classNames: 'primary' },
          { title: 'Log In', onClick: () => props.openAuthModal('login') }
        ]
      }
    ];
  }

  // For Shoppers
  else if (isShopper(user)) {
    navGroups = [
      {
        title: 'Discover & Shop',
        items: [getLink('Shop By Category', '/shop'), getLink('Discover Curators', '/shop/circles')]
      },
      {
        title: 'For Me',
        items: [
          getLink('My Circles', '/shop/my/circles'),
          getLink('My Taste', '/shop/my/taste'),
          getLink('My Wishlists', `/shop/${getUsername(user)}?tab=collections`)
        ]
      },
      {
        title: 'General',
        items: [getLink('Account Settings', '/settings?tab=All'), getLink('Apply for Creator Account', '/settings?tab=Upgrade+To+Earn')]
      },
      {
        actions: [{ title: 'Sign Out', onClick: props.logoutUser }]
      }
    ];
  }

  // For Creators
  else if (isCreator(user)) {
    navGroups = [
      {
        title: 'Affiliates & Earnings',
        items: [
          getLink('Links', '/links', {
            isDisabled: !hasPermission(user, manager, 'canCreateLinks'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getLink('My Shop', `/${getUsername(user)}`, {
            count: newConsultResults.length,
            isDisabled: !hasPermission(user, manager, 'canCreateLinks'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getLink('Earnings', '/payouts', {
            isDisabled: !hasPermission(user, manager, 'canViewEarnings'),
            isDisabledTooltip: defaultDisabledManagerAccess
          })
        ]
      },
      {
        title: 'Working with Brands',
        items: [
          getLink('Chat', '/chat', {
            count: newChatNotifications.length,
            isDisabled: !hasPermission(user, manager, 'canAnswerChats'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getLink('Gifting', '/partners/gifting', {
            count: newRequests.length,
            isDisabled: !hasPermission(user, manager, 'canAcceptGifting'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getLink('Opportunities', '/partners/opportunities', {
            count: newOpportunityRequests.length,
            isDisabled: !hasPermission(user, manager, 'canAnswerOpportunities'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getLink('Discount Codes', '/partners/codes'),
          getLink('Collaborations', '/collaborations', {
            count: newContractProposals.length,
            isHidden: !userContracts.length, // Hide if no contracts since this is basically deprecated
            isDisabled: !hasPermission(user, manager, 'canAnswerCollaborations'),
            isDisabledTooltip: defaultDisabledManagerAccess
          })
        ]
      },
      {
        title: 'Shopping',
        items: [
          getLink('My Shop 2.0', `/shop/${getUsername(user)}`, {
            isNew: true,
            isDisabled: !hasUnlockedNewShopExperience(user) || !hasPermission(user, manager, 'canCreateLinks'),
            isDisabledTooltip: !hasUnlockedNewShopExperience(user) ? 'Unlock Shop 2.0 by reaching Trendsetter status' : defaultDisabledManagerAccess
          }),
          getLink('Shop Top Products', '/shop'),
          getLink('Discover Curators', '/shop/circles'),
          getLink('My Circles', '/shop/my/circles'),
          getLink('My Taste', '/shop/my/taste')
        ]
      },
      {
        title: 'General',
        items: [
          getLink('Latest on ShopMy', '/latest'),
          getLink('Account Settings', '/settings?tab=All', {
            isDisabled: !hasPermission(user, manager, 'canEditSettings'),
            isDisabledTooltip: defaultDisabledManagerAccess
          }),
          getExternalLink('How To Guide', `https://guide.shopmy.us`),
          getLink('Refer & Earn', `/join/${getUsername(user)}`)
        ]
      },
      {
        actions: [
          { title: 'ShopMy 1.0', onClick: props.toggleNewShopMode },
          { title: 'Sign Out', onClick: props.logoutUser }
        ]
      }
    ];
  }

  // For Managers
  else if (isManager(user)) {
    navGroups = [
      {
        title: 'Manage Talent',
        items: [
          getLink('My Talent', '/settings?tab=My+Talent'),
          getLink('Invite Talent', `/invite/${getUsername(user)}`),
          getLink('Earnings', '/payouts')
        ]
      },
      {
        title: 'General',
        items: [getLink('Account Settings', '/settings?tab=Account+Settings')]
      },
      { actions: [{ title: 'Sign Out', onClick: props.logoutUser }] }
    ];
  }

  // For Brands
  else if (isBrand(user)) {
    navGroups = [
      {
        title: 'Expand Your Program',
        items: [
          getLink('Discover Creators', '/discover', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'DISCOVER_2.0'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Gifting', '/gifting', {
            count: newRequests.length,
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'GIFTING'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLinkWithClick('Invite Talent', props.openInviteTalentModal)
        ]
      },
      {
        title: 'Monitor Your Program',
        items: [
          getLink('Analytics', '/links', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'ANALYTICS'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Social Mentions', '/mentions', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'SOCIAL_MENTIONS_FEED'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Program Report', '/report')
        ]
      },
      {
        title: 'Manage Your Program',
        items: [
          getLink('Chat', '/chat', {
            count: newChatNotifications.length,
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'CHAT_WITH_PROMOTERS'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Talent', '/talent', { count: totalTalentBrandNotifications }),
          getLink('Product Catalog', '/catalog', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'AFFILIATES'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Orders Log', '/commissions', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'AFFILIATES'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          })
        ]
      },
      {
        title: 'Amplify Your Program',
        items: [
          getLink('Opportunities', '/opportunities', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'OPPORTUNITIES'),
            isDisabledTooltip: defaultBrandUnsubscribeMessage
          }),
          getLink('Collaborations', '/collaborations', {
            isDisabled: !isSubscribedToFeatureOrAdmin(user, 'COLLABORATE')
          }),
          getLink('Custom Codes', '/codes')
        ]
      },
      {
        title: 'General',
        items: [
          getLink('Account Settings', '/settings?tab=All'),
          getLink('Invoices & Subscriptions', '/subscriptions', { count: numUnpaidInvoicesPastDue }),

          getExternalLink('How To Guide', `https://brands.shopmy.us`)
        ]
      },
      { actions: [{ title: 'Sign Out', onClick: props.logoutUser }] }
    ];
  }

  const additionalClasses = { active: props.active, scrolled: props.isScrolled };

  const profile = isLoggedIn(user) ? getBrand(user) || getProfileFromObject(user) : null;

  return (
    <>
      <div onClick={props.close} className={cn('more-options-menu-fade-container-new', additionalClasses)} />
      <div className={cn('more-options-menu-outer-container-new', additionalClasses)}>
        {profile && (
          <div className='user-info'>
            <div className='image-container'>
              <ProfilePhoto ui={props.ui} profile={profile} basic={true} />
            </div>
            <div className='info-container'>
              <div className='name'>{profile?.name}</div>
              <Link onClick={props.close} to='/settings' className='view-profile'>
                View my account
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        )}
        <nav className='menu-groups'>
          {navGroups.map(group => {
            const { title, items } = group;
            return group.actions ? (
              <div key='actions' className='group-actions'>
                {group.actions.map(action => {
                  const { title, onClick, classNames } = action;
                  const clickAndClose = () => {
                    onClick();
                    props.close();
                  };
                  const additionalClasses = {};
                  return (
                    <div key={title} className={cn('group-action', classNames || '', additionalClasses)} onClick={clickAndClose}>
                      {title}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div key={title} className='menu-group'>
                <div className='group-title'>{title}</div>
                <div className='group-items'>
                  {items?.map(item => {
                    const { title, to, href, isNew, count, isDisabled, isDisabledTooltip, classNames, onClick } = item;

                    if (item.isHidden) return null;

                    const clickAndClose = () => {
                      onClick();
                      props.close();
                    };

                    const badge = isDisabled ? (
                      <Tooltip message={isDisabledTooltip}>
                        <div className='disabled-icon'>
                          <FontAwesomeIcon icon={faLock} />
                        </div>
                      </Tooltip>
                    ) : count > 0 ? (
                      <div className='count'>{count}</div>
                    ) : isNew ? (
                      <div className='new-tag'>New</div>
                    ) : null;

                    const additionalClasses = { 'is-new': isNew, 'has-count': count > 0, 'is-disabled': isDisabled, ...(classNames || {}) };
                    return isDisabled ? (
                      <div key={title} className={cn('group-item', additionalClasses)}>
                        <div>{title}</div>
                        {badge}
                      </div>
                    ) : href ? (
                      <a
                        key={title}
                        href={href}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={props.close}
                        className={cn('group-item', additionalClasses)}
                      >
                        <div>{title}</div>
                        {badge}
                      </a>
                    ) : to ? (
                      <Link to={to} onClick={props.close} key={title} className={cn('group-item', additionalClasses)}>
                        <div>{title}</div>
                        {badge}
                      </Link>
                    ) : onClick ? (
                      <div key={title} onClick={clickAndClose} className={cn('group-item', additionalClasses)}>
                        <div>{title}</div>
                        {badge}
                      </div>
                    ) : null;
                  })}
                </div>
              </div>
            );
          })}
          <div className='close-menu-icon' onClick={props.close}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </nav>
      </div>
    </>
  );
};

MoreOptionsMenu.propTypes = {
  // From Inside
  manager: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toggleNewShopMode: PropTypes.func.isRequired,

  // From Outside
  isScrolled: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  openInviteTalentModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { manager, ui, user } = state;
  return { manager, ui, user };
};

export default connect(mapStateToProps, {
  logoutUser,
  openAuthModal,
  toggleNewShopMode
})(MoreOptionsMenu);
