import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import commaNumber from 'comma-number';
import './ShopProductResult.scss';

import { updateUserProduct } from '../../APIClient/shop';

import { getPrettyNumber, getPrettyTimeAgoFromNow } from '../../Helpers/formatting';
import { getLinkToProductPageViaId, getShopVisibilitySettingExplainer } from '../../Helpers/shop_helpers';
import { getUserId, getUserProductById, isShopper } from '../../Helpers/user_helpers';

import ProductHeart from './Elements/ProductHeart';
import Tooltip from '../General/Tooltip';

const ShopProductResult = props => {
  const { user, result, idx, isFetchingFirstPage, activeRefinementObjects, isYourShop } = props;
  const {
    title,
    image,
    publishedAt,
    is_favorite_only,
    AllBrand_id,
    AllBrand_name,
    Category_name,
    Category_id,
    num_curators,
    curators,
    links,
    yearlyClicks
  } = result || {};
  const { activeCurator, activeCuratorGroup } = activeRefinementObjects;
  const isEmptyResult = !!props.emptyResult;

  // Check visibility
  const userProduct = result && getUserProductById(user, result.id);

  // Linking
  const primaryLink = links?.find(link => link.isPrimary) || links?.[0];
  const hasPriceRange = primaryLink && primaryLink.priceLow !== primaryLink.priceHigh;

  // Smooth Animation of Images
  const [productImageLoaded, setProductImageLoaded] = React.useState(false);
  const onProductImageLoad = () => {
    setTimeout(() => setProductImageLoaded(true), 100);
  };
  React.useEffect(() => {
    setProductImageLoaded(false);
  }, [props.isFetchingFirstPage]);

  // Allow filtering by clicking brand title
  const canFilterToBrand = props.AllBrand_id !== AllBrand_id;
  const filterToBrand = () => canFilterToBrand && props.goToBrandId(AllBrand_id);

  const canFilterToCategory = props.Category_id !== Category_id;
  const filterToCategory = () => canFilterToCategory && props.goToCategoryId(Category_id);

  const canFilterToCurator = activeCuratorGroup;
  const filterToCurator = curator => props.goToCuratorId(curator.id);

  const filterToBrandAndCategory = () => {
    filterToBrand();
    filterToCategory();
  };

  let statEl;

  // Allow selecting product
  const selectProduct = () => {
    props.setActiveProduct(result);

    // We want to apply attribution to this single curator if there is only one
    if (curators?.length === 1) {
      props.setAttributableCuratorId(curators[0].id);
    }
  };

  // Allow visibililty toggling if yours
  const toggleVisibility = () => {
    props.updateUserProduct(userProduct, { isHidden: !userProduct.isHidden });
  };

  const republish = () => {
    const newPublishedAt = moment().format('YYYY-MM-DD HH:mm:ss');
    props.locallyMoveProductToIndex(result, 0);
    updateUserProduct({ User_id: getUserId(props.user), Product_id: result.id }, { publishedAt: newPublishedAt })
      .then(() => {
        window.ALERT.success(`${title} has been re-published and will show up at the top of the search results.`);
      })
      .catch(() => {
        window.ALERT.error(`Failed to re-publish ${title}, please try again.`);
        props.locallyMoveProductToIndex(result, idx);
      });
  };

  /*
    Smart Image Handling.

    We want to show the full-width cover image:

      1) Every 7th image (to shake up the design a bit) (currently disabled)
      2) If the image does not have a white border
      3) If the image has a person in it (coming soon)
  */

  const is7thImage = idx % 7 === 6;
  const weWantCovers = is7thImage && !isMobile;
  const imagesWithRanking = _.map(result?.images, image => {
    const { isFeatured, isCover, metadata } = image;
    const metadataObj = JSON.parse(metadata || '{}') || {};

    // Covers should not have a white border
    const hasWhiteBorder = !metadataObj || metadataObj.borderWhitePercentage >= 85;
    const isValidCover = !hasWhiteBorder;
    const isHardCodeSelected = image.id === result.ProductImage_id;

    // Rank them based on what we're looking for
    let ranking;
    if (isHardCodeSelected) {
      ranking = 10;
    } else if (weWantCovers) {
      if (isCover && isValidCover) ranking = 2;
      else if (isFeatured) ranking = 1;
      else ranking = 0;
    } else {
      if (isFeatured) ranking = 2;
      else if (isCover && isValidCover) ranking = 1;
      else ranking = 0;
    }

    // Determine best display type
    let bestSeenAsCover = false;
    if (!hasWhiteBorder) bestSeenAsCover = true;

    return {
      ...image,
      ranking,
      bestSeenAsCover,
      hasWhiteBorder
    };
  });

  // Determine which image to display
  const orderedImages = _.orderBy(imagesWithRanking, 'ranking', 'desc');
  const [imageToDisplayIdx, setImageToDisplayIdx] = React.useState(0);
  const imageToDisplay = orderedImages[imageToDisplayIdx] || { image }; // Fallback in case of missing row
  const goToNextImage = () => {
    setImageToDisplayIdx((imageToDisplayIdx + 1) % orderedImages.length);
    preFetchAllImages(); // Load the upcoming ones
  };
  const goToPrevImage = () => {
    setImageToDisplayIdx((imageToDisplayIdx - 1 + orderedImages.length) % orderedImages.length);
    preFetchAllImages(); // Load the upcoming ones
  };

  // Smart fetching to maximize visual performance
  const preFetchPrevImage = () => preFetchImageFromIndex((imageToDisplayIdx - 1 + orderedImages.length) % orderedImages.length);
  const preFetchNextImage = () => preFetchImageFromIndex((imageToDisplayIdx + 1) % orderedImages.length);
  const preFetchAllImages = () => orderedImages.forEach((_, idx) => preFetchImageFromIndex(idx));
  const preFetchImageFromIndex = imageIndex => {
    if (orderedImages[imageIndex]) {
      const nextImage = new Image();
      nextImage.src = orderedImages[imageIndex].image; // Fetch it prior to ensure it's ready
    }
  };
  const debouncePrefetchRef = React.useRef(null);
  const debouncePrefetchImages = () => {
    if (debouncePrefetchRef.current) clearTimeout(debouncePrefetchRef.current);
    debouncePrefetchRef.current = setTimeout(preFetchAllImages, 500); // Debounce to prevent over-fetching
  };

  // Primary Images
  const canEditPrimaryImage = isYourShop && result?.images?.length > 1;
  const isPrimaryImage = result?.ProductImage_id ? result.ProductImage_id === imageToDisplay.id : imageToDisplayIdx === 0;
  const makePrimaryImage = () => {
    props.locallyUpdateProduct(result, { ProductImage_id: imageToDisplay.id });
    setImageToDisplayIdx(0); // Reset to first image
    updateUserProduct({ User_id: getUserId(props.user), Product_id: result.id }, { ProductImage_id: imageToDisplay.id })
      .then(() => window.ALERT.success(`Updated primary image for ${title}.`))
      .catch(() => {
        window.ALERT.error(`Failed to update primary image for ${title}, please try again.`);
        props.locallyUpdateProduct(result, { ProductImage_id: result.ProductImage_id });
      });
  };

  /*
    Attribution Handling
  */
  const attributionParams = {};
  if (curators?.length === 1) attributionParams.User_id = curators[0].id;
  if (activeCuratorGroup) attributionParams.CuratorGroup_id = activeCuratorGroup?.id;
  const linkToProduct = getLinkToProductPageViaId(result?.id, attributionParams);

  // UI Management
  const isFetching = isFetchingFirstPage || isEmptyResult;
  const additionalClasses = cn(`column-${idx % 3}`, {
    fetching: isFetching,
    empty: isEmptyResult,
    'image-loaded': productImageLoaded,
    'is-hidden': !!userProduct?.isHidden,

    // Image specific
    'img-full-cover': imageToDisplay?.bestSeenAsCover
  });

  // Display up to 3 promoters
  const NUM_TO_SHOW = 3;

  // Show the number of additional promoters
  if (isEmptyResult) {
    statEl = null;
  } else if (isFetchingFirstPage) {
    statEl = null;
  } else if (!props.tab || props.tab === 'popular') {
    statEl = activeCurator ? (
      <div className={cn('total-clicks extra-stats', additionalClasses)}>
        {is_favorite_only ? (
          <>Favorited</>
        ) : (
          <>
            <span className='number'>{getPrettyNumber(yearlyClicks)}</span> view{yearlyClicks === 1 ? '' : 's'}
          </>
        )}
      </div>
    ) : (
      <div className={cn('total-curators extra-stats', additionalClasses)}>
        <span className='number'>{num_curators > 0 ? getPrettyNumber(num_curators) : '0'}</span> curator{num_curators === 1 ? '' : 's'}
      </div>
    );
  } else if (props.tab === 'latest') {
    const selectCurator = () => canFilterToCurator && filterToCurator(curators[0]);
    statEl =
      curators?.length === 1 && !!activeCuratorGroup ? (
        <div className={cn('found-date-single-curator extra-stats', additionalClasses)}>
          <div onClick={selectCurator} className={cn('name', { clickable: canFilterToCurator })}>
            {curators[0].name}
          </div>
          <div className='when'>{getPrettyTimeAgoFromNow(publishedAt, { longForm: true }).toLowerCase()}</div>
        </div>
      ) : (
        <div className={cn('found-date extra-stats', additionalClasses)}>
          {getPrettyTimeAgoFromNow(publishedAt, { longForm: true }).toLowerCase()}
        </div>
      );
  } else if (props.tab === 'views') {
    statEl = (
      <div className={cn('total-clicks extra-stats', additionalClasses)}>
        <span className='number'>{getPrettyNumber(yearlyClicks)}</span> view{yearlyClicks === 1 ? '' : 's'}
      </div>
    );
  }

  return (
    <div onMouseOver={debouncePrefetchImages} className={cn('shop-product-result', additionalClasses)}>
      {canEditPrimaryImage && (
        <div className='my-shop-actions'>
          {!isPrimaryImage && (
            <div onClick={makePrimaryImage} className='make-primary-image'>
              MAKE PRIMARY IMAGE
            </div>
          )}
        </div>
      )}
      {!!userProduct?.isHidden && (
        <div className={cn('hidden-overlay-outer', additionalClasses)}>
          <div className={cn('hidden-overlay-inner', additionalClasses)}>
            <div className='hidden-title'>{isYourShop ? 'Currently Hidden' : 'Currently Hidden on your shop'}</div>
            <div className='hidden-subtitle'>{getShopVisibilitySettingExplainer(user)}</div>
            <div className='actions'>
              <div className='action primary' onClick={toggleVisibility}>
                Make Visible
              </div>
              <Link to='/settings?tab=Account+Management#visibleproducts' className='action secondary'>
                Edit Settings
              </Link>
              {/* <div className='re-publish action' onClick={republish}>
                Re-Publish
              </div> */}
            </div>
          </div>
        </div>
      )}
      {orderedImages.length > 1 && !isFetching && (
        <div className='image-arrows'>
          <div
            onMouseOver={preFetchPrevImage}
            onClick={goToPrevImage}
            className={cn('arrow left', {
              visible: imageToDisplayIdx > 0
            })}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div
            onMouseOver={preFetchNextImage}
            onClick={goToNextImage}
            className={cn('arrow right', {
              visible: imageToDisplayIdx < orderedImages.length - 1
            })}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      )}
      <Link onClick={selectProduct} to={linkToProduct} className={cn('image-container', additionalClasses)}>
        {!isFetchingFirstPage && !isEmptyResult && (
          <img className={cn('cover-img', additionalClasses)} onLoad={onProductImageLoad} src={imageToDisplay.image} alt={title} />
        )}
      </Link>
      <div className={cn('data-containers', additionalClasses)}>
        <div className={cn('curator-data-container', additionalClasses)}>
          <div className={cn('promoted-by', additionalClasses)}>
            {isEmptyResult ? (
              <div className='curator'>
                <div className='image empty' />
              </div>
            ) : (
              curators?.slice(0, NUM_TO_SHOW).map((curator, idx) => {
                return (
                  <div key={idx} className='curator'>
                    {!isFetchingFirstPage && (
                      // <Tooltip message={activeCurator ? '' : curator.name}>
                      <>{curator.image ? <img loading='lazy' src={curator.image} alt={curator.name} /> : <div className='image empty' />}</>
                      // </Tooltip>
                    )}
                  </div>
                );
              })
            )}
            {statEl}
          </div>
          <div className='actions-container'>
            {userProduct && !isShopper(user) && (
              <Tooltip message={userProduct.isHidden ? 'Currently Hidden, click to make visible' : 'Currently Visible, click to hide from your shop'}>
                <div
                  onClick={toggleVisibility}
                  className={cn('action visibility-container', {
                    fetching: isFetching
                  })}
                >
                  <FontAwesomeIcon icon={userProduct.isHidden ? faEyeSlash : faEye} />
                </div>
              </Tooltip>
            )}
            <div className='favorite-container'>
              <ProductHeart product={result} user={props.user} isLoading={isFetching} />
            </div>
          </div>
        </div>
        <div className={cn('product-data-container', additionalClasses)}>
          <div className={cn('main', additionalClasses)}>
            <div className='filters'>
              <div onClick={filterToBrand} className={cn('brand', { clickable: canFilterToBrand })}>
                {isEmptyResult ? 'Brand' : AllBrand_name}
              </div>
              <div onClick={filterToBrandAndCategory} className='bullet'>
                •
              </div>
              <div onClick={filterToCategory} className={cn('brand', { clickable: canFilterToCategory })}>
                {isEmptyResult ? 'Category' : Category_name}
              </div>
            </div>
            {result && (
              <Link to={linkToProduct} onClick={selectProduct} className='title'>
                {isEmptyResult ? 'Product Title' : title}
              </Link>
            )}
          </div>
          <div className='secondary'>
            {primaryLink && primaryLink.price ? (
              <div className='price'>
                {hasPriceRange && !primaryLink.isPriceAnEstimate
                  ? `${primaryLink.isPriceAnEstimate ? '~' : ''}$${commaNumber(primaryLink.priceLow.toFixed(0))} - $${commaNumber(
                      primaryLink.priceHigh.toFixed(0)
                    )}`
                  : `${primaryLink.isPriceAnEstimate ? '~' : ''}$${commaNumber(primaryLink.price.toFixed(0))}`}
              </div>
            ) : (
              <div className='price'>{isEmptyResult ? '$10' : '-'}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ShopProductResult.propTypes = {
  user: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  createUserProductByProductId: PropTypes.func.isRequired,
  deleteUserProductByProductId: PropTypes.func.isRequired,
  setActiveProduct: PropTypes.func.isRequired,
  setAttributableCuratorId: PropTypes.func.isRequired,

  // One or the other
  result: PropTypes.object,
  emptyResult: PropTypes.object,

  // From Shop.js
  isFetchingResults: PropTypes.bool.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  setAllBrand_id: PropTypes.func.isRequired,
  goToCategoryId: PropTypes.func.isRequired,
  goToCuratorId: PropTypes.func.isRequired,
  activeRefinementObjects: PropTypes.object.isRequired,
  isYourShop: PropTypes.bool.isRequired,
  locallyUpdateProduct: PropTypes.func.isRequired,
  locallyMoveProductToIndex: PropTypes.func.isRequired
};

export default ShopProductResult;
