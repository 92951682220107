import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import Mousetrap from 'mousetrap';
import _ from 'lodash';
import './ShopHeaderSearchResults.scss';

import ShopSearchResultsData from './ShopSearchResultsData';
import ShopHeaderSearchResult from './ShopHeaderSearchResult';
import ShopHeaderEmptySearchResultsPanel from './ShopHeaderEmptySearchResultsPanel';

import { openCuratorGroupModal } from '../../../Actions/UIActions';
import { createCuratorGroupFavorite, deleteCuratorGroupFavorite } from '../../../Actions/ShopActions';

import { getActiveCurator, getActiveCuratorGroup } from '../../../Helpers/shop_helpers';

const ShopHeaderSearchResults = props => {
  const { shop, searchVal } = props;

  const [isSearching, setIsSearching] = React.useState(false);
  const [results, setResults] = React.useState([]);

  const closePanel = () => {
    props.setSearchVal('');
    props.searchInputEl.blur();
  };

  // Actions
  const selectResult = result => {
    const { type, searchAllCurators } = result;

    // Search
    if (type === 'Query') {
      // Navigate to the new URL without reloading the page
      const params = new URLSearchParams();
      params.append('query', searchVal);
      if (searchApplyingCuratorId && !searchAllCurators) params.append('Curator_id', searchApplyingCuratorId);
      if (searchApplyingCuratorGroupId && !searchAllCurators) params.append('CuratorGroup_id', searchApplyingCuratorGroupId);
      if (searchApplyingCuratorGroupId) params.append('tab', 'popular');

      // Navigate to the new URL without reloading the page
      const newUrl = `/shop?${params.toString()}`;
      props.history.push(newUrl);

      // Get rid of the search term
      return closePanel();
    }

    // Shop Hierarchy
    const navigateToShop = () => {
      // Make URL Params
      const params = new URLSearchParams();

      let defaultTab;
      if (result.tab) defaultTab = result.tab;
      else if (result.Curator_id && !searchAllCurators) defaultTab = 'collections';
      else defaultTab = 'popular';

      Object.entries(
        _.omitBy(
          {
            Category_id: result.Category_id,
            Department_id: result.Department_id,
            Industry_id: result.Industry_id,
            AllBrand_id: result.AllBrand_id,
            CuratorGroup_id: result.Curator_id || searchAllCurators ? null : result.CuratorGroup_id || searchApplyingCuratorGroupId,
            Curator_id: result.CuratorGroup_id || searchAllCurators ? null : result.Curator_id || searchApplyingCuratorId,
            tab: defaultTab
          },
          _.isNil
        )
      ).forEach(([key, value]) => {
        params.append(key, value);
      });

      // Navigate to the new URL without reloading the page
      const newUrl = `/shop?${params.toString()}`;
      props.history.push(newUrl);

      // Get rid of the search term
      closePanel();
    };

    // If they are on my/circles/ page, they might be trying to favorite a curator, if so, let's ask them
    const isOnMyCuratorGroup = window.location.href.includes('/my/circles/');
    if (isOnMyCuratorGroup && result.Curator_id) {
      const curatorName = result?.highlightedParts[0];
      confirmAlert({
        title: 'What would you like to do?',
        message: `Are you trying to add ${curatorName || 'this curator'} to your shopping circle? To favorite, you can also use the heart icon.`,
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => {}
          },
          {
            label: 'Shop',
            className: 'secondary',
            onClick: navigateToShop
          },
          {
            label: 'Add to Circle',
            onClick: () => {
              props.openCuratorGroupModal({
                params: {
                  type: 'add',
                  curator: {
                    id: result.Curator_id,
                    name: curatorName
                  }
                }
              });
            }
          }
        ]
      });
    } else {
      navigateToShop();
    }
  };

  // Allow submitting with the enter button
  const [autoEnterSubmitIndex, setAutoEnterSubmitIndex] = React.useState(0);
  const autoResult = results[autoEnterSubmitIndex];
  React.useEffect(() => {
    const onSubmit = e => {
      e.preventDefault();
      // autoResult && selectResult(autoResult);
    };
    props.setOnSearchFormSubmit(() => onSubmit);
  }, [autoResult?.objectID]);

  // Mousetrap to get TAB and Arrows
  React.useEffect(() => {
    const canGoDown = autoEnterSubmitIndex < results.length - 1;
    const canGoUp = autoEnterSubmitIndex > 0;
    const goDownIfPossible = () => canGoDown && setAutoEnterSubmitIndex(autoEnterSubmitIndex + 1);
    const goUpIfPossible = () => canGoUp && setAutoEnterSubmitIndex(autoEnterSubmitIndex - 1);
    const selectAuto = () => autoResult && selectResult(autoResult);

    const onKeyDown = event => {
      const goDownKeys = ['ArrowDown'];
      const goUpKeys = ['ArrowUp'];
      const submitKeys = ['Enter'];
      const clearKeys = ['Escape'];

      if (goDownKeys.includes(event.key) && canGoDown) {
        event.preventDefault();
        goDownIfPossible();
      } else if (goUpKeys.includes(event.key) && canGoUp) {
        event.preventDefault();
        goUpIfPossible();
      } else if (submitKeys.includes(event.key)) {
        event.preventDefault();
        selectAuto();
      } else if (clearKeys.includes(event.key)) {
        props.setSearchVal('');
      }
    };
    props.searchInputEl?.addEventListener('keydown', onKeyDown);

    Mousetrap.bind('down', goDownIfPossible);
    Mousetrap.bind('tab', goDownIfPossible);
    Mousetrap.bind('up', goUpIfPossible);
    Mousetrap.bind('shift+tab', goUpIfPossible);
    Mousetrap.bind('enter', selectAuto);

    return () => {
      props.searchInputEl?.removeEventListener('keydown', onKeyDown);
      Mousetrap.unbind('down');
      Mousetrap.unbind('up');
      Mousetrap.unbind('tab');
      Mousetrap.unbind('shift+tab');
      Mousetrap.unbind('enter');
    };
  }, [searchVal, autoResult?.term]);

  // Reset on search change
  React.useEffect(() => {
    setAutoEnterSubmitIndex(0);
  }, [searchVal]);

  // Allow filtering to a certain type of result
  const [resultTypeDisplay, setResultTypeDisplay] = React.useState('All');
  const resultTypeOptions = [
    { display: 'All', type: null },
    { display: 'Brands', types: ['Brand', 'Brand_Category', 'Brand_Department', 'Brand_Industry', 'Query'] },
    { display: 'Curators', types: ['Curator'] },
    { display: 'Circles', types: ['CuratorGroup'] },
    { display: 'Products', types: ['Product', 'Query'] },
    { display: 'Categories', types: ['Category', 'Department', 'Query'] }
  ].map(({ display, types }) => ({
    display,
    types,
    isActive: display === resultTypeDisplay,
    onSelect: () => setResultTypeDisplay(display)
  }));
  const resultTypes = resultTypeOptions.find(({ display }) => display === resultTypeDisplay)?.types;

  // Curation Scoping
  const activeCurator = getActiveCurator(shop);
  const activeCuratorGroup = getActiveCuratorGroup(shop);
  const activeCuratorId = activeCurator?.id || null;
  const activeCuratorGroupId = activeCuratorGroup?.id || null;
  const isShopPage = window.location.pathname === '/shop' || window.location.pathname === `/shop/${activeCurator?.username}`;
  const [searchApplyingCuratorId, setSearchApplyingCuratorId] = React.useState(isShopPage ? activeCuratorId : null);
  const [searchApplyingCuratorGroupId, setSearchApplyingCuratorGroupId] = React.useState(isShopPage ? activeCuratorGroupId : null);
  const isScopedToCurator = searchApplyingCuratorId || searchApplyingCuratorGroupId;

  // Visible Results
  const visibleResults = results.filter(result => {
    if (!resultTypes) return true;
    return resultTypes.includes(result.type);
  });

  const additionalClasses = { searching: isSearching };
  return (
    <div className='shop-header-search-results-outer-container'>
      <ShopSearchResultsData
        resultTypes={resultTypes}
        searchVal={searchVal}
        results={results}
        setResults={setResults}
        setResultTypeDisplay={setResultTypeDisplay}
        setIsSearching={setIsSearching}
        activeCurator={activeCurator}
        activeCuratorGroup={activeCuratorGroup}
      />
      <div className={cn('shop-header-search-results-inner-container', additionalClasses)}>
        {searchVal && (
          <div className='result-types'>
            {resultTypeOptions.map(({ display, onSelect, isActive }) => {
              return (
                <div onClick={onSelect} className={cn('result-type', { active: isActive })} key={display}>
                  {display}
                </div>
              );
            })}
          </div>
        )}
        {visibleResults.length ? (
          <div className={cn('search-results', additionalClasses)}>
            {visibleResults.map((result, idx) => (
              <ShopHeaderSearchResult
                result={result}
                user={props.user}
                selectResult={selectResult}
                isAutoSubmit={autoEnterSubmitIndex === idx}
                openCuratorGroupModal={props.openCuratorGroupModal}
                createCuratorGroupFavorite={props.createCuratorGroupFavorite}
                deleteCuratorGroupFavorite={props.deleteCuratorGroupFavorite}
              />
            ))}
          </div>
        ) : isSearching ? (
          <div className='search-results empty'>
            {_.range(8).map((_, idx) => (
              <div className='shop-header-search-result empty'>
                <div className='main-result'>
                  <div className='image-container empty'>
                    <div className='empty'>S</div>
                  </div>
                  <div className='term'>Searching...</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <ShopHeaderEmptySearchResultsPanel
            user={props.user}
            shop={shop}
            searchVal={searchVal}
            setSearchVal={props.setSearchVal}
            closePanel={closePanel}
            resultTypeDisplay={resultTypeDisplay}
            isScopedToCurator={isScopedToCurator}
            searchApplyingCuratorId={searchApplyingCuratorId}
            setSearchApplyingCuratorId={setSearchApplyingCuratorId}
            searchApplyingCuratorGroupId={searchApplyingCuratorGroupId}
            setSearchApplyingCuratorGroupId={setSearchApplyingCuratorGroupId}
          />
        )}
      </div>
    </div>
  );
};

ShopHeaderSearchResults.propTypes = {
  // From inside
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  // From outside
  searchVal: PropTypes.string.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  searchInputEl: PropTypes.object.isRequired,
  setOnSearchFormSubmit: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  openCuratorGroupModal,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
})(withRouter(ShopHeaderSearchResults));
