import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ProductQA.scss';

const ProductQA = props => {
  const { product, isFetchingFull } = props;

  const additional_descriptions = JSON.parse(product?.additional_descriptions || '{}');

  let qaSections = [];
  if (additional_descriptions.description_why_buy) {
    qaSections.push({
      question: 'You should buy this product if...',
      answer: additional_descriptions.description_why_buy
    });
  }

  if (additional_descriptions.description_what_people_say) {
    qaSections.push({
      question: 'What people are saying...',
      answer: additional_descriptions.description_what_people_say
    });
  }

  if (additional_descriptions.description_why_keep_searching) {
    qaSections.push({
      question: 'You should keep searching if...',
      answer: additional_descriptions.description_why_keep_searching
    });
  }

  const additionalClasses = { loading: isFetchingFull };

  if (!isFetchingFull && !qaSections.length) return null;

  return (
    <div className={cn('product-qa-outer-container', additionalClasses)}>
      <div className={cn('product-qa-inner-container', additionalClasses)}>
        <div className={cn('qa-sections', additionalClasses)}>
          {qaSections.map((section, index) => {
            const { question, answer } = section;
            return (
              <div key={index} className={cn('qa-section', additionalClasses)}>
                <div className='question'>{question}</div>
                <div className='answer'>{answer}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ProductQA.propTypes = {
  // From Outside
  product: PropTypes.object,
  isFetchingFull: PropTypes.bool.isRequired,

  // From Inside
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(ProductQA);
