import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faPlus, faCheck } from '@fortawesome/pro-light-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';

import { getDomainFromUrl } from '../../Helpers/formatting';
import { getSmartImage, getCollectionsTitle } from '../../Helpers/helpers';
import { getAdjPayoutRate, getAdjPayoutRateData, isAdmin } from '../../Helpers/user_helpers';
import { cleanAlgoliaOtherImageData } from '../../Helpers/sibling_helpers';
import { getMerchantHomeUrl } from '../../Helpers/merchant_helpers';

import './AddPinModalProducts.scss';

import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';

class AddPinModalProducts extends Component {
  static propTypes = {
    results: PropTypes.array.isRequired,
    pinResults: PropTypes.array.isRequired,
    consultPinResults: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    collaborations: PropTypes.object.isRequired,
    removePin: PropTypes.func.isRequired,
    selectSearchResult: PropTypes.func.isRequired,
    selectQuickSearchResult: PropTypes.func.isRequired,
    selectPinResult: PropTypes.func.isRequired,
    selectQuickPinResult: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    switchMode: PropTypes.func.isRequired,
    loadingResults: PropTypes.bool,
    page: PropTypes.number,
    adminControlMode: PropTypes.bool,
    isUrlAddMode: PropTypes.bool,
    isPinAddMode: PropTypes.bool,
    isHighRatesMode: PropTypes.bool,
    isSearchAddMode: PropTypes.bool,
    linksInProgress: PropTypes.array.isRequired,
    Editing_Collection_id: PropTypes.number,
    Editing_ConsultResult_id: PropTypes.number,
    Editing_Contract_id: PropTypes.number,

    facets: PropTypes.object.isRequired,
    brandFilters: PropTypes.array.isRequired,
    retailerFilters: PropTypes.array.isRequired,
    toggleFilter: PropTypes.func.isRequired
  };

  state = {
    urlsBeingAdded: []
  };

  getAugmentedResults = () => {
    const { results, brandFilters, retailerFilters } = this.props;
    let [exactMatches, nearMisses] = [[], []];
    results.forEach(result => {
      if (
        (brandFilters.length && !brandFilters.map(s => s.toLowerCase()).includes(result.brand.toLowerCase())) ||
        (retailerFilters.length && !retailerFilters.map(s => s.toLowerCase()).includes(result.retailer.toLowerCase()))
      ) {
        nearMisses.push(result);
      } else {
        exactMatches.push(result);
      }
    });
    return {
      exactMatches: exactMatches,
      nearMisses: nearMisses
    };
  };

  pinFromCollection = result => {
    if (!this.props.Editing_Collection_id) return null;
    const { url, link } = result;
    const visiblePins = this.props.store?.visibleCollection?.pins || [];
    const visiblePin = _.find(visiblePins, pin => pin.link === (url || link));
    return visiblePin;
  };

  pinFromConsult = result => {
    if (!this.props.Editing_ConsultResult_id) return null;
    const { url, link } = result;
    const visiblePins = this.props.store?.visibleConsultResult?.pins || [];
    const visiblePin = _.find(visiblePins, pin => pin.link === (url || link));
    return visiblePin;
  };

  pinFromContract = result => {
    if (!this.props.Editing_Contract_id) return null;
    const { url, link } = result;
    const visiblePins = this.props.collaborations?.visibleContract?.pins || [];
    const visiblePin = _.find(visiblePins, pin => pin.link === (url || link));
    return visiblePin;
  };

  isBeingAdded = result => {
    const { url, link } = result;
    return !!_.find(this.props.linksInProgress, l => l === (url || link));
  };

  pinBlock = (pin, idx) => {
    const { user, store, selectQuickPinResult, removePin, selectPinResult } = this.props;
    const { image, id, title, link, num_uses } = pin;
    const { merchantData, visibleCollection } = store;
    const domain = getDomainFromUrl(link);
    const payoutData = _.get(merchantData, domain, {});
    const merchantName = _.get(payoutData, 'name', domain);
    const payout = getAdjPayoutRate(visibleCollection ? visibleCollection.user : user, payoutData) || payoutData.payout;
    const pinFromCollection = this.pinFromCollection(pin);
    const pinFromConsult = this.pinFromConsult(pin);
    const pinFromContract = this.pinFromContract(pin);
    return (
      <div className='product-result' key={id}>
        <img alt='Product' src={getSmartImage(image)} />
        <div className='meta-container'>
          <div className='main'>
            <div className='title'>{title}</div>
            <div className='subtitle'>Added {num_uses === 1 ? 'once' : `${num_uses} times`}</div>
          </div>
          <div className='details'>
            <div className='sold-at'>
              <a href={link} target='_blank' rel='noopener noreferrer'>
                {merchantName || domain}
                <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
              </a>
            </div>
            <div className='commission'>{payout ? `${payout.toFixed(0)}% commission` : '...'}</div>
          </div>
        </div>
        <div className='add-product-button' onClick={() => selectPinResult(pin)}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        {this.isBeingAdded(pin) ? (
          <div className='in-collection-loader quick-add-el'>
            <Loader size={60} />
          </div>
        ) : pinFromCollection || pinFromConsult || pinFromContract ? (
          <div onClick={() => removePin(pinFromCollection || pinFromConsult || pinFromContract)} className='in-collection-label quick-add-el'>
            <FontAwesomeIcon icon={faCheck} />
            {`In ${pinFromCollection ? getCollectionsTitle() : pinFromContract ? 'Collaboration' : 'Consult'}`}
          </div>
        ) : (
          <div onClick={() => selectQuickPinResult(pin)} className='quick-add-label quick-add-el'>
            <FontAwesomeIcon icon={faPlus} />
            Quick Add
          </div>
        )}
      </div>
    );
  };

  consultPinBlock = (pin, idx) => {
    const { store } = this.props;
    const { image, description, id, title, link, consult_result } = pin;
    const { merchantData, visibleCollection } = store;
    const { clientName } = consult_result || {};
    const domain = getDomainFromUrl(link);
    const payoutData = _.get(merchantData, domain, {});
    const merchantName = _.get(payoutData, 'name', domain);
    const payout = getAdjPayoutRate(visibleCollection ? visibleCollection.user : this.props.user, payoutData) || payoutData.payout;
    return (
      <div className='product-result' key={id}>
        <img alt='Product' src={getSmartImage(image)} />
        <div className='meta-container'>
          <div className='main'>
            <div className='title'>{title}</div>
            <div className='subtitle'>for {clientName}</div>
            {description && <div className='description'>"{description}"</div>}
          </div>
          <div className='details'>
            <div className='sold-at'>
              <a href={link} target='_blank' rel='noopener noreferrer'>
                {merchantName || domain}
                <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
              </a>
            </div>
            <div className='commission'>{payout ? `${payout.toFixed(0)}% commission` : '...'}</div>
          </div>
        </div>
        <div className='add-product-button' onClick={() => this.props.selectPinResult(pin)}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    );
  };

  resultBlock = (result, idx) => {
    const { store, selectSearchResult, selectQuickSearchResult, removePin } = this.props;
    const { merchantData, visibleCollection } = store;
    const {
      title,
      brand,
      primary_image_data,
      other_image_data,
      url,
      _snippetResult,
      hideFromPublicCatalog,
      Brand_id,
      Variant_id,
      catalog_group_ids
    } = result;
    const { image_url } = primary_image_data || {};
    const domain = getDomainFromUrl(url);
    const isBrandPartner = !!Brand_id;
    const other_image_data_unique = cleanAlgoliaOtherImageData(other_image_data);
    const payoutData = _.get(merchantData, domain, {});
    const name = _.get(payoutData, 'name', domain);
    const matchingVariant = { id: Variant_id, catalog_group_ids };
    const adjPayoutData = getAdjPayoutRateData(visibleCollection ? visibleCollection.user : this.props.user, payoutData, matchingVariant);
    const payout = adjPayoutData.rate || payoutData.payout;

    const formatJsx = string =>
      _.flatten(
        string
          .split('<em>')
          .map((parts, idx) =>
            !parts.includes('</em>') ? parts : [<span className='highlighted'>{parts.split('</em>')[0]}</span>, parts.split('</em>')[1] || '']
          )
      );
    const formattedTitleJsx = formatJsx(_.get(_snippetResult, ['title', 'value'], title));
    const formattedBrandJsx = formatJsx(_.get(_snippetResult, ['brand', 'value'], brand));
    const pinFromCollection = this.pinFromCollection(result);
    const pinFromConsult = this.pinFromConsult(result);
    const pinFromContract = this.pinFromContract(result);

    if (hideFromPublicCatalog && !window.__ADMIN_CONTROL_MODE__) return null;
    return (
      <div className='product-result' key={title + idx}>
        {image_url ? <img alt='Product' src={getSmartImage(image_url)} /> : <div className='image' />}
        <div className='meta-container'>
          <div className='main'>
            <div className='title'>
              {formattedTitleJsx.map((d, idx) => (
                <span key={idx}>{d}</span>
              ))}
            </div>
            <div className='subtitle'>
              {formattedBrandJsx.map((d, idx) => (
                <span key={idx}>{d}</span>
              ))}
            </div>
            {other_image_data_unique.length > 1 && <div className='variation-count'>{other_image_data_unique.length} variations</div>}
            {hideFromPublicCatalog && <div className='hidden-from-public-catalog'>Hidden from public catalog</div>}
          </div>
          <div className='details'>
            <div className='sold-at'>
              <a href={url} target='_blank' rel='noopener noreferrer'>
                {name}
                <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
              </a>
            </div>
            <div className='commission'>
              {payout ? `${payout.toFixed(0)}% commission` : '...'}
              {!!adjPayoutData.rateIcon?.icon && (
                <Tooltip
                  message={adjPayoutData.rateIcon.iconTip}
                  getIconDiv={() => <img src={adjPayoutData.rateIcon.icon} alt='Higher Rate' />}
                  outerClassName='merchant-payout-icon'
                />
              )}
            </div>
            {isBrandPartner && (
              <div className='partner'>
                <FontAwesomeIcon icon={faBadgeCheck} />
                ShopMy Partner
              </div>
            )}
          </div>
        </div>
        <div className='add-product-button' onClick={() => selectSearchResult(result)}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        {this.isBeingAdded(result) ? (
          <div className='in-collection-loader quick-add-el'>
            <Loader size={60} />
          </div>
        ) : pinFromCollection || pinFromConsult || pinFromContract ? (
          <div onClick={() => removePin(pinFromCollection || pinFromConsult || pinFromContract)} className='in-collection-label quick-add-el'>
            <FontAwesomeIcon icon={faCheck} />
            {`In ${pinFromCollection ? getCollectionsTitle() : pinFromContract ? 'Collaboration' : 'Consult'}`}
          </div>
        ) : (
          <div onClick={() => selectQuickSearchResult(result)} className='quick-add-label quick-add-el'>
            <FontAwesomeIcon icon={faPlus} />
            Quick Add
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      results,
      pinResults,
      consultPinResults,
      merchantResults,
      isUrlAddMode,
      adminControlMode,
      isConsultPinAddMode,
      isSearchAddMode,
      isPinAddMode,
      isHighRatesMode,
      brandFilters,
      retailerFilters,
      toggleFilter,
      facets,
      loadingResults,
      page,
      searchValue,
      lockedCatalogBrand,
      store
    } = this.props;
    const { visibleCollection } = store;
    const { exactMatches, nearMisses } = this.getAugmentedResults();

    const merchantFacets = _.orderBy(_.keys(_.get(facets, 'retailer', [])), filter => _.get(facets, ['retailer', filter]), 'desc');
    const brandFacets = _.orderBy(_.keys(_.get(facets, 'brand', [])), filter => _.get(facets, ['brand', filter]), 'desc');
    const showMerchantFilters = !!merchantFacets.length || !!retailerFilters.length;
    const showBrandFilters = !!brandFacets.length || !!brandFilters.length;

    const noResults =
      (isSearchAddMode && !results.length) ||
      (isPinAddMode && !pinResults.length) ||
      (isConsultPinAddMode && !consultPinResults.length) ||
      (isHighRatesMode && !merchantResults.length) ||
      (isUrlAddMode && !loadingResults);
    return (
      <div className='product-results-container'>
        <div
          className={classnames('product-filtering-container', {
            short: true,
            long: brandFacets.length >= 7 || merchantFacets.length >= 7
          })}
        >
          {showBrandFilters && (
            <div className='product-filter-row'>
              <div className='header'>Filter by Brand</div>
              <div className='product-filters'>
                {_.map(_.uniq([...brandFacets, ...brandFilters]), filter => {
                  const isSelected = brandFilters.includes(filter);
                  const inactive = !isSelected && brandFilters.length;
                  return (
                    <div
                      key={filter}
                      className={classnames('product-filter', {
                        selected: isSelected,
                        inactive
                      })}
                      onClick={() => toggleFilter(filter, 'brand')}
                    >
                      {filter}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {showMerchantFilters && !lockedCatalogBrand && (
            <div className='product-filter-row'>
              <div className='header'>Filter by Merchant Website</div>
              <div className='product-filters'>
                {_.map(_.uniq([...merchantFacets, ...retailerFilters]), filter => {
                  const isSelected = retailerFilters.includes(filter);
                  const inactive = !isSelected && retailerFilters.length;
                  return (
                    <div
                      key={filter}
                      className={classnames('product-filter', {
                        selected: isSelected,
                        inactive
                      })}
                      onClick={() => toggleFilter(filter, 'retailer')}
                    >
                      {filter}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!showMerchantFilters && !showBrandFilters && !!results.length && (
            <div className='unlikely-match-results-alert'>
              We don't seem to have any great matches for the search <i>"{searchValue}"</i>. Please try to find the product from our most likely
              matches below, or you can always <span onClick={() => this.props.switchMode('url', false)}>paste a product link</span>{' '}
              {lockedCatalogBrand ? `from the ${lockedCatalogBrand.name} website.` : 'from any website'}.
            </div>
          )}
        </div>
        {isAdmin(this.props.user) && <div className='debug-section'>Page {this.props.page}</div>}
        {noResults && !loadingResults ? (
          <div className='empty-search-results-message'>
            {isPinAddMode ? (
              <>
                We couldn't find any shelf products with a title containing "{searchValue}". Check your spelling or{' '}
                <span onClick={() => this.props.switchMode('search')}>click here</span> to search the product catalog.
              </>
            ) : isUrlAddMode ? (
              <>
                We couldn't analyze "{searchValue}". Make sure the URL is formatted correctly and try again, or{' '}
                <span onClick={() => this.props.switchMode('search')}>search our product catalog</span>.
              </>
            ) : isHighRatesMode ? (
              <>
                We couldn't find any merchants with the search term "{searchValue}". If you would like to add a merchant that we do not currently
                have, please reach out to us by chat and we will explore how to get them on the platform!
              </>
            ) : (
              <>
                We didn't find any results for "{searchValue}". Try another search or{' '}
                <span onClick={() => this.props.switchMode('url', false)}>paste a product link</span>{' '}
                {lockedCatalogBrand ? `from the ${lockedCatalogBrand.name} website` : 'from any website'}.
                {lockedCatalogBrand ? (
                  <>
                    {' '}
                    If you are looking for products outside of {lockedCatalogBrand.name}, you can update your account to a full account via{' '}
                    <a href='/settings'>Account Settings</a>.
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        ) : isSearchAddMode ? (
          <div className='product-results'>
            {_.map(exactMatches, this.resultBlock)}
            {!!nearMisses.length && !loadingResults && (
              <>
                <div className='result-class-seperator'>
                  <div className='header'>Other options</div>
                  <div className='subheader'>The following are close matches outside your current filters.</div>
                </div>
                {_.map(nearMisses, this.resultBlock)}
              </>
            )}
          </div>
        ) : isHighRatesMode ? (
          <div className='high-rate-merchants'>
            {_.map(merchantResults, merchant => {
              const { domain, name, source, rateType, Brand_id } = merchant;
              const payout = getAdjPayoutRate(visibleCollection ? visibleCollection.user : this.props.user, merchant);
              return (
                <div key={domain} className='high-rate-merchant-container'>
                  <a href={getMerchantHomeUrl(merchant)} target='_blank' rel='noopener noreferrer' className='high-rate-merchant'>
                    <div>
                      <div className='name'>{name}</div>
                      <div className='domain'>{domain}</div>
                      {Brand_id && (
                        <div className='partner'>
                          <FontAwesomeIcon icon={faBadgeCheck} />
                          ShopMy Partner
                        </div>
                      )}
                    </div>
                    <div className='rate'>{rateType === 'percentage' ? `${payout.toFixed(0)}%` : `$${payout.toFixed(2)}`}</div>
                    {adminControlMode && <div className='source'>{source}</div>}
                  </a>
                </div>
              );
            })}
          </div>
        ) : isConsultPinAddMode ? (
          <div className='product-results'>{_.map(consultPinResults, this.consultPinBlock)}</div>
        ) : (
          <div className='product-results'>{_.map(pinResults, this.pinBlock)}</div>
        )}
        {loadingResults && page > 0 && (
          <div className='loading-new-page-loader'>
            <Loader size={80} />
          </div>
        )}
      </div>
    );
  }
}

export default AddPinModalProducts;
