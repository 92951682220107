import badge from './images/logos/logo.png';
import app_icon from './images/logos/app_icon.png';
import full_logo from './images/logos/shop_my.png';

// Home
import aboutIcon from '../static/images/home/menu-icon-about.png';
import affiliatesIcon from '../static/images/home/menu-icon-affiliate-links.png';
import blogIcon from '../static/images/home/menu-icon-blog.png';
import partnershipsIcon from '../static/images/home/menu-icon-brand-partnerships.png';
import careersIcon from '../static/images/home/menu-icon-careers.png';
import amplifyIcon from '../static/images/home/menu-icon-amplify.png';
import contactIcon from '../static/images/home/menu-icon-contact.png';
import shopIcon from '../static/images/home/menu-icon-digital-shop.png';
import discoverIcon from '../static/images/home/menu-icon-discover.png';
import engageIcon from '../static/images/home/menu-icon-engage.png';
import faqIcon from '../static/images/home/menu-icon-faq.png';
import brandsIcon from '../static/images/home/menu-icon-for-brands.png';
import creatorsIcon from '../static/images/home/menu-icon-for-creators.png';
import guideIcon from '../static/images/home/menu-icon-guide.png';
import trackIcon from '../static/images/home/menu-icon-track.png';

// Social
import instagramIcon from '../static/images/social_icons/instagram_dark.png';
import instagramIconLight from '../static/images/social_icons/instagram_light.png';
import instagramIconWhite from '../static/images/social_icons/instagram_white.png';
import youtubeIcon from '../static/images/social_icons/youtube_dark.png';
import youtubeIconWhite from '../static/images/social_icons/youtube_white.png';
import youtubeIconPrimary from '../static/images/social_icons/youtube_primary.png';
import tiktokIcon from '../static/images/social_icons/tiktok_dark.png';
import tiktokIconLight from '../static/images/social_icons/tiktok_light.png';
import tiktokIconWhite from '../static/images/social_icons/tiktok_white.png';
import twitterIcon from '../static/images/social_icons/twitter_dark.png';
import twitterIconLight from '../static/images/social_icons/twitter_light.png';
import twitterIconWhite from '../static/images/social_icons/twitter_white.png';
import snapchatIcon from '../static/images/social_icons/snapchat_dark.png';
import snapchatIconLight from '../static/images/social_icons/snapchat_light.png';
import snapchatIconWhite from '../static/images/social_icons/snapchat_white.png';
import spotifyIcon from '../static/images/social_icons/spotify_dark.png';
import spotifyIconLight from '../static/images/social_icons/spotify_light.png';
import spotifyIconWhite from '../static/images/social_icons/spotify_white.png';
import pinterestIcon from '../static/images/social_icons/pinterest_dark.png';
import pinterestIconLight from '../static/images/social_icons/pinterest_light.png';
import pinterestIconWhite from '../static/images/social_icons/pinterest_white.png';

// Misc
import arrowRightIcn from '../static/images/misc/arrow-right.png';
import shopifyIcon from '../static/images/misc/shopify.png';
import aiIcon from '../static/images/misc/ai.png';
import quoteIcon from '../static/images/misc/quote.png';
import quote2Icon from '../static/images/misc/quote2.png';
import mapPinIcon from '../static/images/misc/map_pin.png';
import calendarIcon from '../static/images/misc/calendar.png';
import trackPerformanceIcon from '../static/images/misc/track_performance.png';
import higherRateAlertIcon from '../static/images/misc/higher_rate.png';

// Opportunities
import amplifyBadgeLight from '../static/images/opportunities/amplify_badge_light.png';
import amplifyBadgeDark from '../static/images/opportunities/amplify_badge_dark.png';

const icons = {
  misc: {
    shopify: shopifyIcon,
    ai: aiIcon,
    arrowRight: arrowRightIcn,
    quote: quoteIcon,
    quote2: quote2Icon,
    map_pin: mapPinIcon,
    calendar: calendarIcon,
    track_performance: trackPerformanceIcon,
    higher_rate_alert: higherRateAlertIcon
  },
  home: {
    nav: {
      about: aboutIcon,
      affiliates: affiliatesIcon,
      blog: blogIcon,
      partnerships: partnershipsIcon,
      careers: careersIcon,
      amplify: amplifyIcon,
      contact: contactIcon,
      shop: shopIcon,
      discover: discoverIcon,
      engage: engageIcon,
      faq: faqIcon,
      brands: brandsIcon,
      creators: creatorsIcon,
      guide: guideIcon,
      track: trackIcon
    }
  },
  brand: {
    badge: badge,
    app_icon: app_icon,
    full_logo: full_logo
  },
  opportunities: {
    amplify_badge_light: amplifyBadgeLight,
    amplify_badge_dark: amplifyBadgeDark
  },
  social: {
    instagram: {
      dark: instagramIcon,
      light: instagramIconLight,
      white: instagramIconWhite
    },
    youtube: {
      dark: youtubeIcon,
      light: youtubeIcon,
      white: youtubeIconWhite,
      primary: youtubeIconPrimary
    },
    tiktok: {
      dark: tiktokIcon,
      light: tiktokIconLight,
      white: tiktokIconWhite
    },
    twitter: {
      dark: twitterIcon,
      light: twitterIconLight,
      white: twitterIconWhite
    },
    snapchat: {
      dark: snapchatIcon,
      light: snapchatIconLight,
      white: snapchatIconWhite
    },
    spotify: {
      dark: spotifyIcon,
      light: spotifyIconLight,
      white: spotifyIconWhite
    },
    pinterest: {
      dark: pinterestIcon,
      light: pinterestIconLight,
      white: pinterestIconWhite
    }
  }
};

export default icons;
