import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './HomeHeaderShop.scss';

import { focusShopSearch } from '../../../Actions/UIActions';
import { setActiveCurator, setActiveCuratorGroup } from '../../../Actions/ShopActions';

const HomeHeaderShop = props => {
  const header = (
    <>
      Search less, discover <i>more</i>.{/* The future of shopping is <i>curated</i>. */}
    </>
  );
  const subheader = 'Make a personalized store based on the recommendations of your favorite people.';
  const seo_header = 'ShopMy | Shop From Your Favorite People';
  const seo_description = 'Make a personalized store based on the recommendations of your favorite people.';

  const clickToStartShopping = () => {
    props.setActiveCuratorGroup(null);
    props.setActiveCurator(null);
  };

  return (
    <div className='home-header-shop-outer-container'>
      <MetaTags>
        <title>{seo_header}</title>
        <meta property='og:title' content={seo_header} />
        <meta property='description' content={seo_description} />
        <meta property='og:description' content={seo_description} />
      </MetaTags>
      <div className='home-header-shop-inner-container'>
        <div className='main-container'>
          <h1 className='title'>{header}</h1>
          <h2 className='subtitle'>{subheader}</h2>
          <div className='actions'>
            <Link to='/shop/circles' className='action primary'>
              Find Stores
            </Link>
            <Link to='/shop' onClick={clickToStartShopping} className='action secondary'>
              Start Shopping
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeHeaderShop.propTypes = {
  user: PropTypes.object.isRequired,
  focusShopSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  focusShopSearch,
  setActiveCurator,
  setActiveCuratorGroup
})(HomeHeaderShop);
