import _ from 'lodash';
import slugify from 'slugify';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

import ConfirmPrompt from '../Components/General/ConfirmPrompt';

import { getProductIdsPromoted, getCuratorGroups, getSettings } from './user_helpers';

// Home Page
export const getFeaturedGroups = shop => shop?.featuredGroups || [];

// Hierarchy Page
export const getIndustryById = (shop, Industry_id) => {
  for (const industry of shop.industries) {
    if (industry.id === Industry_id) return industry;
  }
  return null;
};

export const getDepartmentById = (shop, Department_id) => {
  for (const industry of shop.industries) {
    for (const department of industry.departments) {
      if (department.id === Department_id) return department;
    }
  }
  return null;
};
export const getCategoryById = (shop, Category_id) => {
  for (const industry of shop.industries) {
    for (const department of industry.departments) {
      for (const category of department.categories) {
        if (category.id === Category_id) return category;
      }
    }
  }
  return null;
};

export const getIndustries = shop => shop?.industries || [];
export const getDepartments = shop => _.flatMap(getIndustries(shop), 'departments');
export const getCategories = shop => _.flatMap(getDepartments(shop), 'categories');

export const getVisibleIndustries = shop => getIndustries(shop).filter(i => !i.isHidden);
export const getVisibleDepartments = shop => getDepartments(shop).filter(d => !d.isHidden);
export const getVisibleCategories = shop => getCategories(shop).filter(c => !c.isHidden);

export const getLinkToShopForGroupUser = (groupUser, filters) => getLinkToShopForUserId(groupUser.User_id, filters);
export const getLinkToShopForUserId = (User_id, filters) => {
  const params = {};
  if (filters?.Category_id) params.Category_id = filters.Category_id;
  if (filters?.Department_id) params.Department_id = filters.Department_id;
  if (filters?.Industry_id) params.Industry_id = filters.Industry_id;
  if (User_id) params.Curator_id = User_id;
  if (filters?.tab) params.tab = filters.tab;
  return `/shop?${new URLSearchParams(params).toString()}`;
};
export const getLinkToShopForUsername = (username, params = {}) => {
  const validParams = {};
  const validParamsKeys = ['Category_id', 'Department_id', 'Industry_id', 'tab'];
  for (const key of validParamsKeys) {
    if (params[key]) validParams[key] = params[key];
  }
  return `/shop/${username}?${new URLSearchParams(validParams).toString()}`;
};
export const getLinkToShopForCuratorGroupId = id => getLinkToShopForCuratorGroup({ id });
export const getLinkToShopForCuratorGroup = (group, additionalParams = {}) => {
  const params = new URLSearchParams({
    ...(group.Industry_id && { Industry_id: group.Industry_id }),
    ...(group.Department_id && { Department_id: group.Department_id }),
    ...(group.Category_id && { Category_id: group.Category_id }),
    ...additionalParams
  });

  params.append('CuratorGroup_id', group.id); // Ensures it's always last

  return `/shop?${params.toString()}`;
};

export const getLinkToBrandPageViaId = id => `/shop?AllBrand_id=${id}`;
export const getLinkToCategoryPageViaId = id => `/shop?Category_id=${id}`;
export const getLinkToCollectionPageViaId = id => `/shop/collections/${id}`;
export const getLinkToProductPageViaId = (id, attributionParams) =>
  `/shop/product/${id}${attributionParams ? '?' : ''}${new URLSearchParams(attributionParams).toString()}`;
export const getLinkToPrivateCuratorGroupPage = group => `/shop/my/circles/${group.id}`;
export const getLinkToPublicCuratorGroupPage = group => `/shop/circles/${group.id}?share=${group.shareCode}`;

export const getCoverImageForGroup = group => {
  /* Returns the cover image to show in the CuratorGroupCard background. */

  // Always take the group image if there is an override
  if (group.coverImage) return group.coverImage;

  // Otherwise, match the preview image
  return getPreviewImageForGroup(group);
};

export const getPreviewImageForGroup = group => {
  /* Returns the preview image to show in the CuratorGroupCard Badge. */

  // Always take the group image if there is an override
  if (group.previewImage) return group.previewImage;

  // If there are preview_group_users, meaning this is not our group, take the first user image
  const imageFromPreviews = group.preview_group_users?.find(u => u.user?.image)?.user?.image;
  if (imageFromPreviews) return imageFromPreviews;

  // If there are users, this means this is a discover group result
  const imageFromUsers = group.users?.find(u => u.image).image;
  if (imageFromUsers) return imageFromUsers;

  // Otherwise, take the first user image from the group_users after sorting
  const imageFromGroup = _.orderBy(group.group_users, 'sortOrderRank')?.find(u => u.user?.image)?.user?.image;
  return imageFromGroup;
};

export const getShopHeaderSearchElId = () => 'shop-header-search-input';
export const getShopHeaderSearchEl = () => document.getElementById(getShopHeaderSearchElId());

// Recent
export const getRecentlyViewedProducts = shop => shop.recentlyViewedProductsWithTimestamp?.map(p => p.product) || [];

// Discover
export const getDiscoverCurators = shop => shop.discover?.curators || [];
export const getDiscoverGroups = shop => shop.discover?.groups || [];
export const getDiscoverFilters = shop => shop.discover?.filters || {};

// Curators
export const getActiveCurator = shop => shop?.activeCurator;
export const getActiveCuratorGroup = shop => shop?.activeCuratorGroup;
export const getAttributableCuratorId = shop => {
  const attr = shop.attributableCuratorId;
  return typeof attr === 'number' ? attr : null;
};
export const getAttributableCuratorGroupId = shop => {
  const attr = shop.attributableCuratorGroupId;
  return typeof attr === 'number' ? attr : null;
};

// For generating hierarchy option dropdowns
export const generateHierarchyOptionValue = (iid, did, cid) => `${iid || ''}-${did || ''}-${cid || ''}`;
export const expandHierarchyOptionValue = value => {
  const [Industry_idRaw, Department_idRaw, Category_idRaw] = value.split('-');
  const Industry_id = +Industry_idRaw || null;
  const Department_id = +Department_idRaw || null;
  const Category_id = +Category_idRaw || null;
  return { Industry_id, Department_id, Category_id };
};

export const getHierarchyOptions = shop => {
  return [
    {
      label: 'All Industries',
      options: [
        {
          label: 'Trusted for All Industries',
          value: generateHierarchyOptionValue()
        }
      ]
    },
    ...shop.industries?.map(industry => ({
      label: industry.name,
      options: [
        {
          label: `Trusted for All ${industry.name}`,
          value: generateHierarchyOptionValue(industry.id),
          industryId: industry.id
        },
        ...industry.departments.map(department => ({
          label: `Trusted for ${department.name}`,
          value: generateHierarchyOptionValue(industry.id, department.id)
        }))
      ]
    }))
  ];
};

// Related to Products
export const isProductIdFavorited = (user, id) => {
  const productIds = getProductIdsPromoted(user);
  return productIds.includes(id);
};

export const getActiveProduct = shop => shop.activeProduct;
export const isFetchingFullProduct = shop => !!shop.isFetchingFullProduct;

// Modals
export const openCreateGroupModal = ({ user, createCuratorGroup, onSuccessfulGroupAdd }) => {
  const curatorGroups = getCuratorGroups(user);
  const defaultTitle = !curatorGroups.length ? 'My Shopping Circle' : '';
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmPrompt
        header='What do you want to call this circle?'
        subheader='Shopping circles allow you to select which people you want to curate your shopping experience.'
        placeholder='My Shopping Circle'
        isSingleLine
        preloaded={defaultTitle}
        onCancel={onClose}
        submitBtnDisplay='Create Circle'
        submitMustReturnTrueToClose
        onSubmitAwait={async title => {
          if (!title) {
            window.ALERT.warn('Please enter a title for your circle');
            return false;
          }
          const resp = await createCuratorGroup({ title }, { source: 'Create Group Modal' });
          resp.group && onSuccessfulGroupAdd(resp.group);
          return true;
        }}
      />
    )
  });
};

export const getHeaderSubheaderFromTasteProfile = taste_profile => {
  /*
    Ensure the taste_profile is a clean display string.
  */
  let header = taste_profile.split('\n')[0];
  let subheader = taste_profile
    .split('\n')
    .slice(1)
    .join('\n');

  // Clean up the headline
  header = header.replace(/\*\*/g, '').trim(); // Remove markdown bold markers first
  header = header.replace(/^(Taste Profile:\s*|Headline:\s*)/i, '').trim(); // Remove known prefixes (e.g., "Taste Profile:" or "Headline:")
  header = header.replace(/"/g, '').trim(); // Remove any remaining double quotes

  return { header, subheader };
};

export const getShopVisibilitySettingExplainer = user => {
  const { productsVisibleInShop } = getSettings(user) || {};

  if (productsVisibleInShop === 'all') return `Your setting is to show all products so any hidden product has been manually hidden by you.`;
  if (productsVisibleInShop === 'collections')
    return `Only products you've added to visible collections will show up in your shop. You can manually show products as well.`;
  if (productsVisibleInShop === 'collections-and-popular')
    return `Products must be in a visible collection or have driven 10 clicks to show up in your shop. You can manually show products as well.`;

  return null;
};

/*
  Deprecated.
  
  From the old Best in Beauty Website.
*/

export const getDisplayInfoFromFilters = activeFilters => {
  const { industry, department, category, tag } = activeFilters || {};
  const displayInfo = {
    title: 'Top Products',
    seoTitle: 'Top Products Used By Content Creators',
    subtitle: `We've pulled together our experts' top-tier recommendations to give you a view of the top products promoted across the board.`
  };
  if (tag) {
    displayInfo.title = `Best in ${category?.name} | ${tag?.name}`;
    displayInfo.seoTitle = tag.seoTitle || displayInfo.title;
    displayInfo.subtitle =
      tag.seoDescription || `The most highly recommended products in ${category?.name} | ${tag?.name}, as selected by the experts.`;
    displayInfo.createdAt = tag.createdAt;
    displayInfo.updatedAt = tag.updatedAt;
  } else if (category) {
    displayInfo.title = `Best in ${category?.name}`;
    displayInfo.seoTitle = category.seoTitle || displayInfo.title;
    displayInfo.subtitle = category.seoDescription || `The most highly recommended products in ${category?.name}, as selected by the experts.`;
    displayInfo.createdAt = category.createdAt;
    displayInfo.updatedAt = category.updatedAt;
  } else if (department) {
    displayInfo.title = `Best in ${department?.name}`;
    displayInfo.seoTitle = department.seoTitle || displayInfo.title;
    displayInfo.subtitle = department.seoDescription || `The most highly recommended products in ${department?.name}, as selected by the experts.`;
    displayInfo.createdAt = department.createdAt;
    displayInfo.updatedAt = department.updatedAt;
  } else if (industry) {
    displayInfo.title = `Best in ${industry?.name}`;
    displayInfo.seoTitle = industry.seoTitle || displayInfo.title;
    displayInfo.subtitle = industry.seoDescription || `The most highly recommended products in ${industry?.name}, as selected by the experts.`;
    displayInfo.createdAt = industry.createdAt;
    displayInfo.updatedAt = industry.updatedAt;
  }

  return {
    title: displayInfo.title,
    titleStub: displayInfo.title.replace(/([Bb]est [Ii]n )+/, ''),
    seoTitle: displayInfo.seoTitle,
    subtitle: displayInfo.subtitle,
    createdAt: displayInfo.createdAt && moment(displayInfo.createdAt).format('YYYY-MM-DD'),
    createdOn: displayInfo.updatedAt && moment(displayInfo.updatedAt).format('YYYY-MM-DD')
  };
};

export const isNonStandardSize = variant => {
  const adjUrl = variant.rawTitle ? variant.rawTitle.toLowerCase() : '';
  return (
    adjUrl.includes(' mini') || adjUrl.includes('mini ') || adjUrl.includes('value size') || adjUrl.includes('maxi size') || adjUrl.includes('travel')
  );
};

export const getVisibleVariantsForProduct = product => {
  /*
    Given a product, determine which of the variants we want to show.
  */
  const { variants } = product || {};
  const orderedVariants = _.orderBy(variants, [isNonStandardSize, v => v.retailer.toLowerCase()]);
  return _.uniqBy(orderedVariants, 'retailer');
};

export const getCatalogObjectsFromProduct = product => {
  const { category } = product || {};
  const { department } = category || {};
  const { industry } = department || {};
  return {
    category,
    department,
    industry
  };
};

export const getCollectionType = c => {
  /*  
    Deprecated as of February 8th 2023 now that we use "Sections"
  */
  if (c.forceType) return c.forceType;
  if (c.social_links && c.social_links.includes('youtube')) return 'shelf';
  if (c.image) return 'post';
  return 'shelf';
};

export const getCollectionSkin = c => c.skinType || 'classic';

export const sortReviews = reviews =>
  _.orderBy(
    reviews,
    review => {
      if (_.get(review, 'user.isAdmin')) return 10e8;
      return review.numLikes;
    },
    'desc'
  );
export const getPriceForProduct = product => {
  /*
    Given a product, determine which price to show according to the following logic.

    1) Avoid non standard sizes
    2) If a standard size price appears more than 2 times, use it
    3) If no repeated price, use lowest price
    4) Fallback on backup price
  */
  const { fallbackPrice, variants } = product || {};
  const visibleVariants = getVisibleVariantsForProduct(product);
  const orderedVariants = _.orderBy(visibleVariants, [isNonStandardSize, 'price'], ['asc', 'asc']);

  // If a standard size appears more than 2 times, use it
  const counts = _.countBy(
    _.filter(variants, v => !isNonStandardSize(v)),
    'price'
  );
  const repeatedPrice = parseFloat(_.reduce(counts, (r, v, k) => (v > 2 && r[1] < v ? [k, v] : r), [0, 0])[0]);

  // Finally pull the low price
  const bestPrice = _.get(orderedVariants, '0.price');
  return repeatedPrice || bestPrice || fallbackPrice;
};

export const getProductStub = product => {
  return slugify(`${product.brand} ${product.title}`).toLowerCase();
};
