import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import cn from 'classnames';
import './ProductCurators.scss';

import CuratorCardNew from '../Curators/CuratorCardNew';

const ProductCurators = props => {
  const { user, product, isYourCircle, isActiveGroup } = props;

  let header,
    subheader,
    count,
    curators = [];

  if (isYourCircle) {
    curators = product?.circleCurators;
    count = product?.circleCuratorCount;
    header = `Promoted by ${commaNumber(count)} Curator${count === 1 ? '' : 's'} In Your Circles`;
    subheader = 'Explore the shops of curators in your circles who have recommended this product.';
  } else if (isActiveGroup) {
    curators = product?.activeGroupCurators;
    count = product?.activeGroupCuratorCount;
    header = `Recommended by ${commaNumber(count)} Curator${count === 1 ? '' : 's'} in ${product?.activeGroup?.title}`;
    subheader = 'See more recommendations from curators in this group.';
  } else {
    curators = product?.curators;
    count = product?.curatorCount;
    header = `Recommended by ${commaNumber(count)} Curator${count === 1 ? '' : 's'}`;
    subheader = 'Explore the shops of other curators who also have recommended this product.';
  }

  if (!curators || curators.length === 0) return null;

  const num_more = count - curators.length;

  return (
    <div className='section product-curators-container'>
      <div className='section-header-container'>
        <div className='section-header'>{header}</div>
        <div className='section-subheader'>{subheader}</div>
      </div>
      <div
        className={cn('product-curators', {
          yours: isYourCircle
        })}
      >
        {curators.map((curator, index) => {
          return <CuratorCardNew key={curator.id} user={user} curator={curator} large={isYourCircle} hideSocials lazyLoadImages />;
        })}
      </div>
      {num_more > 0 && (
        <div className='more-container'>
          <div className='more-label'>+ {commaNumber(num_more)} more</div>
        </div>
      )}
    </div>
  );
};

ProductCurators.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(ProductCurators);
