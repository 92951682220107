import React, { useState, useEffect } from 'react';
import './CatalogProductModal.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import Image from '../General/Image';
import Modal from '../General/Modal';
import { closeCatalogVariantModal } from '../../Actions/UIActions';
import { getBrandId, getCanBrandSetCustomAffiliateRates } from '../../Helpers/user_helpers';
import { addCustomAffiliateRate, updateCustomAffiliateRate, deleteCustomAffiliateRate } from '../../Actions/AnalyticsActions';
import { getBrandBaseCommissionRate } from '../../Helpers/catalog_helpers';
import CatalogProductModalSiblings from './CatalogProductModalSiblings';
import { getBrandCustomAffiliateRatesMap, getDetailedCatalogGroupRatesMap } from '../../Helpers/brand_helpers';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../Loader/Loader';
import { fetchCatalogVariants } from '../../APIClient/variants';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDomainFromUrl } from '../../Helpers/formatting';
import { isAffiliateOnlyPartnerBrand } from '../../Helpers/subscription_helpers';

const CatalogProductModal = props => {
  const {
    closeCatalogVariantModal,
    user,
    addCustomAffiliateRate,
    updateCustomAffiliateRate,
    deleteCustomAffiliateRate,
    analytics,
    ui,
    shouldShowVariantDomains
  } = props;

  const [variant, setVariant] = useState(props.variant);
  const [isLoadingParentVariant, setIsLoadingParentVariant] = useState(!!variant.requiresLoad);
  const [variantRate, setVariantRate] = useState('');
  const [isSavingVariantRate, setIsSavingVariantRate] = useState(false);

  const { variantRatesMap, variantSiblingRatesMap } = getBrandCustomAffiliateRatesMap(analytics);
  const catalogGroupRatesMap = getDetailedCatalogGroupRatesMap(analytics);
  const existingVariantRate = _.get(variantRatesMap, variant.id);
  const existingCatalogGroupRates = (variant.catalog_group_ids || []).map(id => _.get(catalogGroupRatesMap, id)).filter(Boolean);
  const existingCatalogGroupRate = _.maxBy(existingCatalogGroupRates, 'rate');

  const loadParentVariant = async () => {
    try {
      const Brand_id = getBrandId(user);
      const { variants } = await fetchCatalogVariants(Brand_id, { Variant_id: variant.id });
      setVariant(variants[0]);
      setIsLoadingParentVariant(false);
    } catch (error) {
      console.error('Error fetching product modal variant:', error);
      window.ALERT.error('There was an issue fetching details for this product rate.');
      closeCatalogVariantModal();
    }
  };

  useEffect(() => {
    setVariantRate(existingVariantRate?.rate?.toString() || '');

    if (variant.requiresLoad) {
      loadParentVariant();
    }
  }, [existingVariantRate?.rate]);

  const handleSetVariantRate = evt => {
    const inputRate = evt.target.value;

    if (!inputRate) {
      return setVariantRate('');
    }

    const numericalRate = parseFloat(inputRate);
    if (_.isNaN(numericalRate) || numericalRate < 0 || numericalRate > 100) {
      return;
    }

    // enforce 2 decimal places max
    const formattedRate = inputRate.match(/^\d*\.?\d{0,2}$/) ? inputRate : numericalRate.toFixed(2);
    setVariantRate(formattedRate);
  };

  const handleSaveRate = async ({ inputRate, SpecificVariant_id = null, SpecificVariantSibling_id = null }) => {
    const existingRate = SpecificVariant_id
      ? _.get(variantRatesMap, SpecificVariant_id)
      : SpecificVariantSibling_id
      ? _.get(variantSiblingRatesMap, SpecificVariantSibling_id)
      : null;

    if (!inputRate && existingRate) {
      await deleteCustomAffiliateRate(existingRate);
      return;
    } else if (!inputRate && !existingRate) {
      return;
    }

    const numericalRate = parseFloat(inputRate);
    const rateFloor = isAdminControlMode(ui) ? 0 : isAffiliateOnlyPartnerBrand(user) ? 10 : 5;
    if (_.isNaN(numericalRate) || numericalRate < rateFloor || numericalRate > 100) {
      window.ALERT.error(`Please enter a numerical rate between ${rateFloor} and 100.`);
      return;
    }

    const needsSave = numericalRate !== existingRate?.rate;
    if (!needsSave) return;

    const data = {
      id: existingRate?.id,
      SpecificVariant_id: SpecificVariant_id,
      SpecificVariantSibling_id: SpecificVariantSibling_id,
      rate: numericalRate
    };

    if (existingRate?.id) {
      await updateCustomAffiliateRate(existingRate, data);
    } else {
      await addCustomAffiliateRate(data);
    }
  };

  const handleSaveVariantRate = async inputRate => {
    setIsSavingVariantRate(true);
    await handleSaveRate({ inputRate, SpecificVariant_id: variant.id });
    setIsSavingVariantRate(false);
  };

  const shouldShowSiblingsBreakdown = variant.siblings?.length > 1; // every variant has at least 1 sibling
  const shouldAllowSettingVariantRates = getCanBrandSetCustomAffiliateRates(user);
  const shouldShowGroupRateExplainer = !!existingCatalogGroupRate?.basic_catalog_group;
  const baseCommissionRate = existingCatalogGroupRate?.rate || getBrandBaseCommissionRate(user);

  const getModalContent = () => {
    if (isLoadingParentVariant) {
      return <Loader size={80} />;
    }

    return (
      <div className='catalog-product-modal-content'>
        <div className='variant-info-container'>
          <div className='variant-image'>
            <Image src={variant.image} alt={variant.rawTitle || 'Default'} />
          </div>
          <div className='variant-meta'>
            <div className='variant-brand'>{variant.rawBrand}</div>
            <div className='variant-title'>{variant.rawTitle}</div>
            <div className='variant-info'>
              {variant.siblings?.length > 1 && (
                <>
                  <div className='sibling-count'>{variant.siblings.length} Variants</div>
                </>
              )}
              {!!variant.url && (
                <div
                  className='variant-external-link-container'
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {!!shouldShowVariantDomains ? (
                    <>
                      {variant.siblings?.length > 1 && ' • '}
                      <a href={variant.url} target='_blank' rel='noreferrer' className='variant-domain'>
                        {getDomainFromUrl(variant.url)}
                      </a>
                    </>
                  ) : (
                    <a href={variant.url} target='_blank' rel='noreferrer' className='variant-external-link'>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
          {shouldAllowSettingVariantRates && (
            <div className='variant-rate-container-outer'>
              <div className='variant-rate-container'>
                <div className='rate-display-container'>
                  Set Rate
                  <input
                    value={variantRate}
                    placeholder={baseCommissionRate}
                    onChange={handleSetVariantRate}
                    onBlur={() => handleSaveVariantRate(variantRate)}
                    disabled={isSavingVariantRate}
                  />
                  <span>%</span>
                </div>
                <div
                  className={cn('clear-rate', { disabled: !existingVariantRate })}
                  onClick={() => !!existingVariantRate && handleSaveVariantRate('')}
                >
                  Clear
                </div>
              </div>
              {shouldShowGroupRateExplainer && (
                <div className='rate-explainer'>
                  A rate of
                  <span>{existingCatalogGroupRate.rate}%</span>
                  from the
                  <span>{existingCatalogGroupRate.basic_catalog_group.title}</span>
                  Catalog Group is {!!existingVariantRate ? 'overridden' : 'applied'}.
                </div>
              )}
              {shouldShowSiblingsBreakdown && (
                <div className='rate-explainer'>
                  This custom rate applies to all variations on the right. You can set a rate specific to an item on the right.
                </div>
              )}
            </div>
          )}
        </div>
        {shouldShowSiblingsBreakdown && (
          <CatalogProductModalSiblings
            variant={variant}
            shouldAllowSettingVariantRates={shouldAllowSettingVariantRates}
            variantSiblingRatesMap={variantSiblingRatesMap}
            commissionRatePlaceholder={`${existingVariantRate?.rate || baseCommissionRate}`}
            handleSaveRate={handleSaveRate}
          />
        )}
      </div>
    );
  };

  return (
    <Modal
      visible={true}
      close={closeCatalogVariantModal}
      innerClassName={cn('catalog-product-modal', { loading: isLoadingParentVariant })}
      contentClassName='catalog-product-inner-modal'
      showClose={!isLoadingParentVariant}
    >
      {getModalContent()}
    </Modal>
  );
};

CatalogProductModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  closeCatalogVariantModal: PropTypes.func.isRequired,
  addCustomAffiliateRate: PropTypes.func.isRequired,
  updateCustomAffiliateRate: PropTypes.func.isRequired,
  deleteCustomAffiliateRate: PropTypes.func.isRequired,
  variant: PropTypes.object.isRequired,
  shouldShowVariantDomains: PropTypes.bool
};

const mapStateToProps = state => {
  const { user, ui, analytics } = state;
  return { user, ui, analytics };
};

export default connect(mapStateToProps, {
  closeCatalogVariantModal,
  addCustomAffiliateRate,
  updateCustomAffiliateRate,
  deleteCustomAffiliateRate
})(CatalogProductModal);
