import APIClient from './index';

export const updateUserAnalysis = (analysis, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserAnalysis/${analysis.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const generateTasteProfile = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserAnalysis/generate_taste_profile`, params)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const getTasteProfileMetadata = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/UserAnalysis/taste_profile_metadata?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};
