import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';

import { searchForElements } from '../../APIClient/blog';
import { getAffiliateLink } from '../../Helpers/attribution_helpers';
import { getYoutubeVideoIdFromUrl } from '../../Helpers/social_helpers';
import { getFormattedBlogText, BLOG_ELEMENT_TYPES } from '../../Helpers/blog_helpers';

import './BlogPostElement.scss';
import CollectionPreviewOld from '../CollectionPreview/CollectionPreviewOld';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faArrowUp, faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '../General/Tooltip';
import Image from '../General/Image';

let debouncer;
const BlogPostElement = props => {
  const { element, editBlogPostElement, isEditing } = props;
  const { type, image, disclaimer, user, pin, collection } = element;

  const [curSearch, setCurSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [curText, setCurText] = useState(element.text);
  const [curSubtext, setCurSubtext] = useState(element.subtext);
  const [curImage, setCurImage] = useState(element.image);
  const [curDisclaimer, setCurDisclaimer] = useState(element.disclaimer);
  const [curUser_id, setCurUser_id] = useState(element.User_id || null);
  const [curPin_id, setCurPin_id] = useState(element.Pin_id || null);
  const [curCollection_id, setCurCollection_id] = useState(element.Collection_id || null);

  const needsUpdate = () =>
    element.text !== curText ||
    element.subtext !== curSubtext ||
    element.image !== curImage ||
    element.disclaimer !== curDisclaimer ||
    element.User_id !== curUser_id ||
    element.Pin_id !== curPin_id ||
    element.Collection_id !== curCollection_id;

  const update = () => {
    if (needsUpdate()) {
      editBlogPostElement(element, {
        text: curText,
        subtext: curSubtext,
        image: curImage,
        disclaimer: curDisclaimer,
        User_id: curUser_id,
        Pin_id: curPin_id,
        Collection_id: curCollection_id
      });
    }
  };

  useEffect(() => {
    if (!needsUpdate()) return;

    if (debouncer) clearTimeout(debouncer);
    debouncer = setTimeout(update, 1000);
  }, [curText, curSubtext, curImage, curDisclaimer, curUser_id, curPin_id, curCollection_id]);

  const searchForItemInput = type => {
    const selectValue = selected => {
      switch (type) {
        case 'USER':
          setCurUser_id(selected.id);
          setSearchResults([]);
          setCurSearch('');
          setCurSubtext(element.type === 'SHOP' ? selected.description : curSubtext);
          break;
        case 'PIN':
          setCurCollection_id(selected.collection_id);
          setCurPin_id(selected.id);
          setCurUser_id(selected.user_id);
          setSearchResults([]);
          setCurSearch('');
          break;
        case 'COLLECTION':
          setCurCollection_id(selected.id);
          setSearchResults([]);
          setCurSearch('');
          break;
        default:
          break;
      }
      update();
    };

    const handleSearchChange = async ({ target }) => {
      setCurSearch(target.value);
      if (!target.value.length) {
        setSearchResults([]);
      } else {
        const resp = await searchForElements(target.value, type);
        setSearchResults(resp.results);
      }
    };

    return (
      <div className='search-results-container'>
        <input
          className='search-result-input'
          placeholder={`Search for ${type.toLowerCase()} by name`}
          onChange={handleSearchChange}
          value={curSearch || ''}
        />
        {searchResults.length > 0 && (
          <div className='search-results-panel'>
            {_.map(searchResults, (result, idx) => (
              <div
                key={`${result.name || result.title || result.collection_name}-${idx}`}
                onClick={() => selectValue(result)}
                className='search-result'
              >
                <div className='main'>{result.name || result.title || result.collection_name}</div>
                <div className='secondary'>{result.username || `by ${result.user_name}`}</div>
                {result.collection_name && <div className='tertiary'>from {result.collection_name}</div>}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn('blog-post-element-outer-container', {
        half: ['QUOTE_HALF', 'PIN_HALF'].includes(element.type),
        full: !['QUOTE_HALF', 'PIN_HALF'].includes(element.type),
        isEditing
      })}
    >
      {isEditing && (
        <div className='controls-header'>
          <div className='delete-icn' onClick={() => props.moveItemToFirstPosition(element)}>
            <Tooltip message='Move item to top of elements'>
              <FontAwesomeIcon icon={faArrowUp} />
            </Tooltip>
          </div>
          <div className='delete-icn' onClick={() => props.moveItemToLastPosition(element)}>
            <Tooltip message='Move item to bottom of elements'>
              <FontAwesomeIcon icon={faArrowDown} />
            </Tooltip>
          </div>
          <div className='delete-icn' onClick={() => props.deleteBlogPostElement(element)}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
          {props.rearrangeHandle}
        </div>
      )}
      {type === BLOG_ELEMENT_TYPES.PARAGRAPH ? (
        <>
          {isEditing ? (
            <div className='paragraph block'>
              <textarea
                className='text-input'
                placeholder='Paragraph Text Here'
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                rows={6}
                value={curText || ''}
              />
            </div>
          ) : (
            <p className='paragraph block live'>
              {getFormattedBlogText(curText).map((a, idx) => (
                <Fragment key={idx}>{a}</Fragment>
              ))}
            </p>
          )}
        </>
      ) : type === BLOG_ELEMENT_TYPES.HEADER || type === BLOG_ELEMENT_TYPES.HEADER_LARGE ? (
        <>
          {isEditing ? (
            <div
              className={cn('header block', {
                large: type === 'HEADER_LARGE'
              })}
            >
              <input
                className='text-input'
                placeholder='Header Text Here'
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                value={curText || ''}
              />
            </div>
          ) : (
            <p
              className={cn('header block live', {
                large: type === 'HEADER_LARGE'
              })}
            >
              {curText}
            </p>
          )}
        </>
      ) : type === BLOG_ELEMENT_TYPES.BANNER_IMAGE ? (
        <>
          {isEditing ? (
            <div className='banner-image block'>
              <div className='banner-image-wrapper'>
                {image || curImage ? <img alt='Explanation' src={image || curImage} /> : <div className='empty' />}
                {curDisclaimer && <div className='disclaimer'>{curDisclaimer}</div>}
              </div>
              <input
                className='text-input'
                placeholder='Image URL'
                onChange={({ target }) => {
                  setCurImage(target.value);
                }}
                value={curImage || ''}
              />
              <input
                className='discplaimer-input'
                placeholder='Disclaimer Text Here'
                onChange={({ target }) => {
                  setCurDisclaimer(target.value);
                }}
                value={curDisclaimer || ''}
              />
            </div>
          ) : (
            <div className='banner-image block live'>
              <div className='banner-image-wrapper'>
                {image || curImage ? <img alt='Explanation' src={image} /> : <div className='empty' />}
                {disclaimer && <div className='disclaimer'>{disclaimer}</div>}
              </div>
            </div>
          )}
        </>
      ) : type === BLOG_ELEMENT_TYPES.QUOTE_FULL || type === BLOG_ELEMENT_TYPES.QUOTE_HALF ? (
        <div
          className={cn('quote block', {
            live: !isEditing,
            half: type === 'QUOTE_HALF'
          })}
        >
          {isEditing ? (
            <>
              <textarea
                className='subtext-input'
                placeholder='Quote here, do not need to use quotes'
                rows={3}
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                value={curText || ''}
              />
              <textarea
                className='subtext-input'
                placeholder='Who is the quote from? (Optional)'
                rows={3}
                onChange={({ target }) => {
                  setCurSubtext(target.value);
                }}
                value={curSubtext || ''}
              />
            </>
          ) : (
            <>
              {curText && <div className='quote-body'>&#8220;{curText}&#8221;</div>}
              {curSubtext && <div className='quote-author'>{curSubtext}</div>}
            </>
          )}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.SHOP ? (
        <div className={cn('shop block', { live: !isEditing })}>
          <Link to={user ? `/${user.username}` : '/home'} onClick={() => window.scrollTo(0, 0)} className='see-all-link'>
            <div className='shop-user-container'>
              <Image src={user?.image} alt='Artist Image' failedText={user?.name?.[0] || 'Artist Image'} useGenericFailedStyles />
              <div className='user-data'>
                <div>
                  <div className='name'>{user ? user.name : 'Artist Name'}</div>
                  {isEditing ? (
                    <textarea
                      className='text-input'
                      placeholder='Artist bio'
                      rows={3}
                      onChange={({ target }) => {
                        setCurSubtext(target.value);
                      }}
                      value={curSubtext || ''}
                    />
                  ) : (
                    <>{!!curSubtext && <div className='subtext'>{curSubtext}</div>}</>
                  )}
                </div>
                {!isEditing && user && <div className='view-shop-link'>View Shop</div>}
              </div>
            </div>
          </Link>
          {isEditing && searchForItemInput('USER')}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.PIN_FULL || type === BLOG_ELEMENT_TYPES.PIN_HALF ? (
        <div
          className={cn('pin block', {
            live: !isEditing,
            half: type === 'PIN_HALF'
          })}
        >
          <a
            className='pin-container'
            target='_blank'
            rel='noopener noreferrer nofollow'
            href={pin ? getAffiliateLink(pin, props.user, pin.user) : '/#'}
          >
            <div className='pin-product'>
              <Image
                alt={pin?.title || 'Product Image'}
                src={pin?.image}
                className='image-container'
                failedText='Product Image'
                useGenericFailedStyles
              />
              <div className='title'>{pin ? pin.title : 'Pin Title'}</div>
            </div>
          </a>
          {isEditing && searchForItemInput('PIN')}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.COLLECTION_SHELF ? (
        <div className={cn('collection block', { live: !isEditing })}>
          {collection && (
            <CollectionPreviewOld
              fluid
              user={props.user}
              collection={collection}
              feature={{
                tag: null,
                user: collection.user
              }}
            />
          )}
          {isEditing && !collection && searchForItemInput('COLLECTION')}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.YOUTUBE ? (
        <div className={cn('youtube block', { live: !isEditing })}>
          <YouTube
            videoId={getYoutubeVideoIdFromUrl(curText)}
            className='youtube-video'
            opts={{
              width: '100%',
              playerVars: {
                modestbrand: 1,
                rel: 0
              }
            }}
          />
          {isEditing && (
            <>
              <input
                placeholder='Youtube URL Here'
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                value={curText || ''}
              />
            </>
          )}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.INSTAGRAM || type === BLOG_ELEMENT_TYPES.INSTAGRAM_LARGE ? (
        <div className={cn('banner-image instagram block', { live: !isEditing })}>
          <Image src={image} alt='instagram post image' className={'banner-image-wrapper'} failedText='Instagram Post' useGenericFailedStyles />
          {isEditing && (
            <>
              <input
                placeholder='Instagram URL Here'
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                value={curText || ''}
              />
            </>
          )}
        </div>
      ) : type === BLOG_ELEMENT_TYPES.LINE ? (
        <div className={cn('line block', { live: !isEditing })}></div>
      ) : type === BLOG_ELEMENT_TYPES.SPACER || type === BLOG_ELEMENT_TYPES.SPACER_SMALL ? (
        <div
          className={cn('spacer block', {
            live: !isEditing,
            small: type === 'SPACER_SMALL'
          })}
        ></div>
      ) : type === BLOG_ELEMENT_TYPES.DISCLAIMER ? (
        <>
          {isEditing ? (
            <div className='disclaimer block'>
              <textarea
                className='text-input'
                placeholder='The products featured here may contain affiliate links, meaning Shop My Shelf, Inc or the artists may earn a commission on products purchased through links to retailer sites.'
                onChange={({ target }) => {
                  setCurText(target.value);
                }}
                rows={3}
                value={curText || ''}
              />
            </div>
          ) : (
            <div className='disclaimer block live'>
              {curText ||
                'The products featured here may contain affiliate links, meaning Shop My Shelf, Inc or the artists may earn a commission on products purchased through links to retailer sites.'}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

BlogPostElement.propTypes = {
  element: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  rearrangeHandle: PropTypes.object,
  moveItemToFirstPosition: PropTypes.func,
  moveItemToLastPosition: PropTypes.func,

  editBlogPostElement: PropTypes.func.isRequired,
  deleteBlogPostElement: PropTypes.func.isRequired
};

export default BlogPostElement;
