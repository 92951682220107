import APIClient from './index';
import _ from 'lodash';

export const updateCategory = (category, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Categories/${category.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateDepartment = (department, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Departments/${department.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateIndustry = (industry, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Industries/${industry.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
