import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import './ConnectInstagramModal.scss';

import Modal from '../General/Modal';
import VideoPlayer from '../General/VideoPlayer';

import { closeConnectInstagramModal } from '../../Actions/UIActions';

const ConnectInstagramModal = props => {
  return (
    <Modal
      showClose
      close={props.closeConnectInstagramModal}
      visible
      className='connect-instagram-modal-container'
      innerClassName='connect-instagram-modal-inner-container'
      contentClassName='connect-instagram-modal-content-container'
    >
      <div className='header-container'>
        <FontAwesomeIcon className='warning-icon' icon={faExclamationCircle} />
        <div className='header'>Oops! Looks like you might be having trouble connecting your account.</div>
        <div className='subheader'>
          Please use either the standard setup guide or our video tutorials to ensure you are able to connect your Instagram account. If you still
          cannot connect, please get in contact with us at creatorsupport@shopmy.us!
        </div>
      </div>
      <div className='sections'>
        <div className='section'>
          <div className='section-header'>Standard Setup Guide</div>
          <div className='section-body'>
            <a href='https://guide.shopmy.us/how-to-link-your-social-media' rel='noopener noreferrer' target='_blank' className='section-btn'>
              Go to Setup Guide
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
        </div>
        <div className='section'>
          <div className='section-header'>Video Walkthrough</div>
          <div className='section-body'>
            <div className='videos'>
              <div className='video-container'>
                <VideoPlayer
                  hasCover
                  coverHeader='Step One'
                  coverSubheader='Setting up a Professional Instagram Account and Linking it to a Facebook Business Page'
                  src='https://static.shopmy.us/Guide/Connect_Instagram_Mobile.mov'
                />
              </div>
              <div className='video-container'>
                <VideoPlayer
                  hasCover
                  coverHeader='Step Two'
                  coverSubheader='Connecting your Instagram to your ShopMy Account'
                  src='https://static.shopmy.us/Guide/Connect_Instagram_Web.mov'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConnectInstagramModal.propTypes = {
  user: PropTypes.object.isRequired,
  closeConnectInstagramModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  closeConnectInstagramModal
})(ConnectInstagramModal);
