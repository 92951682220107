import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Opportunity.scss';

import { getOpportunityFeed } from '../../APIClient/opportunities';
import {
  syncActiveOpportunityFullData,
  syncActiveOpportunityResultFullData,
  setActiveOpportunity,
  setActiveOpportunityRequest
} from '../../Actions/OpportunityActions';
import { openRequestModal } from '../../Actions/UIActions';
import { isOpportunityRequestAccepted } from '../../Helpers/opportunity_helpers';
import { getOpportunityRequestsForOpportunity } from '../../Helpers/brand_helpers';
import {
  isBrand as isBrandCheck,
  isShopper,
  isLoggedIn,
  getOpportunityRequestForOpportunityId,
  getBrandId,
  getUserId
} from '../../Helpers/user_helpers';

import OpportunityHeader from '../../Components/Opportunity/OpportunityHeader';
import OpportunityListing from '../../Components/Opportunity/OpportunityListing';
import OpportunityLeaderboard from '../../Components/Opportunity/OpportunityLeaderboard';
import OpportunityPlans from '../../Components/Opportunity/OpportunityPlans';
import OpportunityFeed from '../../Components/Opportunity/OpportunityFeed';
import OpportunityRequestProgress from '../../Components/Opportunity/OpportunityRequestProgress';
import OpportunitySetupGuide from '../../Components/Opportunity/OpportunitySetupGuide';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';
import OpportunityPlanner from '../../Components/Opportunity/OpportunityPlanner';

import ScrollToTop from '../../Components/General/ScrollToTop';

const Opportunity = props => {
  const { user, analytics, opportunities } = props;
  const { id, tab } = useParams();
  const isBrand = isBrandCheck(user);

  // Grab the Opportunity Object
  const opportunity = String(opportunities?.activeOpportunity?.id) === id ? opportunities.activeOpportunity : null;

  // Brand Data
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);

  // Grab the Opportunity Request Object (for Users Only)
  const opportunityRequest = String(opportunities?.activeOpportunityRequest?.opportunity?.id) === id ? opportunities.activeOpportunityRequest : null;
  const user_requestIsAccepted = opportunityRequest && isOpportunityRequestAccepted(opportunityRequest);

  // Grab the Opportunity Feed (for Brands Only)
  const [feed, setFeed] = React.useState([]);
  const [feedSummary, setFeedSummary] = React.useState({});
  const [feedPage, setFeedPage] = React.useState(0);
  const [hasFetchedAllFeed, setHasFetchedAllFeed] = React.useState(false);
  const [isFetchingFeed, setIsFetchingFeed] = React.useState(isBrand);
  const [isFetchingFirstFeedPage, setIsFetchingFirstFeedPage] = React.useState(isBrand);
  const FEED_PAGE_SIZE = 25;
  const getFeedPage = () => {
    setIsFetchingFeed(true);
    getOpportunityFeed(id, { page: feedPage, limit: FEED_PAGE_SIZE }).then(resp => {
      const newFeed = feed.slice(0, feedPage * FEED_PAGE_SIZE).concat(resp.results);
      setFeed(newFeed);
      setFeedSummary(resp.summary);
      if (!resp.summary.has_more) setHasFetchedAllFeed(true);
      setIsFetchingFirstFeedPage(false);
      setIsFetchingFeed(false);
    });
  };
  const getNextFeedPage = () => {
    if (isFetchingFeed || hasFetchedAllFeed) return;
    setFeedPage(feedPage + 1);
  };
  useEffect(() => {
    if (isBrand) getFeedPage();
  }, [feedPage]);

  // Fetch Additional Data on Opportunity and ensure Redux is up to date
  const isFetchingRequests = analytics.brandAnalytics?.isFetchingAnalytics;
  const isLoadingOpportunity = !opportunity;
  useEffect(() => {
    if (isBrand) {
      // For Brands
      props.syncActiveOpportunityFullData(id);
    } else {
      // For Users
      const requestFound = getOpportunityRequestForOpportunityId(user, +id);
      props.setActiveOpportunityRequest(requestFound);

      // If we found a request and do not have an opportunity, set the opportunity
      if (requestFound?.opportunity) props.setActiveOpportunity(requestFound.opportunity);

      // Augment the request with the full data, including expectations
      if (requestFound?.result?.id) props.syncActiveOpportunityResultFullData(requestFound.result.id);
    }
  }, []);

  // Allow Editing
  const canEdit = isBrand;
  const [isEditing, setIsEditing] = React.useState(window.location.search.includes('edit=true') && isBrand);

  // Setup Guide Management
  const shouldShowSetupGuideInitially = opportunity && !opportunity.title;
  const [isSetupGuideVisible, setIsSetupGuideVisible] = React.useState(shouldShowSetupGuideInitially); // Show if no title specified
  const [showOnlyPlanningInSetupGuide, setShowOnlyPlanningInSetupGuide] = React.useState(false);
  const [lockedUserIds, setLockedUserIds] = React.useState([]);
  const [blockedUserIds, setBlockedUserIds] = React.useState([]);
  const toggleSetupGuide = (showPlanningOnly = false) => {
    setShowOnlyPlanningInSetupGuide(showPlanningOnly === true);
    setIsSetupGuideVisible(!isSetupGuideVisible);
  };
  const openNewPlanModal = (additionalConfiguration = {}) => {
    setLockedUserIds(additionalConfiguration.LockedUser_ids || []);
    setBlockedUserIds(additionalConfiguration.BlockedUser_ids || []);
    toggleSetupGuide(true);
  };
  React.useEffect(() => {
    if (opportunity?.id) setIsSetupGuideVisible(shouldShowSetupGuideInitially);
  }, [opportunity?.id]);

  // Plan UI
  const plans = opportunity?.plans || [];
  const [activePlanId, setActivePlanId] = React.useState(null); // Use ID so we can update full object via redux
  const activePlan = plans.find(plan => plan.id === activePlanId);
  const setActivePlan = plan => setActivePlanId(plan?.id);
  React.useEffect(() => {
    // Always switch to the most recently added one when we add a new plan or load for the first time
    setActivePlan(_.orderBy(plans, ['createdAt'], ['desc'])[0]);
  }, [plans.length]);

  // Manual Planning
  const [isPlannerVisible, setIsPlannerVisible] = React.useState(false);
  const openPlanner = () => setIsPlannerVisible(true);
  const closePlanner = () => setIsPlannerVisible(false);

  // Tab Management
  const defaultTab = requests.length >= 1 && isBrand ? 'leaderboard' : user_requestIsAccepted ? 'progress' : 'overview';
  const [activeTabValue, setActiveTabValue] = React.useState(tab || defaultTab);
  const selectTabByValue = value => selectTab({ value });
  const selectTab = tab => {
    setActiveTabValue(tab.value);

    // Change navigation URL from /opportunity/1 to /opportunity/1/analytics
    window.history.pushState({}, '', `/opportunity/${id}/${tab.value}`);
  };
  const isEventType = opportunity?.type === 'experience';
  const tabs = (isBrand
    ? [
        {
          display: 'The Opportunity',
          value: 'overview',
          panel: opportunity && <OpportunityListing isEditing={isEditing} canEdit={canEdit} opportunity={opportunity} setIsEditing={setIsEditing} />
        },
        ...(opportunity && !isEventType
          ? [
              {
                display: 'Plans',
                value: 'plans',
                panel: opportunity && (
                  <OpportunityPlans
                    opportunity={opportunity}
                    openNewPlanModal={openNewPlanModal}
                    openPlanner={openPlanner}
                    activePlan={activePlan}
                    setActivePlan={setActivePlan}
                  />
                )
              }
            ]
          : []),
        {
          display: isEventType ? 'RSVPs' : 'Leaderboard',
          isDisabled: !isFetchingRequests && !requests.length,
          isDisabledTooltip: `${isEventType ? `RSVPs` : `Leaderboard`} is only available once this opportunity has been sent to creators.`,
          value: 'leaderboard',
          panel: opportunity && <OpportunityLeaderboard opportunity={opportunity} />
        },
        {
          display: 'Social Feed',
          isDisabled: !isFetchingFirstFeedPage && !feed.length,
          isDisabledTooltip: 'Social Feed is only available once you receive your first social mention.',
          value: 'social-feed',
          count: feedSummary.total,
          panel: opportunity && (
            <OpportunityFeed
              opportunity={opportunity}
              feed={feed}
              feedPage={feedPage}
              isFetchingFirstFeedPage={isFetchingFirstFeedPage}
              getNextFeedPage={getNextFeedPage}
              hasFetchedAllFeed={hasFetchedAllFeed}
              isFetchingFeed={isFetchingFeed}
            />
          )
        }
      ]
    : [
        {
          display: 'The Opportunity',
          value: 'overview',
          panel: opportunity && (
            <OpportunityListing
              isEditing={isEditing}
              canEdit={false}
              opportunity={opportunity}
              opportunityRequest={opportunityRequest}
              setIsEditing={setIsEditing}
            />
          )
        },
        {
          display: opportunityRequest?.payment_tier?.fixedFee === 0 ? 'Your Content' : 'Progress + Payment',
          isDisabled: !user_requestIsAccepted,
          isDisabledTooltip: `You must accept this opportunity before you can view your progress.`,
          value: 'progress',
          panel: opportunity && <OpportunityRequestProgress opportunityRequest={opportunityRequest} opportunity={opportunity} />
        }
      ]
  ).map(tab => ({
    ...tab,
    isActive: tab.value === activeTabValue,
    select: () => selectTab(tab)
  }));

  // Handle tab changes on certain actions
  const didUserAccept = React.useRef(user_requestIsAccepted);
  React.useEffect(() => {
    if (user_requestIsAccepted && didUserAccept.current !== opportunityRequest.userAccepted) {
      setActiveTabValue('progress');
      window.scrollTo(0, 0);
    }
    if (opportunityRequest) didUserAccept.current = opportunityRequest.userAccepted;
  }, [user_requestIsAccepted]);

  // Ensure they have permission
  let hasPermission = true;
  if (opportunity) {
    hasPermission = opportunity.Brand_id === getBrandId(user) || opportunityRequest?.User_id === getUserId(user);
  }

  if (!isLoggedIn(user)) return <RequiresLoginPanel />;
  if (isShopper(user)) return <RequiresNonShopperLoginPanel />;
  return (
    <div className='opportunity-outer-container'>
      <ScrollToTop />
      <div className='opportunity-inner-container'>
        {hasPermission ? (
          <>
            <OpportunityHeader
              user={user}
              opportunity={opportunity}
              opportunityRequest={opportunityRequest}
              canEdit={canEdit}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              selectTabByValue={selectTabByValue}
              tabs={tabs}
              isLoadingOpportunity={isLoadingOpportunity}
              openRequestModal={props.openRequestModal}
            />
            {tabs.find(tab => tab.isActive)?.panel}
          </>
        ) : (
          <div className='no-permission-container'>
            <div className='no-permission-message'>You do not have access to this opportunity.</div>
          </div>
        )}
      </div>
      {opportunity && isBrand && isSetupGuideVisible && (
        <OpportunitySetupGuide
          opportunity={opportunity}
          lockedUserIds={lockedUserIds}
          blockedUserIds={blockedUserIds}
          closeModal={toggleSetupGuide}
          selectTabByValue={selectTabByValue}
          showPlanningSteps={showOnlyPlanningInSetupGuide}
        />
      )}
      {opportunity && isPlannerVisible && <OpportunityPlanner opportunity={opportunity} closePlanner={closePlanner} activePlan={activePlan} />}
    </div>
  );
};

Opportunity.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  opportunities: PropTypes.object.isRequired,
  syncActiveOpportunityFullData: PropTypes.func.isRequired,
  syncActiveOpportunityResultFullData: PropTypes.func.isRequired,
  setActiveOpportunity: PropTypes.func.isRequired,
  setActiveOpportunityRequest: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, opportunities, analytics } = state;
  return { user, opportunities, analytics };
};

export default connect(mapStateToProps, {
  syncActiveOpportunityFullData,
  syncActiveOpportunityResultFullData,
  setActiveOpportunity,
  setActiveOpportunityRequest,
  openRequestModal
})(Opportunity);
