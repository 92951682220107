import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../General/ConfirmPrompt';
import './ComingSoonModal.scss';

import Modal from '../General/Modal';

import { toggleNewShopMode } from '../../Actions/UIActions';

const ComingSoonModal = props => {
  const enterAccessCode = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Please Enter Access Code'
          subheader='Enter access code for an early preview of the new, multi-curator shopping experience.'
          onCancel={onClose}
          isSingleLine
          submitMustReturnTrueToClose
          onSubmit={code => {
            const validCodes = ['sharkoftheweek', 'shopmy2025', 'if_you_see_this_apply_to_work_here'];
            const foundCode = validCodes.find(validCode => validCode === code.toLowerCase());
            if (foundCode) {
              props.toggleNewShopMode();
              window.ALERT.success('Welcome to the new shop, please note that this is a preview and not all features will be fully functional.');
              return true;
            } else {
              window.ALERT.error('Invalid Access Code');
            }
          }}
        />
      )
    });
  };
  return (
    <Modal
      visible
      close={() => {}}
      noPadding
      className='coming-soon-modal'
      innerClassName='coming-soon-modal-inner'
      contentClassName='coming-soon-modal-content'
    >
      <div className='headlines'>
        {/* <img className='shop-gif' src='http://static.shopmy.us/Animation+-+1743702479229.gif' alt='Coming Soon' /> */}
        <h1>One shop, curated by all your favorite people.</h1>
        <h2>Coming Summer 2025</h2>
      </div>
      <div className='actions'>
        <div onClick={enterAccessCode} className='action primary'>
          Enter Access Code
        </div>
        <Link to='/' className='action secondary'>
          Back to ShopMy
        </Link>
      </div>
    </Modal>
  );
};

ComingSoonModal.propTypes = {
  toggleNewShopMode: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  toggleNewShopMode
})(ComingSoonModal);
