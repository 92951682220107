import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './SmartImage.scss';

import { getSmartImage } from '../../Helpers/helpers';

const SmartImage = props => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const onLoad = () => {
    setTimeout(() => {
      setIsLoaded(true);
    }, props.fadeInDelay || 10);
  };

  const propsToPassThrough = _.omit(props, ['fadeInDelay']);

  return (
    <img
      className={cn('smart-image', { loaded: isLoaded, 'grow-in': props.growIn || true }, props.className)}
      src={getSmartImage(props.src, false)}
      alt={props.alt || 'Smart Img'}
      {...propsToPassThrough}
      onLoad={onLoad}
    />
  );
};

SmartImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  fadeInDelay: PropTypes.number
};

export default SmartImage;
