import React from 'react';
// import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './DiscoverCuratorFilters.scss';

const DiscoverCuratorFilters = props => {
  const { shop, activeFilters } = props;
  const selectedIndustry = _.find(shop.industries, { id: activeFilters.Industry_id });
  const visibleIndustries = selectedIndustry
    ? [selectedIndustry]
    : shop.industries.filter(industry => !industry.isHidden && industry.departments.length);
  const visibleDepartments = selectedIndustry
    ? selectedIndustry.departments.filter(department => !department.isHidden && department.categories.length)
    : [];

  return (
    <div className='discover-curator-filters-container'>
      <div className='discover-filters'>
        {visibleIndustries.map(industry => {
          const isActive = activeFilters.Industry_id === industry.id;
          const isAnotherActive = !isActive && !!activeFilters.Industry_id;
          const select = () => {
            isActive
              ? props.setActiveFilters({ ...activeFilters, Department_id: null, Industry_id: null })
              : props.setActiveFilters({ ...activeFilters, Industry_id: industry.id, Department_id: null });
            !isActive && window.__ADD_EVENT__('Shop - Click Discover Filter', { type: 'Industry', name: industry.name });
          };
          return (
            <div key={industry.id} onClick={select} className={cn('discover-filter', { active: isActive, 'another-active': isAnotherActive })}>
              {industry.name}
              {isActive && (
                <div className='remove-icon-container'>
                  <FontAwesomeIcon icon={faTimes} className='remove-icon' />
                </div>
              )}
            </div>
          );
        })}
        {visibleDepartments.map(department => {
          const isActive = activeFilters.Department_id === department.id;
          const isAnotherActive = !isActive && !!activeFilters.Department_id;
          const select = () => {
            isActive
              ? props.setActiveFilters(_.omit(activeFilters, 'Department_id'))
              : props.setActiveFilters({ ...activeFilters, Department_id: department.id });
            !isActive && window.__ADD_EVENT__('Shop - Click Discover Filter', { type: 'Department', name: department.name });
          };
          return (
            <div key={department.id} onClick={select} className={cn('discover-filter', { active: isActive, 'another-active': isAnotherActive })}>
              {department.name}
              {isActive && (
                <div className='remove-icon-container'>
                  <FontAwesomeIcon icon={faTimes} className='remove-icon' />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

DiscoverCuratorFilters.propTypes = {
  shop: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  setActiveFilters: PropTypes.func.isRequired
};

export default DiscoverCuratorFilters;
