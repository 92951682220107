import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import _ from 'lodash'
// import cn from 'classnames'
import './HomeFeaturedGroups.scss';

import { syncFeaturedCuratorGroups } from '../../../Actions/ShopActions';

import { getFeaturedGroups } from '../../../Helpers/shop_helpers';

import CuratorGroupCard from '../../Curators/CuratorGroupCard';

const HomeFeaturedGroups = props => {
  const { shop } = props;

  const featuredGroups = getFeaturedGroups(shop);

  React.useEffect(() => {
    props.syncFeaturedCuratorGroups();
  }, []);

  return (
    <div className='home-featured-groups-outer-container'>
      <div className='home-featured-groups-inner-container'>
        <div className='featured-groups'>
          {featuredGroups.map(group => {
            return (
              <div key={group.id} className='featured-group'>
                <CuratorGroupCard key={group.id} isLinkToShop hideActions group={group} user={props.user} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HomeFeaturedGroups.propTypes = {
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  syncFeaturedCuratorGroups
})(HomeFeaturedGroups);
