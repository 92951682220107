import React from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Product.scss';

import {
  syncActiveProduct,
  syncActiveProductSimilarProducts,
  setAttributableCuratorId,
  setAttributableCuratorGroupId,
  addRecentlyViewedProduct
} from '../../Actions/ShopActions';
import { getActiveProduct, isFetchingFullProduct } from '../../Helpers/shop_helpers';
import { getUrlParam } from '../../Helpers/helpers';

import ProductErrorPanel from '../../Components/Product/ProductErrorPanel';
import ProductMainSection from '../../Components/Product/ProductMainSection';
import ProductMetaTags from '../../Components/Product/ProductMetaTags';
import ProductQA from '../../Components/Product/ProductQA';
import ProductBrandPanel from '../../Components/Product/ProductBrandPanel';
import ProductCurators from '../../Components/Product/ProductCurators';
import ProductOtherProducts from '../../Components/Product/ProductOtherProducts';
import ProductBrandUpsell from '../../Components/Product/ProductBrandUpsell';

const Product = props => {
  const { shop, match } = props;
  const product = getActiveProduct(shop);
  const isFetchingFull = isFetchingFullProduct(shop);

  // Go Back
  const comingFromOtherProduct = props.lastLocation?.pathname.includes('/shop/product/');
  const comingFromShop = props.lastLocation?.pathname === '/shop';
  const goBack = () => {
    comingFromShop || comingFromOtherProduct
      ? props.history.goBack()
      : isFetchingFull
      ? props.history.push('/shop')
      : props.history.push(`/shop?Category_id=${product.category.id}&AllBrand_id=${product.brand.id}`);
  };

  // Sync Product
  const syncProduct = () => {
    // Allow passthrough of URL params
    const params = {};
    if (getUrlParam('User_id')) params.Curator_id = getUrlParam('User_id');
    if (getUrlParam('CuratorGroup_id')) params.CuratorGroup_id = getUrlParam('CuratorGroup_id');
    if (getUrlParam('Pin_id')) params.Pin_id = getUrlParam('Pin_id');

    props.syncActiveProduct(+match.params.id, params).then(resp => {
      if (!resp.product) setErrorFetchingProduct(true);
      else setErrorFetchingProduct(false);

      resp.product &&
        window.__ADD_EVENT__('Shop - View Product Page', {
          Product_id: resp.product.id,
          title: resp.product.title,
          brand: resp.product.brand?.name,
          category: resp.product.category?.name
        });
    });
    props.syncActiveProductSimilarProducts(+match.params.id);
  };

  // Store in recently viewed if they stay there for long enough
  const recentlyViewedDebounce = React.useRef(null);
  React.useEffect(() => {
    clearTimeout(recentlyViewedDebounce.current);
    if (product?.id) {
      recentlyViewedDebounce.current = setTimeout(() => {
        props.addRecentlyViewedProduct(product);
      }, 10_000); // If they stay for more than 10 seconds, store as recently viewed
    }
    return () => clearTimeout(recentlyViewedDebounce.current);
  }, [product?.id]);

  // If match changes, sync the product
  React.useEffect(() => {
    syncProduct();
    window.scrollTo(0, 0);
  }, [match.params.id]);

  // Error Handling
  const [errorFetchingProduct, setErrorFetchingProduct] = React.useState(false);

  // Fetch on initial load
  React.useEffect(() => {
    // Scroll smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
    syncProduct();
  }, []);

  // Pull attribution from the URL if it exists
  const urlParams = new URLSearchParams(props.location.search);
  React.useEffect(() => {
    if (urlParams.has('User_id')) props.setAttributableCuratorId(+urlParams.get('User_id'));
    else if (urlParams.has('CuratorGroup_id')) props.setAttributableCuratorGroupId(+urlParams.get('CuratorGroup_id'));
  }, [props.location.search]);

  // Allow Scrolling
  const activeCuratorGroupRef = React.useRef(null);
  const myCircleRef = React.useRef(null);
  const otherCuratorsRef = React.useRef(null);

  const scrollToActiveCuratorGroup = () => {
    activeCuratorGroupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollToMyCircle = () => {
    myCircleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToCurators = () => {
    otherCuratorsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className='product-outer-container'>
      {product && <ProductMetaTags product={product} />}
      <div className='product-inner-container'>
        {errorFetchingProduct ? (
          !isFetchingFull && <ProductErrorPanel retry={syncProduct} />
        ) : (
          <>
            <div className='main-section'>
              <ProductMainSection
                shop={shop}
                goBack={goBack}
                product={product}
                isFetchingFull={isFetchingFull}
                syncProduct={syncProduct}
                scrollToMyCircle={scrollToMyCircle}
                scrollToCurators={scrollToCurators}
              />
            </div>
            <div className='secondary-sections'>
              <div className='full-width-section-outer white'>
                <div className='full-width-section-inner'>
                  <ProductQA product={product} isFetchingFull={isFetchingFull} />
                </div>
              </div>
              <div className='full-width-section-outer'>
                <div className='full-width-section-inner'>
                  <ProductOtherProducts isFetchingFull={isFetchingFull} product={product} similarProducts />
                </div>
              </div>
              <div className='full-width-section-outer'>
                <div className='full-width-section-inner'>
                  <ProductOtherProducts noMarginTop product={product} similarProductsDifferentBrandSameCategory />
                </div>
              </div>
              <div className='full-width-section-outer white'>
                <div className='full-width-section-inner'>
                  <ProductBrandPanel isFetchingFull={isFetchingFull} product={product} />
                  <ProductOtherProducts noMarginTop isFetchingFull={isFetchingFull} product={product} similarProductsSameBrand />
                </div>
              </div>
              {!isFetchingFull && (
                <>
                  <div ref={activeCuratorGroupRef} className='full-width-section-outer'>
                    <div className='full-width-section-inner'>
                      <ProductCurators isActiveGroup product={product} />
                    </div>
                  </div>
                  <div ref={myCircleRef} className='full-width-section-outer'>
                    <div className='full-width-section-inner'>
                      <ProductCurators isYourCircle product={product} />
                    </div>
                  </div>
                  <div ref={otherCuratorsRef} className='full-width-section-outer'>
                    <div className='full-width-section-inner'>
                      <ProductCurators product={product} />
                    </div>
                  </div>
                </>
              )}
              <div className='full-width-section-outer'>
                <div className='full-width-section-inner'>
                  <ProductOtherProducts product={product} isRecentlyViewed />
                </div>
              </div>
              <div className='full-width-section-outer'>
                <div className='full-width-section-inner'>
                  <ProductBrandUpsell product={product} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Product.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  syncActiveProduct: PropTypes.func.isRequired,
  syncActiveProductSimilarProducts: PropTypes.func.isRequired,
  setAttributableCuratorId: PropTypes.func.isRequired,
  setAttributableCuratorGroupId: PropTypes.func.isRequired,
  addRecentlyViewedProduct: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  syncActiveProduct,
  syncActiveProductSimilarProducts,
  setAttributableCuratorId,
  setAttributableCuratorGroupId,
  addRecentlyViewedProduct
})(withLastLocation(withRouter(Product)));
