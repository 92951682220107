import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ShopifyIntegrationPanel.scss';

import { updateBrandIntegration, updateBrandInventoryLocations } from '../../../../Actions/BrandActions';
import { getAllShopifyIntegrations } from '../../../../Helpers/user_helpers';
import SelectOption from '../../../General/SelectOption';
import InfiniteScrollSelect from '../../../General/InfiniteScrollSelect';
import { getShopifyLocations } from '../../../../APIClient/shopify';
import TagTypeInput from '../../../General/TagTypeInput';
import CheckboxButton from '../../../General/Buttons/CheckboxButton';
import { SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS } from '../../../../Helpers/shopify_helpers';

const ShopifyIntegrationPanel = props => {
  const { user, updateBrandInventoryLocations } = props;
  const shopifyIntegrations = getAllShopifyIntegrations(user);
  const hasMultipleShopifyIntegrations = shopifyIntegrations.length > 1;
  const integrationsById = _.keyBy(shopifyIntegrations, 'id');

  /*************************************************** */
  // State
  /*************************************************** */
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(shopifyIntegrations[0]?.id || null);
  const selectedIntegration = integrationsById[selectedIntegrationId];
  const shopifySiblingReductionConfig = JSON.parse(selectedIntegration?.shopifySiblingReductionConfig || '{}'); // prettier-ignore

  // LOCATION MANAGEMENT
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [lastCursor, setLastCursor] = useState(null);
  const [locationsHasNextPage, setLocationsHasNextPage] = useState(true);

  /*************************************************** */
  // Data Loading
  /*************************************************** */
  useEffect(() => {
    const loadSelectedLocations = async () => {
      if (!selectedIntegrationId) return;

      const integration = integrationsById[selectedIntegrationId];
      const inventoryLocations = integration?.inventory_locations || [];
      if (!inventoryLocations.length) {
        setSelectedLocations([]);
        return;
      }

      try {
        setIsLoadingLocations(true);
        const data = { BrandIntegration_id: selectedIntegrationId, locationIds: inventoryLocations.map(l => l.shopifyLocationId) };
        const response = await getShopifyLocations(data);

        const formatedLocations = response.locations.map(l => ({ label: l.name, value: +l.id.split('/').pop() }));
        setSelectedLocations(formatedLocations);
      } catch (err) {
        console.error(err);
        cogoToast.error('There was an error loading your selected locations, please try again.');
      } finally {
        setIsLoadingLocations(false);
      }
    };

    loadSelectedLocations();
  }, [selectedIntegrationId]);

  const loadMoreLocations = async (searchTerm, isNewSearch = false) => {
    if (!locationsHasNextPage && !isNewSearch) return [];

    const data = { BrandIntegration_id: selectedIntegrationId };
    if (searchTerm) data.searchTerm = searchTerm;
    if (!isNewSearch && lastCursor) data.lastCursor = lastCursor;

    let response;
    try {
      setIsLoadingLocations(true);
      response = await getShopifyLocations(data);
    } catch (err) {
      console.error(err);
      cogoToast.error('There was an error loading your store locations, please try again.');
      setIsLoadingLocations(false);
      return [];
    }

    const locations = response.locations?.edges || [];
    const hasNextPage = response.locations?.pageInfo?.hasNextPage || false;
    const formattedLocations = locations.map(lEdge => ({
      label: lEdge.node.name,
      value: +lEdge.node.id.split('/').pop(),
      subLabel: `${lEdge.node.address.city}, ${lEdge.node.address.province}, ${lEdge.node.address.country}`
    }));

    if (hasNextPage) {
      const lastCollection = _.last(locations);
      setLastCursor(lastCollection?.cursor || null);
    }

    setLocationsHasNextPage(hasNextPage);
    setIsLoadingLocations(false);
    return formattedLocations;
  };

  /*************************************************** */
  // Update Functions
  /*************************************************** */
  const updateShopifyIntegration = variable => {
    const orderCreationIsOn = _.get(selectedIntegration, 'allowOrderCreation');
    const changingWillDisableAutomatedLookbookShopifyHandling = orderCreationIsOn && variable === 'allowOrderCreation';
    if (changingWillDisableAutomatedLookbookShopifyHandling) {
      return confirmAlert({
        title: 'Just confirming',
        message: `By turning off order creation, you will no longer be able to use automated lookbook fulfillment. Are you sure you want to continue?`,
        buttons: [
          { label: 'No', onClick: () => {}, className: 'cancel' },
          { label: 'Yes', onClick: () => props.updateBrandIntegration(selectedIntegration.id, { [variable]: !_.get(selectedIntegration, variable) }) }
        ]
      });
    }

    props.updateBrandIntegration(selectedIntegration.id, { [variable]: !_.get(selectedIntegration, variable) });
  };

  /**
   * Used for handling product availability / inventory based on warehouse locations
   *
   * Example: A brand is selling socks.
   * Warehouse A has 100 socks, Warehouse B has 50 socks, and Warehouse C has 0 socks.
   * Shopify by default will show 150 socks available, but if the brand doesn't base their inventory
   * on all warehouses, this might be misleading. This allows the brand to only add up inventory
   * from warehouses they actually base their inventory on.
   *
   */
  const updateLocationsOnBlur = async () => {
    const selectedLocationIds = selectedLocations?.map(l => l.value) || [];
    const existingInventoryLocations = selectedIntegration?.inventory_locations || [];
    const existingIds = existingInventoryLocations.map(l => l.shopifyLocationId);

    const needsUpdate = !(selectedLocationIds?.length === existingIds?.length && selectedLocationIds.every(id => existingIds.includes(id)));
    if (!needsUpdate) return;
    await updateBrandInventoryLocations(selectedIntegrationId, selectedLocationIds);
  };

  const updateShopfiyCustomOrderTags = newTags =>
    props.updateBrandIntegration(selectedIntegration.id, { shopifyOrderCustomTags: newTags.join(',') || null });
  const updateShopifyOrderInventoryBehavior = option =>
    props.updateBrandIntegration(selectedIntegration.id, { shopifyOrderInventoryBehavior: option.value });
  const updateShopifySiblingReductionConfig = variable =>
    props.updateBrandIntegration(selectedIntegration.id, {
      shopifySiblingReductionConfig: JSON.stringify({ ...shopifySiblingReductionConfig, [variable]: !shopifySiblingReductionConfig[variable] })
    });

  const sections = [
    /*************************************************** */
    // General
    /*************************************************** */
    {
      label: 'Integration Settings',
      settings: [
        {
          display: 'Allow Order Creation',
          description: 'Allow ShopMy to create orders in your Shopify store. This is required for automatic order fulfillment in Lookbooks.',
          variable: 'allowOrderCreation'
        },
        {
          display: 'Allow Catalog Syncing',
          description: 'Allow ShopMy direct access to your public Shopify catalog. This is required for automatic order fulfillment in Lookbooks.',
          variable: 'allowProductSyncing',
          secondaryCheckboxes: window.__ADMIN_CONTROL_MODE__
            ? [
                {
                  display: 'Ignore Prices on Sibling Merge',
                  description: 'Ignore prices when grouping variations into sibling groups.',
                  active: shopifySiblingReductionConfig['ignorePricesOnMerge'],
                  variable: 'ignorePricesOnMerge',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Sibling Information in Title',
                  description:
                    'Siblings exist on different PDPs and can be identified with the sibling information in the title and a clear, distinct separator. Examples: "Title in Red" and "Title in Blue" or "Title - Red" and "Title - Blue"',
                  active: shopifySiblingReductionConfig['siblingInformationInTitle'],
                  variable: 'siblingInformationInTitle',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Sibling Information in URL',
                  description:
                    'Siblings can only be discerend from differences in the handle or URL, example: "bonne-nuit-white-navy" and "bonne-nuit-pale-blue-red" (https://lalignenyc.com/collections/sleepwear/products/bonne-nuit-white-navy)',
                  active: shopifySiblingReductionConfig['siblingInformationInUrl'],
                  variable: 'siblingInformationInUrl',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Sibling Information in Numerical Sku (Rare)',
                  description:
                    'Siblings exist on different PDPs and can be identified with the numerical part of the Variant SKU. Examples: "1234BLUE" and "1234RED" or "SK1234-BLUE" and "SK1234-RED"',
                  active: shopifySiblingReductionConfig['groupBySubSkusNumericalOnly'],
                  variable: 'groupBySubSkusNumericalOnly',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Ignore Sibling Option 1',
                  description: 'First option should be ignored when grouping variations into sibling groups.',
                  active: shopifySiblingReductionConfig['ignoreOption1'],
                  variable: 'ignoreOption1',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Ignore Sibling Option 2',
                  description: 'Second option should be ignored when grouping variations into sibling groups.',
                  active: shopifySiblingReductionConfig['ignoreOption2'],
                  variable: 'ignoreOption2',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Ignore Sibling Option 3',
                  description: 'Third option should be ignored when grouping variations into sibling groups.',
                  active: shopifySiblingReductionConfig['ignoreOption3'],
                  variable: 'ignoreOption3',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Has Multiple Brands',
                  description: 'If the site is a retailer that carries multiple brands, this will disable the retailer name override.',
                  active: shopifySiblingReductionConfig['hasMultipleBrands'],
                  variable: 'hasMultipleBrands',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Override Inventory Quantity for Non Tracked Products',
                  description:
                    "If a shopify product's inventory_management = null (inventory not tracked) AND inventory_quantity <= 0, this will override availability and allow those products always be ordered through lookbooks.",
                  active: shopifySiblingReductionConfig['overrideInventoryQuantity'],
                  variable: 'overrideInventoryQuantity',
                  updateFnOverride: updateShopifySiblingReductionConfig
                },
                {
                  display: 'Remove Variant Id From Sibling Urls',
                  description:
                    'If the brand\'s site is headless, there is a good chance they do not want the variant id appended to the end of of sibling urls. This will remove the "variant" from the end of the url.',
                  active: shopifySiblingReductionConfig['removeVariantIdFromSiblingUrls'],
                  variable: 'removeVariantIdFromSiblingUrls',
                  updateFnOverride: updateShopifySiblingReductionConfig
                }
              ]
            : []
        },
        {
          display: 'Allow Custom Codes',
          description:
            'ShopMy will sync your discount codes so that any update or creation you make in Shopify will be reflected in ShopMy. You can also create custom codes in ShopMy that will be automatically udpated in Shopify.',
          variable: 'allowCustomCodes'
        },
        {
          display: 'Allow Affiliate Tracking',
          description:
            'You must be on a package higher than Shopify Basic to use this feature. ShopMy will sync affiliate sales directly to your Shopify store so we always have the most up to date information on your sales.',
          variable: 'allowAffiliateTracking',
          requiresAdminControlMode: true
        },
        {
          display: 'Enable Custom Inventory Locations',
          description: _.get(selectedIntegration, 'allowInventoryLocations')
            ? 'We use shopify to determine product availability if catalog syncing is enabled above. You may want to limit the inventory locations that we use to determine product availability. You can do this below by selecting up to two store or warehouse locations. Otherwise, we will use all locations.'
            : 'Optionally, choose specific inventory locations to determine your product availability.',
          variable: 'allowInventoryLocations',
          customComponent: () => {
            if (!_.get(selectedIntegration, 'allowInventoryLocations')) return null;

            return (
              <div className='location-info-container'>
                <div className='location-select'>
                  <InfiniteScrollSelect
                    className='location-infinite-scroll-select'
                    isMultiple={true}
                    closeMenuOnSelect={false}
                    onSelectOptions={selected => {
                      setSelectedLocations(selected);
                    }}
                    handleBlur={updateLocationsOnBlur}
                    value={selectedLocations}
                    loadMoreOptions={loadMoreLocations}
                    menuHeight={'170px'}
                    isLoading={isLoadingLocations}
                    placeholder={'Optional inventory locations...'}
                  />
                </div>
              </div>
            );
          }
        }
      ]
    },

    /*************************************************** */
    // Orders
    /*************************************************** */
    {
      label: 'Order Settings',
      settings: [
        {
          display: 'Set Lookbook Shopify Line Items to $0',
          description:
            'By default, we set line item prices in Shopify Lookbook orders to true item prices, and then apply an order-level discount of 100%. This is to ensure that the order subtotal is accurate, and to allow you to report on the true value of gifted items. Depending on your accounting practices, you may want us to set Shopify line item prices for Lookbook gifting to $0 instead. Check this box to enable this behavior. *Note: For international orders, we must still set line item prices to a non-zero value to comply with international tax laws and customs regulations.',
          variable: 'allowZeroedOutLineItems'
        },
        {
          display: 'Use Creator Name as Order Customer',
          description:
            "By default, we use 'ShopMy, Inc.' as the customer name for Shopify orders. Check this box to use the creator's name for the customer field instead. *Note: This will only apply to orders placed after this setting is enabled.",
          variable: 'allowShopifyOrderUserAsCustomer',
          isHidden: !window.__ADMIN_CONTROL_MODE__
        },
        {
          display: 'Require Creator Phone Number',
          description:
            'Check this box to require creators to provide a phone number when placing Shopify orders. This phone number will then be attached to Shopify orders.',
          variable: 'requireOrderPhoneNumber',
          isHidden: !window.__ADMIN_CONTROL_MODE__
        },
        {
          display: 'Use Shopify Draft Order Flow for Lookbooks',
          description:
            'When enabled, we will create and complete draft orders in Shopify for Lookbook orders instead of creating orders directly. This is beneficial for some Shopify setups that utilize Shopify Flow or other automated tools to make order adjustments before finalizing orders.',
          variable: 'allowShopifyDraftOrderCompletion'
        }
      ]
    },
    {
      component: () => {
        return (
          <div className='settings-section'>
            <div className='settings-section-title'>Custom Order Tags</div>
            <div className='settings-section-subtitle'>
              Add custom Shopfiy tags used for Lookbook orders placed through ShopMy. By default, we add a 'ShopMy' tag to all Lookbook orders. Use
              comma or enter keys below to add a tag.
            </div>

            <TagTypeInput
              tags={(selectedIntegration?.shopifyOrderCustomTags || '').split(',').filter(Boolean)}
              restrictedTags={['ShopMy']}
              handleSaveOnBlur={updateShopfiyCustomOrderTags}
              maxTagLength={40}
              showDescription={false}
            />
          </div>
        );
      }
    },
    {
      component: () => {
        return (
          <div className='settings-section'>
            <div className='settings-section-title'>Specify Order Inventory Behavior</div>
            <div className='settings-section-subtitle'>
              Choose how ShopMy should handle Shopify item inventory when placing Shopify Lookbook orders.
            </div>

            <Select
              options={SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS}
              value={SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS.find(o => o.value === selectedIntegration?.shopifyOrderInventoryBehavior)}
              onChange={updateShopifyOrderInventoryBehavior}
              components={{ Option: SelectOption }}
              isClearable={false}
              styles={{
                singleValue: provided => ({
                  ...provided,
                  fontWeight: 'bold',
                  fontSize: '14px'
                })
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className='shopify-integration-panel-outer'>
      <div className='shopify-integration-panel-inner'>
        {hasMultipleShopifyIntegrations && (
          <div className='integration-select-container'>
            <div className='description'>
              You have multiple Shopify integrations. Select one to manage it. If you want to add a new Shopify integration, please contact your
              account manager.
            </div>
            <div className='shopify-integration-select-field'>
              <Select
                options={shopifyIntegrations.map(i => ({ value: i.id, label: i.shopName, sublabel: i.domain }))}
                value={{ value: selectedIntegrationId, label: selectedIntegration.shopName }}
                onChange={selected => setSelectedIntegrationId(selected.value)}
                components={{ Option: SelectOption }}
                styles={{
                  singleValue: provided => ({
                    ...provided,
                    fontWeight: 'bold',
                    fontSize: '14px'
                  })
                }}
              />
            </div>
          </div>
        )}

        {sections.map(section => {
          const { label, settings, component } = section;

          if (component) return component();
          return (
            <div key={label} className='settings-section'>
              <div className='settings-section-title'>{label}</div>

              <div className='settings-section-list'>
                {settings.map(setting => {
                  const {
                    display,
                    description,
                    variable,
                    updateFnOverride,
                    secondaryCheckboxes,
                    customComponent,
                    isHidden,
                    requiresAdminControlMode
                  } = setting;
                  const currentValue = _.get(selectedIntegration, variable);
                  const updateFn = updateFnOverride || updateShopifyIntegration;

                  if (isHidden) return null;
                  return (
                    <div key={display} className='settings-section-list'>
                      <CheckboxButton
                        text={display}
                        description={description}
                        isChecked={!!currentValue}
                        onChange={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (requiresAdminControlMode && !window.__ADMIN_CONTROL_MODE__)
                            return window.ALERT.warn('Please contact your account manager to change this setting.');
                          else updateFn(variable);
                        }}
                      />
                      {!!secondaryCheckboxes?.length && (
                        <div className='settings-section-list secondary-checkboxes'>
                          {secondaryCheckboxes.map(secondarySetting => {
                            const { display, description, variable, updateFnOverride, active, requiresAdminControlMode } = secondarySetting;
                            const updateFn = updateFnOverride || updateShopifyIntegration;

                            return (
                              <div key={variable}>
                                <CheckboxButton
                                  text={display}
                                  description={description}
                                  isChecked={!!active}
                                  onChange={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (requiresAdminControlMode && !window.__ADMIN_CONTROL_MODE__)
                                      return window.ALERT.warn('Please contact your account manager to change this setting.');
                                    else updateFn(variable);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {customComponent && customComponent()}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ShopifyIntegrationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateBrandIntegration: PropTypes.func.isRequired,
  updateBrandInventoryLocations: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  updateBrandIntegration,
  updateBrandInventoryLocations
})(ShopifyIntegrationPanel);
