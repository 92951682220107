import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faEyeSlash, faEye, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import _ from 'lodash';
import './ProductHeartOverlay.scss';

import { toggleBodyScrollLock, setUIKeyValue, openAuthModal } from '../../../Actions/UIActions';
import {
  getSimpleCollectionsForAdding,
  createUserProductByProductId,
  deleteUserProductByProductId,
  createPinViaProduct,
  updateUserProduct,
  deleteProductFromCollection
} from '../../../Actions/ShopActions';
import { addSection } from '../../../Actions/SectionActions';

import { createCollection } from '../../../APIClient/collections';

import { isShopper, getUserId, isLoggedIn, getSections, hasProductIdsPromoted, getUserProductById } from '../../../Helpers/user_helpers';
import { getLinkToCollectionPageViaId } from '../../../Helpers/shop_helpers';
import { getPrettyTimeAgoFromNow, getFormattedSectionTitle, getGMTTime } from '../../../Helpers/formatting';

import ConfirmPrompt from '../../General/ConfirmPrompt';
import InputActions from '../../General/InputActions';
import Loader from '../../Loader/Loader';
import Tooltip from '../../General/Tooltip';

const ProductHeartOverlay = props => {
  const { user, shop, product, children, closeOverlay } = props;
  const simpleCollectionsForAdding = shop.simpleCollectionsForAdding || [];
  const overlayRef = React.useRef(null);
  const loggedIn = isLoggedIn(user);
  const userProduct = getUserProductById(user, product.id);

  // Allow Searching Collections
  const [inSearchMode, setInSearchMode] = React.useState(false);
  const [curSearchVal, setCurSearchVal] = React.useState('');

  // Fetch key data
  const [isSearchingCollections, setIsSearchingCollections] = React.useState(false);
  const [collectionIdOrder, setCollectionIdOrder] = React.useState([]);
  const syncData = async query => {
    const resp = await props.getSimpleCollectionsForAdding(_.omitBy({ query }, _.isEmpty));
    !collectionIdOrder.length && setCollectionIdOrder(resp.collections.map(collection => collection.id));
  };
  const searchDebounce = React.useRef(null);
  const updateSearch = async newVal => {
    setIsSearchingCollections(true);
    setCurSearchVal(newVal);
    clearTimeout(searchDebounce.current);
    searchDebounce.current = setTimeout(
      async () => {
        await syncData(newVal);
        setIsSearchingCollections(false);
      },
      newVal ? 250 : 0
    );
  };

  React.useEffect(() => {
    if (loggedIn) {
      syncData();
    } else {
      props.openAuthModal('register-shopper');
    }
  }, [loggedIn]);

  // Store whether this panel is open for global usage
  React.useEffect(() => {
    props.setUIKeyValue('isProductHeartOverlayOpen', true, 'shop');
    return () => props.setUIKeyValue('isProductHeartOverlayOpen', false, 'shop');
  }, []);

  const createNewSection = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Create New Section'
          onCancel={onClose}
          submitBtnDisplay='Create'
          customInputFields={[
            {
              display: 'Section title',
              placeholder: '',
              value: 'title',
              preloaded: '',
              isSingleLine: true
            },
            {
              label: 'Visible on Shop Page',
              isBoolean: true,
              preloaded: true,
              disabledMsg: isShopper(user) ? 'Only creator accounts can share their collections.' : null,
              value: 'visible'
            }
          ]}
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            const resp = await props.addSection({
              User_id: getUserId(user),
              title: responseValues.title,
              isHidden: !responseValues.visible
            });
            props.syncDataOnDataChange && props.syncDataOnDataChange();
            window.__ADD_EVENT__('Shop - Created Section', { Section: responseValues.title });
            return !!resp.section;
          }}
        />
      )
    });
  };

  const createNewCollection = () => {
    const category = product?.Category_name || product?.category?.name;
    const sections = _.orderBy(getSections(user), 'sortOrderRank', 'asc');
    const needsSection = sections.length > 1;
    const sectionOptions = sections.map(section => ({
      value: section.id,
      label: getFormattedSectionTitle(section)
    }));

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header={simpleCollectionsForAdding.length ? 'Create New Collection' : 'Create Your First Collection'}
          onCancel={onClose}
          submitBtnDisplay='Create'
          customInputFields={[
            ...(needsSection
              ? [
                  {
                    display: 'Shop Section',
                    subdisplay: (
                      <>
                        Where should this collection be placed? To make a new section, {` `}
                        <span className='clickable' onClick={createNewSection}>
                          click here.
                        </span>
                      </>
                    ),
                    isSelect: true,
                    selectOptions: sectionOptions,
                    preloaded: sections[0]?.id,
                    enableSelectSearch: true,
                    value: 'Section_id',
                    selectPlaceholder: 'Which Shop Section?'
                  }
                ]
              : []),
            {
              display: 'Collection Name',
              placeholder: 'My Favorite ',
              value: 'name',
              preloaded: category ? `My Favorite ${category}` : '',
              isSingleLine: true
            },
            {
              display: 'Describe this collection',
              placeholder: 'Description',
              value: 'description',
              numRows: 3
            },

            ...(isShopper(user)
              ? []
              : [
                  {
                    label: 'Visible on Shop Page',
                    isBoolean: true,
                    disabledMsg: isShopper(user) ? 'Only creator accounts can share their collections.' : null,
                    value: 'private'
                  }
                ])
          ]}
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            try {
              const newCollection = await createCollection({
                User_id: getUserId(user),
                name: responseValues.name,
                Section_id: isShopper(user) ? sections[0]?.id : responseValues.Section_id,
                description: responseValues.description,
                private: isShopper(user) ? false : responseValues.private
              });
              await addProductToCollection(newCollection);
              await props.getSimpleCollectionsForAdding();
              props.syncDataOnDataChange && props.syncDataOnDataChange();
              window.__ADD_EVENT__('Shop - Created Collection', { Collection: newCollection.name, ViaProduct_id: product.id });
              return true;
            } catch (error) {
              window.ALERT.error(`Error creating collection: ${error}`);
            }
          }}
        />
      )
    });
  };

  const [isAddingToCollectionIds, setIsAddingToCollectionIds] = React.useState([]);
  const addProductToCollection = async collection => {
    const hasntAddedProduct = !hasProductIdsPromoted(props.user);
    setIsAddingToCollectionIds([...isAddingToCollectionIds, collection.id]);
    await props.createPinViaProduct(product, {
      Collection_id: collection.id,
      sortOrderRank: collection.addPinsToTop ? -1 * getGMTTime() : getGMTTime(),
      viaCurator_id: props.attribution?.Curator_id || null,
      viaCuratorGroup_id: props.attribution?.CuratorGroup_id || null
    });
    await syncData(curSearchVal);
    props.syncDataOnDataChange && props.syncDataOnDataChange();
    setIsAddingToCollectionIds(isAddingToCollectionIds.filter(id => id !== collection.id));
    if (hasntAddedProduct)
      window.ALERT.success(`Added first product! You can find all your products in your wishlist tab.`, {
        hideAfter: 8
      });
    window.__ADD_EVENT__('Shop - Favorited Product', {
      source: 'Add To Collection',
      Collection: collection.name,
      title: product.title,
      Product_id: product.id
    });
  };

  const [isRemovingFromCollectionIds, setIsRemovingFromCollectionIds] = React.useState([]);
  const removeProductFromCollection = async collection => {
    setIsRemovingFromCollectionIds([...isRemovingFromCollectionIds, collection.id]);
    await props.deleteProductFromCollection(product.id, collection.id);
    await syncData(curSearchVal);
    props.syncDataOnDataChange && props.syncDataOnDataChange();
    setIsRemovingFromCollectionIds(isRemovingFromCollectionIds.filter(id => id !== collection.id));
    window.__ADD_EVENT__('Shop - Removed From Collection', { Collection: collection.name, title: product.title, Product_id: product.id });
  };

  const isInAtLeastOneCollection = simpleCollectionsForAdding.some(collection => collection.product_ids.includes(product.id));
  const toggleUserProductVisibility = async () => {
    if (!userProduct) {
      await props.createUserProductByProductId(product.id);
      window.__ADD_EVENT__('Shop - Favorited Product', { source: 'General Overlay Heart', title: product.title, Product_id: product.id });
    } else if (userProduct.isHidden) {
      const resp = await props.updateUserProduct(userProduct, { isHidden: false });
      resp.success &&
        window.__ADD_EVENT__('Shop - Hid Product from Shop', { source: 'General Overlay Heart', title: product.title, Product_id: product.id });
    } else {
      const resp = await props.updateUserProduct(userProduct, { isHidden: true });
      resp.success &&
        window.__ADD_EVENT__('Shop - Making Product from Shop Visible', {
          source: 'General Overlay Heart',
          title: product.title,
          Product_id: product.id
        });
    }
  };

  // Animate in the UI
  const [isVisible, setIsVisible] = React.useState(false);
  React.useEffect(() => {
    loggedIn && setTimeout(() => setIsVisible(true), 50);
  }, [loggedIn]);

  // Handle overlay positioning when active.
  const lastClickRef = React.useRef(window.lastClick || { x: 0, y: 0 });
  React.useEffect(() => {
    const lastClick = lastClickRef.current;
    const WIDTH = 360; // Keep in sync with SCSS
    const MAX_HEIGHT = 400; // Keep in sync with SCSS
    const shouldOpenRight = lastClick.x < WIDTH;
    const pixelsFromBottom = window.innerHeight - lastClick.y;
    const shouldOpenUp = pixelsFromBottom < MAX_HEIGHT;

    if (overlayRef.current) {
      overlayRef.current.style.width = `${WIDTH}px`;

      if (shouldOpenUp) overlayRef.current.style.bottom = `${window.innerHeight - lastClick.y + 5}px`;
      else overlayRef.current.style.top = `${lastClick.y + 5}px`;

      const maxLeftAllowed = window.innerWidth - WIDTH - 5;
      const leftShouldBeMax = lastClick.x - (shouldOpenRight ? 0 : WIDTH) - 2 > maxLeftAllowed;
      overlayRef.current.style.left = `${Math.min(maxLeftAllowed, lastClick.x - (shouldOpenRight ? 0 : WIDTH) - 2)}px`;

      // overlayRef.current.style.left = `${lastClick.x - (shouldOpenRight ? 0 : WIDTH) - 2}px`;
      if (!leftShouldBeMax)
        overlayRef.current.style[`border-${shouldOpenUp ? 'bottom' : 'top'}-${shouldOpenRight ? 'left' : 'right'}-radius`] = `0px`;
      else overlayRef.current.style[`border-${shouldOpenUp ? 'bottom' : 'top'}-${shouldOpenRight ? 'left' : 'right'}-radius`] = `10px`;
    }
  }, [isVisible]);

  // Handle locking the scroll in the case of the overlay press
  React.useEffect(() => {
    props.toggleBodyScrollLock(true);
    return () => props.toggleBodyScrollLock(false, { skipDelayedCheck: true });
  }, [loggedIn]);

  const additionalClasses = { visible: isVisible };
  const overlay = (
    <div className={cn('product-heart-overlay-outer-container', additionalClasses)}>
      <div onClick={closeOverlay} className={cn('product-heart-overlay-fade', additionalClasses)} />
      <div ref={overlayRef} className={cn('collections-container', additionalClasses)}>
        <div className='header-outer-container'>
          {inSearchMode ? (
            <>
              <div className='input-container has-actions'>
                <input
                  autoFocus
                  value={curSearchVal}
                  onChange={e => updateSearch(e.target.value)}
                  onBlur={() => !curSearchVal && setInSearchMode(false)}
                  placeholder='Search Collections'
                  className='search-input'
                />
                <InputActions searchVal={curSearchVal} onCancel={() => updateSearch('')} />
              </div>
              <div className='actions'>
                <div
                  onClick={() => {
                    updateSearch('');
                    setInSearchMode(false);
                  }}
                  className='action search done'
                >
                  Done
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='header-container'>
                <div className='header'>Add to Collection:</div>
              </div>
              <div className='actions'>
                {!isShopper(user) && userProduct && (
                  <Tooltip
                    message={
                      isInAtLeastOneCollection
                        ? userProduct.isHidden
                          ? 'Currently hidden from shop page, click to show'
                          : 'Currently visible on shop page, click to hide'
                        : userProduct.isHidden
                        ? 'Currently hidden from shop page, click to show without adding it to a collection'
                        : 'Currently visible on shop page due to one of your quick links, click to hide'
                    }
                  >
                    <div onClick={toggleUserProductVisibility} className='action search'>
                      <FontAwesomeIcon icon={userProduct.isHidden ? faEyeSlash : faEye} />
                    </div>
                  </Tooltip>
                )}
                <div onClick={() => setInSearchMode(true)} className='action search'>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div onClick={createNewCollection} className='action new-collection'>
                  <FontAwesomeIcon icon={faPlus} />
                  New
                </div>
              </div>
            </>
          )}
        </div>
        <div className={cn('collections', { searching: isSearchingCollections })}>
          {!simpleCollectionsForAdding.length ? (
            <div className='no-collections'>
              {curSearchVal ? (
                <div className='message'>Could not find any collections matching your search.</div>
              ) : (
                !isSearchingCollections && <div className='message'>You have no collections. Create a new collection to save this product.</div>
              )}
            </div>
          ) : (
            _.orderBy(simpleCollectionsForAdding, c => collectionIdOrder.indexOf(c.id) || -1)?.map(collection => {
              const { id, image, name, createdAt, lastPinAdded, product_ids } = collection;

              const isInCollection = product_ids.includes(product.id);
              const isAddingToCollection = isAddingToCollectionIds.includes(collection.id);
              const isRemovingFromCollection = isRemovingFromCollectionIds.includes(collection.id);

              const toggle = () => (isInCollection ? removeProductFromCollection(collection) : addProductToCollection(collection));

              const additionalClasses = { active: isInCollection, adding: isAddingToCollection, removing: isRemovingFromCollection };
              return (
                <div key={id} className={cn('collection', additionalClasses)}>
                  <div className='main'>
                    <div className='image-container'>{image ? <img src={image} alt={name} /> : <div className='no-image' />}</div>
                    <div className='data'>
                      <Link to={getLinkToCollectionPageViaId(collection.id)} className='name'>
                        {name}
                      </Link>
                      {lastPinAdded ? (
                        <div className='datetime'>Updated {getPrettyTimeAgoFromNow(lastPinAdded, { longForm: true })}</div>
                      ) : (
                        <div className='datetime'>Created {getPrettyTimeAgoFromNow(createdAt, { longForm: true })}</div>
                      )}
                    </div>
                  </div>
                  <div className={cn('actions', additionalClasses)}>
                    <div className={cn('action', additionalClasses)} onClick={toggle}>
                      {isAddingToCollection || isRemovingFromCollection ? (
                        <Loader size={40} />
                      ) : (
                        <FontAwesomeIcon icon={isInCollection ? faCheck : faPlus} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );

  if (!isLoggedIn(user)) {
    return children;
  }

  return (
    <>
      {children}
      {ReactDOM.createPortal(overlay, document.body)}
    </>
  );
};

ProductHeartOverlay.propTypes = {
  // From Outside
  product: PropTypes.object.isRequired,
  attribution: PropTypes.object.isRequired,
  closeOverlay: PropTypes.func.isRequired,

  // From Inside
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  toggleBodyScrollLock: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  setUIKeyValue: PropTypes.func.isRequired,
  getSimpleCollectionsForAdding: PropTypes.func.isRequired,
  createUserProductByProductId: PropTypes.func.isRequired,
  deleteUserProductByProductId: PropTypes.func.isRequired,
  updateUserProduct: PropTypes.func.isRequired,
  createPinViaProduct: PropTypes.func.isRequired,
  deleteProductFromCollection: PropTypes.func.isRequired,
  addSection: PropTypes.func.isRequired,

  // Optional from Outside
  syncDataOnDataChange: PropTypes.func // If we have a non-redux behavior we need to sync with
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  toggleBodyScrollLock,
  openAuthModal,
  setUIKeyValue,
  addSection,
  createPinViaProduct,
  deleteProductFromCollection,
  createUserProductByProductId,
  updateUserProduct,
  deleteUserProductByProductId,
  getSimpleCollectionsForAdding
})(ProductHeartOverlay);
