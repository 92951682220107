import _ from 'lodash';

import { isPromotePage } from './helpers';
import defaultPhoto from '../static/images/logos/logo.png';

export const isSearching = ui => !!_.get(ui, 'isSearching');
export const inSearchMode = ui => !!_.get(ui, 'headerSearchVal.length');
export const getSearchVal = ui => _.get(ui, 'headerSearchVal') || '';
export const hasJoinedEmailList = ui => !!_.get(ui, 'hasJoinedMailingList') || false;
export const isAdminControlMode = ui => !!_.get(ui, 'isAdminControlMode') || false;

// Chat overlay
export const isChatOverlayVisible = ui => !!_.get(ui, 'activeChatOverlayTalent') || false;

// Moving Pins
export const getPinCompletionFn = ui => _.get(ui, 'pinMoveCompletionFn') || (() => {});
export const getPinsBeingMoved = ui => _.get(ui, 'pinsBeingMoved') || [];
export const isMovingPins = ui => !!getPinsBeingMoved(ui).length;
export const isPinBeingMoved = (pin, ui) => !!_.find(getPinsBeingMoved(ui), p => p.id === pin.id);

// Navigation & Header
export const getShopMyLabel = ui => _.get(ui, 'shopMyLabel') || 'shelf';

// General UI Key Value Store
export const getUIKeyValue = (ui, key, type, initial) => (key in (ui[type] || {}) ? _.get(ui, [type, key]) : initial);
export const getUIKeyValueGeneral = (ui, key, initial) => getUIKeyValue(ui, key, 'general', initial);
export const getUIKeyValueTalent = (ui, key, initial) => getUIKeyValue(ui, key, 'talent', initial);
export const getUIKeyValueDiscover = (ui, key, initial) => getUIKeyValue(ui, key, 'discover', initial);
export const getUIKeyValueLatest = (ui, key, initial) => getUIKeyValue(ui, key, 'latest', initial);
export const getUIKeyValuePartners = (ui, key, initial) => getUIKeyValue(ui, key, 'partners', initial);
export const getUIKeyValueShop = (ui, key, initial) => getUIKeyValue(ui, key, 'shop', initial);

// Artist Modal
export const isArtistModalVisible = ui => !!_.get(ui, 'activeArtistModalTalent') || false;
export const showCompetitorBrandsOnArtistModal = ui => getUIKeyValueGeneral(ui, 'showCompetitorBrandsOnArtistModal') || false;

// Brand Modal
export const isBrandModalVisible = ui => !!_.get(ui, 'isBrandModalVisible') || false;
export const getBrandModalStartingParams = ui => _.get(ui, 'brandModalStartingParams') || {};

// Bulk Talent Modal
export const isBulkTalentModalVisible = ui => _.get(ui, 'isBulkTalentModalVisible') || false;
export const getBulkTalentModalStartingParams = ui => _.get(ui, 'bulkTalentModalStartingParams') || {};

// Fulfillment Modal
export const isFulfillmentModalVisible = ui => _.get(ui, 'isFulfillmentModalVisible') || false;
export const getFulfillmentModalStartingParams = ui => _.get(ui, 'fulfillmentModalStartingParams') || {};

// Bonus Modal
export const isBonusModalVisible = ui => _.get(ui, 'isBonusModalVisible') || false;
export const getBonusModalStartingParams = ui => _.get(ui, 'bonusModalStartingParams') || {};

// Brand Budget Modal
export const isBrandBudgetModalVisible = ui => _.get(ui, 'isBrandBudgetModalVisible') || false;
export const getBrandBudgetModalStartingParams = ui => _.get(ui, 'brandBudgetModalStartingParams') || {};

// Subscription Offer Modal
export const isSubscriptionOfferModalVisible = ui => _.get(ui, 'isSubscriptionOfferModalVisible') || false;
export const getSubscriptionOfferModalStartingParams = ui => _.get(ui, 'subscriptionOfferModalStartingParams') || {};

// Unpaid Invoices Modal
export const isUnpaidInvoicesModalVisible = ui => _.get(ui, 'isUnpaidInvoicesModalVisible') || false;
export const getUnpaidInvoicesModalStartingParams = ui => _.get(ui, 'unpaidInvoicesModalStartingParams') || {};

// Codes Modal
export const isCodesModalVisible = ui => _.get(ui, 'isCodesModalVisible') || false;
export const getCodesModalStartingParams = ui => _.get(ui, 'codesModalStartingParams') || {};
export const getCodesModalCloseCallback = ui => _.get(ui, 'codesModalCloseCallback');
export const getCodesModalSubmitCallback = ui => _.get(ui, 'codesModalSubmitCallback');

// Gifting Modal - Deprecated
export const isGiftingModalVisible = ui => _.get(ui, 'isGiftingModalVisible') || false;
export const getGiftingModalStartingParams = ui => _.get(ui, 'giftingModalStartingParams') || {};

// Gifting Modal
export const isBulkRequestModalVisible = ui => _.get(ui, 'bulkRequestModalIsVisible') || false;
export const getbulkRequestModalStartingParams = ui => _.get(ui, 'bulkRequestModalStartingParams') || {};
export const getbulkRequestModalCloseCallback = ui => _.get(ui, 'bulkRequestModalCloseCallback');
export const getbulkRequestModalSubmitCallback = ui => _.get(ui, 'bulkRequestModalSubmitCallback');

// Shop Search
export const isShopHeaderSearchFocused = ui => _.get(ui, 'isShopHeaderSearchFocused') === true || getShopHeaderSearchVal(ui).length > 0;
export const getShopHeaderSearchVal = ui => _.get(ui, 'shopHeaderSearchVal') || '';

// Shop Products
export const isProductHeartOverlayOpen = ui => getUIKeyValueShop(ui, 'isProductHeartOverlayOpen') || false;

// Curator Group Modal
export const isCuratorGroupModalVisible = ui => _.get(ui, 'isCuratorGroupModalVisible') || false;
export const getCuratorGroupModalStartingParams = ui => _.get(ui, 'curatorGroupModalStartingParams') || {};

// Terms and Conditions
export const isTermsAndConditionsModalVisible = ui => _.get(ui, 'termsAndConditionsVisible') || false;

// Login Modal
export const getAuthCallbackFunctions = ui => _.get(ui, 'authPanelCallbackFns') || {}; // { onRegister, onLogin }

// Catalog Product Modal
export const isCatalogProductModalVisible = ui => !!_.get(ui, 'catalogProductModalVariant');

/*
  Brand Theming:

  Brand themes allow premium plus brands to stylize pages in their own brand, with their
  logo and main color palette. To set the theme, use a dispatch in one of the Action files:

  ex: dispatch(setBrandTheme(resp?.user?.brand))
*/
export const getBrandTheme = ui => _.get(ui, 'brandTheme') || null;
export const getDefaultImage = () => defaultPhoto;

export const getBrandThemeStyling = brand => {
  const defaultColor = '#1f1f1f'; // ShopMy dark gray
  return {
    primaryColor: brand?.primaryColor || defaultColor
  };
};

/*
  This is an edge case so we can offer a promotion page for the NCEA
*/
export const isNCEA = brand => brand?.name === 'NCEA';

export const updateGlobalThemeStyling = brand => {
  const brandThemeStyling = getBrandThemeStyling(brand);

  const applyStyling = () => {
    const root = document.documentElement;
    root.style.setProperty('--background-color', brandThemeStyling.primaryColor);
    root.style.setProperty('--background-color-pro', brandThemeStyling.primaryColor);
    let metaTheme = document.querySelector('meta[name=theme-color]');
    metaTheme && metaTheme.setAttribute('content', brandThemeStyling.primaryColor);
  };

  // Update global scss colors
  if (isPromotePage()) applyStyling();
};
