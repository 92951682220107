import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import smsLogo from '../../../static/images/logos/logo.png';
import cn from 'classnames';
import './ShopPageHeaderNew.scss';

import { openCuratorGroupModal, focusShopSearch } from '../../../Actions/UIActions';
import { updateCurrentUser } from '../../../Actions/UserActions';
import { createCuratorGroupFavorite, deleteCuratorGroupFavorite, updateCuratorGroup } from '../../../Actions/ShopActions';
import {
  getCuratorGroupsIncludingUserId,
  getCuratorGroupFavorites,
  isCuratorGroupIdMine,
  getUserId,
  hasProductIdsPromoted,
  isShopper
} from '../../../Helpers/user_helpers';
import { getPreviewImageForGroup, getLinkToPrivateCuratorGroupPage, getLinkToPublicCuratorGroupPage } from '../../../Helpers/shop_helpers';
import { getSocialIconsForArtist } from '../../../Helpers/social_helpers';

import SmartImage from '../../General/SmartImage';
import Tooltip from '../../General/Tooltip';
import Loader from '../../Loader/Loader';

const ShopPageHeaderNew = props => {
  const { user, activeRefinementObjects, isUnfilteredState, query } = props;
  const { activeCurator, activeCuratorGroup, activeCategory, activeDepartment, activeIndustry, activeBrands } = activeRefinementObjects;
  const curatorGroupsForActiveCurator = activeCurator ? getCuratorGroupsIncludingUserId(user, activeCurator.id) : [];
  const curatorGroupFavorite = activeCuratorGroup ? getCuratorGroupFavorites(user)?.find(f => f.CuratorGroup_id === activeCuratorGroup.id) : null;
  const isMyShop = activeCurator && activeCurator.id === getUserId(user);
  const isAllCurators = !activeCurator && !activeCuratorGroup;

  // Handle advanced transitioning
  const isTransitioningRefinements = !!props.isFetchingRefinements;

  // Creator Transitions
  const curatorAndGroupHash = `${props.Curator_id}-${props.Curator_username}-${props.CuratorGroup_id}`;
  const [isTransitioningCurators, setIsTransitioningCurators] = React.useState(false);
  const lastCuratorAndGroupHash = React.useRef(curatorAndGroupHash);
  React.useEffect(() => {
    if (lastCuratorAndGroupHash.current !== curatorAndGroupHash) {
      setIsTransitioningCurators(true);
    }
    lastCuratorAndGroupHash.current = curatorAndGroupHash;
  }, [curatorAndGroupHash]);

  // Brand Transitions
  const [isTransitioningBrands, setIsTransitioningBrands] = React.useState(false);
  const currentBrandIdRef = React.useRef(props.AllBrand_id);
  React.useEffect(() => {
    if (currentBrandIdRef.current !== props.AllBrand_id) {
      setIsTransitioningBrands(true);
    }
    currentBrandIdRef.current = props.AllBrand_id;
  }, [props.AllBrand_id]);

  // Clear transitions
  React.useEffect(() => {
    if (!isTransitioningRefinements) {
      setIsTransitioningBrands(false);
      setIsTransitioningCurators(false);
    }
  }, [isTransitioningRefinements]);

  // Images
  let name, bio, badgeImage, isBrandBadge, preview_images;

  if (activeCurator) {
    name = activeCurator.name;
    badgeImage = activeCurator.image;
    bio = activeCurator.description;
    preview_images = [];
  } else if (activeCuratorGroup) {
    name = activeCuratorGroup.title;
    badgeImage = getPreviewImageForGroup(activeCuratorGroup);
    preview_images = activeCuratorGroup.users.filter(u => !!u.image).slice(0, 5);
  } else if (props.Curator_id || props.Curator_username || props.CuratorGroup_id) {
    name = '';
    preview_images = [];
  } else {
    name = 'All ShopMy curators';
    badgeImage = smsLogo;
    preview_images = [];

    if (activeBrands?.length) {
      badgeImage = activeBrands[0].logo || badgeImage;
      isBrandBadge = true;
    }
  }

  /*
    Favoriting Logic
  */
  const isMyGroup = activeCuratorGroup && isCuratorGroupIdMine(user, activeCuratorGroup.id);
  const [isFavoriting, setIsFavoriting] = React.useState(false);
  const canFavorite = !isMyGroup && !isMyShop;
  const isFavorited = curatorGroupsForActiveCurator.length > 0 || !!curatorGroupFavorite;
  const heartTootlip = activeCuratorGroup
    ? isMyGroup
      ? 'This is your circle so you cannot unfavorite it'
      : isFavorited
      ? 'Remove this favorite'
      : 'Favorite this circle'
    : isFavorited
    ? 'This curator is in your circle'
    : isMyShop
    ? 'Add myself to my own circle'
    : 'Add this curator to your circle';

  const pressHeart = e => {
    e.stopPropagation();
    if (isMyGroup) return;

    if (activeCurator) {
      props.openCuratorGroupModal({
        source: 'shop_header',
        params: {
          type: 'add',
          curator: activeCurator,
          curatorGroup: activeCuratorGroup
        }
      });
    } else if (activeCuratorGroup) {
      if (isFavorited) {
        props.deleteCuratorGroupFavorite(curatorGroupFavorite, { source: 'shop_header' }).then(() => {
          window.ALERT.success(`Unfavorited the shopping circle "${activeCuratorGroup.title}"`);
        });
      } else {
        if (isFavoriting) return;
        setIsFavoriting(true);
        props
          .createCuratorGroupFavorite(activeCuratorGroup, { source: 'shop_header' })
          .then(resp => {
            resp.groupFavorite && window.ALERT.success(`Favorited the shopping circle "${activeCuratorGroup.title}"`);
          })
          .finally(() => {
            setIsFavoriting(false);
          });
      }
    }
  };

  /*
    Tab Handling
  */
  const hasSearchQuery = query && query.length > 0;
  const [activeTabStyle, setActiveTabStyle] = React.useState({ left: 0, width: 0 });
  const tabsRef = React.useRef([]);
  const tabOptions = [
    {
      value: `foryou`,
      label: 'For You',
      sublabel: 'AI powered recommendations.',
      isHidden: isMyShop,
      isDisabled: hasSearchQuery || isUnfilteredState || !hasProductIdsPromoted(user),
      isDisabledWarning: hasSearchQuery
        ? 'Not available when searching, clear search pill to reset.'
        : isUnfilteredState
        ? 'Please select a category'
        : !hasProductIdsPromoted(user)
        ? 'Heart a few products to get personalized recommendations'
        : null
    },
    {
      value: `latest`,
      label: 'Latest Finds',
      sublabel: 'Newly discovered.',
      isDisabled: hasSearchQuery || isUnfilteredState,
      isDisabledWarning: hasSearchQuery
        ? 'Not available when searching, clear search pill to reset.'
        : isUnfilteredState
        ? 'Please select a category'
        : null
    },
    {
      value: `popular`,
      // label: props.isCuratedShop ? 'By Category' : 'Most Popular',
      label: 'Most Popular',
      sublabel: 'Most talked about products.',
      isHidden: isShopper(user) && isMyShop,
      isDisabled: hasSearchQuery || isUnfilteredState,
      isDisabledWarning: hasSearchQuery
        ? 'Not available when searching, clear search pill to reset.'
        : isUnfilteredState
        ? 'Please select a category'
        : null
    },
    {
      value: `collections`,
      label: isMyShop && isShopper(user) ? 'Wishlists' : 'Collections',
      sublabel: 'Curated Lists.',
      isDisabled: hasSearchQuery || (!activeCuratorGroup && !activeCurator),
      isDisabledWarning: hasSearchQuery
        ? 'Collections are not available when searching.'
        : isUnfilteredState
        ? 'Please select a category'
        : activeCuratorGroup
        ? "Top Lists don't have collections."
        : activeCurator
        ? null
        : 'Filter to specific curator shops to see all recent collections'
    }
  ]
    .filter(c => !c.isHidden)
    .map(tab => ({
      ...tab,
      onClick: () => {
        if (tab.isDisabled) {
          window.ALERT.warn(tab.isDisabledWarning);
          window.__ADD_EVENT__(`Shop - Clicked Disabled Tab`, {
            value: tab.value,
            Curator: activeCurator?.name || 'None',
            'Curator Group': activeCuratorGroup?.title || 'None'
          });
        } else {
          props.goToTab(tab.value);
          window.__ADD_EVENT__(`Shop - Clicked Tab`, {
            value: tab.value,
            Curator: activeCurator?.name || 'None',
            'Curator Group': activeCuratorGroup?.title || 'None'
          });
        }
      },
      isActive: tab.isActiveOverride || tab.value === props.tab
    }));
  const activeTabIndex = tabOptions.findIndex(t => t.isActive);
  React.useEffect(() => {
    if (tabsRef.current[activeTabIndex]) {
      const { offsetLeft, offsetWidth } = tabsRef.current[activeTabIndex];
      setActiveTabStyle({ left: offsetLeft, width: offsetWidth });
    }
  }, [activeTabIndex, tabOptions.length]);

  // Handle switching the curator group
  const openSwitchGroupModal = e => {
    e.stopPropagation();
    props.openCuratorGroupModal({
      params: {
        type: 'select',
        curator: activeCurator,
        curatorGroup: activeCuratorGroup
      }
    });
  };

  // UI for category
  let curatedByParts = [];

  // Add the category, department, or industry
  if (activeCategory) curatedByParts.push({ display: activeCategory.name, unrefine: () => props.clearCategoryId(activeCategory.id) });
  else if (activeDepartment) curatedByParts.push({ display: activeDepartment.name, unrefine: () => props.clearDepartmentId(activeDepartment.id) });
  else if (activeIndustry) curatedByParts.push({ display: activeIndustry.name, unrefine: () => props.clearIndustryId(activeIndustry.id) });
  else if (hasSearchQuery) curatedByParts.push({ display: query, unrefine: () => props.setQuery('') });

  // Add the brand
  if (activeBrands) {
    if (curatedByParts.length) curatedByParts.push({ display: ', by ' });
    curatedByParts.push({
      display: activeBrands.map(b => b.name).join(', '),
      unrefine: () => props.clearBrandIds(activeBrands.map(b => b.id))
    });
  }

  // Add the curated text
  curatedByParts.push({ display: curatedByParts.length ? ', curated by' : 'Curated by', unrefine: null });

  const additionalClasses = {
    'transitioning-curators': isTransitioningCurators,
    'transitioning-refinements': isTransitioningRefinements,
    'transitioning-brands': isTransitioningBrands
  };
  return (
    <header className={cn('shop-page-header-new-container', additionalClasses)}>
      <div className={cn('main-panel', additionalClasses)}>
        <div className={cn('header-section', additionalClasses)}>
          <h2 className={cn('label', additionalClasses)}>
            {curatedByParts.map((part, idx) => {
              return (
                <span key={idx} className={cn('curated-by-part', { clickable: !!part.unrefine })} onClick={part.unrefine}>
                  {part.display}
                </span>
              );
            })}
          </h2>
          {name ? (
            <div className='name-container'>
              {badgeImage && (
                <div className={cn('badge-image', additionalClasses, { 'brand-badge': isBrandBadge })}>
                  <SmartImage src={badgeImage} alt={name} />
                </div>
              )}
              <h1 onClick={openSwitchGroupModal} className={cn('name', additionalClasses)}>
                {name}
              </h1>
            </div>
          ) : (
            <div className='name-container'>
              <div className={cn('name loading', additionalClasses)}>Fetching...</div>
            </div>
          )}
          {activeCurator && bio && <h3 className='bio'>{bio}</h3>}
          {activeCuratorGroup && (
            <div className='metadata'>
              <div className={cn('count', additionalClasses)}>
                {activeCuratorGroup ? `${activeCuratorGroup.num_curators} curator${activeCuratorGroup.num_curators !== 1 ? 's' : ''}` : 'Fetching...'}
              </div>
              <div className='bullet'>•</div>
              <Link
                to={
                  isFavorited || isMyGroup
                    ? getLinkToPrivateCuratorGroupPage(activeCuratorGroup)
                    : getLinkToPublicCuratorGroupPage(activeCuratorGroup)
                }
                className={cn('view-group', additionalClasses)}
              >
                View Curators
              </Link>
            </div>
          )}
          {!isAllCurators && (
            <div className='links-and-actions'>
              {activeCurator && getSocialIconsForArtist(activeCurator, { iconStyle: 'dark' })}
              <div
                className={cn('action favorite', {
                  active: isFavorited || isMyGroup,
                  clickable: canFavorite,
                  loading: isFavoriting
                })}
                onClick={pressHeart}
              >
                <Tooltip message={heartTootlip}>
                  <div className='icon'>
                    <FontAwesomeIcon
                      icon={isFavorited || isMyGroup ? (activeCurator ? faHeartSolid : faHeartSolid) : activeCurator ? faHeart : faHeart}
                    />
                    {isFavoriting && <Loader size={24} />}
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        {tabOptions.length && (
          <div className='tab-options-container'>
            <div onClick={props.focusShopSearch} className='tab-option search'>
              <div className='search-icon'>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
            {tabOptions.map((option, index) => {
              const { label, isDisabled, isActive } = option;
              return (
                <div
                  ref={el => (tabsRef.current[index] = el)}
                  className={cn('tab-option', { active: isActive, disabled: isDisabled })}
                  onClick={option.onClick}
                  key={index}
                >
                  <div>{label}</div>
                </div>
              );
            })}
            {!!tabOptions.length && (
              <div
                className='active-tab-background'
                style={{
                  transform: `translateX(${activeTabStyle.left}px)`,
                  width: `${activeTabStyle.width}px`
                }}
              />
            )}
          </div>
        )}
      </div>
    </header>
  );
};

ShopPageHeaderNew.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  openCuratorGroupModal: PropTypes.func.isRequired,
  createCuratorGroupFavorite: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired,
  updateCuratorGroup: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  focusShopSearch: PropTypes.func.isRequired,

  // From Outside
  isFetchingRefinements: PropTypes.bool.isRequired,
  activeRefinementObjects: PropTypes.object.isRequired,
  clearCategoryId: PropTypes.func.isRequired,
  clearDepartmentId: PropTypes.func.isRequired,
  clearIndustryId: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  openCuratorGroupModal,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite,
  updateCuratorGroup,
  updateCurrentUser,
  focusShopSearch
})(ShopPageHeaderNew);
