import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './OnboardingWizardBadge.scss';

import { getNumOnboardingStepsComplete, getOnboardingStepsEnum, hasCompletedOnboarding } from '../../../Helpers/onboarding_helpers';
import { isLoggedIn, isShopper } from '../../../Helpers/user_helpers';

import OnboardingWizard from './OnboardingWizard';

const OnboardingWizardBadge = props => {
  const { user, shop } = props;

  // Onboarding Wizard
  const [isOnboardingWizardVisible, setIsOnboardingWizardVisible] = React.useState(false);
  const closeOnboardingWizard = () => setIsOnboardingWizardVisible(false);
  const openOnboardingWizard = () => setIsOnboardingWizardVisible(true);
  const onboardingStepsEnum = getOnboardingStepsEnum({ user });
  const onboardingStepsComplete = getNumOnboardingStepsComplete({ user, shop });
  const [canOpenOnboardingWizard, setCanOpenOnboardingWizard] = React.useState(!hasCompletedOnboarding({ user })); // Use state with onboarding so we show it even when its complete until another reload
  React.useEffect(() => {
    !canOpenOnboardingWizard && onboardingStepsComplete < onboardingStepsEnum.length && setCanOpenOnboardingWizard(true);
  }, [onboardingStepsComplete, isLoggedIn(user)]);

  if (!isShopper(user)) return null;
  if (!canOpenOnboardingWizard) return null;

  return (
    <div className='onboarding-wizard-badge-outer-container'>
      <div className='onboarding-wizard-badge-inner-container'>
        <div className='onboarding-status' onClick={openOnboardingWizard}>
          {onboardingStepsComplete}/{onboardingStepsEnum.length} complete
        </div>
        <OnboardingWizard isVisibleFromOuterClick={isOnboardingWizardVisible} closePanel={closeOnboardingWizard} />
      </div>
    </div>
  );
};

OnboardingWizardBadge.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  // functions go here
})(OnboardingWizardBadge);
