import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import './TasteProfileHeader.scss';

import { getTasteProfileMetadata } from '../../APIClient/user_analysis';

import { generateTasteProfile } from '../../Actions/UserActions';
import { createCuratorGroupFromTasteProfile } from '../../Actions/ShopActions';

import ConfirmPrompt from '../General/ConfirmPrompt';
import SmartImage from '../General/SmartImage';
import Tooltip from '../General/Tooltip';

import { onboardingConstants } from '../../Helpers/onboarding_helpers';
import { getLinkToShopForCuratorGroup, getHeaderSubheaderFromTasteProfile } from '../../Helpers/shop_helpers';
import { getProductIdsPromoted, getTaste, getUserId } from '../../Helpers/user_helpers';

const TasteProfileHeader = props => {
  const { user } = props;
  const numProductsPromoted = getProductIdsPromoted(user).length;
  const { taste_profile, taste_profile_ran_at } = getTaste(user) || {};
  const hasTasteProfile = !!taste_profile;

  // Check if we can generate a taste profile
  const notEnoughProductsToCreateTasteProfile = numProductsPromoted < onboardingConstants.MIN_PRODUCTS_FOR_TASTE;
  const hasRunTooRecently =
    taste_profile_ran_at && moment(taste_profile_ran_at).isAfter(moment().subtract(onboardingConstants.MINUTES_BETWEEN_TASTE_RUNS, 'minutes'));
  const cannotGenerateTasteProfileTooltip = hasRunTooRecently
    ? `You can regenerate your taste profile in ${moment(taste_profile_ran_at)
        .add(onboardingConstants.MINUTES_BETWEEN_TASTE_RUNS, 'minutes')
        .fromNow()}. Keep hearting products!`
    : notEnoughProductsToCreateTasteProfile
    ? `You need to heart at least ${onboardingConstants.MIN_PRODUCTS_FOR_TASTE} products to generate a taste profile.`
    : null;

  const [isGeneratingTasteProfile, setIsGeneratingTasteProfile] = React.useState(false);
  const generateTasteProfile = () => {
    if (cannotGenerateTasteProfileTooltip) return window.ALERT.warn(cannotGenerateTasteProfileTooltip);
    if (isGeneratingTasteProfile) return;
    setIsGeneratingTasteProfile(true);
    props.generateTasteProfile().then(resp => {
      resp.taste && window.ALERT.success(`Generated Taste Profile`);
      resp.taste && window.__ADD_EVENT__('Shop - Generated Taste Profile');
      setIsGeneratingTasteProfile(false);
    });
  };

  let tasteHeadline, tasteDisplay;
  if (isGeneratingTasteProfile) {
    tasteHeadline = (
      <>
        Generating your
        <br />
        <i>taste</i> profile
      </>
    );
    tasteDisplay = <>This may take a few moments...</>;
  } else if (taste_profile) {
    const { header, subheader } = getHeaderSubheaderFromTasteProfile(taste_profile);
    tasteHeadline = header;
    tasteDisplay = subheader;
  } else {
    tasteHeadline = (
      <>
        Build your
        <br />
        <i>taste</i> profile
      </>
    );
    tasteDisplay = notEnoughProductsToCreateTasteProfile ? (
      <>
        Heart {onboardingConstants.MIN_PRODUCTS_FOR_TASTE - numProductsPromoted}
        {numProductsPromoted > 0 ? ' more ' : ' '}product{onboardingConstants.MIN_PRODUCTS_FOR_TASTE - numProductsPromoted > 1 ? 's' : ''} to build
        your taste profile.
      </>
    ) : (
      <>click below to generate your taste profile!</>
    );
  }

  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false);
  const createGroupFromProfile = async () => {
    if (hasRunTooRecently) return window.ALERT.error('Please wait before generating another taste profile.');
    if (isGeneratingTasteProfile) return;

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='How many curators?'
          subheader='How many curators would you like to curate your custom shop? Please chose a number from 10 to 200'
          onCancel={onClose}
          customInputFields={[{ placeholder: '25', value: 'num_curators', isSingleLine: true, preloaded: 25 }]}
          submitMustReturnTrueToClose
          onSubmitAwait={async responseValues => {
            const num_curators = +responseValues.num_curators;

            // Check that the value is between 0 and 100
            if (!(num_curators >= 10 && num_curators <= 200)) {
              window.ALERT.error('Number of curators must be between 10 and 200.');
              return false;
            }

            setIsCreatingGroup(true);
            const resp = await props.createCuratorGroupFromTasteProfile(num_curators);
            if (resp.group) {
              window.ALERT.success('Personalized circle created successfully!');
              window.__ADD_EVENT__('Shop - Created Circle from Taste Profile', { 'Num Curators': num_curators });
              props.history.push(getLinkToShopForCuratorGroup(resp.group, { tab: 'foryou' }));
              setIsCreatingGroup(false);
              return true;
            }
            window.ALERT.error('Error creating circle, please try again.');
            return false;
          }}
        />
      )
    });
  };

  const [recentImages, setRecentImages] = React.useState([]);
  let [leftColumnImages, rightColumnImages] = [[], []];
  recentImages.forEach((image, idx) => (idx % 2 === 0 ? leftColumnImages.push(image) : rightColumnImages.push(image)));
  leftColumnImages = [...leftColumnImages, ...Array(7 - leftColumnImages.length).fill(null)].slice(0, 7);
  rightColumnImages = [...rightColumnImages, ...Array(7 - rightColumnImages.length).fill(null)].slice(0, 7);
  React.useEffect(() => {
    getUserId(user) &&
      getTasteProfileMetadata({ User_id: getUserId(user) }).then(resp => {
        setRecentImages(resp.recent_images);
      });
  }, []);

  const getColumn = (images, className) => (
    <div className={cn('product-column', className)}>
      <div className='product-column-images'>
        {images.map((image, idx) => {
          return (
            <div key={idx} className='image-container'>
              <SmartImage src={image} alt='Featured product' />
            </div>
          );
        })}
      </div>
    </div>
  );
  const additionalClasses = { 'no-taste-profile': !hasTasteProfile, generating: isGeneratingTasteProfile };

  return (
    <div className={cn('taste-profile-header-container', additionalClasses)}>
      {getColumn(leftColumnImages, 'left')}
      <div className='main'>
        <div className={cn('profile', additionalClasses)}>
          <h3 className={cn('headline', additionalClasses)}>{tasteHeadline}</h3>
          <div className={cn('display', additionalClasses)}>{tasteDisplay}</div>
        </div>
        {!isGeneratingTasteProfile && (
          <div className='actions'>
            {hasTasteProfile && (
              <div onClick={createGroupFromProfile} className='action create-list'>
                {isCreatingGroup ? 'Creating...' : 'Create Circle from Your Taste'}
              </div>
            )}
            <Tooltip message={cannotGenerateTasteProfileTooltip}>
              <div
                onClick={generateTasteProfile}
                className={cn('action create-btn', { secondary: hasTasteProfile, disabled: cannotGenerateTasteProfileTooltip })}
              >
                {hasTasteProfile ? 'Regenerate Taste Profile' : 'Generate Taste Profile'}
              </div>
            </Tooltip>
            {!hasTasteProfile && (
              <Link to='/shop' className='action secondary'>
                Shop Top Products
              </Link>
            )}
          </div>
        )}
      </div>
      {getColumn(rightColumnImages, 'right')}
    </div>
  );
};

TasteProfileHeader.propTypes = {
  user: PropTypes.object.isRequired,
  generateTasteProfile: PropTypes.func.isRequired,
  createCuratorGroupFromTasteProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  generateTasteProfile,
  createCuratorGroupFromTasteProfile
})(withRouter(TasteProfileHeader));
