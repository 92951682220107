import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import { useCodePreRegistration as codePreRegistration } from '../../APIClient/users';
import { addEvent } from '../../APIClient/events';
import { isValidEmail } from '../../Helpers/formatting';
import { isLoggedIn } from '../../Helpers/user_helpers';

import './BrandApplyPanel.scss';

const BrandApplyPanel = props => {
  const [brandName, setBrandName] = useState('');
  const [brandWebsite, setBrandWebsite] = useState('');
  const [brandPitch, setBrandPitch] = useState('');
  const [brandEmail, setBrandEmail] = useState('');
  const [isApplying, setIsApplying] = useState(false);

  const [registrationCode, setRegistrationCode] = useState('');
  const [registrationEmail, setRegistrationEmail] = useState('');
  const [registrationPassword, setRegistrationPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);

  const getErrorMsg = (variable, errorMsg, validationTest = a => !!a) => (validationTest(variable) ? null : errorMsg);

  const apply = event => {
    event.preventDefault();
    if (isApplying) return;

    const validityError =
      getErrorMsg(brandName, 'Please enter your brand name.') ||
      getErrorMsg(brandWebsite, 'Please enter the website for your brand.') ||
      getErrorMsg(brandEmail, 'Please enter a valid email to reach you at.') ||
      getErrorMsg(brandPitch, 'Please tell us a little bit about your business.');

    if (validityError) {
      cogoToast.error(validityError);
      return;
    }

    setIsApplying(true);
    props
      .applyToWaitlist({ email: brandEmail, brand_website: brandWebsite, name: brandName, pitch: brandPitch })
      .then(
        ({ success, error }) => {
          if (success) {
            cogoToast.success('Successfully applied, we will be in touch shortly!');
            setBrandName('');
            setBrandWebsite('');
            setBrandPitch('');
            setBrandEmail('');
          } else {
            cogoToast.error(error);
          }
        },
        err => cogoToast('There was an error with your application. Please try again.')
      )
      .finally(() => setIsApplying(false));
  };

  const register = event => {
    event.preventDefault();
    if (isRegistering) return;

    const validityError =
      getErrorMsg(registrationCode, 'Please enter the registration code sent to your email.') ||
      getErrorMsg(registrationEmail, 'Please enter an email to use for logging in.', isValidEmail) ||
      getErrorMsg(registrationPassword, 'Please enter a password to use for logging in.');

    if (validityError) {
      cogoToast.error(validityError);
      return;
    }

    setIsRegistering(true);
    codePreRegistration({ email: registrationEmail, code: registrationCode })
      .then(resp => {
        const { success, code } = resp;
        const { brandName } = code || {};
        if (success) {
          const username = brandName.toLowerCase().replaceAll(/[^A-Za-z0-9]/g, '');
          props
            .registerUser({ email: registrationEmail, password: registrationPassword, username, name: brandName, code: registrationCode })
            .then(resp => {
              if (resp.success) {
                addEvent(`Brand - Successfully Registered`, { code: registrationCode, email: registrationEmail });
                setTimeout(() => props.history.push('/talent'), 250);
              } else {
                addEvent(`Brand - Error Registering`, {
                  error: resp ? JSON.stringify(resp) : 'Unknown'
                });
              }
              setIsRegistering(false);
            })
            .catch(error => {
              cogoToast.error(_.get(error, 'message', 'There was an issue with this code. Please try again.'));
              setIsRegistering(false);
            });
        } else {
          cogoToast.error(_.get(resp, 'error', 'There was an issue with this code. Please try again.'));
          setIsRegistering(false);
        }
      })
      .catch(error => {
        cogoToast.error('There was an issue with this code. Please try again.');
        setIsRegistering(false);
      });
  };

  return (
    <div ref={props.authFormRef} className='brand-apply-panel-outer-container'>
      <div className='apply-panel-header'>Fill out the form below and someone from our team will be in touch!</div>
      <div className='brand-apply-panel-inner-container'>
        {isLoggedIn(props.user) ? (
          <div className='brand-apply-panel-already-logged-in'>You are already logged in!</div>
        ) : (
          <div className='panels'>
            <div className='panel'>
              <div className='panel-header'>Apply</div>
              <form onSubmit={apply} className='panel-body'>
                <input type='text' placeholder='Brand Name' onChange={({ target }) => setBrandName(target.value)} value={brandName} />
                <input type='text' placeholder='Brand Website' onChange={({ target }) => setBrandWebsite(target.value)} value={brandWebsite} />
                <input type='email' placeholder='Email Address' onChange={({ target }) => setBrandEmail(target.value)} value={brandEmail} />
                <textarea
                  type='text'
                  rows={5}
                  placeholder='Briefly describe your business and how you want to work with the platform.'
                  onChange={({ target }) => setBrandPitch(target.value)}
                  value={brandPitch}
                />
                <div onClick={apply} className='submit-btn'>
                  {isApplying ? 'Applying...' : 'Apply'}
                </div>
              </form>
            </div>
            <div className='panel'>
              <div className='panel-header'>Register</div>
              <form onSubmit={register} className='panel-body'>
                <input
                  type='text'
                  placeholder='Invitation Code'
                  onChange={({ target }) => setRegistrationCode(target.value)}
                  value={registrationCode}
                />
                <input type='text' placeholder='Email' onChange={({ target }) => setRegistrationEmail(target.value)} value={registrationEmail} />
                <input
                  type='password'
                  placeholder='Password'
                  onChange={({ target }) => setRegistrationPassword(target.value)}
                  value={registrationPassword}
                />
                <div onClick={register} className='submit-btn'>
                  {isRegistering ? 'Registering...' : 'Register'}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BrandApplyPanel.propTypes = {
  registerUser: PropTypes.func.isRequired,
  applyToWaitlist: PropTypes.func.isRequired,
  authFormRef: PropTypes.object.isRequired
};

export default withRouter(BrandApplyPanel);
