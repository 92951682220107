import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faBagShopping, faTrash } from '@fortawesome/pro-light-svg-icons';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './CuratorGroupModalCuratorGroups.scss';

import { getCuratorGroups, getUserId } from '../../../Helpers/user_helpers';

import Loader from '../../Loader/Loader';
import ShopEmptyResults from '../../Curators/ShopEmptyResults';

const CuratorGroupModalCuratorGroups = props => {
  const { user, isAddMode, isAddingCuratorToGroupIds, curatorToAdd, groups } = props;

  const curatorGroups = getCuratorGroups(user);
  const needsToAddFirstGroup = !curatorGroups.length;

  return (
    <div className='curator-modal-curator-groups-container'>
      <div className='curator-groups'>
        {groups.map(group => {
          const { id } = group;
          const isCuratedByYou = group.User_id === getUserId(user);
          const isCuratedByShopMy = !group.User_id;
          const previewImage =
            group.previewImage ||
            group.group_users?.find(u => u.user?.image)?.user?.image || // From yours
            group.preview_group_users?.find(u => u.user?.image)?.user?.image; // From favorites
          const num_curators = group.group_users?.length || group.num_curators;

          /*
            Specific to Add Mode
          */
          let canAdd, isAddingToGroup, isCuratorInGroup, groupUserAddedByMe;
          if (isAddMode) {
            groupUserAddedByMe = curatorToAdd && group.group_users.find(gu => gu.User_id === curatorToAdd.id);
            isAddingToGroup = isAddMode && isAddingCuratorToGroupIds.includes(group.id);
            isCuratorInGroup = !!groupUserAddedByMe;
            canAdd = !!isCuratedByYou && !isAddingToGroup && !isCuratorInGroup;
          }
          const add = () => canAdd && props.addCuratorToGroup(group);
          const remove = async () => {
            if (isAddingToGroup) return;
            await props.deleteCuratorGroupUser(groupUserAddedByMe);
          };
          const removeGroup = () => {
            const confirm = window.confirm(`Are you sure you want to delete the group "${group.title}"?`);
            confirm && props.deleteCuratorGroup(group);
          };

          /*
              Specific to Select Mode
            */
          const select = () => {
            if (!num_curators) return window.ALERT.warn('This group has no curators.');
            props.selectGroupId(id);
          };

          /*
            Both
          */
          const viewCurators = () => props.setShowingCuratorsFromGroup(group);

          const additionalClasses = {
            'is-select-mode': props.isSelectMode,
            'can-add': canAdd,
            'is-adding': isAddingToGroup,
            'is-added': isCuratorInGroup,
            'has-image': !!previewImage
          };
          return (
            <div className={cn('curator-group-card', additionalClasses)} key={id}>
              {!!previewImage && (
                <div className={cn('background-image', additionalClasses)}>
                  <img src={previewImage} alt={group.title} />
                </div>
              )}
              <div className={cn('actions', additionalClasses)}>
                {!isAddMode ? (
                  <div onClick={select} className={cn('action', additionalClasses, { disabled: !num_curators })}>
                    <FontAwesomeIcon icon={faBagShopping} />
                  </div>
                ) : (
                  <>
                    <div onClick={removeGroup} className={cn('action show-action-on-card-hover', additionalClasses)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                    {isCuratorInGroup ? (
                      <div onClick={remove} className={cn('action', additionalClasses)}>
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    ) : (
                      <div onClick={add} className={cn('action', additionalClasses)}>
                        {isAddingToGroup ? <Loader size={36} /> : <FontAwesomeIcon icon={faPlus} />}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div onClick={() => !isAddMode && viewCurators()} className={cn('content', { clickable: !isAddMode }, additionalClasses)}>
                <div className='curator'>{isCuratedByYou ? 'Curated By You' : isCuratedByShopMy ? 'Curated by ShopMy' : group.title}</div>
                <div className='title'>{group.title || 'No Title'}</div>
                <div className='count'>{`${num_curators} ${pluralize('curator', num_curators)}`}</div>
              </div>
            </div>
          );
        })}
        {needsToAddFirstGroup && isAddMode && (
          <div onClick={props.createNewCuratorGroupAndAdd} className='curator-group-card add-new'>
            <div className='actions'>
              <div className='action add'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            <div className={cn('content')}>
              <div className='title'>Add New Circle</div>
              <div className='count'>0 curators</div>
            </div>
          </div>
        )}
      </div>
      {needsToAddFirstGroup && !isAddMode && (
        <ShopEmptyResults createGroup={props.createNewCuratorGroupAndGoToGroup} isSelectingCuratorGroups closeModal={props.closeModal} />
      )}
    </div>
  );
};

CuratorGroupModalCuratorGroups.propTypes = {
  user: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  isSelectMode: PropTypes.bool,
  curatorToAdd: PropTypes.object,
  groups: PropTypes.array.isRequired,
  isAddingCuratorToGroupIds: PropTypes.array.isRequired,

  // Actions
  closeModal: PropTypes.func.isRequired,
  deleteCuratorGroup: PropTypes.func.isRequired,
  addCuratorToGroup: PropTypes.func.isRequired,
  deleteCuratorGroupUser: PropTypes.func.isRequired,
  createNewCuratorGroupAndAdd: PropTypes.func.isRequired,
  createNewCuratorGroupAndGoToGroup: PropTypes.func.isRequired
};

export default CuratorGroupModalCuratorGroups;
