import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Mousetrap from 'mousetrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './HeaderSearch.scss';

import { setShopHeaderSearchVal, blurShopSearch, focusShopSearch } from '../../../Actions/UIActions';
import { getUserId } from '../../../Helpers/user_helpers';
import {
  getShopHeaderSearchElId,
  getActiveCurator,
  getActiveCuratorGroup,
  getAttributableCuratorId,
  getAttributableCuratorGroupId
} from '../../../Helpers/shop_helpers';
import { getShopHeaderSearchVal, isShopHeaderSearchFocused } from '../../../Helpers/ui_helpers';

import ShopHeaderSearchResults from './ShopHeaderSearchResults';
import InputActions from '../../General/InputActions';

const HeaderSearch = props => {
  const { user, shop, ui, setShopHeaderSearchVal } = props;

  // Searching
  const searchInputRef = React.useRef(null);
  const shopHeaderSearchVal = getShopHeaderSearchVal(props.ui);
  const [onSearchFormSubmit, setOnSearchFormSubmit] = React.useState(null);
  React.useEffect(() => {
    Mousetrap.bind('esc', () => setShopHeaderSearchVal(''));
    Mousetrap.bind('/', () => setTimeout(() => searchInputRef.current.focus(), 50));
    return () => {
      Mousetrap.unbind('esc');
      Mousetrap.unbind('enter');
    };
  }, []);

  // Shop Statuses
  const activeCurator = getActiveCurator(shop);
  const activeCuratorGroup = getActiveCuratorGroup(shop);
  // const primaryCuratorGroup = getPrimaryCuratorGroup(props.user);
  const isMyShop = activeCurator && getUserId(user) === activeCurator.id;
  const isShopPage = window.location.pathname === '/shop';

  // Focus State
  const isFocusedOnSearchInput = isShopHeaderSearchFocused(ui);
  const isAttemptingToSearch = shopHeaderSearchVal.length > 0 || isFocusedOnSearchInput;
  const focusIfNotFocused = () => {
    window.__ADD_EVENT__('Shop - Click To Search From Header');
    !isAttemptingToSearch && searchInputRef.current.focus();
  };

  const additionalClasses = { 'is-in-search-mode': isAttemptingToSearch };
  return (
    <div onClick={focusIfNotFocused} className={cn('header-search-container', additionalClasses)}>
      <div onClick={() => setShopHeaderSearchVal('')} className={cn('active-searching-fade', { searching: isAttemptingToSearch })} />
      <form onSubmit={onSearchFormSubmit} className={cn('search-form has-actions', additionalClasses)}>
        <div className='search-icon'>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <input
          id={getShopHeaderSearchElId()}
          value={shopHeaderSearchVal}
          ref={searchInputRef}
          onChange={e => setShopHeaderSearchVal(e.target.value)}
          onFocus={() => {
            props.focusShopSearch();
          }}
          onBlur={() => {
            props.blurShopSearch();
          }}
          className='search-input'
          placeholder={
            (isShopPage && activeCurator
              ? isMyShop
                ? `Search Your Products`
                : `Search ${activeCurator.name}'s Shop`
              : isShopPage && activeCuratorGroup
              ? `Search ${activeCuratorGroup.title}`
              : 'Brands, products, categories, curators') +
            ` - ${
              getAttributableCuratorId(shop)
                ? 'c' + getAttributableCuratorId(shop)
                : getAttributableCuratorGroupId(shop)
                ? 'cg' + getAttributableCuratorGroupId(shop)
                : 'ShopMy'
            }`
          }
        />
        <InputActions
          searchVal={shopHeaderSearchVal}
          onCancel={() => {
            setShopHeaderSearchVal('');
          }}
        />
      </form>
      {(shopHeaderSearchVal || isFocusedOnSearchInput) && (
        <div className='search-results-container'>
          <ShopHeaderSearchResults
            searchVal={shopHeaderSearchVal}
            setSearchVal={setShopHeaderSearchVal}
            searchInputEl={searchInputRef.current}
            setOnSearchFormSubmit={setOnSearchFormSubmit}
          />
        </div>
      )}
    </div>
  );
};

HeaderSearch.propTypes = {
  shop: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setShopHeaderSearchVal: PropTypes.func.isRequired,
  blurShopSearch: PropTypes.func.isRequired,
  focusShopSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { shop, ui, user } = state;
  return { shop, ui, user };
};

export default connect(mapStateToProps, {
  setShopHeaderSearchVal,
  blurShopSearch,
  focusShopSearch
})(withRouter(HeaderSearch));
