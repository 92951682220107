import APIClient from './index';
import _ from 'lodash';

export const fetchHierarchy = params => {
  return new Promise((resolve, reject) => {
    APIClient.get('/Shop/hierarchy')
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getProducts = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Shop/products?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
export const getProductRefinements = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Shop/products/refinements?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

// Curators
export const getCuratorCollections = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Shop/Collections?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getPinsForCuratorCollection = (Collection_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Shop/Collections/${Collection_id}/pins?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getSimilarCuratorsToMe = (User_id, params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Shop/Curators/similar_to_me/${User_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

// Curator Groups
export const createCuratorGroup = params => {
  return new Promise((resolve, reject) => {
    APIClient.post('/CuratorGroups', params)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createCuratorGroupFromInstagramZIP = (url, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post('/CuratorGroups/from_instagram_zip', { User_id, url })
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createCuratorGroupFromTasteProfile = params => {
  return new Promise((resolve, reject) => {
    APIClient.post('/CuratorGroups/from_taste_profile', params)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const duplicateCuratorGroup = (group, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CuratorGroups/duplicate/${group.id}`, { User_id })
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateCuratorGroup = (group, updaates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/CuratorGroups/${group.id}`, updaates)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteCuratorGroup = group => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CuratorGroups/${group.id}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getCuratorGroupsByIds = (groupIds, additionalParams = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(
      '/CuratorGroups/from_ids?ids=' + groupIds.join(',') + (additionalParams ? '&' + new URLSearchParams(additionalParams).toString() : '')
    )
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getCuratorsAndGroupsViaDiscover = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CuratorGroups/discover?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getCuratorsViaDiscover = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CuratorGroups/discover/curators?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

// Curator Group Users
export const createCuratorGroupUser = curatorGroupUser => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CuratorGroupUsers`, curatorGroupUser)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateCuratorGroupUser = (groupUser, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/CuratorGroupUsers/${groupUser.id}`, updates)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteCuratorGroupUser = groupUser => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CuratorGroupUsers/${groupUser.id}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

// Curator Group Favorites
export const createCuratorGroupFavorite = curatorGroupFavorite => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/CuratorGroupFavorites`, curatorGroupFavorite)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteCuratorGroupFavorite = groupFavorite => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CuratorGroupFavorites/${groupFavorite.id}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createUserProduct = userProduct => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserProducts`, userProduct)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateUserProduct = ({ User_id, Product_id }, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserProducts/${User_id}/${Product_id}`, updates)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteUserProduct = ({ User_id, Product_id }) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/UserProducts/${User_id}/${Product_id}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
