import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { getSignedUrl } from '../../APIClient/uploads';
import S3Upload from './S3Upload';
import Dropzone from 'react-dropzone';
import './DropUploader.scss';
import cn from 'classnames';
import _ from 'lodash';
import { IMAGE_ACCEPT_TYPES, ALL_ACCEPT_TYPES } from '../../Helpers/constants';

import Loader from '../Loader/Loader';

/**
 * This is a wrapper function that can be used to drag and drop files into a
 * component. It will automatically upload the file to S3 and return the URL
 */
const DropUploader = props => {
  const { onUpload, onDrop, children, onlyAllowImages, isDisabled } = props;
  const [isUploading, setIsUploading] = useState(false);
  const onProgress = props.onProgress || (() => {});

  const uploaderOptions = {
    signingUrl: '/s3/sign',
    s3path: '',
    onFinishS3Put: (info, file) => handleFinishedUpload(info, file),
    getSignedUrl: (file, callback) => getSignedUrlFunc(file, callback),
    onProgress,
    accept: ALL_ACCEPT_TYPES,
    contentDisposition: 'auto'
  };

  const handleFinishedUpload = (info, file) => {
    if (onUpload) {
      const fileUrl = info.signedUrl.split('?')[0];
      onUpload(fileUrl);
    }
    setIsUploading(false);
  };

  const getSignedUrlFunc = (file, callback) => {
    const params = {
      objectName: file.name,
      contentType: file.type
    };

    getSignedUrl(params)
      .then(data => {
        callback({ signedUrl: data });
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setIsUploading(true);
      const files = acceptedFiles;
      const options = {
        files,
        ...uploaderOptions
      };

      new S3Upload(options); // eslint-disable-line

      onDrop && onDrop(files, rejectedFiles);

      if (rejectedFiles.length) {
        setIsUploading(false);
      }
    },
    [onDrop, uploaderOptions]
  );

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={onlyAllowImages ? IMAGE_ACCEPT_TYPES : props.customAcceptTypes || ALL_ACCEPT_TYPES}
      noClick={!props.allowClicks}
      noKeyboard
      disabled={isDisabled}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} className={cn('uploader-display', props.className)}>
          <input {...getInputProps()} />
          {children}
          <div className={cn('dropzone-overlay', { active: isDragActive || isUploading })}>
            {isUploading ? <Loader /> : _.isNil(props.dropMessage) ? 'Drag and drop file here' : props.dropMessage}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

DropUploader.propTypes = {
  onUpload: PropTypes.func,
  onProgress: PropTypes.func,
  onDrop: PropTypes.func,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,

  // Specifify what to accept
  onlyAllowImages: PropTypes.bool,
  customAcceptTypes: PropTypes.array,

  // Optional UI Updates
  allowClicks: PropTypes.bool,
  dropMessage: PropTypes.string,
  className: PropTypes.string
};

DropUploader.defaultProps = {
  onUpload: null,
  onDrop: null,
  onProgress: null,
  children: null,
  onlyAllowImages: false,
  isDisabled: false
};

export default DropUploader;
