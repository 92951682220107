import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faImage, faBagShopping } from '@fortawesome/pro-regular-svg-icons';

import cn from 'classnames';
import _ from 'lodash';
import './CuratorGroup.scss';

import { openCuratorGroupModal, focusShopSearch } from '../../Actions/UIActions';
import {
  updateCuratorGroup,
  updateCuratorGroupUser,
  deleteCuratorGroupUser,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
} from '../../Actions/ShopActions';
import { getUrlParam } from '../../Helpers/helpers';
import { getUserId, getFavoriteCuratorGroupWithId } from '../../Helpers/user_helpers';
import {
  getLinkToShopForCuratorGroup,
  getDepartmentById,
  getIndustryById,
  generateHierarchyOptionValue,
  expandHierarchyOptionValue,
  getHierarchyOptions
} from '../../Helpers/shop_helpers';

import CuratorGroupCard from './CuratorGroupCard';
import CuratorCardNew from './CuratorCardNew';
import ShopEmptyResults from './ShopEmptyResults';
import CuratorGroupHeader from './CuratorGroupHeader';
import ScrollToTop from '../General/ScrollToTop';
import ImageUploader from '../General/ImageUploader';
import SortableList from '../General/SortableList';
import Select from '../General/Select';

const CuratorGroup = props => {
  const { shop, user, group } = props;
  const groupUsers = group.group_users || [];

  const favorite = getFavoriteCuratorGroupWithId(user, group.id);

  // Figure out who's this is
  const isCuratedByMe = props.group.curated_by?.id === getUserId(user);

  // Allow Editing
  const [isEditing, setIsEditing] = React.useState(getUrlParam('edit') === 'true');
  React.useEffect(() => {
    const shouldBeEditing = getUrlParam('edit') === 'true';
    if (shouldBeEditing && !isEditing) setIsEditing(true);
    if (!shouldBeEditing && isEditing) setIsEditing(false);
  }, [props.history.location.search]);

  // Cover Image
  const [showUploadCoverImageModal, setShowUploadCoverImageModal] = React.useState(false);
  const completeImageUploadFromModal = url => {
    props.updateCuratorGroup(group, { coverImage: url });
    setShowUploadCoverImageModal(false);
  };
  const clickToUploadCoverImage = () => setShowUploadCoverImageModal(true);

  // Focus on specific Industry, Department or Category Focus
  const industrySpecialization = group.Industry_id && getIndustryById(shop, group.Industry_id);
  const departmentSpecialization = group.Department_id && getDepartmentById(shop, group.Department_id);
  const activeHierarchyValue = generateHierarchyOptionValue(group.Industry_id, group.Department_id);
  const selectHierarchyValue = value => {
    const { Industry_id, Department_id } = expandHierarchyOptionValue(value);
    props.updateCuratorGroup(group, { Industry_id, Department_id }).then(resp => {
      window.ALERT.success('Circle Focus Updated. We will now filter you to those types of products when you click to "Shop Circle".');
    });
  };

  // Searchable
  const toggleSearchable = () => {
    props.updateCuratorGroup(group, { isSearchable: !group.isSearchable }).then(resp => {
      window.ALERT.success('Circle Searchable Status Updated');
    });
  };

  const togglePrimary = () => {
    props.updateCuratorGroup(group, { isPrimary: !group.isPrimary }).then(resp => {
      window.ALERT[group.isPrimary ? 'success' : 'info'](
        `${group.title} is ${
          !group.isPrimary ? 'now your primary circle and will be used as a default for your shopping experience.' : 'no longer your primary circle.'
        }`
      );
    });
  };

  // Get cards here, for sorting
  const getCuratorCardElement = (item, additionalProps = {}) => {
    const { rearrangeHandle } = additionalProps;

    return (
      <CuratorCardNew
        key={item.id}
        shop={shop}
        groupUser={item}
        isEditing={isEditing}
        onDark={props.isDiscover}
        user={user}
        rearrangeHandle={rearrangeHandle}
        isCuratedByMe={isCuratedByMe}
        openCuratorGroupModal={props.openCuratorGroupModal}
        deleteCuratorGroupUser={props.deleteCuratorGroupUser}
      />
    );
  };

  const showingPreviewCard = isCuratedByMe && isEditing;
  const curatorListClasses = { 'is-editing': isEditing, 'showing-preview-card': showingPreviewCard };

  return (
    <div className='curator-group-outer-container'>
      <ScrollToTop />
      <ImageUploader
        initialImageUrl={group.coverImage}
        onSaveCallback={completeImageUploadFromModal}
        isVisible={showUploadCoverImageModal}
        setIsVisible={setShowUploadCoverImageModal}
      />
      <CuratorGroupHeader
        history={props.history}
        group={group}
        favorite={favorite}
        isDiscover={props.isDiscover}
        goBack={props.goBack}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        forceReloadNewlyPublicGroup={props.forceReloadNewlyPublicGroup}
        forceReloadNewlyPrivateGroup={props.forceReloadNewlyPrivateGroup}
      />
      <div className='curator-group-body'>
        {props.isAugmentingGroup ? (
          <div className={cn('curators', curatorListClasses)}>
            {_.range(group.num_curators).map(i => (
              <CuratorCardNew
                key={i}
                shop={shop}
                user={user}
                isEmpty
                loading
                openCuratorGroupModal={props.openCuratorGroupModal}
                deleteCuratorGroupUser={props.deleteCuratorGroupUser}
                isCuratedByMe={isCuratedByMe}
              />
            ))}
          </div>
        ) : !groupUsers.length ? (
          <ShopEmptyResults isMyCuratorGroupPage focusShopSearch={props.focusShopSearch} />
        ) : isEditing ? (
          <div className={cn('curators', curatorListClasses)}>
            <SortableList
              isEditing
              containerClassName='items'
              items={groupUsers}
              updateItem={props.updateCuratorGroupUser}
              additionalCard={
                <div onClick={props.focusShopSearch} className='add-new-card'>
                  <FontAwesomeIcon icon={faPlus} />
                  <div className='add-label'>Add Curator</div>
                </div>
              }
              props={props}
              getCard={getCuratorCardElement}
              axis='xy'
            />
          </div>
        ) : (
          <div className={cn('curators', curatorListClasses)}>{_.orderBy(groupUsers, 'sortOrderRank', 'asc').map(getCuratorCardElement)}</div>
        )}

        {showingPreviewCard && (
          <div className='curator-group-preview-card-container'>
            <CuratorGroupCard user={user} group={group} favorite={favorite} />
            <div className='card-actions'>
              {isEditing ? (
                <>
                  <div className='card-action secondary' onClick={clickToUploadCoverImage}>
                    <FontAwesomeIcon icon={faImage} />
                    Edit Cover Image
                  </div>
                  <div className='card-select'>
                    <Select
                      noStyling
                      options={getHierarchyOptions(shop)}
                      value={activeHierarchyValue}
                      placeholder='Expertise Focus'
                      onChangeValue={selectHierarchyValue}
                    />
                  </div>
                  <div className='card-toggle'>
                    <div className='label'>Is Primary Circle</div>
                    <Switch
                      onChange={togglePrimary}
                      checked={group.isPrimary}
                      height={14}
                      width={24}
                      onColor='#333'
                      offColor='#ccc'
                      className='switch'
                      checkedIcon={false}
                      uncheckedIcon={false}
                    />
                  </div>
                  {window.__ADMIN_CONTROL_MODE__ && (
                    <div className='card-toggle'>
                      <div className='label'>Live on Site</div>
                      <Switch
                        onChange={toggleSearchable}
                        checked={group.isSearchable}
                        height={14}
                        width={24}
                        onColor='#333'
                        offColor='#ccc'
                        className='switch'
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!!groupUsers.length && (
                    <Link to={getLinkToShopForCuratorGroup(group)} className='card-action primary'>
                      <FontAwesomeIcon icon={faBagShopping} />
                      Shop Circle
                    </Link>
                  )}
                  <div className='card-specialty'>Trusted for {departmentSpecialization?.name || industrySpecialization?.name || 'All Products'}</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CuratorGroup.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,
  focusShopSearch: PropTypes.func.isRequired,
  openCuratorGroupModal: PropTypes.func.isRequired,
  updateCuratorGroup: PropTypes.func.isRequired,
  updateCuratorGroupUser: PropTypes.func.isRequired,
  deleteCuratorGroupUser: PropTypes.func.isRequired,

  // From outside
  group: PropTypes.object.isRequired,
  isDiscover: PropTypes.bool,
  isAugmentingGroup: PropTypes.bool,
  favorite: PropTypes.object, // If it's a Favorite, this is the outer object
  goBack: PropTypes.func.isRequired,
  forceReloadNewlyPublicGroup: PropTypes.func.isRequired,
  forceReloadNewlyPrivateGroup: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  focusShopSearch,
  openCuratorGroupModal,
  updateCuratorGroup,
  updateCuratorGroupUser,
  deleteCuratorGroupUser,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
})(withRouter(CuratorGroup));
