import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import classnames from 'classnames';
import MetaTags from 'react-meta-tags';
import arrayMove from 'array-move';
import slugify from 'slugify';

import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import { SortableContainer } from '../../Helpers/sort_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import {
  setVisibleConsult,
  setVisibleCollection,
  getVisibleShop,
  addCollection,
  editCollection,
  deleteCollection,
  addConsult,
  editConsult,
  deleteConsult,
  getShopExamples
} from '../../Actions/StoreActions';
import { setAnalyticsMode, openConnectInstagramModal } from '../../Actions/UIActions';
import { connectInstagram, updateUserSettings } from '../../Actions/UserActions';
import ConsultPreview from '../../Components/Consults/ConsultPreview';
import ConsultImageSelectionModal from '../../Components/Consults/ConsultImageSelectionModal';
import ConsultTemplatesModal from '../../Components/Consults/ConsultTemplatesModal';
import CollectionPreviewOld from '../../Components/CollectionPreview/CollectionPreviewOld';
import CollectionPreviewAddNew from '../../Components/CollectionPreview/CollectionPreviewAddNew';
import ProfileDataPanel from '../../Components/Profile/Elements/ProfileDataPanel';
import ProfileHeaderAlert from '../../Components/Profile/ProfileHeaderAlert';
import ProfileManagementHeader from '../../Components/Profile/ProfileManagementHeader';
import UserCollaborationsPanel from '../../Components/Profile/UserCollaborationsPanel';
import EmptyShopIntro from '../../Components/Onboarding/EmptyShopIntro';
import ScrollToTop from '../../Components/General/ScrollToTop';
import SearchMediaModal from '../../Components/Modals/SearchMediaModal';
import Loader from '../../Components/Loader/Loader';
import Spotlight from '../../Components/Spotlight/Spotlight';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';
import ProfileTabs from '../../Components/Profile/ProfileTabs';
import ShopSectionsModal from '../../Components/Sections/ShopSectionsModal';
import AppPromotionBanner from '../../Components/General/AppPromotionBanner';

import { addEvent } from '../../APIClient/events';
import { getShopAnalytics } from '../../APIClient/users';

import {
  isYou,
  isBrand,
  getUserId,
  getNameWithS,
  isAdmin,
  canEditShop,
  getIncompleteConsultResults,
  getSettings,
  getSocialAccount
} from '../../Helpers/user_helpers';
import { getGMTTime, cleanUsernameString } from '../../Helpers/formatting';
import { getConsultUrl, formatPublicConsultDisplay } from '../../Helpers/consult_helpers';
import { isPublicPage, isContentRendered } from '../../Helpers/helpers';
import { openInstagramAuthModal, openYoutubeAuthModal, openTikTokAuthModal } from '../../Helpers/social_helpers';
import { spotlightKeys } from '../../Helpers/spotlight_helpers';

import './Profile.scss';
import '../../Components/Analytics/Analytics.scss';

class Profile extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    editCollection: PropTypes.func.isRequired,
    setVisibleCollection: PropTypes.func.isRequired,
    getVisibleShop: PropTypes.func.isRequired,
    deleteCollection: PropTypes.func.isRequired,
    addConsult: PropTypes.func.isRequired,
    editConsult: PropTypes.func.isRequired,
    deleteConsult: PropTypes.func.isRequired,
    setAnalyticsMode: PropTypes.func.isRequired,
    openConnectInstagramModal: PropTypes.func.isRequired
  };

  initProfile = async () => {
    const { user, ui } = this.props;
    this.syncProfile().then(profile => {
      const visibleShop = _.get(profile, 'visibleShop');
      const canEdit = canEditShop(visibleShop, user);

      if (!canEdit && visibleShop) {
        // Ensure its a real user
        addEvent('SHOP_VIEW', {
          eventUserId: getUserId(user),
          userId: visibleShop.id,
          shopUsername: visibleShop.username,
          shopName: visibleShop.name
        });
      }

      // Fetch analytics if already in mode
      if (canEdit && _.get(ui, 'inAnalyticsMode')) {
        this.fetchAnalytics(visibleShop);
      }
    });
  };

  handleComponentUpdate = async prevProps => {
    // Fetch analytics if switching into mode
    if (_.get(this.props.ui, 'inAnalyticsMode') && !_.get(prevProps.ui, 'inAnalyticsMode')) {
      if (_.isEmpty(this.state.analytics)) {
        this.fetchAnalytics(_.get(this.props.store, 'visibleShop'));
      }
    }

    // switching between shops
    const username = cleanUsernameString(_.get(this.props.match, ['params', 'username']));
    const prevUsername = cleanUsernameString(_.get(prevProps.match, ['params', 'username']));

    if (username !== prevUsername) {
      await this.initProfile();
    }

    // switching tabs
    const tab = _.get(this.props.match, ['params', 'tab']);
    const prevTab = _.get(prevProps.match, ['params', 'tab']);

    if (tab !== prevTab) {
      // set cur tab from url
      const selectedTabUrl = _.get(this.props, 'match.params.tab');
      const tabViaUrl = this.getTabs().find(tab => this.getTabSlug(tab) === selectedTabUrl);
      const tab = tabViaUrl || null;

      this.setState({
        curTab: tab,
        tempElements: null
      });
    }
  };

  componentDidMount = () => {
    this.initProfile().then(() => {
      this.state.waitingOnSSR && this.setState({ waitingOnSSR: false });
    });
  };

  componentDidUpdate(prevProps) {
    this.handleComponentUpdate(prevProps);
  }

  fetchAnalytics = shop => {
    if (shop) {
      this.setState({ fetchingAnalytics: true });
      getShopAnalytics(shop.id).then(
        resp => {
          this.setState({
            analytics: _.get(resp, 'analytics', {}),
            fetchingAnalytics: false
          });
        },
        err => {
          this.setState({
            fetchingAnalytics: false
          });
          console.error(err);
        }
      );
    }
  };

  state = {
    isCreatingCollection: false,
    tempElements: null, // used to ensure stablility while reordering

    // Analytics
    fetchingAnalytics: false,
    analytics: {},

    // UI Handling
    editingConsultImage: null,
    viewingConsultTemplates: false,
    isSearchingMediaType: false,
    addingSocialAccountType: null,
    isShopSectionsModalVisible: false,
    shopSectionsModalInitialConfig: null,
    curTab: null,
    waitingOnSSR: isContentRendered() // ensure admin specific elements wait on SSR to complete before loading to ensure hydration completes accurately
  };

  openShopSectionsModal = (config = {}) => this.setState({ isShopSectionsModalVisible: true, shopSectionsModalInitialConfig: config });
  closeShopSectionsModal = () => this.setState({ isShopSectionsModalVisible: false, shopSectionsModalInitialConfig: null });

  isFetching = () => {
    const { store } = this.props;
    const { visibleShop } = store || {};
    const username = cleanUsernameString(_.get(this.props.match, ['params', 'username']));
    return !_.get(visibleShop, 'username') || !username || visibleShop.username.toLowerCase() !== username.toLowerCase();
  };

  syncProfile = async () => {
    const username = cleanUsernameString(_.get(this.props.match, ['params', 'username']));
    const resp = await this.props.getVisibleShop(username, isYou(username, this.props.user) || isAdmin(this.props.user) ? { detailed: true } : {});
    return resp;
  };

  addConsult = async fromTemplate => {
    const { store, user } = this.props;
    const { visibleShop } = store;

    if (this.state.isCreatingNew) return;

    const templateAttributes = fromTemplate
      ? {
          ...(fromTemplate.coverImage ? { coverImage: fromTemplate.coverImage } : {}),
          ...(fromTemplate.description ? { description: formatPublicConsultDisplay(fromTemplate.description) } : {}),
          ...(fromTemplate.price ? { price: fromTemplate.price } : {}),
          ...(fromTemplate.title ? { title: fromTemplate.title } : {}),
          ...(fromTemplate.content ? { content: fromTemplate.content } : {})
        }
      : {};

    addEvent('Consults - Add New Consult', { user, template: fromTemplate?.title || 'Blank' });

    this.setState({ isCreatingNew: true });
    const resp = await this.props.addConsult({ User_id: _.get(visibleShop, 'id'), title: `Consult Name Here`, ...templateAttributes });
    this.setState({ isCreatingNew: false });
    if (resp.element) {
      this.props.history.push(getConsultUrl(resp.element));
      window.scrollTo(0, 0);
    }
  };

  addCollectionToTop = () => this.addCollection({ addToTop: true });
  addCollection = async (options = {}) => {
    const { store } = this.props;
    const { visibleShop } = store;
    const { addToTop } = options;
    const tab = this.getVisibleTab();

    if (this.state.isCreatingNew) return;
    this.setState({ isCreatingNew: true });

    let newCollection = {
      private: false,
      User_id: _.get(visibleShop, 'id'),
      sortOrderRank: addToTop ? -1 * getGMTTime() : getGMTTime()
    };

    if (tab.section) {
      newCollection.Section_id = tab.section.id;
    } else if (tab.id === 'shelf') {
      newCollection.forceType = 'shelf';
    } else if (tab.id === 'closet') {
      newCollection.forceType = 'closet';
    } else if (tab.id === 'home') {
      newCollection.forceType = 'home';
    } else if (tab.id === 'gift-guide') {
      newCollection.forceType = 'gift-guide';
    } else if (tab.id === 'post') {
      newCollection.forceType = 'post';
    }

    const resp = await this.props.addCollection(newCollection);
    this.setState({ isCreatingNew: false });

    if (resp.collection) {
      const collectionId = _.get(resp.collection, ['id']);
      if (collectionId) {
        this.props.history.push(`/collections/${isPublicPage() ? 'public/' : ''}${collectionId}`);
        window.scrollTo(0, 0);
      }
    } else {
      window.ALERT.error(resp.error || 'There was an error creating your collection. Please try again.');
    }
  };

  addMediaToTop = (media, type) => this.addMedia(media, type, { addToTop: true });
  addMedia = async (media, type, options = {}) => {
    const { addToTop } = options;
    const { url, createdAt, image, title } = media;
    const { store } = this.props;
    const { visibleShop } = store;
    const tab = this.getVisibleTab();

    if (this.state.isCreatingNew) return;
    this.setState({ isCreatingNew: true });

    let naming = {};
    if (type === 'tiktok') {
      naming.name = `Post on ${moment(createdAt).format('MMMM Do')}`;
    } else if (type === 'instagram') {
      naming.name = `Post on ${moment(createdAt).format('MMMM Do')}`;
    } else if (type === 'youtube') {
      naming.name = title;
    }

    let newCollection = {
      ...naming,
      private: false,
      User_id: _.get(visibleShop, 'id'),
      forceType: 'post',
      image,
      social_links: url,
      sortOrderRank: addToTop ? -1 * getGMTTime() : getGMTTime()
    };

    if (tab.section) {
      newCollection.Section_id = tab.section.id;
    } else {
      newCollection.forceType = 'post';
    }

    const resp = await this.props.addCollection(newCollection);
    this.setState({ isCreatingNew: false });

    if (resp.collection) {
      const collectionId = _.get(resp.collection, ['id']);
      if (collectionId) {
        this.props.history.push(`/collections/${isPublicPage() ? 'public/' : ''}${collectionId}`);
        window.scrollTo(0, 0);
      }
    } else {
      window.ALERT.error('There was an error creating your collection. Please try again.');
    }
  };

  onSortEnd = async ({ newIndex, oldIndex }) => {
    const { isRearranging } = this.state;
    if (isRearranging || newIndex === oldIndex) return;
    const movingBack = newIndex > oldIndex;
    const movingToTopOfPage = newIndex === 0 && !movingBack;
    const visibleElements = this.getVisibleElements();
    const elementToUpdateId = _.get(visibleElements, [oldIndex, 'id']);
    const stampBeforeRank = _.get(visibleElements, [movingBack ? newIndex : newIndex - 1, 'sortOrderRank'], -1 * getGMTTime());
    const stampAfterRank = movingToTopOfPage
      ? -1 * getGMTTime()
      : _.get(visibleElements, [movingBack ? newIndex + 1 : newIndex, 'sortOrderRank'], getGMTTime());
    const newStampRank = (stampAfterRank + stampBeforeRank) / 2;
    const newSortedElements = arrayMove(visibleElements, oldIndex, newIndex);
    this.setState({
      isRearranging: true,
      tempElements: newSortedElements
    });

    try {
      const editResponse = this.isConsultsTab()
        ? await this.props.editConsult(elementToUpdateId, { sortOrderRank: newStampRank })
        : await this.props.editCollection(elementToUpdateId, { sortOrderRank: newStampRank });
      editResponse.error
        ? cogoToast.error('There was an error, please try again. If the issue persists, please log out and log back in.', { hideAfter: 3 })
        : this.setState({ tempElements: null });
    } catch (error) {
      console.error(error);
      cogoToast.error('There was an error, please try again. If the issue persists, please log out and log back in.', { hideAfter: 3 });
    }

    this.setState({ isRearranging: false });
  };

  /********************************************************************************
   ******************************* Handling Tabs **********************************
   ********************************************************************************/
  getTabSlug = tab => slugify(tab?.display || '').toLowerCase();
  switchTabAndScrollToTop = tab => this.switchTab(tab, true);
  switchTab = (tab, shouldScroll) => {
    const username = cleanUsernameString(_.get(this.props.match, ['params', 'username']));
    addEvent('Influencer Shop - Switch Tab', {
      username,
      tab: tab.display,
      fromBottomOfShop: !!shouldScroll
    });
    this.props.history.push(`/${isPublicPage() ? 'public/' : ''}${username}/${this.getTabSlug(tab)}`);

    shouldScroll && window.scrollTo(0, 0);
  };

  getTabType = () => this.getVisibleTab().section?.previewType || this.getVisibleTab()?.id;
  isShelfTab = () => ['shelf', 'shelves', 'home', 'closet'].includes(this.getTabType());
  isPostsTab = () => ['post', 'posts', 'post_vertical', 'post_horizontal'].includes(this.getTabType());
  isEditorialTab = () => ['gift-guide', 'gift-guides', 'editorial', 'large_preview'].includes(this.getTabType());
  isConsultsTab = () => this.getVisibleTab()?.id === 'consults';
  isCollaborationsTab = () => this.getVisibleTab()?.id === 'collaborations';

  /********************************************************************************
   ************************** Handling Visible Elements ***************************
   ********************************************************************************/
  getVisibleTab = () => this.getTabs().find(tab => tab.isActive) || this.getTabs()[0];
  getVisibleElements = () => this.state.tempElements || this.getVisibleTab()?.items || [];

  getTabs = () => {
    const { store, user } = this.props;
    const { curTab, waitingOnSSR } = this.state;
    const { visibleShop } = store || {};
    const tabs = [];
    const editable = canEditShop(visibleShop, user) && !waitingOnSSR;
    const settings = getSettings(user);
    const isYourShop = isYou(visibleShop?.username, user);

    // Collections
    const sections = _.orderBy(visibleShop?.sections || [], 'sortOrderRank');
    const collections = visibleShop?.collections || [];
    const publicCollections = collections.filter(c => !c.private && !c.isArchived);
    const viewableCollections = (editable ? collections : publicCollections).filter(c => !c.isArchived);

    sections.forEach(section => {
      // Skip hidden sections
      if (!editable && section.isHidden) return;

      const items = viewableCollections
        .filter(c => c.Section_id === section.id)
        .map(c => ({
          ...c,
          section // Augment with section object to make it easier
        }));

      if (editable || items.length) {
        tabs.push({
          id: `section-${section.id}`,
          display: section.title,
          shortDisplay: section.title,
          isHidden: section.isHidden,
          items,
          section
        });
      }
    });

    // Consults
    const consults = visibleShop?.consults || [];
    const publicConsults = consults.filter(c => !c.isPrivate);
    const viewableConsults = editable ? consults : publicConsults;
    const hasConsults = consults.length > 0;
    (publicConsults.length || editable) &&
      (!settings?.hideConsultsFromShop || !isYourShop) &&
      tabs.push({
        id: 'consults',
        display: editable ? 'Consults' : 'Consults',
        shortDisplay: editable ? 'Consults' : 'Consults',
        tooltip: hasConsults ? '' : 'Product Consultations or 1 on 1s',
        badgeCount: editable && getIncompleteConsultResults(visibleShop).length,
        items: viewableConsults
      });

    // Collaborations
    const tasks = visibleShop?.tasks || [];
    (editable || isBrand(user)) &&
      tabs.push({
        id: 'collaborations',
        display: editable ? 'Collaborations' : 'Collaborations',
        shortDisplay: editable ? 'Collabs' : 'Collabs',
        items: tasks,
        tooltip: !tasks.length ? '' : 'Only Visible To Brands'
      });

    // Archive
    const archivedCollections = collections.filter(c => c.isArchived);
    archivedCollections.length &&
      editable &&
      tabs.push({
        id: 'archive',
        display: 'Archive',
        shortDisplay: 'Archive',
        items: archivedCollections
      });

    // Establish defaults
    const selectedTabUrl = _.get(this.props, 'match.params.tab');
    const tabViaUrl = tabs.find(tab => this.getTabSlug(tab) === selectedTabUrl);

    // If no tabs, add a shelf one
    if (!tabs.length) {
      return [
        {
          id: 'shelves',
          display: 'Shelves',
          shortDisplay: 'Shelves',
          items: [],
          isActive: true
        }
      ];
    }

    return tabs.map((tab, idx) => ({
      ...tab,
      isActive: curTab ? curTab?.id === tab.id : tabViaUrl ? tabViaUrl.id === tab.id : idx === 0 // Default to whatever is in the URL then by what is first
    }));
  };

  toggleConsultImageEditing = consult => {
    this.setState({ editingConsultImage: consult });
  };
  toggleConsultTemplatesModal = () => {
    this.setState({ viewingConsultTemplates: !this.state.viewingConsultTemplates });
  };
  openMediaSearch = type => {
    const { user } = this.props;
    const instaAccount = getSocialAccount(user, 'instagram');
    const youtubeAccount = getSocialAccount(user, 'youtube');
    const tiktokAccount = getSocialAccount(user, 'tiktok');
    if (type === 'instagram') {
      instaAccount
        ? this.setState({ isSearchingMediaType: type })
        : openInstagramAuthModal(
            async authResponse => {
              this.setState({ isAddingSocialAccountType: type });
              this.props
                .connectInstagram(authResponse)
                .then(resp => {})
                .catch(errorMessage => {
                  cogoToast.error(errorMessage);
                })
                .finally(() => {
                  this.setState({ isAddingSocialAccountType: null });
                });
            },
            () => this.props.openConnectInstagramModal()
          );
    }
    if (type === 'youtube') {
      youtubeAccount ? this.setState({ isSearchingMediaType: type }) : openYoutubeAuthModal();
    }
    if (type === 'tiktok') {
      tiktokAccount ? this.setState({ isSearchingMediaType: type }) : openTikTokAuthModal();
    }
  };

  getSections = () => _.orderBy(this.props.store?.visibleShop?.sections || [], 'sortOrderRank');

  render() {
    const { setAnalyticsMode, ui, store, user } = this.props;
    const { isCreatingNew, analytics, fetchingAnalytics, isSearchingMediaType, waitingOnSSR } = this.state;
    const { visibleShop } = store;
    const { username } = visibleShop || {};
    const tabs = this.getTabs();
    const visibleElements = this.getVisibleElements();
    const you = isYou(username, user);
    const analyticsOn = _.get(ui, 'inAnalyticsMode') === true; // Due to issue with redux and false
    const pluralizedName = getNameWithS(visibleShop);
    const fromHome = _.get(this.props.lastLocation, 'pathname') === '/home';
    const editable = canEditShop(visibleShop, user) && !waitingOnSSR;
    const isFetching = this.isFetching();
    const sections = this.getSections();

    // Tabs
    const tabType = this.getTabType();
    const isShelfTab = this.isShelfTab();
    const isPostsTab = this.isPostsTab();
    const isEditorialTab = this.isEditorialTab();
    const isConsultsTab = this.isConsultsTab();
    const isCollaborationsTab = this.isCollaborationsTab();

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div>
          {isPostsTab && (
            <CollectionPreviewAddNew
              user={user}
              posts={items}
              tabType={tabType}
              addCollectionToTop={this.addCollectionToTop}
              openMediaSearch={this.openMediaSearch}
              hasSocialAccount={hasSocialAccount}
              isPost
            />
          )}
          {items.map((element, idx) => (
            <SortableItem axis='xy' key={`item-${element.id}`} index={idx} value={{ ...element, idx }} />
          ))}
        </div>
      );
    });

    const SortableItem = SortableElement(({ value }) => {
      const element = value;
      const idx = value.idx;
      const elementAnalytics = _.get(analytics, [isConsultsTab ? 'consult_stats' : 'collection_stats', element.id]);
      const DragHandle = sortableHandle(() => (
        <div className={classnames('reorder-icn')}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      ));
      const moveToTop = () => this.onSortEnd({ oldIndex: idx, newIndex: 0 });

      return (
        <div
          className={classnames('profile-element-preview-shell-container', tabType, {
            shelf: isShelfTab,
            posts: isPostsTab,
            editorial: isEditorialTab,
            consults: isConsultsTab
          })}
          key={element.id}
        >
          <div className='profile-element-preview-inner-container'>
            {editable && <DragHandle />}
            {isConsultsTab ? (
              <ConsultPreview
                key={element.id}
                idx={idx}
                canEdit
                moveToTop={moveToTop}
                setVisibleConsult={this.props.setVisibleConsult}
                deleteConsult={this.props.deleteConsult}
                editConsult={this.props.editConsult}
                consult={element}
                isFetching={isFetching}
                analyticsOn={analyticsOn}
                analytics={elementAnalytics}
                fetchingAnalytics={fetchingAnalytics}
                editImage={this.toggleConsultImageEditing}
              />
            ) : (
              <CollectionPreviewOld
                user={user}
                index={idx}
                moveToTop={moveToTop}
                syncProfile={this.syncProfile}
                history={this.props.history}
                setVisibleCollection={this.props.setVisibleCollection}
                deleteCollection={this.props.deleteCollection}
                collection={element}
                sections={sections}
                isShelf={isShelfTab}
                isPost={isPostsTab}
                isEditorial={isEditorialTab}
                tabType={tabType}
                analyticsOn={analyticsOn}
                isFetching={isFetching}
                analytics={elementAnalytics}
                fetchingAnalytics={fetchingAnalytics}
              />
            )}
          </div>
        </div>
      );
    });

    const getSeoTitle = () =>
      isConsultsTab
        ? !isFetching
          ? pluralizedName.full + ' Product Consultations'
          : 'Product Consultations'
        : !isFetching
        ? pluralizedName.full + ' Recommended Products'
        : 'Recommended Products';
    const getSeoDescription = () => _.get(visibleShop, 'description') || `Shop ${pluralizedName?.full || 'their'} favorite products.`;

    const shopUsername = _.get(visibleShop, 'username');
    const realShopUsername = _.get(this.props.match, ['params', 'username']); // Might be transitioning directly from another shop
    const isProfileFullyLoaded = window.location.href.includes(shopUsername);

    // Social handling
    const instaAccount = getSocialAccount(user, 'instagram');
    const youtubeAccount = getSocialAccount(user, 'youtube');
    const tiktokAccount = getSocialAccount(user, 'tiktok');
    const hasSocialAccount = instaAccount || youtubeAccount || tiktokAccount;

    return (
      <RequiresPermissions permission='canEditShop'>
        {visibleShop && (
          <MetaTags>
            <title>{getSeoTitle()}</title>
            <meta property='og:title' content={getSeoTitle()} />
            <meta property='description' content={getSeoDescription()} />
            <meta property='og:description' content={getSeoDescription()} />
            <meta property='og:image' content={_.get(visibleShop, 'image')} />
          </MetaTags>
        )}
        {editable && you && <AppPromotionBanner />}
        <ScrollToTop from={['/products/', '/p/', '/home', '/forbrands', '/latest']} />
        {visibleShop && (
          <div className='profile-content'>
            {editable && <ProfileHeaderAlert user={user} />}
            {editable && <ProfileManagementHeader user={user} ui={ui} shop={visibleShop} setAnalyticsMode={setAnalyticsMode} />}
            <div className='profile-content-inner-container'>
              {fromHome && !you && (
                <span onClick={() => this.props.history.goBack()} className='back-link'>
                  <FontAwesomeIcon icon={faArrowLeft} className='back' />
                  Back Home
                </span>
              )}
              <div className='profile-header'>
                <ProfileDataPanel
                  syncProfile={this.syncProfile}
                  ui={ui}
                  user={user}
                  shop={visibleShop}
                  canEdit={editable}
                  isFetching={isFetching}
                  analytics={analytics}
                  analyticsOn={analyticsOn}
                  shopUsername={realShopUsername}
                  fetchingAnalytics={fetchingAnalytics}
                  waitingOnSSR={this.state.waitingOnSSR}
                />
              </div>
              {visibleShop && (
                <div className='profile-data'>
                  <ProfileTabs
                    user={user}
                    store={store}
                    tabs={tabs}
                    waitingOnSSR={waitingOnSSR}
                    visibleElements={visibleElements}
                    switchTab={this.switchTab}
                    openShopSectionsModal={this.openShopSectionsModal}
                  />
                  <div
                    className={classnames('profile-element-block', tabType, {
                      collaborations: isCollaborationsTab,
                      shelf: isShelfTab,
                      posts: isPostsTab,
                      consults: isConsultsTab,
                      editorial: isEditorialTab
                    })}
                  >
                    {isCollaborationsTab ? (
                      editable || isBrand(user) ? (
                        isProfileFullyLoaded ? (
                          <UserCollaborationsPanel collaborationUser={visibleShop} />
                        ) : (
                          <Loader />
                        )
                      ) : (
                        <div className='must-be-logged-in-msg'>You must be logged in to see collaboration options.</div>
                      )
                    ) : editable ? (
                      <SortableList axis='xy' items={visibleElements} onSortEnd={this.onSortEnd} useDragHandle />
                    ) : (
                      _.map(visibleElements, (element, idx) => {
                        return isConsultsTab ? (
                          <ConsultPreview
                            key={element.id}
                            idx={idx}
                            canEdit={false}
                            setVisibleConsult={this.props.setVisibleConsult}
                            consult={element}
                          />
                        ) : (
                          <CollectionPreviewOld
                            user={user}
                            index={idx}
                            key={element.id}
                            syncProfile={this.syncProfile}
                            history={this.props.history}
                            setVisibleCollection={this.props.setVisibleCollection}
                            isFetching={isFetching}
                            collection={element}
                            isShelf={isShelfTab}
                            isPost={isPostsTab}
                            sections={sections}
                            isEditorial={isEditorialTab}
                            tabType={tabType}
                            analyticsOn={analyticsOn}
                          />
                        );
                      })
                    )}
                  </div>
                  {isCollaborationsTab
                    ? null
                    : editable &&
                      !_.get(visibleElements, 'length') &&
                      !isCreatingNew &&
                      (isConsultsTab ? (
                        <EmptyShopIntro
                          user={user}
                          visibleShop={visibleShop}
                          updateUserSettings={this.props.updateUserSettings}
                          getStarted={this.toggleConsultTemplatesModal}
                          setVisibleConsult={this.props.setVisibleConsult}
                          isConsultsTab={isConsultsTab}
                        />
                      ) : isPostsTab ? null : null)}
                  {isCollaborationsTab ? null : !editable ? (
                    tabs.length >= 2 && (
                      <div className='view-more-container'>
                        <div className='view-more-explainer'>Want to see more?</div>
                        <div className='view-more-of-shop-links'>
                          {tabs
                            .filter(tab => !tab.isActive)
                            .map(tab => {
                              const { id, display } = tab;
                              return (
                                <div key={id} onClick={() => this.switchTabAndScrollToTop(tab)} className='view-more-of-shop-link'>
                                  {display}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )
                  ) : isConsultsTab ? (
                    !!_.get(visibleElements, 'length') && (
                      <div className='add-element-btn-container'>
                        <Spotlight spotlightKey={spotlightKeys.CREATE_CONSULT}>
                          <div onClick={this.toggleConsultTemplatesModal} className='add-element-btn'>
                            ADD CONSULTATION
                            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                          </div>
                        </Spotlight>
                      </div>
                    )
                  ) : isPostsTab ? null : (
                    <div className='add-element-btn-container'>
                      <Spotlight spotlightKey={spotlightKeys.CREATE_SHELF}>
                        <div onClick={this.addCollection} className='add-element-btn'>
                          {isCreatingNew ? (
                            <span>Adding Collection...</span>
                          ) : (
                            <span>
                              <span>Add Collection</span>
                              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </span>
                          )}
                        </div>
                      </Spotlight>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {isConsultsTab && editable && (
          <>
            <ConsultImageSelectionModal
              consult={this.state.editingConsultImage}
              editConsult={this.props.editConsult}
              visible={!!this.state.editingConsultImage}
              closeModal={() => this.toggleConsultImageEditing(null)}
            />
            <ConsultTemplatesModal
              getShopExamples={this.props.getShopExamples}
              store={this.props.store}
              addConsult={this.addConsult}
              visible={!!this.state.viewingConsultTemplates}
              closeModal={this.toggleConsultTemplatesModal}
            />
          </>
        )}
        {isPostsTab && isSearchingMediaType && (
          <SearchMediaModal
            type={isSearchingMediaType}
            Searching_User_id={visibleShop?.id}
            selectResult={this.addMediaToTop}
            addCollection={this.addCollection}
            closeModal={() => this.setState({ isSearchingMediaType: null })}
          />
        )}
        {editable && this.state.isShopSectionsModalVisible && (
          <ShopSectionsModal close={this.closeShopSectionsModal} sectionsUser={visibleShop} initConfig={this.state.shopSectionsModalInitialConfig} />
        )}
      </RequiresPermissions>
    );
  }
}

const mapStateToProps = state => {
  const { user, ui, store } = state;
  return { user, ui, store };
};

export default connect(mapStateToProps, {
  addCollection,
  editCollection,
  deleteCollection,
  setVisibleCollection,
  setVisibleConsult,
  addConsult,
  editConsult,
  deleteConsult,
  updateUserSettings,
  setAnalyticsMode,
  openConnectInstagramModal,
  getVisibleShop,
  getShopExamples,
  connectInstagram
})(withLastLocation(withRouter(Profile)));
