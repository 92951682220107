import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbtack,
  faHeart,
  faTrash,
  faPencil,
  faCheck,
  faCopy,
  faChevronLeft,
  faPaperPlane,
  faBagShopping
} from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import './CuratorGroupHeader.scss';

import {
  updateCuratorGroup,
  deleteCuratorGroup,
  duplicateCuratorGroup,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
} from '../../Actions/ShopActions';

import Loader from '../Loader/Loader';
import ConfirmDelete from '../General/ConfirmDelete';
import Tooltip from '../General/Tooltip';

import { copyToClipboard } from '../../Helpers/helpers';
import {
  getLinkToShopForCuratorGroup,
  getLinkToPublicCuratorGroupPage,
  getIndustryById,
  getDepartmentById,
  getCategoryById
} from '../../Helpers/shop_helpers';
import { getUserId, getUsername } from '../../Helpers/user_helpers';

const CuratorGroupHeader = props => {
  const { shop, favorite, group, isEditing, setIsEditing } = props;

  // Figure out who's this is
  const isCuratedByMe = group.curated_by?.id === getUserId(props.user);

  // Figure out if they have any specializations
  const industrySpecialization = group.Industry_id && getIndustryById(shop, group.Industry_id);
  const departmentSpecialization = group.Department_id && getDepartmentById(shop, group.Department_id);
  const catalogSpecialization = departmentSpecialization || industrySpecialization;

  // Allow editing the title
  const [title, setTitle] = React.useState(group.title);
  const titleElRef = React.useRef(null);
  const toggleEditing = e => {
    e.preventDefault();

    // About to edit
    if (!isEditing) {
      setTimeout(() => titleElRef.current?.focus(), 50); // Focus on the input
    }

    // Done editing
    if (isEditing) {
      if (title !== group.title) {
        props.updateCuratorGroup(group, { title }).then(resp => {
          if (!resp.success) setTitle(group.title);
        });
      }
    }

    setIsEditing(!isEditing);
  };

  // Allow Editing Public Ones as admins
  const makePrivateToEdit = () => {
    if (getUserId(props.user) !== 226) return window.ALERT.warn('You must be logged in to the ShopMy account (username: shopmy) to edit this group.');
    confirmAlert({
      title: 'Just Confirming',
      message:
        'This will move this public group into a ShopMy account group. When you are complete editing, make sure to remove it from the ShopMy account.',
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {}
        },
        {
          label: 'Move to ShopMy Account',
          onClick: () => {
            props.updateCuratorGroup(group, { User_id: 226 }).then(resp => {
              if (resp.success) {
                window.ALERT.success(`Moved the group "${group.title}" to your account, remember to move it back once complete!`, { duration: 5000 });
                props.history.push(`/shop/my/circles/${resp.group.id}?edit=true`);
                props.forceReloadNewlyPrivateGroup();
              }
            });
          }
        }
      ]
    });
  };

  const makePublicAsAdmin = () => {
    const industry = group.Industry_id && getIndustryById(shop, group.Industry_id);
    const department = group.Department_id && getDepartmentById(shop, group.Department_id);
    const category = group.Category_id && getCategoryById(shop, group.Category_id);
    const hasHierarchy = group.Industry_id || group.Department_id || group.Category_id;
    confirmAlert({
      title: !hasHierarchy ? 'Something Looks Off' : 'Just Confirming',
      message: !hasHierarchy
        ? "You have not selected a category, department, or industry for this group. Are you sure you want to make it public at the root? Reminder that this is for admin's only in order to manage the public site!"
        : `This will make this group public in the ${category?.name ||
            department?.name ||
            industry?.name} section. It will remove it from your account.`,
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {}
        },
        {
          label: group.isSearchable ? 'Done Editing, Make Hidden' : 'Done Editing, Keep Hidden',
          className: 'cancel',
          onClick: () => {
            props.updateCuratorGroup(group, { User_id: null, isSearchable: false }).then(resp => {
              if (resp.success) {
                window.ALERT.success(`Made the group "${group.title}" public and removed it from the ShopMy account!`, { duration: 5000 });
                props.history.push(`/shop/circles/${resp.group.id}`);
                props.forceReloadNewlyPublicGroup();
              }
            });
          }
        },
        {
          label: 'Make Public',
          onClick: () => {
            props.updateCuratorGroup(group, { User_id: null, isSearchable: true }).then(resp => {
              if (resp.success) {
                window.ALERT.success(`Made the group "${group.title}" public and removed it from the ShopMy account!`, { duration: 5000 });
                props.history.push(`/shop/circles/${resp.group.id}`);
                props.forceReloadNewlyPublicGroup();
              }
            });
          }
        }
      ]
    });
  };

  // Sharing
  const [isCopyingUrl, setIsCopyingUrl] = React.useState(false);
  const shareGroup = async () => {
    setIsCopyingUrl(true);
    copyToClipboard(window.location.origin + getLinkToPublicCuratorGroupPage(group));
    window.ALERT.success(`Copied circle URL to clipboard`);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsCopyingUrl(false);
  };

  // Removing
  const removeGroup = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Are you sure?'
          subheader='This will permanently delete this shopping circle from your account as well as for anyone you have shared it with.'
          onCancel={onClose}
          hideCancel={false}
          onDelete={async () => {
            onClose();
            props.goBack();
            props.deleteCuratorGroup(group);
            window.ALERT.success(`Deleted the shopping circle "${group.title}"`);
          }}
        />
      )
    });
  };

  // Favoriting
  const [isFavoriting, setIsFavoriting] = React.useState(false);
  const favoriteGroup = () => {
    if (favorite) {
      props.deleteCuratorGroupFavorite(favorite);
    } else {
      setIsFavoriting(true);
      props.createCuratorGroupFavorite(group).then(() => {
        setIsFavoriting(false);
      });
    }
  };

  // Duplicating
  const [isDuplicating, setIsDuplicating] = React.useState(false);
  const makeCopy = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: `Copying this group will disconnect it from ${group.curated_by?.name ||
        'ShopMy'} and it will no longer update with changes to the original group.`,
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {}
        },
        ...(favorite
          ? []
          : [
              {
                label: 'Favorite Instead',
                className: 'cancel',
                onClick: favoriteGroup
              }
            ]),
        {
          label: 'Copy',
          onClick: () => {
            setIsDuplicating(true);
            props.duplicateCuratorGroup(group).then(resp => {
              setIsDuplicating(false);
              props.history.push(`/shop/my/circles/${resp.group.id}?edit=true`);
            });
          }
        }
      ]
    });
  };

  let metadata = [isCuratedByMe ? 'My Circle' : `by ${group.curated_by?.name || 'ShopMy'}`];
  if (catalogSpecialization) metadata.push(`Trusted for ${catalogSpecialization.name}`);

  let cannotShop, cannotShopMessage;
  if (!group.group_users?.length) {
    cannotShop = true;
    cannotShopMessage = 'You must add users to this group before you can shop it.';
  }

  const additionalClasses = {};
  return (
    <div className={cn('curator-group-header-container', additionalClasses)}>
      <div className={cn('go-back', additionalClasses)} onClick={props.goBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
        {props.isDiscover ? 'Back To Discover' : 'Back To My Circles'}
      </div>
      <div className={cn('curator-group-header', additionalClasses)}>
        {isEditing ? (
          <div className='header'>
            <form onSubmit={toggleEditing}>
              <input ref={titleElRef} value={title} onChange={e => setTitle(e.target.value)} placeholder='Name your group' />
            </form>
          </div>
        ) : (
          <div className={cn('header', additionalClasses)}>
            <span className='emphasize'>{title || 'Untitled Group'}</span>
          </div>
        )}
        <div className='subheader'>{metadata.join(' • ')}</div>
      </div>
      <div className={cn('actions', additionalClasses)}>
        {isEditing ? (
          <div className='action primary' onClick={toggleEditing}>
            <FontAwesomeIcon icon={faCheck} />
            Done Editing
          </div>
        ) : (
          <>
            {isCuratedByMe ? (
              <>
                {window.__ADMIN_CONTROL_MODE__ && (
                  <div
                    className={cn('action', {
                      primary: getUsername(props.user) === 'shopmy',
                      secondary: getUsername(props.user) !== 'shopmy'
                    })}
                    onClick={makePublicAsAdmin}
                  >
                    <FontAwesomeIcon icon={faThumbtack} />
                    Save & Make Public
                  </div>
                )}
                <div className='action secondary' onClick={toggleEditing}>
                  <FontAwesomeIcon icon={faPencil} />
                  Edit
                </div>
                <div className='action secondary' onClick={removeGroup}>
                  <FontAwesomeIcon icon={faTrash} />
                  Delete
                </div>
              </>
            ) : (
              <>
                {window.__ADMIN_CONTROL_MODE__ && (
                  <div className='action secondary' onClick={makePrivateToEdit}>
                    <FontAwesomeIcon icon={faPencil} />
                    Edit Public
                  </div>
                )}
                <div className='action secondary' onClick={makeCopy}>
                  <FontAwesomeIcon icon={faCopy} />
                  {isDuplicating ? 'Copying...' : 'Make Copy'}
                </div>
              </>
            )}
            {!isCuratedByMe && (
              <div className={cn('action secondary', { 'taking-action': isFavoriting })} onClick={favoriteGroup}>
                <FontAwesomeIcon icon={favorite ? faHeartSolid : faHeart} />
                {favorite ? 'Unfavorite' : 'Favorite'}
                {isFavoriting && <Loader size={48} />}
              </div>
            )}
            <div className='action secondary' onClick={shareGroup}>
              <FontAwesomeIcon icon={isCopyingUrl ? faCheck : faPaperPlane} />
              Share
            </div>
            <Tooltip message={cannotShopMessage}>
              <Link to={getLinkToShopForCuratorGroup(group)} className={cn('action primary', { disabled: cannotShop })}>
                <FontAwesomeIcon icon={faBagShopping} />
                Shop Circle
              </Link>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

CuratorGroupHeader.propTypes = {
  // From Outside
  history: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  favorite: PropTypes.object,
  goBack: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isDiscover: PropTypes.bool,
  forceReloadNewlyPublicGroup: PropTypes.func.isRequired,
  forceReloadNewlyPrivateGroup: PropTypes.func.isRequired,

  // From Inside
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateCuratorGroup: PropTypes.func.isRequired,
  deleteCuratorGroup: PropTypes.func.isRequired,
  duplicateCuratorGroup: PropTypes.func.isRequired,
  createCuratorGroupFavorite: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  updateCuratorGroup,
  deleteCuratorGroup,
  duplicateCuratorGroup,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
})(CuratorGroupHeader);
