import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { getProducts, getCuratorCollections, getProductRefinements } from '../../APIClient/shop';
import {
  saveSearchResultsCache,
  setActiveCurator,
  setActiveCuratorGroup,
  setAttributableCuratorId,
  setAttributableCuratorGroupId
} from '../../Actions/ShopActions';
import { getUserId } from '../../Helpers/user_helpers';

const LOG_FOR_DEBUGGING = false;

const ShopData = props => {
  // Catch updates to main filtering
  const hierarchyHash = [`c${props.Category_id}`, `d${props.Department_id}`, `i${props.Industry_id}`, `b${props.AllBrand_id}`].join('-');
  const curatorHash = [`c${props.Curator_id}`, `c${props.Curator_username}`, `cg${props.CuratorGroup_id}`].join('-');
  const tabHash = [`t${props.tab}`].join('-');
  const pagingHash = [`l${props.limit}`, `p${props.page}`].join('-');
  const queryHash = [`q${props.query}`].join('-');
  const collectionHash = [`c${props.isShowingCollections ? 'yes' : 'no'}`, `s${props.Section_id}`, `s${props.sectionType}`].join('-');

  // If changing params, scroll to the top of the page
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [hierarchyHash, curatorHash]);

  const filterParams = {
    // Catalog Based
    Category_id: props.Category_id,
    Department_id: props.Department_id,
    Industry_id: props.Industry_id,
    AllBrand_id: props.AllBrand_id,

    // Curators Based
    Curator_username: props.Curator_username,
    Curator_id: props.Curator_id,
    CuratorGroup_id: props.CuratorGroup_id,

    // You Based
    User_id: getUserId(props.user)
  };

  const tabParams = {
    tab: props.tab
  };

  const queryParams = {
    query: props.query
  };

  /* Reset Results on Filter Change */
  React.useEffect(() => {
    props.setHasMoreResults(true);
  }, [hierarchyHash, curatorHash, queryHash, tabHash, collectionHash]);

  /* 
    Caching Strategy

    Store the results on the dismount so that we can quickly reload them if the hash is the same to ensure
    we keep our place even in the case of infinite scroll.

    !! Only store values here that would not show up in the URL.
  */
  const memoryRef = React.useRef({});
  const hasCachedResults = window.location.href === props.shop.searchResultsCache?.key;
  const [usingCachedResults, setUsingCachedResults] = React.useState(hasCachedResults);
  memoryRef.current = {
    key: window.location.href,
    page: props.page,
    hasMoreResults: props.hasMoreResults,
    productResults: props.productResults,
    productRefinements: props.productRefinements,
    activeProductRefinements: props.activeProductRefinements,
    activeRefinementObjects: props.activeRefinementObjects,
    collectionResults: props.collectionResults,
    sectionResults: props.sectionResults
  };

  React.useEffect(() => {
    if (hasCachedResults) {
      props.setIsFetchingResults(false);
      props.setIsFetchingRefinements(false);
      props.setPage(props.shop.searchResultsCache.data.page || 0);
      props.setHasMoreResults(props.shop.searchResultsCache.data.hasMoreResults || false);
      props.setProductResults(props.shop.searchResultsCache.data.productResults || []);
      props.setProductRefinements(props.shop.searchResultsCache.data.productRefinements || []);
      props.setActiveProductRefinements(props.shop.searchResultsCache.data.activeProductRefinements || []);
      props.setActiveRefinementObjects(props.shop.searchResultsCache.data.activeRefinementObjects || {});
      props.setCollectionResults(props.shop.searchResultsCache.data.collectionResults || []);
      props.setSectionResults(props.shop.searchResultsCache.data.sectionResults || []);
      setTimeout(() => setUsingCachedResults(false), 250);
    }

    return () => {
      props.saveSearchResultsCache(memoryRef.current.key, memoryRef.current);
    };
  }, []);

  // Store current hashes in ref to ensure response is for the current request
  const searchHash = [hierarchyHash, curatorHash, pagingHash, tabHash, queryHash, collectionHash].join('-');
  const searchHashRef = React.useRef(searchHash);
  React.useEffect(() => {
    searchHashRef.current = searchHash;
  }, [searchHash]);

  /* Fetch Core Collection Results */
  React.useEffect(() => {
    if (usingCachedResults) return;

    // Fetch Collections
    if (props.isShowingCollections) {
      props.setIsFetchingResults(true);
      const limit = props.limit || 24;
      const params = {
        Curator_id: props.Curator_id,
        Curator_username: props.Curator_username,
        CuratorGroup_id: props.CuratorGroup_id,
        Section_id: props.Section_id,
        sectionType: props.sectionType,
        limit,
        page: props.page
      };
      getCuratorCollections(_.omitBy(params, val => !val))
        .then(resp => {
          if (searchHashRef.current !== searchHash) {
            return; // Ignore stale responses
          }
          props.setCollectionResults([...props.collectionResults.slice(0, props.page * limit), ...resp.collections]);
          props.setSectionResults(resp.sections || []);
          props.setHasMoreResults(resp.collections.length === limit);
          props.setHadErrorResults(false);
          props.setIsFetchingResults(false);
        })
        .catch(err => {
          console.error(err);
          window.ALERT.error(typeof err === 'string' ? err : 'Unknown error');
          props.setHadErrorResults(true);
          props.setIsFetchingResults(false);
        });
    }
  }, [curatorHash, pagingHash, queryHash, collectionHash]);

  /* Fetch Core Product Results */
  React.useEffect(() => {
    LOG_FOR_DEBUGGING && console.info(usingCachedResults ? `Using Cached Results for page ${props.page}` : `Fetching Results for page ${props.page}`);
    if (usingCachedResults) return;

    // Otherwise, Fetch Products
    if (!props.isShowingCollections) {
      props.setIsFetchingResults(true);
      const limit = props.limit || 12;
      const params = {
        ...filterParams,
        ...tabParams,
        ...queryParams,
        showHidden: props.isYourShop,
        limit,
        page: props.page
      };
      getProducts(_.omitBy(params, val => !val))
        .then(resp => {
          if (searchHashRef.current !== searchHash) return; // Ignore stale responses
          props.setProductResults([...props.productResults.slice(0, props.page * limit), ...resp.results]);
          props.setHasMoreResults(resp.results.length === limit);
          props.setHadErrorResults(false);
          props.setIsFetchingResults(false);
        })
        .catch(err => {
          console.error(err);
          window.ALERT.error(typeof err === 'string' ? err : 'Unknown error');
          props.setHadErrorResults(true);
          props.setIsFetchingResults(false);
        });
    }
  }, [hierarchyHash, curatorHash, pagingHash, tabHash, queryHash]);

  // Store current hashes in ref to ensure response is for the current request
  const refinementSearchHash = [hierarchyHash, curatorHash].join('-');
  const refinementSearchHashRef = React.useRef(refinementSearchHash);
  React.useEffect(() => {
    refinementSearchHashRef.current = refinementSearchHash;
  }, [searchHash]);

  /* Fetch Filters */
  React.useEffect(() => {
    props.setIsFetchingRefinements(true);
    getProductRefinements(
      _.omitBy({ ...filterParams, ...queryParams, skipRefinements: !!props.query || props.isShowingCollections || null }, val => !val)
    )
      .then(resp => {
        if (refinementSearchHashRef.current !== refinementSearchHash) return; // Ignore stale responses
        props.setProductRefinements(resp.refinements);
        props.setActiveProductRefinements(resp.activeRefinements);
        props.setActiveRefinementObjects(resp.activeRefinementObjects);
        props.setHadErrorRefinements(false);

        const { activeCurator, activeCuratorGroup } = resp.activeRefinementObjects || {};
        if (activeCurator) {
          props.setActiveCurator(activeCurator);
          props.setAttributableCuratorId(activeCurator.id);
        } else if (activeCuratorGroup) {
          props.setActiveCuratorGroup(activeCuratorGroup);
          props.setAttributableCuratorGroupId(activeCuratorGroup.id);
        } else {
          props.setActiveCurator(null);
          props.setActiveCuratorGroup(null);
          props.setAttributableCuratorId(null);
          props.setAttributableCuratorGroupId(null);
        }
      })
      .catch(err => {
        console.error(err);
        window.ALERT.error(typeof err === 'string' ? err : 'Unknown error');
        props.setHadErrorRefinements(true);
      })
      .then(() => {
        props.setIsFetchingRefinements(false);
      });
  }, [hierarchyHash, curatorHash, queryHash]);

  /* Manage Changes in URLS */
  const stateVariablesInURL = [
    'Category_id',
    'Department_id',
    'Industry_id',
    'AllBrand_id',
    'Curator_id',
    'CuratorGroup_id',
    'Section_id',
    'sectionType',
    'tab',
    'query'
  ];
  // const prettyUrlNames = { Category_id: 'category', Department_id: 'department', Industry_id: 'industry', AllBrand_id: 'brand', Curator_id: 'curator', CuratorGroup_id: 'curatog_group', tab: 'tab'};

  // Check current URL state
  const curUrlSearchQuery = window.location.search;
  const activeParamsInCurrentUrl = {};
  const urlParams = new URLSearchParams(curUrlSearchQuery);
  urlParams.forEach((value, key) => (activeParamsInCurrentUrl[key] = value));

  /* State Change => URL Change */
  React.useEffect(() => {
    const newParams = new URLSearchParams();

    // Add state variables to the URL if they exist in props AND are not default
    stateVariablesInURL.forEach(param => {
      const isDefault = param === 'tab' && props[param] === 'popular';
      if (props[param] && !isDefault) newParams.set(param, props[param]);
    });

    // Add all non state variables back to the URL if we use them for other things on this page
    const unrelatedUrlVariables = ['i', 'd'];
    unrelatedUrlVariables.forEach(param => {
      const paramValue = urlParams.get(param);
      if (paramValue) newParams.set(param, paramValue);
    });

    // Update URL without reload if it differs from existing, regardless of order
    const hasParams = newParams.toString().length > 0;
    const newUrl = `${window.location.pathname}${hasParams ? '?' : ''}${newParams}`;
    if (window.location.search !== `?${newParams}`) {
      window.history.pushState({}, '', newUrl);
      LOG_FOR_DEBUGGING && console.info('State => URL Update', newUrl);
    }
  }, [hierarchyHash, curatorHash, tabHash, collectionHash]);

  /* URL Change => State Change */
  const [currentUrl, setCurrentUrl] = React.useState(window.location.href);
  React.useEffect(() => {
    const updateURL = () => setCurrentUrl(window.location.href);

    // Intercept history changes and popstate events
    const interceptHistory = method => {
      const original = window.history[method];
      window.history[method] = (...args) => {
        original.apply(window.history, args);
        updateURL();
      };
    };

    interceptHistory('pushState');
    interceptHistory('replaceState');
    window.addEventListener('popstate', updateURL);

    return () => {
      window.removeEventListener('popstate', updateURL);
    };
  }, [currentUrl]);

  // Update state based on URL
  const [completedFirstUrlToStateUpdate, setCompletedFirstUrlToStateUpdate] = React.useState(false);
  React.useEffect(() => {
    const stateUpdatesRequired = {};

    // If we have changed the "match" of the username we need to consider that a state variable
    const Curator_username_from_url = props.match.params?.username;

    // If we have a username in the state but not in the URL, we need to update the URL
    if (props.Curator_username && !Curator_username_from_url) {
      stateUpdatesRequired.Curator_username = null;
    }

    // Identify parameters that differ between state and URL
    stateVariablesInURL.forEach(param => {
      const urlValue = activeParamsInCurrentUrl[param];
      const stateValue = props[param];
      const isStringMatch = stateValue === urlValue;
      const isIntMatch = parseInt(stateValue) === parseInt(urlValue);
      const isNilMatch = !stateValue && !urlValue;

      if (!isStringMatch && !isIntMatch && !isNilMatch) {
        // Check if the param is an integer or string
        const isInteger = ['Category_id', 'Department_id', 'Industry_id', 'AllBrand_id', 'Curator_id', 'CuratorGroup_id', 'Section_id'].includes(
          param
        );
        const isString = ['tab', 'query', 'sectionType'].includes(param);
        if (!isInteger && !isString) console.error(`Unknown type for ${param}`);

        // Even if we strip these values we don't want to refresh
        const disallowNullValues = ['tab'];
        if (disallowNullValues.includes(param) && !urlValue) return;

        // Update state if the URL value is different
        stateUpdatesRequired[param] = !urlValue ? null : isInteger ? parseInt(activeParamsInCurrentUrl[param]) : activeParamsInCurrentUrl[param];
      }
    });

    // Apply necessary updates to state
    if (!_.isEmpty(stateUpdatesRequired)) {
      // Update state
      Object.entries(stateUpdatesRequired).forEach(([param, value]) => {
        const updateFunction = {
          Category_id: props.setCategory_id,
          Department_id: props.setDepartment_id,
          Industry_id: props.setIndustry_id,
          AllBrand_id: props.setAllBrand_id,
          Curator_id: props.setCurator_id,
          Curator_username: props.setCurator_username,
          CuratorGroup_id: props.setCuratorGroup_id,
          Section_id: props.setSection_id,
          sectionType: props.setSectionType,
          tab: props.setTab,
          query: props.setQuery
        }[param];

        const wasDefault = props.defaultValuesIfNull[param] === props[param];
        const isSettingToNull = value === null;

        if (wasDefault && isSettingToNull && !completedFirstUrlToStateUpdate) return;
        else if (updateFunction) updateFunction(value);
        else console.error(`No update function found for ${param}`);
      });

      // We only want to use Curator_id or Curator_username, so reset if we see it
      if (stateUpdatesRequired.CuratorGroup_id) props.setCurator_username(null);
      if (stateUpdatesRequired.Curator_id) props.setCurator_username(null);
      if (stateUpdatesRequired.Curator_username) props.setCurator_id(null);

      LOG_FOR_DEBUGGING && console.info('URL => State Update', stateUpdatesRequired);

      // Reset paging
      props.setPage(0);
    }
    setCompletedFirstUrlToStateUpdate(true);
  }, [currentUrl]);

  return null;
};

ShopData.propTypes = {
  shop: PropTypes.object.isRequired,

  // Hierarchy Based
  Category_id: PropTypes.number,
  Department_id: PropTypes.number,
  Industry_id: PropTypes.number,
  AllBrand_id: PropTypes.number,

  // Curators Based
  Curator_id: PropTypes.number,
  CuratorGroup_id: PropTypes.number,

  // Tabs
  tab: PropTypes.string,
  setTab: PropTypes.func,

  // Search Query
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,

  // Collections
  isShowingCollections: PropTypes.bool.isRequired,
  Section_id: PropTypes.number,
  setSection_id: PropTypes.func.isRequired,

  // Paging
  page: PropTypes.number.isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  setPage: PropTypes.func.isRequired,
  setHasMoreResults: PropTypes.func.isRequired,

  // Errors
  setHadErrorResults: PropTypes.func.isRequired,
  setHadErrorRefinements: PropTypes.func.isRequired,

  // Results & Refinements
  setProductResults: PropTypes.func.isRequired,
  setProductRefinements: PropTypes.func.isRequired,
  setActiveProductRefinements: PropTypes.func.isRequired,
  setActiveRefinementObjects: PropTypes.func.isRequired,
  saveSearchResultsCache: PropTypes.func.isRequired,

  // Fetching
  setIsFetchingResults: PropTypes.func.isRequired,
  isFetchingResults: PropTypes.bool.isRequired,
  setIsFetchingRefinements: PropTypes.func.isRequired,
  isFetchingRefinements: PropTypes.bool.isRequired,

  // Defaults to ensure valid url transitions
  defaultValuesIfNull: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  saveSearchResultsCache,
  setActiveCurator,
  setActiveCuratorGroup,
  setAttributableCuratorId,
  setAttributableCuratorGroupId
})(ShopData);
