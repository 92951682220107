import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import './UpgradeShopperAccountPanel.scss';

import { openAuthModal } from '../../../../Actions/UIActions';
import { getShopperUpgradeWaitlistApplication } from '../../../../Helpers/user_helpers';

const UpgradeShopperAccountPanel = props => {
  const daysUntilReapply = 90;
  const currentApplication = getShopperUpgradeWaitlistApplication(props.user);
  const hasNotYetApplied = !currentApplication;
  const hasAppliedButBeenRejected = currentApplication && !!currentApplication.isRejected;
  const daysSinceApplication = currentApplication ? moment().diff(moment(currentApplication.createdAt), 'days') : null;
  const hasAppliedButBeenRejectedAndCannotYetReapply = hasAppliedButBeenRejected && daysSinceApplication <= daysUntilReapply;
  const hasAppliedButBeenRejectedAndCanReapply = hasAppliedButBeenRejected && daysSinceApplication > daysUntilReapply;
  const hasAppliedAndIsPending = currentApplication && !currentApplication.isRejected;

  let header, subheader, btnText;
  if (hasNotYetApplied) {
    header = 'Upgrade to a creator account.';
    subheader =
      'Your account is currently a shopper account, in order to access earning opportunities you must apply to upgrade it to a creator account.';
    btnText = 'Apply to Upgrade';
  } else if (hasAppliedButBeenRejectedAndCannotYetReapply) {
    header = 'Application Not Accepted';
    subheader = `Unfortunately your application to upgrade your account to a creator account was not accepted. To help your case, continue promoting products via your social channels and reapply after ${moment(
      currentApplication.createdAt
    )
      .add(daysUntilReapply, 'days')
      .format('MMMM Do, YYYY')}.`;
  } else if (hasAppliedButBeenRejectedAndCanReapply) {
    header = 'Application Not Accepted';
    subheader = 'Unfortunately your application to upgrade your account to a creator account was not accepted, however you can now reapply.';
    btnText = 'Reapply';
  } else if (hasAppliedAndIsPending) {
    header = 'Application Pending';
    subheader =
      daysSinceApplication <= 2
        ? 'Thank you for applying to upgrade your account to a full creator account. Your application is currently pending review by our team.'
        : `You applied to upgrade your account on ${moment(currentApplication.createdAt).format(
            'MMMM Do, YYYY'
          )}. Your application is currently pending review.`;
  }

  return (
    <div className='upgrade-shopper-account-panel-outer'>
      <div className='upgrade-shopper-account-panel-inner'>
        <div className='header'>{header}</div>
        <div className='subheader'>{subheader}</div>
        {btnText && (
          <div onClick={() => props.openAuthModal('apply-upgrade')} className='upgrade-btn'>
            {btnText}
          </div>
        )}
      </div>
    </div>
  );
};

UpgradeShopperAccountPanel.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(UpgradeShopperAccountPanel);
