import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import commaNumber from 'comma-number';
import { parseFullName } from 'parse-full-name';
import countryList from 'react-select-country-list';

export const numToString = num =>
  [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty'
  ][num] || num;

const countryData = countryList().getData();
export const formatCountryFromCode = countryCode =>
  _.get(
    _.find(countryData, data => data.value === countryCode),
    'label',
    countryCode
  );

export const getPrettyNumber = (numberRaw, options = {}) => {
  /*
    Shorten the number with standard endings.

    1000000 => 1m
    124000 => 124k
    12500 => 12.5k
    1149 => 1.1k
    839 => 839
  */
  const { precision } = options;
  if (_.isNil(numberRaw)) return null;
  if (numberRaw >= 1e6) {
    let millions = Math.floor(numberRaw / 1e6);
    let hundredthousands = Math.round((numberRaw % 1e6) / 1e5);
    if (hundredthousands === 10) {
      millions += 1;
      hundredthousands = 0;
    }
    return millions >= 100 ? `${millions}m` : `${millions}${hundredthousands ? `.${hundredthousands}` : ''}m`;
  } else if (numberRaw >= 1e3) {
    let thousands = Math.floor(numberRaw / 1e3);
    let hundreds = Math.round((numberRaw % 1e3) / 1e2);
    if (hundreds === 10) {
      thousands += 1;
      hundreds = 0;
    }
    return thousands >= 100 ? `${thousands}k` : `${thousands}${hundreds ? `.${hundreds}` : ''}k`;
  } else {
    const hasDecimals = Math.floor(numberRaw) !== numberRaw;
    if (hasDecimals) {
      return parseFloat(numberRaw.toFixed(_.isNil(precision) ? 2 : precision));
    }
  }
  return numberRaw;
};

export const getPrettyScoreDisplay = score => {
  // rounds down 1 decimal place unless toFixed(1) rounds to a whole number (ie: 100.0 -> 100)
  return parseFloat(Math.floor(score * 10) / 10);
};

export const getPrettyPriceDisplay = (priceRaw, options = {}) => {
  /*
    1.5 => $1.50
    2 => $2
  */
  const { perDay, precision, useUSD } = options;
  if (!priceRaw && priceRaw !== 0) return '';
  const price = parseFloat(priceRaw);
  const prettyNumber = commaNumber(price.toFixed(!_.isNil(precision) ? precision : price === Math.floor(price) ? 0 : 2));
  return `${useUSD ? '' : '$'}${prettyNumber}${useUSD ? ' USD' : ''}${perDay ? ' per day' : ''}`;
};

export const getCodeRateDisplay = code => {
  // Works on CustomCodes and ContractRateCodes
  if (!code) return '';
  const isPercentage = code.isPercentage || code.codeIsPercentage;
  const rate = code.rate || code.codeRate;
  if (!rate) return '—';
  else return isPercentage ? `${rate}%` : `$${rate}`;
};

export const getCodeRateDisplayLong = code => {
  // Works on CustomCodes and ContractRateCodes
  return `${code.displayText || code.codeDisplayText} for ${getCodeRateDisplay(code)} off`;
};

export const getRateDisplay = (rate, merchant) => {
  // Works on CustomCodes and ContractRateCodes
  if (!rate) return null;
  const isPercentage = merchant.rateType === 'percentage';
  const numDigits = rate < 3 ? (isPercentage ? 1 : 2) : 0;
  return isPercentage ? `${rate.toFixed(numDigits)}%` : `$${rate.toFixed(numDigits)}`;
};

export const cleanUsernameString = username => {
  /*
    We have an issue where some people copy paste the URLs and end up with
    zero width symbols on the username,
      ie: https://shopmy.us/drwhitneybowe%E2%80%8B

    This should be used any time we pull the username from the URL.
  */
  let cleanUsername = (username || '').replace(/[\u200B-\u200D\uFEFF]/g, '');
  try {
    cleanUsername = encodeURI(cleanUsername).split('%')[0];
  } catch (error) {
    console.error(`Could not clean username ${username}`);
  }
  return cleanUsername;
};

export const getGMTTime = () => new Date(new Date().toGMTString()).getTime();
export const getServerFormattedDate = (dateString = '') => moment(dateString || new Date()).format('YYYY-MM-DD HH:mm:ss');
export const getCurrentTimeForRanking = () => parseInt(String(getGMTTime() / 10e2).slice(3)) / 100;

export const getPrettyTimeAgoFromNow = (time, options = {}) => {
  const { longForm } = options;
  const now = moment();
  const secondsAgo = now.diff(moment(time).local(), 'seconds');
  const minutesAgo = now.diff(moment(time).local(), 'minutes');
  const hoursAgo = now.diff(moment(time).local(), 'hours');
  const daysAgo = now.diff(moment(time).local(), 'days');
  const weeksAgo = now.diff(moment(time).local(), 'weeks');
  const monthsAgo = now.diff(moment(time).local(), 'months');
  const yearsAgo = now.diff(moment(time).local(), 'years');

  if (secondsAgo < 1) return 'just now';
  if (secondsAgo < 60) return longForm ? `${secondsAgo} second${secondsAgo === 1 ? '' : 's'} ago` : `${secondsAgo}s`;
  if (minutesAgo < 60) return longForm ? `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'} ago` : `${minutesAgo}m`;
  if (hoursAgo < 24) return longForm ? `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago` : `${hoursAgo}h`;
  if (daysAgo === 1 && longForm) return 'Yesterday';
  if (daysAgo < 7) return longForm ? `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago` : `${daysAgo}d`;
  if (weeksAgo < 30) return longForm ? `${weeksAgo} week${weeksAgo === 1 ? '' : 's'} ago` : `${weeksAgo}w`;
  if (monthsAgo < 18) return longForm ? `${monthsAgo} month${monthsAgo === 1 ? '' : 's'} ago` : `${monthsAgo}m`;
  return longForm ? `${yearsAgo} year${yearsAgo === 1 ? '' : 's'} ago` : `${yearsAgo}y`;
};

export const getPrettyDate = (date, formatInSameYear = 'MMM Do', formatInDifferentYear = 'MMM Do, YYYY') => {
  const momentDate = moment(date).local();
  const currentDate = moment().local();
  if (momentDate.isSame(currentDate, 'year')) return momentDate.format(formatInSameYear);
  return momentDate.format(formatInDifferentYear);
};

export const getRelativePrettyDate = time => {
  const now = moment();
  const minutesAgo = now.diff(moment(time).local(), 'minutes');
  const hoursAgo = now.diff(moment(time).local(), 'hours');
  const daysAgo = now.diff(moment(time).local(), 'days');
  const isSameYear = now.diff(moment(time).local(), 'years') === 0;

  if (!minutesAgo) return 'Just Now';
  if (minutesAgo < 60) return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  if (daysAgo === 1) return 'Yesterday';
  return moment(time)
    .local()
    .format(isSameYear ? 'MMMM Do' : 'MMMM Do, YYYY');
};

export const getPrettyFileTypeDisplay = url => {
  const ending = _.last(_.last(url?.split('.')).split('/')) || '';
  if (ending.length > 5) return 'URL';
  return ending.toUpperCase();
};

export const getFirstName = name => {
  if (!name) return '';

  // Clean up name
  let finalName = name.trim();
  finalName = finalName.split('@').join('');
  finalName = finalName.split('Dr. ').join('');
  finalName = _.first(finalName.split('('));
  finalName = _.first(finalName.split('['));
  finalName = _.first(finalName.split(','));
  finalName = _.first(finalName.split(' - '));
  finalName = _.first(finalName.split('/'));
  finalName = _.first(finalName.split('|'));
  finalName = _.first(finalName.split(':'));
  finalName = finalName.trim();

  // Edge Cases
  if (finalName.includes('The ')) return finalName;
  if (finalName.includes(' & ')) return finalName;
  if (finalName.includes("'s")) return finalName;
  if (finalName.includes('Skincare')) return finalName;
  if (finalName.includes('Beauty')) return finalName;
  if (finalName.includes('Makeup')) return finalName;
  if (finalName.includes('Fashion')) return finalName;
  if (finalName.includes('Aging')) return finalName;

  // Leverage Library
  let resp = parseFullName(finalName);
  const parts = finalName.trim().split(' ');
  if (parts.length <= 3) return resp.first || resp.last;
  return finalName;
};

export const getInitialsForUser = user => {
  if (!user) return '';
  const name = user.name || user.profile?.name || 'Creator';
  const cleanName = name
    .split('Dr.')
    .join('')
    .trim();
  const nameParts = cleanName.split(' ');
  if (nameParts.length === 1) return cleanName[0]?.toUpperCase() || '';
  if (nameParts.length === 2) return (nameParts[0][0]?.toUpperCase() || '') + (nameParts[1][0]?.toUpperCase() || '');
  if (nameParts.length === 3) return (nameParts[0][0]?.toUpperCase() || '') + (nameParts[1][0]?.toUpperCase() || '') + nameParts[2][0]?.toUpperCase() || ''; // prettier-ignore
  return cleanName[0]?.toUpperCase();
};

export const getInitialsForBrand = brand => {
  if (!brand) return '';
  const cleanName = brand.name
    .split(' Skincare')
    .join('')
    .trim();
  const nameParts = cleanName.split(' ');
  return nameParts.map(p => p[0]?.toUpperCase()).join('');
};

export const getFormattedSectionTitle = section => {
  /*
    We used to always capitalize section titles, so this function ensures the casing is correct
    for all section titles. This is useful for when we want to display the section title in a
    non-uppercase format.
  */
  if (!section || typeof section.title !== 'string') {
    return '';
  }

  // List of minor words that should remain lowercase unless they are at the beginning or end.
  const minorWords = new Set(['a', 'an', 'and', 'as', 'at', 'but', 'by', 'en', 'for', 'if', 'in', 'of', 'on', 'or', 'the', 'to', 'vs', 'via']);

  /**
   * Process a single word while preserving punctuation.
   *
   * This function uses a regex to separate any leading/trailing punctuation from the core
   * alphanumeric/hyphenated word. It then applies title-case formatting to the core,
   * including special handling for hyphenated compounds.
   *
   * @param {string} word - The word to process.
   * @param {boolean} isFirst - True if this is the first word of the title.
   * @param {boolean} isLast - True if this is the last word of the title.
   * @returns {string} - The formatted word.
   */
  const processWord = (word, isFirst, isLast) => {
    // The regex captures three groups:
    // 1. Leading punctuation (if any)
    // 2. The core word (alphanumeric, with optional apostrophes or hyphens)
    // 3. Trailing punctuation (if any)
    const match = word.match(/^([^a-zA-Z0-9]*)([a-zA-Z0-9]+(?:['-][a-zA-Z0-9]+)*)([^a-zA-Z0-9]*)$/);
    if (match) {
      const [, leading, core, trailing] = match;
      let processedCore = '';

      // Handle hyphenated words by processing each segment separately.
      if (core.includes('-')) {
        processedCore = core
          .split('-')
          .map((subword, idx, arr) => {
            // Within a hyphenated compound, always capitalize the first and last segment,
            // and for the rest, only capitalize if they are not minor words.
            if (idx === 0 || idx === arr.length - 1 || !minorWords.has(subword.toLowerCase())) {
              return subword.charAt(0).toUpperCase() + subword.slice(1).toLowerCase();
            } else {
              return subword.toLowerCase();
            }
          })
          .join('-');
      } else {
        // For non-hyphenated words: if it is the first or last word or not a minor word, capitalize.
        processedCore =
          isFirst || isLast || !minorWords.has(core.toLowerCase()) ? core.charAt(0).toUpperCase() + core.slice(1).toLowerCase() : core.toLowerCase();
      }
      return leading + processedCore + trailing;
    }

    // Fallback: if the regex fails, simply apply basic title-casing.
    return isFirst || isLast || !minorWords.has(word.toLowerCase()) ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase();
  };

  // Trim extra spaces and split the title into words based on one or more whitespace characters.
  const words = section.title.trim().split(/\s+/);
  if (words.length === 0) {
    return '';
  }

  // Process each word with awareness of its position in the title.
  const formattedWords = words.map((word, index) => {
    const isFirst = index === 0;
    const isLast = index === words.length - 1;
    return processWord(word, isFirst, isLast);
  });

  return formattedWords.join(' ');
};

export const formatAmountInCurrency = (amount, currency = 'USD') => {
  let display;
  try {
    display = new Intl.NumberFormat('EN', { style: 'currency', currency: currency || 'USD' }).format(amount);
  } catch (error) {
    display = new Intl.NumberFormat('EN', { style: 'currency', currency: 'USD' }).format(amount);
  }
  return display;
};

export const getFollowerCountDisplay = ({ instagramCount, tiktokCount, youtubeCount }, platform) => {
  const options = [
    { count: instagramCount, platform: 'instagram', name: 'Instagram', type: 'Followers' },
    { count: tiktokCount, platform: 'tiktok', name: 'TikTok', type: 'Followers' },
    { count: youtubeCount, platform: 'youtube', name: 'YouTube', type: 'Subscribers' }
  ];
  const option = _.find(options, { platform });
  if (option?.count) {
    return `${getPrettyNumber(option.count)} ${option.name} ${option.type}`;
  }
  return null;
};

export const cleanCommissionId = commission_id => {
  /*
    LEGACY: For our network if we see two commissions with the same ID we append
    the brand ID. Visually we don't want to show that so the brands don't
    get confused. Ie: convert Order #4812-349 to #4812

    In other cases the brand uses a "-" in the commission ID so we need to
    handle that as well. It's a little hacky but we will split and check
    if the parsed number is below 400. If it is we will assume it's the
    brand id and remove it. All other cases we just return the entire
    commission ID.
  */
  const splitCommissionId = commission_id?.split('-');
  const isLegacyCommissionHandling = splitCommissionId?.length === 2 && parseInt(splitCommissionId[1]) < 400;

  if (isLegacyCommissionHandling) return splitCommissionId[0];
  else return commission_id;
};

export const replaceTemplateVariables = (message, talent, brand) => {
  let finalMessage = message;
  finalMessage = finalMessage.replaceAll('{{FIRSTNAME}}', getFirstName(talent.name));
  finalMessage = finalMessage.replaceAll('{{FULLNAME}}', talent.name);
  finalMessage = finalMessage.replaceAll('{{BRANDNAME}}', brand.name);
  return finalMessage;
};

export const getDomainFromUrl = unsanitized_url => {
  if (!unsanitized_url) return null;

  /* Please keep this in sync with the special cases needed on the server calculation. */
  let domain = _.first(_.last(_.split(unsanitized_url, '://')).split('/'))
    .split('www.')
    .join('');

  // Handle edge cases, more specific substring checks per domain first to avoid conflicts
  if (unsanitized_url.includes('allbeauty.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('allbeauty.com/us')) domain += '/us';
  else if (unsanitized_url.includes('beautypie.com/us')) domain += '/us';
  else if (unsanitized_url.includes('rodial.com/us')) domain += '/us';
  else if (unsanitized_url.includes('spacenk.com/us')) domain += '/us';
  else if (unsanitized_url.includes('spacenk.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('spacenk.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('spacenk.com/global')) domain += '/global';
  else if (unsanitized_url.includes('spacenk.com/ie')) domain += '/ie';
  else if (unsanitized_url.includes('charlottetilbury.com/ie')) domain += '/ie';
  else if (unsanitized_url.includes('charlottetilbury.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('charlottetilbury.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('charlottetilbury.com/eu')) domain += '/eu';
  else if (unsanitized_url.includes('charlottetilbury.com/de')) domain += '/de';
  else if (unsanitized_url.includes('charlottetilbury.com/nl')) domain += '/nl';
  else if (unsanitized_url.includes('charlottetilbury.com/it')) domain += '/it';
  else if (unsanitized_url.includes('charlottetilbury.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('charlottetilbury.com/us')) domain += '/us';
  else if (unsanitized_url.includes('charlottetilbury.com/au')) domain += '/au';
  else if (unsanitized_url.includes('charlottetilbury.com/es')) domain += '/es';
  else if (unsanitized_url.includes('theordinary.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('deciem.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('theordinary.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('theordinary.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('theordinary.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('theordinary.com/en-mx')) domain += '/en-mx';
  else if (unsanitized_url.includes('niod.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('niod.com/en-nz')) domain += '/en-nz';
  else if (unsanitized_url.includes('niod.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('niod.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('niod.com/en-mx')) domain += '/en-mx';
  else if (unsanitized_url.includes('deciem.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('libertylondon.com/us')) domain += '/us';
  else if (unsanitized_url.includes('net-a-porter.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('net-a-porter.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('cos.com/en_gbp')) domain += '/en_gbp';
  else if (unsanitized_url.includes('cos.com/en_eur')) domain += '/en_eur';
  else if (unsanitized_url.includes('cos.com/en-eu-e')) domain += '/en-eu-e';
  else if (unsanitized_url.includes('cos.com/en-eu')) domain += '/en-eu';
  else if (unsanitized_url.includes('cos.com/pl-pl')) domain += '/pl-pl';
  else if (unsanitized_url.includes('cos.com/es-es')) domain += '/es-es';
  else if (unsanitized_url.includes('cos.com/it-it')) domain += '/it-it';
  else if (unsanitized_url.includes('cos.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('fresh.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('harveynichols.com/int')) domain += '/int';
  else if (unsanitized_url.includes('italist.com/us')) domain += '/us';
  else if (unsanitized_url.includes('ae.com/us/en/p/aerie')) domain += '/us/en/p/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/c/aerie')) domain += '/us/en/c/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/p/ae/aerie')) domain += '/us/en/p/ae/aerie';
  else if (unsanitized_url.includes('ae.com/us/en/c/ae/aerie')) domain += '/us/en/c/ae/aerie';
  else if (unsanitized_url.includes('ugg.com/uk/')) domain += '/uk';
  else if (unsanitized_url.includes('stradivarius.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('stradivarius.com/us')) domain += '/us';
  else if (unsanitized_url.includes('harrods.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('mrporter.com/en-au')) domain += '/en-au';
  else if (unsanitized_url.includes('mrporter.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('mrporter.com/en-de')) domain += '/en-de';
  else if (unsanitized_url.includes('shop.mango.com/us')) domain += '/us';
  else if (unsanitized_url.includes('shop.mango.com/au')) domain += '/au';
  else if (unsanitized_url.includes('shop.mango.com/mx')) domain += '/mx';
  else if (unsanitized_url.includes('shop.mango.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('calzedonia.com/us')) domain += '/us';
  else if (unsanitized_url.includes('sephora.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('dior.com/en_us/beauty')) domain += '/en_us/beauty';
  else if (unsanitized_url.includes('zadig-et-voltaire.com/us/en')) domain += '/us/en';
  else if (unsanitized_url.includes('reiss.com/fr/fr')) domain += '/fr/fr';
  else if (unsanitized_url.includes('reiss.com/nl/en')) domain += '/nl/en';
  else if (unsanitized_url.includes('reiss.com/ie/en')) domain += '/ie/en';
  else if (unsanitized_url.includes('reiss.com/au/en')) domain += '/au/en';
  else if (unsanitized_url.includes('reiss.com/us/en')) domain += '/us/en';
  else if (unsanitized_url.includes('reiss.com/de/de')) domain += '/de/de';
  else if (unsanitized_url.includes('anthropologie.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('anthropologie.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('hudabeauty.com/us/en_US')) domain += '/us/en_US';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_ES')) domain += '/dt/en_ES';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_FR')) domain += '/dt/en_FR';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_IT')) domain += '/dt/en_IT';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_PL')) domain += '/dt/en_PL';
  else if (unsanitized_url.includes('hudabeauty.com/en_IN')) domain += '/en_IN';
  else if (unsanitized_url.includes('hudabeauty.com/en_AU')) domain += '/en_AU';
  else if (unsanitized_url.includes('hudabeauty.com/ar_SA')) domain += '/ar_SA';
  else if (unsanitized_url.includes('hudabeauty.com/en_CA')) domain += '/en_CA';
  else if (unsanitized_url.includes('hudabeauty.com/en_KW')) domain += '/en_KW';
  else if (unsanitized_url.includes('hudabeauty.com/en_AE')) domain += '/en_AE';
  else if (unsanitized_url.includes('hudabeauty.com/dt/en_DE')) domain += '/dt/en_DE';
  else if (unsanitized_url.includes('mytheresa.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('shop.lululemon.com/en-ca')) domain += '/en-ca';
  else if (unsanitized_url.includes('sezane.com/us')) domain += '/us';
  else if (unsanitized_url.includes('sezane.com/eu')) domain += '/eu';
  else if (unsanitized_url.includes('sezane.com/fr')) domain += '/fr';
  else if (unsanitized_url.includes('sezane.com/en')) domain += '/en';
  else if (unsanitized_url.includes('sezane.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('meandem.com/us')) domain += '/us';
  else if (unsanitized_url.includes('uniqlo.com/us')) domain += '/us';
  else if (unsanitized_url.includes('uniqlo.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('roddandgunn.com/us')) domain += '/us';
  else if (unsanitized_url.includes('roddandgunn.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('roddandgunn.com/au')) domain += '/au';
  else if (unsanitized_url.includes('roddandgunn.com/nz')) domain += '/nz';
  else if (unsanitized_url.includes('aldoshoes.com/us')) domain += '/us';
  else if (unsanitized_url.includes('aldoshoes.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('freepeople.com/uk')) domain += '/uk';
  else if (unsanitized_url.includes('freepeople.com/france')) domain += '/france';
  else if (unsanitized_url.includes('toryburch.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('toryburch.com/de-de')) domain += '/de-de';
  else if (unsanitized_url.includes('armani.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('armani.com/en-nl')) domain += '/en-nl';
  else if (unsanitized_url.includes('armani.com/es-es')) domain += '/es-es';
  else if (unsanitized_url.includes('armani.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('armani.com/de-de')) domain += '/de-de';
  else if (unsanitized_url.includes('armani.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('armani.com/it-it')) domain += '/it-it';
  else if (unsanitized_url.includes('arket.com/en_gbp')) domain += '/en_gbp';
  else if (unsanitized_url.includes('ruggable.com/en-CA')) domain += '/en-CA';
  else if (unsanitized_url.includes('stories.com/en_usd')) domain += '/en_usd';
  else if (unsanitized_url.includes('nike.com/gb')) domain += '/gb';
  else if (unsanitized_url.includes('allsaints.com/us')) domain += '/us';
  else if (unsanitized_url.includes('allsaints.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('urbanoutfitters.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('urbanoutfitters.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('guess.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('mintvelvet.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('barrie.com/en')) domain += '/en';
  else if (unsanitized_url.includes('noblepanacea.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('noblepanacea.com/en-eu')) domain += '/en-eu';
  else if (unsanitized_url.includes('chewy.com/g/shelter-partners')) domain += '/g/shelter-partners';
  else if (unsanitized_url.includes('chewy.com/ca')) domain += '/ca';
  else if (unsanitized_url.includes('dolcegabbana.com/it-it')) domain += '/it-it';
  else if (unsanitized_url.includes('dolcegabbana.com/es-es')) domain += '/es-es';
  else if (unsanitized_url.includes('dolcegabbana.com/fr-fr')) domain += '/fr-fr';
  else if (unsanitized_url.includes('dolcegabbana.com/en-gb')) domain += '/en-gb';
  else if (unsanitized_url.includes('dolcegabbana.com/de-de')) domain += '/de-de';
  else if (unsanitized_url.includes('dolcegabbana.com/en-us')) domain += '/en-us';
  else if (unsanitized_url.includes('furla.com/gb/en')) domain += '/gb/en';
  else if (unsanitized_url.includes('furla.com/us/en')) domain += '/us/en';
  else if (unsanitized_url.includes('intimissimi.com/us')) domain += '/us';
  else if (unsanitized_url.includes('chanel.com/us')) domain += '/us';
  else if (unsanitized_url.includes('levi.com/US')) domain += '/US';
  else if (unsanitized_url.includes('levi.com/GB')) domain += '/GB';
  else if (unsanitized_url.includes('levi.com/CA')) domain += '/CA';
  else if (unsanitized_url.includes('abercrombie.com/shop/eu')) domain += '/shop/eu';
  else if (unsanitized_url.includes('abercrombie.com/shop/uk')) domain += '/shop/uk';

  return domain;
};

export const isUrlString = str => {
  return str.includes('http') || str.includes('.co') || str.includes('www.');
};

export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) && ![/@gnail/, /@gmial/, /@gnial/, /@gmail\.(?!com$)/].some(commonTypo => commonTypo.test(email));
};

export const isValidPercentageOrDollarAmount = (string = '') => {
  let numericalString = String(string)
    .split('$')
    .join('')
    .split('%')
    .join('');
  return !_.isNaN(parseFloat(numericalString));
};

export const hasSaleEnded = item => {
  const endsInDays = item.activeEndDate
    ? moment(item.activeEndDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days')
    : null;
  return endsInDays && endsInDays < 0;
};

export const getDateStringForSale = item => {
  let dateString;
  const startsInDays = item.activeStartDate
    ? moment(item.activeStartDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days')
    : null;
  const endsInDays = item.activeEndDate
    ? moment(item.activeEndDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days')
    : null;
  const hasStarted = !startsInDays || startsInDays <= 0;
  const hasEnded = hasSaleEnded(item);

  if (item.activeStartDate && !item.activeEndDate) {
    if (hasStarted) {
      dateString = 'Ongoing Sale';
    } else {
      dateString =
        startsInDays < 7 ? `Starts on ${moment(item.activeStartDate).format('dddd')}` : `Starts on ${moment(item.activeStartDate).format('MMM Do')}`;
    }
  } else if (!item.activeStartDate && item.activeEndDate) {
    if (hasEnded) {
      dateString = !endsInDays
        ? 'Ends Today'
        : endsInDays > -7
        ? `Ended on ${moment(item.activeEndDate).format('dddd')}`
        : `Ended on ${moment(item.activeEndDate).format('MMM Do')}`;
    } else {
      dateString = !endsInDays
        ? 'Ends Today'
        : endsInDays < 7
        ? `Ends on ${moment(item.activeEndDate).format('dddd')}`
        : `Ends on ${moment(item.activeEndDate).format('MMM Do')}`;
    }
  } else if (item.activeStartDate && item.activeEndDate) {
    if (hasEnded) {
      dateString = !endsInDays
        ? 'Ends Today'
        : endsInDays > -7
        ? `Ended on ${moment(item.activeEndDate).format('dddd')}`
        : `Ended on ${moment(item.activeEndDate).format('MMM Do')}`;
    } else if (hasStarted) {
      dateString = !endsInDays
        ? 'Ends Today'
        : endsInDays < 7
        ? `Ends on ${moment(item.activeEndDate).format('dddd')}`
        : `Ends on ${moment(item.activeEndDate).format('MMM Do')}`;
    } else {
      dateString = `${moment(item.activeStartDate).format('dddd MMM D')} - ${moment(item.activeEndDate).format('MMM D')} `;
    }
  }
  return dateString;
};

export const getTimeStampForServer = () => {
  const date = new Date();
  return (
    date.getUTCFullYear() +
    '-' +
    ('00' + (date.getUTCMonth() + 1)).slice(-2) +
    '-' +
    ('00' + date.getUTCDate()).slice(-2) +
    ' ' +
    ('00' + date.getUTCHours()).slice(-2) +
    ':' +
    ('00' + date.getUTCMinutes()).slice(-2) +
    ':' +
    ('00' + date.getUTCSeconds()).slice(-2)
  );
};

export const getDisplayForSource = source => {
  return (
    {
      awin: 'Awin',
      avantlink: 'Avantlink',
      digidip: 'Digidip',
      flexoffer: 'Flex Offers',
      goaffpro: 'Go Aff Pro',
      impact: 'Impact',
      narrativ: 'Narrativ',
      pepperjam: 'Pepperjam',
      rakuten: 'Rakuten',
      refersion: 'Refersion',
      shareasale: 'ShareASale',
      shopmyshelf: 'ShopMy',
      skimlink: 'Skimlinks',
      viglink: 'Viglinks'
    }[source] || source
  );
};

export const getDisplaySourceFromMerchant = merchant => getDisplayForSource(merchant.source);

export const formatUserTypeTag = tag => {
  return (
    {
      LIFESTYLE: 'MODELS & CELEBS'
    }[tag] || tag
  );
};

export const isImageUrl = url => /\.(jpeg|jpg|gif|png)/.test(url.toLowerCase());
export const isPDFUrl = url => /\.(pdf)/.test(url.toLowerCase());

export const enhanceHTML = text => {
  /*
    Convert all links into anchor refs
  */

  // Add spaces before all enters to handle correctly
  const adjText = text.split('\n').join(` \n`);
  const parts = adjText.split(' ').map((part, idx) => {
    if (!part.slice(0, part.length - 2).includes('.'))
      return (
        <Fragment key={idx}>
          {part}
          {` `}
        </Fragment>
      );
    try {
      let url = part.replace(/[()]/g, ''); // Get rid of wrapping parens
      url = url[url.length - 1] === '.' ? url.slice(0, url.length - 1) : url; // Remove final periods coming after the parens
      url = new URL(url);
      return (
        <a href={url.href} key={idx} target='_blank' className='standard-link' rel='noopener noreferrer'>
          {part}
        </a>
      );
    } catch (_) {
      return (
        <Fragment key={idx}>
          {part}
          {` `}
        </Fragment>
      );
    }
  });

  return parts;
};

export const removeAllNonNumbers = string => string?.replace(/\D/g, '');
export const removeStringNewLines = string => string?.replace(/(\r\n|\n|\r)/gm, '');
export const convertToNumber = val => (val ? +val?.replace(/[^0-9]/g, '') : null);
