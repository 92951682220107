import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './InstagramCSVGroupCreationModal.scss';

import Modal from '../General/Modal';
import DropUploader from '../Uploader/DropUploader';

import { createCuratorGroupFromInstagramZIP } from '../../Actions/ShopActions';

const InstagramCSVGroupCreationModal = props => {
  // Handle Uploading & Progress
  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const onDrop = files => {
    const isZip = files[0]?.type === 'application/zip';
    if (isZip) {
      setIsUploading(true);
      return true;
    }

    window.ALERT.error('Please the full zip file provided by Instagram.');
    return false;
  };

  const [zipUrl, setZipUrl] = React.useState('');
  const [errorOnZipUpload, setErrorOnZipUpload] = React.useState('');
  const onUpload = url => {
    setZipUrl(url);
    setIsUploading(false);
  };

  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false);
  const createGroup = () => {
    if (isCreatingGroup) return;
    if (!zipUrl) return window.ALERT.error('Please upload the full .zip file from Instagram first.');
    setIsCreatingGroup(true);
    props.createCuratorGroupFromInstagramZIP(zipUrl).then(resp => {
      if (resp.group) {
        window.ALERT.success('Group created successfully');
        props.closeModal();
        props.history.push(`/shop/my/circles/${resp.group.id}`);
      } else if (resp.error) {
        setErrorOnZipUpload(resp.error);
      }
      setIsCreatingGroup(false);
    });
  };

  const steps = [
    {
      description: (
        <>
          Go to{' '}
          <a href='https://accountscenter.instagram.com/info_and_permissions/dyi/' target='_blank' rel='noopener noreferrer'>
            Instagram Account Settings
          </a>
          .
          <div className='fallback'>
            If that doesn't load, go{' '}
            <a href='https://accountscenter.instagram.com' target='_blank' rel='noopener noreferrer'>
              here
            </a>{' '}
            and click "Your Information and Permissions" {`>`} "Download Your Information".
          </div>
        </>
      )
    },
    {
      description: (
        <>
          Click <span className='emphasize'>Download or transfer information</span> and <span className='emphasize'>select your account</span>.
        </>
      )
    },
    {
      description: (
        <>
          Click <span className='emphasize'>Some of your information</span> and scroll down to "Connections" to select{' '}
          <span className='emphasize'>Following and Followers</span>.
        </>
      )
    },
    {
      description: (
        <>
          Click <span className='emphasize'>Download To Device</span>.
        </>
      )
    },
    {
      description: (
        <>
          Set the Date Range to <span className='emphasize'>All Time</span>.
        </>
      )
    },
    {
      description: (
        <>
          Set the Format to <span className='emphasize'>JSON</span> and click <span className='emphasize'>Create File</span>.
        </>
      )
    },
    {
      description: <>Wait for an email from Instagram, this can take up to 1 hour!</>
    }
  ];

  return (
    <Modal visible close={props.closeModal} showClose className='instagram-csv-creation-modal'>
      <div className='instagram-csv-header-section'>
        <div className='instagram-csv-header'>Connecting Your Instagram</div>
        <div className='instagram-csv-subheader'>
          Instagram requires you to download the accounts you follow in order to use them to generate your first circle. For a video walkthrough,
          click{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://static.shopmy.us/Downloading+Instagram+Followers.mov'>
            here
          </a>
          .
        </div>
      </div>
      <div className='steps'>
        {steps.map((step, idx) => {
          const { description } = step;

          return (
            <div className='step'>
              <div className='number'>{idx + 1}</div>
              <div className='description'>{description}</div>
            </div>
          );
        })}
      </div>
      {errorOnZipUpload && <div>Error: {errorOnZipUpload}</div>}
      <DropUploader
        className={cn('upload-btn', {
          uploaded: zipUrl,
          uploading: isUploading
        })}
        allowClicks
        customAcceptTypes={['application/zip']}
        onDrop={onDrop}
        onUpload={onUpload}
        isDisabled={isUploading}
        onProgress={progress => setProgress(progress)}
      >
        {zipUrl ? 'Zip File Uploaded!' : progress > 0 ? <div>Uploading... {progress}%</div> : <div>Upload your .zip file</div>}
      </DropUploader>
      <div className={cn('create-btn', { disabled: !zipUrl })} onClick={createGroup}>
        {isCreatingGroup ? 'Creating...' : 'Create Circle'}
      </div>
    </Modal>
  );
};

InstagramCSVGroupCreationModal.propTypes = {
  // From Inside
  createCuratorGroupFromInstagramZIP: PropTypes.func.isRequired,

  // From Outside
  closeModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {} = state;
  return {};
};

export default connect(mapStateToProps, {
  createCuratorGroupFromInstagramZIP
})(withRouter(InstagramCSVGroupCreationModal));
