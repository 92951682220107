import React from 'react';
import PropTypes from 'prop-types';
import './CuratorGroupModalCurators.scss';

const CuratorGroupModalCurators = props => {
  const { group, curators } = props;

  // If this is yours, we have group_users
  const shopGroup = () => props.selectGroupId(group.id);

  return (
    <div className='curator-group-modal-curators'>
      {!!group && (
        <div onClick={shopGroup} className='curator-group-group all'>
          <div className='images-container'>
            {curators.slice(0, curators.length > 2 ? 3 : 1).map(({ user }) => (
              <img key={user.image} src={user.image} alt={user.name} />
            ))}
          </div>
          <div className='data-container'>
            <div className='name'>All {group.title}</div>
          </div>
        </div>
      )}
      {curators.map(groupUser => {
        const { user } = groupUser;
        const { image } = user || {};
        const select = () => props.selectCuratorId(groupUser.User_id);
        return (
          <div onClick={select} key={user.id} className='curator-group-curator'>
            <div className='images-container'>{image ? <img src={image} alt={user.name} /> : <div className='empty-image' />}</div>
            <div className='data-container'>
              <div className='name'>{user.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CuratorGroupModalCurators.propTypes = {
  group: PropTypes.object,
  selectCuratorId: PropTypes.func.isRequired,
  selectGroupId: PropTypes.func.isRequired
};

export default CuratorGroupModalCurators;
