import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './ShopCatalogResults.scss';

import { updateCategory, updateDepartment, updateIndustry } from '../../APIClient/catalog';
import { syncHierarchy } from '../../Actions/ShopActions';

import ImageUploader from '../General/ImageUploader';
import useCurrentUrl from '../../CustomHooks/useCurrentUrl';

import { getPrettyNumber } from '../../Helpers/formatting';
import { getCuratorGroupsWithUsers } from '../../Helpers/user_helpers';
import { getVisibleIndustries, getVisibleDepartments, getVisibleCategories, getPreviewImageForGroup } from '../../Helpers/shop_helpers';

const ShopCatalogResults = props => {
  const { shop, user, productRefinements, isCuratedShop } = props;

  let industries = getVisibleIndustries(shop);
  let departments = getVisibleDepartments(shop);
  let categories = getVisibleCategories(shop);
  const groups = getCuratorGroupsWithUsers(user);

  /*
    We use the URL parameter as the source of truth for the active industry and department.
  */
  const currentUrl = useCurrentUrl();
  const activeIndustryId = new URL(currentUrl).searchParams.get('i');
  const activeDepartmentId = new URL(currentUrl).searchParams.get('d');
  const activeIndustry = activeIndustryId && industries.find(ind => ind.id === +activeIndustryId);
  const activeDepartment = activeDepartmentId && departments.find(dep => dep.id === +activeDepartmentId);

  // If the shop has productRefinement pills, only show those here
  if (isCuratedShop) {
    const industryRefinements = productRefinements.find(ref => ref.label === 'Industries')?.items || [];
    const departmentRefinements = productRefinements.find(ref => ref.label === 'Departments')?.items || [];
    const categoryRefinements = productRefinements.find(ref => ref.label === 'Categories')?.items || [];

    industries = industries
      .filter(ind => industryRefinements.find(ref => ref.refinement.Industry_id === ind.id))
      .map(industry => {
        return {
          ...industry,
          count: industryRefinements.find(ref => ref.refinement.Industry_id === industry.id)?.count || 0
        };
      })
      .filter(industry => industry.count > 0 || activeIndustry?.id === industry.id);
    departments = departments
      .map(department => {
        return {
          ...department,
          count: departmentRefinements.find(ref => ref.refinement.Department_id === department.id)?.count || 0
        };
      })
      .filter(department => department.count > 0 || activeDepartment?.id === department.id);
    categories = categories
      .map(category => {
        return {
          ...category,
          count: categoryRefinements.find(ref => ref.refinement.Category_id === category.id)?.count || 0
        };
      })
      .filter(category => category.count > 0 || activeDepartment?.id === category.id);

    // Order them by where they show up in the productRefinements
    industries = _.orderBy(industries, industry => {
      const index = industryRefinements.findIndex(ref => ref.refinement.Industry_id === industry.id);
      return index === -1 ? 9999 : index;
    });
    departments = _.orderBy(departments, department => {
      const index = departmentRefinements.findIndex(ref => ref.refinement.Department_id === department.id);
      return index === -1 ? 9999 : index;
    });
    categories = _.orderBy(categories, category => {
      const index = categoryRefinements.findIndex(ref => ref.refinement.Category_id === category.id);
      return index === -1 ? 9999 : index;
    });
  }

  const makeUrlToParams = params => {
    const rootShop = props.Curator_username ? `/shop/${props.Curator_username}` : '/shop';
    const urlParams = new URLSearchParams();

    if (props.Curator_id) urlParams.set('Curator_id', props.Curator_id);
    if (props.CuratorGroup_id) urlParams.set('CuratorGroup_id', props.CuratorGroup_id);

    for (const key in params) {
      if (params[key] === null || params[key] === undefined) continue;
      urlParams.set(key, params[key]);
    }
    const url = `${rootShop}?${urlParams.toString()}`;
    return url;
  };

  let filters = [];

  if (activeIndustry)
    filters.push({
      label: activeIndustry.name,
      to: `/shop`,
      active: true
    });
  if (activeDepartment)
    filters.push({
      label: activeDepartment.name,
      to: makeUrlToParams({ i: activeDepartment.Industry_id }),
      active: true
    });

  if (!activeIndustry && !activeDepartment) {
    groups.forEach(group => {
      filters.push({
        label: group.title,
        image: getPreviewImageForGroup(group),
        to: makeUrlToParams({ CuratorGroup_id: group.id })
      });
    });

    industries.forEach(industry => {
      filters.push({
        Industry_id: industry.id,
        label: industry.name,
        to: `/shop?i=${industry.id}`
      });
    });
  } else if (activeIndustry && !activeDepartment) {
    departments
      .filter(department => department.Industry_id === activeIndustry.id)
      .forEach(department => {
        filters.push({
          Department_id: department.id,
          label: department.name,
          to: makeUrlToParams({ i: activeIndustry.id, d: department.id })
        });
      });
  }

  const doesIndustryMatchFilters = industry => {
    if (!activeIndustry) return true;
    return industry.id === activeIndustry.id;
  };

  const doesDepartmentMatchFilters = department => {
    if (activeDepartment) return department.id === activeDepartment.id;
    if (activeIndustry) return department.Industry_id === activeIndustry.id;
    return true;
  };

  const doesCategoryMatchFilters = category => {
    const department = departments.find(department => department.id === category.Department_id);

    if (!activeIndustry) return true;
    if (activeIndustry.id !== department.Industry_id) return false;
    if (activeDepartment) return department.id === activeDepartment.id;
    if (!activeDepartment) return true;
  };

  // Results
  let results = [];
  industries.forEach(industry => {
    if (!doesIndustryMatchFilters(industry) || activeDepartment) return;
    results.push({
      name: activeIndustry ? `All ${industry.name}` : industry.name,
      image: industry.exampleProductImage,
      onUpdateImage: image => updateHierarchyImage(image, industry, 'industry'),
      to: activeIndustry || isCuratedShop ? makeUrlToParams({ Industry_id: industry.id }) : makeUrlToParams({ i: industry.id }),
      count: industry.count
    });
  });

  departments.forEach(department => {
    if (!doesDepartmentMatchFilters(department)) return;
    results.push({
      name: activeDepartment ? `All ${department.name}` : department.name,
      image: department.exampleProductImage,
      onUpdateImage: image => updateHierarchyImage(image, department, 'department'),
      to:
        activeDepartment || isCuratedShop
          ? makeUrlToParams({ Department_id: department.id })
          : makeUrlToParams({ i: department.Industry_id, d: department.id }),
      count: department.count
    });
  });

  categories.forEach(category => {
    if (!doesCategoryMatchFilters(category)) return;
    results.push({
      name: category.name,
      image: category.exampleProductImage,
      onUpdateImage: image => updateHierarchyImage(image, category, 'category'),
      to: makeUrlToParams({ Category_id: category.id }),
      count: category.count
    });
  });

  // Show results in order of count if curated
  if (isCuratedShop) {
    results = _.orderBy(results, result => result.count, 'desc');
  }

  // Sync Hierarchy
  React.useEffect(() => {
    props.syncHierarchy();
  }, []);

  // Updating Images (Admin Only)
  const canEditImages = window.__ADMIN_CONTROL_MODE__;
  const [isUploadingImage, setIsUploadingImage] = React.useState(false);
  const [onUploadComplete, setOnUploadComplete] = React.useState(null);
  const updateHierarchyImage = (image, hierarchy, type) => {
    const updateFn = type === 'industry' ? updateIndustry : type === 'department' ? updateDepartment : updateCategory;
    updateFn(hierarchy, { exampleProductImage: image }).then(() => props.syncHierarchy());
  };

  // References for smooth scrolling experience
  const containerRef = React.useRef();
  const scrollTopIntoView = () => {
    const isTopInView = containerRef.current.getBoundingClientRect().top > 0;
    if (!isTopInView) {
      const rect = containerRef.current.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.scrollY;
      const offsetPosition = absoluteElementTop - 40;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  return (
    <div ref={containerRef} className='shop-catalog-results-container'>
      {canEditImages && <ImageUploader isVisible={isUploadingImage} setIsVisible={setIsUploadingImage} onSaveCallback={onUploadComplete} />}
      {!isCuratedShop && (
        <div className='catalog-result-filters'>
          {filters.map((filter, idx) => {
            const { label, active, to, image } = filter;
            const onClick = () => {
              filter.Industry_id
                ? window.__ADD_EVENT__(`Shop - Clicked Catalog Filter`, { type: 'Industry', name: label })
                : filter.Department_id
                ? window.__ADD_EVENT__(`Shop - Clicked Catalog Filter`, { type: 'Department', name: label })
                : filter.CuratorGroup_id &&
                  window.__ADD_EVENT__(`Shop - Clicked Quick Group Filter`, {
                    type: 'Curator Group',
                    name: label
                  });
            };
            return (
              <Link key={label + idx} to={to} onClick={onClick} className={cn('filter', { active, inactive: !active, 'has-image': !!image })}>
                {image && (
                  <div className='image-container'>
                    <img src={image} alt={label} />
                  </div>
                )}
                <div className='label'>{label}</div>
                {active && (
                  <div className='remove-icn'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
              </Link>
            );
          })}
        </div>
      )}
      <div className='shop-catalog-results'>
        {results.map((result, idx) => {
          if (!result.image) return null;

          const editImage = e => {
            e.stopPropagation();
            e.preventDefault();
            setIsUploadingImage(true);
            setOnUploadComplete(() => result.onUpdateImage);
          };

          const card = (
            <div className='shop-catalog-result-inner'>
              <div className='image-container'>
                <img className='example-image' src={result.image} alt={result.name} />
              </div>
              <div className='data-container'>
                <div className='title'>{result.name}</div>
                {result.count && <div className='count'>{getPrettyNumber(result.count)}</div>}
              </div>
              {canEditImages && (
                <div className='admin-actions'>
                  <div onClick={editImage} className='action edit'>
                    Edit Image
                  </div>
                </div>
              )}
            </div>
          );

          const clickResult = () => {
            scrollTopIntoView();
            window.__ADD_EVENT__(`Shop - Clicked Catalog Result`, { name: result.name });
          };

          return (
            <Link key={idx} to={result.to} onClick={clickResult} className='shop-catalog-result'>
              {card}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

ShopCatalogResults.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  syncHierarchy: PropTypes.func.isRequired,

  // From Shop.js
  setCategory_id: PropTypes.func.isRequired,
  setDepartment_id: PropTypes.func.isRequired,
  setIndustry_id: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  syncHierarchy
})(withLastLocation(ShopCatalogResults));
