import { fetchUserThroughUsername } from '../APIClient/users';
import _ from 'lodash';

import { blockOnRequiringSubscription } from '../Helpers/subscription_helpers';
import { getBrandRequests } from '../Helpers/brand_helpers';
import { getShopHeaderSearchEl } from '../Helpers/shop_helpers';
import { getBrandListById, isLoggedIn } from '../Helpers/user_helpers';
import { getTalentById } from '../Helpers/talent_helpers';
import { formatUsersForBulkRequests } from '../Helpers/gifting_helpers';

export const PROMPT_USER_LOGIN = 'PROMPT_USER_LOGIN';
export const CLEAR_USER_LOGIN = 'CLEAR_USER_LOGIN';
export const CLEAR_EDITING_PINS = 'CLEAR_EDITING_PINS';
export const SET_ANALYTICS_MODE = 'SET_ANALYTICS_MODE';
export const TOGGLE_ADMIN_CONTROL_MODE = 'TOGGLE_ADMIN_CONTROL_MODE';
export const TOGGLE_NEW_SHOP_MODE = 'TOGGLE_NEW_SHOP_MODE';
export const TOGGLE_BODY_SCROLL_LOCK = 'TOGGLE_BODY_SCROLL_LOCK';
export const CHANGE_SEARCH_VAL = 'CHANGE_SEARCH_VAL';
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const SET_JOINED_MAILING_LIST = 'SET_JOINED_MAILING_LIST';
export const SET_BRAND_THEME = 'SET_BRAND_THEME';
export const SET_SHOP_MY_LABEL = 'SET_SHOP_MY_LABEL';
export const TOGGLE_PIN_TO_MOVE = 'TOGGLE_PIN_TO_MOVE';
export const CLEAR_PINS_TO_MOVE = 'CLEAR_PINS_TO_MOVE';
export const SET_AUTH_REDIRECT_URL = 'SET_AUTH_REDIRECT_URL';
export const SET_COMPLETE_MOVING_PINS_FN = 'SET_COMPLETE_MOVING_PINS_FN';
export const SET_SPOTLIGHT_TASKS_VISIBLE = 'SET_SPOTLIGHT_TASKS_VISIBLE';
export const SET_SPOTLIGHT_KEY = 'SET_SPOTLIGHT_KEY';
export const CLEAR_SPOTLIGHT_KEY = 'CLEAR_SPOTLIGHT_KEY';
export const SET_UI_KEY_VALUE = 'SET_UI_KEY_VALUE';

// Header Search Actions
export const SET_SHOP_HEADER_SEARCH_VAL = 'SET_SHOP_HEADER_SEARCH_VAL';
export const FOCUS_SHOP_SEARCH = 'FOCUS_SHOP_SEARCH';
export const BLUR_SHOP_SEARCH = 'BLUR_SHOP_SEARCH';

// Modals / Overlays
export const OPEN_ARTIST_MODAL = 'OPEN_ARTIST_MODAL';
export const CLOSE_ARTIST_MODAL = 'CLOSE_ARTIST_MODAL';
export const OPEN_BRAND_MODAL = 'OPEN_BRAND_MODAL';
export const CLOSE_BRAND_MODAL = 'CLOSE_BRAND_MODAL';
export const OPEN_BULK_TALENT_MODAL = 'OPEN_BULK_TALENT_MODAL';
export const CLOSE_BULK_TALENT_MODAL = 'CLOSE_BULK_TALENT_MODAL';
export const OPEN_CODES_MODAL = 'OPEN_CODES_MODAL';
export const CLOSE_CODES_MODAL = 'CLOSE_CODES_MODAL';
export const OPEN_REQUEST_MODAL = 'OPEN_REQUEST_MODAL';
export const CLOSE_REQUEST_MODAL = 'CLOSE_REQUEST_MODAL';
export const OPEN_FULFILLMENT_MODAL = 'OPEN_FULFILLMENT_MODAL';
export const CLOSE_FULFILLMENT_MODAL = 'CLOSE_FULFILLMENT_MODAL';
export const OPEN_BONUS_MODAL = 'OPEN_BONUS_MODAL';
export const CLOSE_BONUS_MODAL = 'CLOSE_BONUS_MODAL';
export const OPEN_BRAND_BUDGET_MODAL = 'OPEN_BRAND_BUDGET_MODAL';
export const CLOSE_BRAND_BUDGET_MODAL = 'CLOSE_BRAND_BUDGET_MODAL';
export const OPEN_SUBSCRIPTION_OFFER_MODAL = 'OPEN_SUBSCRIPTION_OFFER_MODAL';
export const CLOSE_SUBSCRIPTION_OFFER_MODAL = 'CLOSE_SUBSCRIPTION_OFFER_MODAL';
export const OPEN_UNPAID_INVOICES_MODAL = 'OPEN_UNPAID_INVOICES_MODAL';
export const CLOSE_UNPAID_INVOICES_MODAL = 'CLOSE_UNPAID_INVOICES_MODAL';
export const OPEN_CHAT_OVERLAY = 'OPEN_CHAT_OVERLAY';
export const CLOSE_CHAT_OVERLAY = 'CLOSE_CHAT_OVERLAY';
export const OPEN_CONNECT_INSTAGRAM_MODAL = 'OPEN_CONNECT_INSTAGRAM_MODAL';
export const CLOSE_CONNECT_INSTAGRAM_MODAL = 'CLOSE_CONNECT_INSTAGRAM_MODAL';
export const OPEN_CURATOR_GROUP_MODAL = 'OPEN_CURATOR_GROUP_MODAL';
export const CLOSE_CURATOR_GROUP_MODAL = 'CLOSE_CURATOR_GROUP_MODAL';
export const SET_TERMS_AND_CONDITIONS_VISIBLE = 'SET_TERMS_AND_CONDITIONS_VISIBLE';
export const OPEN_CATALOG_PRODUCT_MODAL = 'OPEN_CATALOG_PRODUCT_MODAL';
export const CLOSE_CATALOG_PRODUCT_MODAL = 'CLOSE_CATALOG_PRODUCT_MODAL';

export const OPEN_ADDRESS_MODAL = 'OPEN_ADDRESS_MODAL';
export const CLOSE_ADDRESS_MODAL = 'CLOSE_ADDRESS_MODAL';

export const openAuthModal = (panel = 'login', callbackFns) => async (dispatch, getState) => {
  dispatch({ type: TOGGLE_BODY_SCROLL_LOCK, turnOn: true });
  return dispatch({
    type: PROMPT_USER_LOGIN,
    panel: panel || 'login',
    callbackFns // { onLogin, onRegister, ... }
  });
};

export const setAnalyticsMode = toggleOn => {
  return {
    type: SET_ANALYTICS_MODE,
    toggleOn: !!toggleOn
  };
};

export const hideUserLogin = user => async (dispatch, getState) => {
  dispatch({ type: TOGGLE_BODY_SCROLL_LOCK, turnOn: false });
  return dispatch({
    type: CLEAR_USER_LOGIN
  });
};

export const clearEditingPins = () => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_EDITING_PINS
  });
};

export const requireAuth = func => async (dispatch, getState) => {
  const { user } = getState();
  const { isLoggedIn } = user;

  if (!isLoggedIn) {
    dispatch(openAuthModal());
  } else {
    func();
  }
};

export const toggleBodyScrollLock = (turnOn, options = {}) => async (dispatch, getState) => {
  /*
    Adds a class to the main container to block scrolling for things like modals and overlays.

    To be extra safe with locking in case of rendering ordering mistakes, we unlock once and then again
    after a delay to ensure the lock is in place. If you want to bypass this, pass skipDelayedCheck: true
    in the case that you need a rapid fire lock/unlock for some reason.
  */
  return dispatch({
    type: TOGGLE_BODY_SCROLL_LOCK,
    turnOn,
    skipDelayedCheck: options.skipDelayedCheck
  });
};

export const toggleAdminControlMode = () => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_ADMIN_CONTROL_MODE
  });
};

export const toggleNewShopMode = () => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_NEW_SHOP_MODE
  });
};

export const changeSearchVal = value => async (dispatch, getState) => {
  dispatch(setIsSearching(true));
  return dispatch({
    type: CHANGE_SEARCH_VAL,
    value
  });
};

export const setIsSearching = value => async (dispatch, getState) => {
  return dispatch({
    type: SET_IS_SEARCHING,
    value
  });
};

export const setJoinedMailingList = value => async (dispatch, getState) => {
  return dispatch({
    type: SET_JOINED_MAILING_LIST,
    value
  });
};

export const getBrandTheme = username => async (dispatch, getState) => {
  dispatch(setBrandTheme(null));
  const resp = await fetchUserThroughUsername(username);
  return dispatch(setBrandTheme(resp?.user?.brand));
};

export const setBrandTheme = brand => async (dispatch, getState) => {
  return dispatch({
    type: SET_BRAND_THEME,
    brand
  });
};

export const setShopMyLabel = label => async (dispatch, getState) => {
  return dispatch({
    type: SET_SHOP_MY_LABEL,
    label
  });
};

export const togglePinToMove = pin => async (dispatch, getState) => {
  return dispatch({
    type: TOGGLE_PIN_TO_MOVE,
    pin
  });
};

export const clearPinsToMove = () => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_PINS_TO_MOVE
  });
};

export const setUIAuthRedirectUrl = url => async (dispatch, getState) => {
  return dispatch({
    type: SET_AUTH_REDIRECT_URL,
    url
  });
};

export const setCompleteMovingPinsFn = fn => async (dispatch, getState) => {
  /*
    This is a function that will be called upon the completion of an action on the MovePinModal.
    This should be set to any high level function to ensure the data refreshes on the main page.

    See it in action in Links.js
  */
  return dispatch({
    type: SET_COMPLETE_MOVING_PINS_FN,
    fn
  });
};

export const setShowSpotlightOptionsUi = turnOn => async (dispatch, getState) => {
  return dispatch({
    type: SET_SPOTLIGHT_TASKS_VISIBLE,
    turnOn
  });
};

export const setSpotlightKey = key => async (dispatch, getState) => {
  return dispatch({
    type: SET_SPOTLIGHT_KEY,
    key
  });
};

export const clearSpotlightKey = key => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_SPOTLIGHT_KEY
  });
};

export const setShopHeaderSearchVal = value => async (dispatch, getState) => {
  return dispatch({
    type: SET_SHOP_HEADER_SEARCH_VAL,
    value
  });
};

export const focusShopSearch = () => async (dispatch, getState) => {
  const headerEl = getShopHeaderSearchEl();
  if (headerEl) headerEl.focus();
  return dispatch({
    type: FOCUS_SHOP_SEARCH
  });
};

export const blurShopSearch = source => async (dispatch, getState) => {
  const headerEl = getShopHeaderSearchEl();
  if (headerEl) headerEl.blur();
  return dispatch({
    type: BLUR_SHOP_SEARCH
  });
};

export const openArtistModal = (talent, closeCallback, sourceForEventing) => async (dispatch, getState) => {
  /* 
    Talent only needs to require an { id } param

    To maximize event tracking, you can pass a sourceForEventing string as well as name in the talent object
  */
  window.__ADD_EVENT__('General - Open Artist Modal', { talent, Source: sourceForEventing || 'Unknown' });
  return dispatch({
    type: OPEN_ARTIST_MODAL,
    talent,
    closeCallback
  });
};

export const closeArtistModal = () => async (dispatch, getState) => {
  const { artistModalCloseCallback } = getState().ui;
  artistModalCloseCallback && artistModalCloseCallback();
  return dispatch({
    type: CLOSE_ARTIST_MODAL
  });
};

export const openBrandModal = (params = {}, closeCallback) => async (dispatch, getState) => {
  /*
    Example:
      props.openBrandModal({
        brand: {
          id: 6,
          name: 'Chanel
        },
        initialTab: 'about
      }, () => console.info('Closed'))
  */
  if (!('brand' in params)) throw new Error('brand must be passed as a parameter to openBrandModal');
  return dispatch({
    type: OPEN_BRAND_MODAL,
    params,
    closeCallback
  });
};

export const closeBrandModal = () => async (dispatch, getState) => {
  const { brandModalCloseCallback } = getState().ui;
  brandModalCloseCallback && brandModalCloseCallback();
  return dispatch({
    type: CLOSE_BRAND_MODAL
  });
};

export const openBulkTalentModal = (params, closeCallback) => async (dispatch, getState) => {
  /*
    Params are passed to the bulk talent modal to know where to start in terms of displaying
    selected talent and templates. An example is:

      {
        BrandList_id: 1234, // Automatically adds members from that list
        outreachType: 'general', // Sets action type of the panel (general, gifting) 
        talent: [{ id: 45 }], // Array of talent to automatically add
        Lookbook_id: 12, // Preselects a lookbook
      }
  */
  return dispatch({
    type: OPEN_BULK_TALENT_MODAL,
    params,
    closeCallback
  });
};

export const closeBulkTalentModal = () => async (dispatch, getState) => {
  const { bulkTalentModalCloseCallback } = getState().ui;
  bulkTalentModalCloseCallback && bulkTalentModalCloseCallback();
  return dispatch({
    type: CLOSE_BULK_TALENT_MODAL
  });
};

export const openCodesModal = ({ params, closeCallback = null, submitCallback = null, sourceForEventing }) => async (dispatch, getState) => {
  /*
    Params are passed to the codes modal to know where to start in terms of displaying
    selected talent and templates. An example is:

      {
        User_id: 45,
        name: 'Harry Rein',
        showTemplates: false // Whether or not to show code sending templates
      }
  */
  const user = getState().user;
  if (blockOnRequiringSubscription(user, 'CUSTOM_CODES')) return;

  window.__ADD_EVENT__('General - Open Codes Modal', {
    User_id: params.User_id,
    User_name: params.name,
    Source: sourceForEventing || 'Unknown'
  });

  return dispatch({
    type: OPEN_CODES_MODAL,
    params,
    closeCallback,
    submitCallback
  });
};

export const closeCodesModal = () => async (dispatch, getState) => {
  const { codesModalCloseCallback } = getState().ui;
  codesModalCloseCallback && codesModalCloseCallback();
  return dispatch({
    type: CLOSE_CODES_MODAL
  });
};

export const openRequestModal = ({ params, closeCallback = null, submitCallback = null, useNewModal = false, sourceForEventing }) => async (
  dispatch,
  getState
) => {
  /*
    Params are passed to the reqeust modal to know where to start in terms of displaying
    selected talent and templates. An example is:

      {
        type: 'gifting', // 'gifting' or 'opportunities', defaults to 'gifting'
        Lookbook_id: 12,
        BrandList_id: 1234,
        warningOnClose: null, // Optional warning message to display when closing the modal
        preselectedUsers: User[]
          * array of users resulting from formatUsersForBulkRequests helper function
      }

    closeCallback is a function that will be called when the modal is closed
    submitCallback is a function that will be called only when the modal is successfully submitted
  */
  const user = getState().user;
  const talent = getState().talent;
  if (blockOnRequiringSubscription(user, params.type === 'opportunities' ? 'OPPORTUNITIES' : 'GIFTING')) return;

  let augmentedParams = {
    ...params
  };

  if (params.BrandList_id) {
    const list = getBrandListById(user, params.BrandList_id);
    augmentedParams.preselectedUsers = formatUsersForBulkRequests(
      list.users
        .map(u => {
          return getTalentById(talent, u.User_id);
        })
        .filter(u => !!u?.id)
    );
  }

  if (!augmentedParams.Lookbook_id) {
    // Try to guess which lookbook they might want to use
    const requests = getBrandRequests(getState().analytics);
    const lastRequest = _.orderBy(requests, 'id', 'desc')[0];
    if (lastRequest) augmentedParams.Lookbook_id = lastRequest.Lookbook_id;
  }

  window.__ADD_EVENT__(params.type === 'opportunities' ? 'General - Open Opportunities Modal' : 'General - Open Gifting Modal', {
    Source: sourceForEventing || 'Unknown'
  });

  return dispatch({
    type: OPEN_REQUEST_MODAL,
    params: augmentedParams,
    useNewModal,
    closeCallback,
    submitCallback
  });
};

export const closeRequestModal = () => async (dispatch, getState) => {
  const { requestModalCloseCallback } = getState().ui;
  requestModalCloseCallback && requestModalCloseCallback();
  return dispatch({
    type: CLOSE_REQUEST_MODAL
  });
};

export const openFulfillmentModal = ({ params, closeCallback = null, submitCallback = null }) => async (dispatch, getState) => {
  /*
    Allows a request object to easily be fulfilled, example:

    props.openFulfillmentModal({
      params: {
        Request_id: 1234
      },
      closeCallback: () => console.info('Closed'),
      submitCallback: () => console.info('Submitted')
    })
  */
  return dispatch({
    type: OPEN_FULFILLMENT_MODAL,
    params,
    closeCallback,
    submitCallback
  });
};

export const closeFulfillmentModal = (didSubmit = false) => async (dispatch, getState) => {
  const { fulfillmentModalCloseCallback, fulfillmentModalSubmitCallback } = getState().ui;
  didSubmit ? fulfillmentModalSubmitCallback && fulfillmentModalSubmitCallback() : fulfillmentModalCloseCallback && fulfillmentModalCloseCallback();
  return dispatch({
    type: CLOSE_FULFILLMENT_MODAL
  });
};

export const openBonusModal = ({ params, closeCallback = null, submitCallback = null }) => async (dispatch, getState) => {
  /*
    Opens a modal used to bonus talent. Example:

    props.openBonusModal({
      params: {
        User_id: 1234
      },
      closeCallback: () => console.info('Closed'),
      submitCallback: () => console.info('Submitted')
    })
  */
  return dispatch({
    type: OPEN_BONUS_MODAL,
    params,
    closeCallback,
    submitCallback
  });
};

export const closeBonusModal = (didSubmit = false) => async (dispatch, getState) => {
  const { bonusModalCloseCallback, bonusModalSubmitCallback } = getState().ui;
  didSubmit ? bonusModalSubmitCallback && bonusModalSubmitCallback() : bonusModalCloseCallback && bonusModalCloseCallback();
  return dispatch({
    type: CLOSE_BONUS_MODAL
  });
};

export const openBrandBudgetModal = params => async (dispatch, getState) => {
  /*
    Open the brand budget modal in order to add to the budget. Example:

    props.openBrandBudgetModal({
      amountRequired: 1234
    })
  */
  return dispatch({
    type: OPEN_BRAND_BUDGET_MODAL,
    params
  });
};

export const closeBrandBudgetModal = () => async (dispatch, getState) => {
  return dispatch({
    type: CLOSE_BRAND_BUDGET_MODAL
  });
};

export const openSubscriptionOfferModal = ({ params, closeCallback = null, submitCallback = null } = {}) => async (dispatch, getState) => {
  /*
    Opens the subscription offer modal in order to ask brands to subscribe to that feature.

    props.openSubscriptionOfferModal({
      params: {
        feature: 'DISCOVER_2.0'
      },
      closeCallback: () => console.info('Closed'),
      submitCallback: () => console.info('Submitted')
    })
  */
  return dispatch({
    type: OPEN_SUBSCRIPTION_OFFER_MODAL,
    params,
    closeCallback,
    submitCallback
  });
};

export const closeSubscriptionOfferModal = (didSubmit = false) => async (dispatch, getState) => {
  const { subscriptionOfferModalCloseCallback, subscriptionOfferModalSubmitCallback } = getState().ui;
  didSubmit
    ? subscriptionOfferModalSubmitCallback && subscriptionOfferModalSubmitCallback()
    : subscriptionOfferModalCloseCallback && subscriptionOfferModalCloseCallback();
  return dispatch({
    type: CLOSE_SUBSCRIPTION_OFFER_MODAL
  });
};

export const openUnpaidInvoicesModal = (params = {}) => async dispatch => {
  /*
    Opens the unpaid invoices modal in order to ask brands to pay their invoices.

    props.openUnpaidInvoicesModal({})
  */
  return dispatch({
    type: OPEN_UNPAID_INVOICES_MODAL,
    params
  });
};

export const closeUnpaidInvoicesModal = () => async (dispatch, getState) => {
  return dispatch({
    type: CLOSE_UNPAID_INVOICES_MODAL
  });
};

export const openChatOverlay = (talent, sourceForEventing) => async (dispatch, getState) => {
  /*
    Open the chat overlay with a given talent. Talent only needs { id, name, image }

    props.openChatOverlay({
      id: 1234,
      name: 'Harry Rein',
      image: 'https://...'
    })

    sourceForEventing helps track where the chat was opened from for UI tracking
  */
  window.__ADD_EVENT__('General - Open Chat Overlay', {
    User_id: talent.id,
    User_name: talent.name,
    Source: sourceForEventing || 'Unknown'
  });
  return dispatch({
    type: OPEN_CHAT_OVERLAY,
    talent
  });
};

export const closeChatOverlay = () => async (dispatch, getState) => {
  return dispatch({
    type: CLOSE_CHAT_OVERLAY
  });
};

export const openConnectInstagramModal = () => async (dispatch, getState) => dispatch({ type: OPEN_CONNECT_INSTAGRAM_MODAL });
export const closeConnectInstagramModal = () => async (dispatch, getState) => dispatch({ type: CLOSE_CONNECT_INSTAGRAM_MODAL });

export const openAddressModal = () => async (dispatch, getState) => dispatch({ type: OPEN_ADDRESS_MODAL });
export const closeAddressModal = () => async (dispatch, getState) => dispatch({ type: CLOSE_ADDRESS_MODAL });

export const openCuratorGroupModal = ({ params, source, closeCallback = null, submitCallback = null } = {}) =>
  withShopperLoginCheck(async (dispatch, getState) => {
    /*
    Opens the curator group modal in order to select or add to a group of curators. Example:

    props.openCuratorGroupModal({
      source: 'Event Source', // For tracking purposes
      params: {
        type: 'add', // 'add' or 'select'
        curator: {
          id: 1234, // If type is 'add', this is the user to add to the group
          name: '',
          image: ''
        }
      },
      closeCallback: () => console.info('Closed'),
      submitCallback: () => console.info('Submitted')
    })
  */
    window.__ADD_EVENT__(`Shop - Open Curator Group Modal`, { type: params.type, source: source || 'Unknown' });
    return dispatch({
      type: OPEN_CURATOR_GROUP_MODAL,
      params,
      closeCallback,
      submitCallback
    });
  });
export const closeCuratorGroupModal = (didSubmit = false) => async (dispatch, getState) => {
  const { curatorGroupModalCloseCallback, curatorGroupModalSubmitCallback } = getState().ui;
  didSubmit
    ? curatorGroupModalSubmitCallback && curatorGroupModalSubmitCallback()
    : curatorGroupModalCloseCallback && curatorGroupModalCloseCallback();
  return dispatch({
    type: CLOSE_CURATOR_GROUP_MODAL
  });
};

export const openCatalogProductModal = variant => async (dispatch, getState) => dispatch({ type: OPEN_CATALOG_PRODUCT_MODAL, variant });
export const closeCatalogVariantModal = () => async (dispatch, getState) => dispatch({ type: CLOSE_CATALOG_PRODUCT_MODAL });

export const setUIKeyValue = (key, value, page = 'general') => async (dispatch, getState) => {
  /*
    Store a general UI Key / Value pair for a given page, or a "general" tab

    You can use this just like state, for example:
      const [isEditing, setIsEditing] = [getUIKeyValueDiscover(ui, 'isEditing', false), val => props.setUIKeyValue('isEditing', val, 'discover')];
  */
  return dispatch({
    type: SET_UI_KEY_VALUE,
    page,
    key,
    value
  });
};

export const setTermsAndConditionsVisible = visible => async (dispatch, getState) => {
  return dispatch({
    type: SET_TERMS_AND_CONDITIONS_VISIBLE,
    visible
  });
};

export const withShopperLoginCheck = action => async (dispatch, getState) => {
  if (!isLoggedIn(getState().user)) {
    return dispatch(
      openAuthModal('register-shopper', {
        onRegister: newUserObject => action(dispatch, getState, newUserObject)
      })
    );
  }
  return action(dispatch, getState);
};
