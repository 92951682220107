import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SocialAuth.scss';

import { logoutUser } from '../../Actions/UserActions';
import { isShopper } from '../../Helpers/user_helpers';

import Loader from '../Loader/Loader';

const Logout = props => {
  const { logoutUser, user } = props;
  const [isLoggingOut, setIsLoggingOut] = useState(true);
  const [wasShopping] = useState(isShopper(user));

  useEffect(() => {
    logoutUser().then(resp => setIsLoggingOut(false));
  }, [logoutUser]);
  return isLoggingOut ? <Loader /> : <Redirect to={{ pathname: wasShopping ? '/shop' : '/home' }} />;
};

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return { user: state.user };
};

export default connect(mapStateToProps, {
  logoutUser
})(Logout);
