import { getCuratorGroupsWithUsers, getProductIdsPromoted, getTaste, isShopper } from './user_helpers';

export const getOnboardingStepsEnum = ({ user }) => {
  if (isShopper(user)) {
    return ['FIRST_USER_PRODUCT', 'FIRST_CIRCLE', 'TASTE_PROFILE'];
  }
  return [];
};

export const onboardingConstants = {
  MIN_PRODUCTS_FOR_TASTE: 25,
  MINUTES_BETWEEN_TASTE_RUNS: 60
};

export const getNumOnboardingStepsComplete = ({ user, shop }) => {
  return getOnboardingStepsEnum({ user }).filter(stepEnum => isOnboardingStepComplete(stepEnum, { user, shop })).length;
};

export const hasCompletedOnboarding = ({ user, shop }) => {
  const onboardingStepsEnum = getOnboardingStepsEnum({ user });
  const numComplete = getNumOnboardingStepsComplete({ user, shop });
  return numComplete >= onboardingStepsEnum.length;
};

export const canBuildTasteProfile = user => {
  const numProductsPromoted = getProductIdsPromoted(user).length;
  return numProductsPromoted >= onboardingConstants.MIN_PRODUCTS_FOR_TASTE;
};

export const isOnboardingStepComplete = (stepEnum, { user, shop }) => {
  if (!user) {
    console.error('isOnboardingStepComplete: User is required');
    return false;
  }
  const curatorGroupsWithUsers = getCuratorGroupsWithUsers(user);
  const numProductsPromoted = getProductIdsPromoted(user).length;
  switch (stepEnum) {
    case 'FIRST_CIRCLE':
      return curatorGroupsWithUsers.length > 0;
    case 'FIRST_USER_PRODUCT':
      const hasProductsPromoted = numProductsPromoted > 0;
      return hasProductsPromoted;
    case 'TASTE_PROFILE':
      const tasteProfile = !!getTaste(user)?.taste_profile;
      return !!tasteProfile;
    default:
      return false;
  }
};

export const getNumProductsBeforeTasteProfile = user => {
  const numProductsPromoted = getProductIdsPromoted(user).length;
  return onboardingConstants.MIN_PRODUCTS_FOR_TASTE - numProductsPromoted;
};
