import React from 'react';
import { connect } from 'react-redux';
import './Taste.scss';

import { getSimilarCuratorsToMe } from '../../APIClient/shop';

import TasteProfileHeader from '../../Components/TasteProfile/TasteProfileHeader';
import TasteProfileSimilarCurators from '../../Components/TasteProfile/TasteProfileSimilarCurators';

import { getTaste, getUserId } from '../../Helpers/user_helpers';
import { getUrlParam } from '../../Helpers/helpers';

const Taste = props => {
  const { user } = props;
  const { taste_profile } = getTaste(user) || {};
  const hasTasteProfile = !!taste_profile;

  const initialPageToLoadRef = React.useRef(+getUrlParam('page') || 0);
  const [similarCurators, setSimilarCurators] = React.useState([]);
  const [page, setPage] = React.useState(initialPageToLoadRef.current);
  const limit = 25;
  const [isFetching, setIsFetching] = React.useState(false);
  const isFirstPage = initialPageToLoadRef.current > 0 ? initialPageToLoadRef.current === page : page === 0;
  const isFetchingFirstPage = isFetching && isFirstPage;
  const fetchNextPage = () => {
    setPage(page + 1);
    const url = new URL(window.location);
    url.searchParams.set('page', page + 1);
    window.history.replaceState(null, '', url);
  };
  const fetchSimilarCurators = page => {
    if (isFetching) return;
    setIsFetching(true);

    const params = { page, limit };
    const initalBatchShouldFetchFromZero = initialPageToLoadRef.current > 0 && isFirstPage;
    if (initalBatchShouldFetchFromZero) {
      params.page = 0;
      params.limit = limit * (page + 1);
    }

    getSimilarCuratorsToMe(getUserId(user), params)
      .then(resp => resp.curators && setSimilarCurators([...similarCurators.slice(0, params.page * params.limit), ...resp.curators]))
      .finally(() => setIsFetching(false));
  };

  React.useEffect(() => {
    hasTasteProfile && fetchSimilarCurators(page);
  }, [taste_profile, page]);

  return (
    <div className='taste-outer-container'>
      <div className='taste-inner-container'>
        <TasteProfileHeader />
        {hasTasteProfile && (
          <>
            <TasteProfileSimilarCurators
              curators={similarCurators}
              isFetching={isFetching}
              isFetchingFirstPage={isFetchingFirstPage}
              page={page}
              limit={limit}
              fetchNextPage={fetchNextPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

Taste.propTypes = {
  // user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(Taste);
