import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import Loader from '../../Components/Loader/Loader';
import ScrollToTop from '../../Components/General/ScrollToTop';
import InputActions from '../../Components/General/InputActions';
import ContractPreview from '../../Components/Contracts/ContractPreview';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';
import CollaborationsExplainer from '../../Components/Collaborations/CollaborationsExplainer';
import BrandPartner from '../../Components/Collaborations/BrandPartner';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';

import './Collaborations.scss';

import { logToSlack } from '../../APIClient/alerts';
import { createContractWithBrand } from '../../Actions/CollaborationsActions';
import { syncCurrentUser } from '../../Actions/UserActions';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { isBrand, getUserId, isShopper, getBrandId, isLoggedIn, getContracts, getName, getTasks } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import { discoverBrands as discoverBrandsAPI } from '../../APIClient/brands';
import { getContracts as getContractsAPI } from '../../APIClient/collaborations';

class Collaborations extends Component {
  componentDidMount() {
    isLoggedIn(this.props.user) && this.fetchData();
    this.setupInfiniteScroll();

    window.__ADD_EVENT__('Collaborations - View Page ');
  }

  componentDidUpdate(prevProps) {
    // Load contracts on login
    if (isLoggedIn(this.props.user) && !isLoggedIn(prevProps.user)) {
      this.fetchData();
    }

    // tab changes
    const curTab = this.props.match?.params?.tab;
    const prevTab = prevProps.match?.params?.tab;
    if (curTab && curTab !== prevTab) {
      this.setState(
        {
          curTab,
          searchVal: '',
          page: 0,
          receivedAllResults: false,
          tags: []
        },
        () => {
          this.fetchData();
        }
      );
    }
  }

  state = {
    // Data
    contracts: [],
    brands: [],
    tags: [],

    // querying
    searchVal: '',
    page: 0,
    selectedTags: [],
    receivedAllResults: false,

    // UI
    curTab: this.props.match?.params?.tab || 'all', // 'new'
    numVisiblePerGroup: {},
    isFirstFetch: true,
    isFetching: true,
    isFetchingNewPage: false
  };

  setupInfiniteScroll = () => {
    window.addEventListener('scroll', e => {
      const BOTTOM = isMobile ? 900 : 500;
      const pixelsFromBottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight;
      if (pixelsFromBottom < BOTTOM && !this.state.isFetching && !this.state.isFetchingNewPage && !this.state.receivedAllResults) {
        this.setState({ page: this.state.page + 1, isFetchingNewPage: true }, this.fetchDataWithoutLoader);
      }
    });
  };

  fetchDataAndResetPage = () => this.setState({ page: 0 }, this.fetchData);
  fetchDataAndResetPageWithoutLoader = () => this.setState({ page: 0 }, this.fetchDataWithoutLoader);
  fetchDataWithoutLoader = () => this.fetchData({ withoutLoader: true });
  fetchData = ({ withoutLoader = false } = {}) => {
    const { user, ui } = this.props;
    const { searchVal, curTab, selectedTags, brands, page } = this.state;
    !withoutLoader && this.setState({ isFetching: true });
    const hasContracts = getContracts(user).length > 0;

    const PAGE_SIZE = 18;

    curTab === 'all' && (hasContracts || isBrand(user))
      ? getContractsAPI({
          ...(isBrand(user) ? { Brand_id: getBrandId(user) } : { User_id: getUserId(user) }),
          query: searchVal
        })
          .then(resp => {
            this.setState({
              contracts: resp.contracts
            });
          })
          .finally(() => {
            this.setState({ isFirstFetch: false, isFetching: false, isFetchingNewPage: false });
          })
      : !isBrand(user) &&
        discoverBrandsAPI({
          User_id: getUserId(user),
          query: searchVal,
          Tag_ids: _.map(selectedTags, 'id').join(','),
          limit: PAGE_SIZE,
          ...(isAdminControlMode(ui) ? { showAll: true } : {}),
          page
        })
          .then(resp => {
            this.setState({
              brands: [...brands.slice(0, page * PAGE_SIZE), ...resp.brands],
              tags: _.orderBy(
                _.uniqBy(_.concat(this.state.selectedTags, resp.tags), tag => tag.id),
                'value'
              ),
              receivedAllResults: !resp.brands.length
            });
          })
          .finally(() => {
            this.setState({ isFirstFetch: false, isFetching: false, isFetchingNewPage: false });
          });
  };

  scrollToSearchBrands = () => {
    if (this.searchContainerEl) {
      window.scrollTo({ top: this.searchContainerEl.offsetTop, behavior: 'smooth' });
      setTimeout(() => this.searchEl.focus(), 1000);
    }
  };

  updateSearch = newVal => {
    this.setState({ searchVal: newVal, isFetching: true });
    clearTimeout(this.debouncer);
    this.debouncer = setTimeout(this.fetchDataAndResetPage, 500);
  };

  changeTab = newTab => {
    const { history } = this.props;
    history.push(`/collaborations/${newTab}`);
  };

  isTagSelected = tag => !!this.state.selectedTags.find(t => t.id === tag.id);
  toggleTag = tag => {
    this.setState(
      {
        selectedTags: this.isTagSelected(tag) ? this.state.selectedTags.filter(t => t.id !== tag.id) : [...this.state.selectedTags, tag],
        receivedAllResults: false
      },
      this.fetchDataAndResetPageWithoutLoader
    );
  };

  render() {
    const { user } = this.props;
    const { isFetching, numVisiblePerGroup, isFirstFetch, selectedTags, isFetchingNewPage, searchVal, curTab, tags, contracts, brands } = this.state;
    if (!isLoggedIn(user)) return <RequiresLoginPanel />;
    if (isShopper(user)) return <RequiresNonShopperLoginPanel />;
    const tasks = getTasks(user);
    const hasTasks = !!tasks.length;
    const hasContracts = getContracts(user).length > 0 || contracts.length > 0;
    const isSearching = !!searchVal || isFetching;
    const showAllPanel = curTab === 'all' && (hasContracts || isBrand(user));
    const showNewPanel = !isBrand(user) && !showAllPanel && (hasContracts || hasTasks);

    const tabs = isBrand(user)
      ? [{ value: 'all', display: 'Your Collaborations' }]
      : [...(hasContracts ? [{ value: 'all', display: 'Your Collaborations' }] : []), { value: 'new', display: 'Find New Collaborations' }];

    const isCreatedByYou = contract => (isBrand(user) ? contract.isCreatedByBrand : contract.isCreatedByUser);

    const INITIAL_NUM_VISIBLE = 5;
    const NUM_PER_NEXT_PAGE = 5;
    const contractSections = [
      {
        header: isBrand(user) ? 'Proposals From Creators' : 'Proposals From Brands',
        results: contracts.filter(c => c.status === 'proposed' && (isBrand(user) ? c.isCreatedByUser : c.isCreatedByBrand)),
        value: 'proposed-to'
      },
      {
        header: 'Live Collaborations',
        results: contracts.filter(c => c.status === 'accepted' || c.status === 'active'),
        value: 'active'
      },
      {
        header: 'Proposed By You',
        results: contracts.filter(c => c.status === 'proposed' && isCreatedByYou(c)),
        value: 'proposed-by'
      },
      {
        header: "Proposals You're Creating",
        results: contracts.filter(c => c.status === 'pending'),
        value: 'pending'
      },
      {
        header: 'Closed Collaborations',
        results: contracts.filter(c => c.status === 'closed'),
        value: 'closed'
      },
      {
        header: 'Dismissed Collaborations',
        results: contracts.filter(c => c.status === 'rejected'),
        value: 'rejected',
        hideInitially: true
      },
      {
        header: 'Expired Proposals',
        results: contracts.filter(c => c.status === 'expired'),
        value: 'expired',
        hideInitially: true
      }
    ].map(section => {
      const numVisible = _.get(numVisiblePerGroup, [section.value], section.hideInitially ? 0 : INITIAL_NUM_VISIBLE);
      const setVisible = newNum => this.setState({ numVisiblePerGroup: { ...numVisiblePerGroup, [section.value]: newNum } });
      return {
        ...section,
        numVisible, // Default show three
        setVisible,
        showMore: () => setVisible(numVisible + NUM_PER_NEXT_PAGE)
      };
    });

    const showFilters =
      tags.length > 1 && // Has results
      (brands.length > 1 || selectedTags.length > 0);

    const controlsPanel = (
      <div ref={ref => (this.searchContainerEl = ref)} className='controls-panel-outer'>
        <div className='controls-panel'>
          <div className='search-input-container has-actions'>
            <input
              ref={ref => (this.searchEl = ref)}
              value={searchVal}
              className='title'
              placeholder={isBrand(user) ? 'Search Your Collaborations' : showAllPanel ? 'Search Your Collaborations' : 'Search For Brand Partners'}
              onChange={e => this.updateSearch(e.target.value)}
            />
            <InputActions searchVal={searchVal} showSearchIcon onCancel={() => this.updateSearch('')} />
          </div>
        </div>
        {showFilters && (
          <div className='filters'>
            {tags.map(tag => {
              const selected = this.isTagSelected(tag);
              const toggle = () => this.toggleTag(tag);
              return (
                <div onClick={toggle} key={tag.id} className={cn('filter', { selected })}>
                  {tag.value}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );

    return (
      <RequiresPermissions permission='canAnswerCollaborations'>
        {insertMetaTags({
          title: isBrand(user) ? 'Collaborate with Talent' : 'Collaborate with Brands',
          description: '',
          image: ''
        })}
        <div className='collaborations-outer-container'>
          <ScrollToTop />
          <div className={cn('collaborations-inner-container', { all: showAllPanel, new: showNewPanel })}>
            {isFirstFetch ? (
              <div className='loading-contract'>
                <Loader />
              </div>
            ) : (
              <>
                {isBrand(user)
                  ? !hasContracts && !isSearching && <CollaborationsExplainer user={user} />
                  : !hasTasks && <CollaborationsExplainer user={user} clickSearchBrands={this.scrollToSearchBrands} />}
                {tabs.length > 1 && (
                  <div className='tabs-container'>
                    <div className='tabs'>
                      {tabs.map(({ value, display }) => (
                        <div key={value} onClick={() => this.changeTab(value)} className={cn('tab', { active: curTab === value })}>
                          {display}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {showAllPanel && (
                  <>
                    {(hasContracts || isSearching) && controlsPanel}
                    {isFetching ? (
                      <div className='loading-contract'>
                        <Loader />
                      </div>
                    ) : (
                      <div className='collaborations'>
                        {contractSections.map(({ header, value, results, numVisible, setVisible, showMore }) => {
                          if (!results.length) return null;
                          const hasMore = results.length > numVisible;
                          const toggle = () => (numVisible ? setVisible(0) : setVisible(INITIAL_NUM_VISIBLE));
                          return (
                            <div key={value} className='collaborations-section'>
                              <div onClick={toggle} className='section-header'>
                                <div>{header}</div>
                                <div className={cn('toggle', { hide: numVisible, show: !numVisible })}>{numVisible ? 'HIDE' : 'SHOW'}</div>
                              </div>
                              <div className='section-body'>
                                {results.slice(0, numVisible).map(contract => (
                                  <ContractPreview
                                    syncExternal={() => {
                                      this.fetchDataWithoutLoader();
                                      this.props.syncCurrentUser();
                                    }}
                                    key={contract.id}
                                    contract={contract}
                                    user={user}
                                  />
                                ))}
                              </div>
                              {hasMore && !!numVisible && (
                                <div onClick={showMore} className='show-more'>
                                  Show More
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
                {showNewPanel && (
                  <>
                    {controlsPanel}
                    {isFetching ? (
                      <Loader />
                    ) : (
                      <div className='brand-partners'>
                        {brands.length ? (
                          brands.map(brand => (
                            <BrandPartner
                              key={brand.id}
                              user={this.props.user}
                              analytics={this.props.analytics}
                              brand={brand}
                              createContractWithBrand={this.props.createContractWithBrand}
                            />
                          ))
                        ) : (
                          <div className='empty-results-message-container'>
                            <div className='empty-results-message'>
                              <div className='empty-message'>
                                We do not have any brand partners that match the search <i>{searchVal}.</i>
                              </div>
                            </div>
                          </div>
                        )}
                        {isFetchingNewPage && <Loader />}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </RequiresPermissions>
    );
  }
}

Collaborations.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  syncCurrentUser: PropTypes.func.isRequired,
  createContractWithBrand: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics } = state;
  return { user, ui, analytics };
};

export default connect(mapStateToProps, {
  createContractWithBrand,
  syncCurrentUser
})(withRouter(Collaborations));
