import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './OnboardingPanelBio.scss';
import cn from 'classnames';

import { doesUserExist } from '../../../APIClient/users';

import UploadImage from '../../../Components/General/UploadImage';
import { getUserId, getName, getUsername, getBio, getImage, didUpgradeFromShopper } from '../../../Helpers/user_helpers';
import { matchScrollHeight } from '../../../Helpers/helpers';

let debounceSave;
const OnboardingPanelBio = props => {
  const { updateCurrentUser, user } = props;
  const [name, setName] = useState(getName(user) || '');
  const [username, setUsername] = useState(''); // Start empty so they fill it in
  const [usernameAlreadyExists, setUsernameAlreadyExists] = useState(false);
  const [description, setDescription] = useState(getBio(user) || '');
  const [image, setImage] = useState(getImage(user));

  const updateImage = newImage => {
    setImage(newImage);
    saveData({ image: newImage, name, username: username || getUsername(user), description });
  };

  const updateName = newName => {
    setName(newName);
    saveData({ image, name: newName, username: username || getUsername(user), description });
  };

  const userNameDebouncer = React.useRef(null);
  const updateUsername = newUsername => {
    clearTimeout(userNameDebouncer.current);
    setUsername(newUsername);
    userNameDebouncer.current = setTimeout(async () => {
      const resp = await doesUserExist({ username: newUsername });
      setUsernameAlreadyExists(resp.exists);
      if (!usernameAlreadyExists) {
        saveData({ image, name, username: newUsername || getUsername(user), description });
      }
    }, 500);
  };

  const updateDescription = newDescription => {
    setDescription(newDescription);
    saveData({ image, name, username: username || getUsername(user), description: newDescription });
  };

  const saveData = data => {
    clearTimeout(debounceSave);
    debounceSave = setTimeout(() => {
      updateCurrentUser(data, getUserId(user));
    }, 800);
  };

  return (
    <div className='onboarding-panel-bio-container'>
      <div className='onboarding-header'>Tell us about yourself</div>
      <div className='onboarding-subheader'>This information will appear on your public shop header.</div>
      <div className='profile-data-panel'>
        <div className='profile-image'>
          <UploadImage imagesOnly currentImage={image} hiddenHeader completeUpload={updateImage} />
        </div>
        <textarea type='text' rows='1' className='name' placeholder='Your Name' onChange={({ target }) => updateName(target.value)} value={name} />
        {didUpgradeFromShopper(user) && (
          <div className='input-container'>
            <textarea
              autoFocus
              type='text'
              rows='1'
              className={cn('username', { error: usernameAlreadyExists })}
              placeholder='Pick a Username'
              onChange={({ target }) => updateUsername(target.value)}
              value={username}
            />
            {usernameAlreadyExists && <div className='error-message'>Username already exists</div>}
          </div>
        )}
        <textarea
          type='text'
          rows='4'
          ref={ref => matchScrollHeight(ref)}
          className='description'
          placeholder='Tell your shop visitors a little bit about yourself (optional)'
          onChange={({ target }) => updateDescription(target.value)}
          value={description}
        />
      </div>
    </div>
  );
};

OnboardingPanelBio.propTypes = {
  user: PropTypes.object.isRequired,
  updateCurrentUser: PropTypes.func.isRequired
};

export default OnboardingPanelBio;
