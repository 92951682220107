import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';

import OutsideClickHandler from 'react-outside-click-handler';
import { sortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import {
  faTimes,
  faTrash,
  faEye,
  faCopy,
  faEyeSlash,
  faLevelDown,
  faBars,
  faArrowAltUp,
  faPencilAlt,
  faEllipsisH,
  faLink,
  faDollarSign
} from '@fortawesome/pro-solid-svg-icons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from 'lodash';

import './CollectionProduct.scss';
import '../Analytics/Analytics.scss';

import CollectionAnalytics from './CollectionAnalytics';
import CollectionProductCompact from './Skins/CollectionProductCompact';
import CollectionProductClassic from './Skins/CollectionProductClassic';
import CollectionProductGrid from './Skins/CollectionProductGrid';
import CollectionProductHorizontal from './Skins/CollectionProductHorizontal';
import CollectionProductLongform from './Skins/CollectionProductLongform';
import ProductHeart from '../Shop/Elements/ProductHeart';
import Tooltip from '../General/Tooltip';

// Need to import here for compatibility when pre-rendering
import './Skins/CollectionProductCompact.scss';
import './Skins/CollectionProductLongform.scss';
import './Skins/CollectionProductClassic.scss';
import './Skins/CollectionProductHorizontal.scss';
import './Skins/CollectionProductGrid.scss';

import { submitBonus } from '../../APIClient/admin';
import { deletePin as deletePinAPI, editPin as editPinAPI, duplicatePin as duplicatePinAPI } from '../../APIClient/pins';
import { addEvent } from '../../APIClient/events';
import { getUserId, isShopper, canEditCollection } from '../../Helpers/user_helpers';
import { copyToClipboard, getCollectionsTitle, dontBubble } from '../../Helpers/helpers';
import { getShortPinLink } from '../../Helpers/attribution_helpers';
import { getCodeForPin } from '../../Helpers/store_helpers';
import { getLinkToProductPageViaId } from '../../Helpers/shop_helpers';
import { getAffiliateLink } from '../../Helpers/attribution_helpers';

class CollectionProduct extends Component {
  static propTypes = {
    syncCollection: PropTypes.func.isRequired,
    analytics: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    adminControlMode: PropTypes.bool.isRequired,
    lastLocation: PropTypes.object,

    // For editing
    index: PropTypes.number,
    moveToTop: PropTypes.func,
    updatePin: PropTypes.func,
    toggleEditMode: PropTypes.func,
    showingActionsPanel: PropTypes.bool,
    setShowingActionsPanel: PropTypes.func,
    togglePinToMove: PropTypes.func,

    isBeingDeleted: PropTypes.bool,
    isBeingDuplicated: PropTypes.bool,
    setBeingDeleted: PropTypes.func,
    setBeingDuplicated: PropTypes.func,
    clearBeingDeleted: PropTypes.func,
    clearBeingDuplicated: PropTypes.func,

    // Set scroll reactor
    addScrollSubscriber: PropTypes.func,

    // Only if editable
    setAnalyticsMode: PropTypes.func,
    fetchingAnalytics: PropTypes.bool
  };

  componentDidMount() {
    this.editPinDebouncer = AwesomeDebouncePromise(async updates => {
      await editPinAPI(this.props.pin.id, updates);
    }, 600);
  }

  state = {
    showMoreActions: false
  };

  closeActionsPanel = e => {
    const isFromWithin = !!e?.target?.closest('.actions-panel');
    const isFromCloseIcon = !!e?.target?.closest('.close-icn-container');
    if (!isFromWithin || isFromCloseIcon) this.props.setShowingActionsPanel(false);
  };

  updateLinkRefAttributes = ref => {
    const { pin } = this.props;
    const source = _.get(pin.merchant_data, 'source');
    const domain = _.get(pin, 'link') || '';
    if (domain.includes('amazon') || source === 'cj' || source === 'skimlink') {
      ref.setAttribute('rel', 'nofollow noopener');
    }
  };

  navigateToProduct = async () => {
    const { pin, collection, user } = this.props;
    const { id, Collection_id, User_id } = pin;
    const canEdit = canEditCollection(collection, user);
    const collectionUser = collection.user;

    this.props.setAttributableCuratorId(User_id);

    !canEdit &&
      addEvent('PIN_VIEW', {
        eventUserId: getUserId(user),
        pinId: id,
        collectionId: Collection_id,
        userId: User_id,
        pinTitle: pin.title,
        pinLink: pin.selectedGeoLink || pin.link,
        collectionName: collection.name,
        shopUsername: collectionUser.username,
        shopName: collectionUser.name,
        isShortenedLink: false
      });
  };

  duplicateProduct = () => {
    this.props.setBeingDuplicated();
    this.closeActionsPanel();
    duplicatePinAPI(this.props.pin.id, { sortOrderRank: this.props.pin.sortOrderRank + 500 }).then(
      resp => {
        this.props.syncCollection();
        this.props.clearBeingDuplicated();
      },
      resp => {
        const error = _.get(resp, 'error');
        cogoToast.error(error || 'There was an issue duplicating your product.');
        this.props.clearBeingDuplicated();
      }
    );
  };

  deleteProduct = () => {
    const { pin } = this.props;
    const { isHidden, stats } = pin;
    const { orders, clicks } = stats || {};

    const confirmDelete = () => {
      this.props.setBeingDeleted();
      this.closeActionsPanel();
      deletePinAPI(this.props.pin.id).then(
        resp => {
          this.props.syncCollection();
          this.props.clearBeingDeleted();
        },
        resp => {
          const error = _.get(resp, 'error');
          cogoToast.error(error || 'There was an issue deleting your product.');
          this.props.clearBeingDeleted();
        }
      );
    };

    // If there are orders, check if they want to just hide it
    const shouldAskAboutDeletion = orders > 0 || clicks > 1;
    if (shouldAskAboutDeletion) {
      confirmAlert({
        title: 'Just Confirming',
        message: `This link has ${orders === 1 ? 'an order' : orders > 1 ? 'orders' : 'clicks'}. Are you sure you want to delete it? ${
          isHidden ? 'It is already hidden and therefore not visible to users.' : 'You can also just hide it.'
        }.`,
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => null
          },
          {
            label: 'Delete',
            className: isHidden ? 'primary' : 'cancel',
            onClick: confirmDelete
          },
          ...(!isHidden
            ? [
                {
                  label: 'Hide Product',
                  onClick: this.toggleVisibility
                }
              ]
            : [])
        ]
      });
    } else {
      confirmDelete();
    }
  };

  createBonus = () => {
    const { pin } = this.props;
    const amountResp = window.prompt('How large of a bonus would you like to offer?', '20');
    const amount = parseFloat(amountResp);
    if (_.isNaN(amount)) return amountResp && cogoToast.warn('Invalid bonus amount.');
    const title = window.prompt('Title of the payout?', 'ShopMy Bonus');
    if (!title) return;
    const Pin_id = window.prompt('What pin would you like to associate this bonus with? Leave this blank to add a general bonus.', pin.id);
    const confirm = window.confirm(
      `Please confirm you are sending $${amount} to User ${pin.User_id}${Pin_id ? ` for Pin ${Pin_id}` : ''} with title ${title}.`
    );
    if (!confirm) return;

    submitBonus({ Pin_id: Pin_id ? parseInt(Pin_id) : null, User_id: Pin_id ? null : pin.User_id, title, amount }).then(
      resp => cogoToast.success(`Successfully added bonus for $${amount.toFixed(2)}`),
      err => cogoToast.error(err || 'Failure. Please look at the console.')
    );
  };

  toggleVisibility = () => {
    this.props.updatePin(this.props.pin, { isHidden: !this.props.pin.isHidden });
    this.closeActionsPanel();
  };

  moveToTop = () => {
    this.props.moveToTop();
    this.closeActionsPanel();
  };

  updatePinAttributes = updates => this.editPinDebouncer(updates);

  toggleEditMode = () => {
    this.props.toggleEditMode(this.props.pin);
    this.closeActionsPanel();
  };

  toggleMove = () => {
    this.props.togglePinToMove(this.props.pin);
    this.closeActionsPanel();
  };

  copyLink = () => {
    const { pin } = this.props;
    copyToClipboard(getShortPinLink(pin));
    cogoToast.success(`Copied product link to clipboard`, { hideAfter: 1 });
    this.closeActionsPanel();
  };

  getActionsPanel = actionBtnDiv => {
    const { user, pin, adminControlMode, collection, showingActionsPanel, setShowingActionsPanel } = this.props;
    const { showMoreActions } = this.state;
    const { isHidden } = pin;
    return (
      <div className={cn('actions-panel-container', { visible: showingActionsPanel })}>
        {showingActionsPanel && (
          <OutsideClickHandler onOutsideClick={this.closeActionsPanel}>
            <div className={cn('actions-panel', collection.skinType)}>
              <div className='close-icn-container'>
                <FontAwesomeIcon onClick={this.closeActionsPanel} icon={faTimes} />
              </div>
              <div className='actions'>
                {!isShopper(user) && (
                  <div onClick={this.toggleEditMode} className='edit action'>
                    <FontAwesomeIcon icon={faPencilAlt} />
                    Edit
                  </div>
                )}
                <div onClick={this.deleteProduct} className='delete action'>
                  <FontAwesomeIcon icon={faTrash} />
                  Delete
                </div>
                {!isShopper(user) && (
                  <div onClick={this.copyLink} className='copy action'>
                    <FontAwesomeIcon icon={faLink} />
                    Copy Link
                  </div>
                )}
                {!isShopper(user) && (
                  <div onClick={this.duplicateProduct} className='duplicate action'>
                    <FontAwesomeIcon icon={faCopy} />
                    Duplicate
                  </div>
                )}
                {this.props.index > 0 && (
                  <div onClick={this.moveToTop} className='move-to-top action'>
                    <FontAwesomeIcon icon={faArrowAltUp} />
                    Move To {collection.skinType === 'horizontal' ? 'Front' : 'Top'}
                  </div>
                )}
                {adminControlMode && !isShopper(user) && (
                  <div onClick={this.createBonus} className='bonus action'>
                    <FontAwesomeIcon icon={faDollarSign} />
                    Give Bonus
                  </div>
                )}
                {(isHidden || showMoreActions) && (
                  <div onClick={this.toggleVisibility} className='visibility action'>
                    <FontAwesomeIcon icon={isHidden ? faEye : faEyeSlash} />
                    {isHidden ? 'Show' : 'Hide'}
                  </div>
                )}
                {showMoreActions || isShopper(user) ? (
                  <>
                    <div onClick={this.toggleMove} className='move action'>
                      <FontAwesomeIcon icon={faLevelDown} />
                      Change {getCollectionsTitle({ uppercase: true })}
                    </div>
                  </>
                ) : (
                  <div onClick={() => this.setState({ showMoreActions: true })} className='show-more-actions'>
                    MORE
                  </div>
                )}
              </div>
            </div>
          </OutsideClickHandler>
        )}
        <div className='toggle-action-panel-visibility-btn' onClick={() => setShowingActionsPanel(true)}>
          {actionBtnDiv || <FontAwesomeIcon icon={faEllipsisH} />}
        </div>
      </div>
    );
  };

  copyCode = () => {
    const { pin, collection } = this.props;
    const code = getCodeForPin(pin, collection);
    copyToClipboard(code?.displayText, 'Copied!');
    window.__ADD_EVENT__(`Collections - Copy Code`, { displayText: code?.displayText });
  };

  wrapInLink = (el, className) => {
    const { pin, collection, isShop, user } = this.props;
    const affiliateLink = getAffiliateLink(pin, user, collection.user, collection);
    const hasProductDetailPage = isShop && !!pin.Product_id;
    if (hasProductDetailPage) {
      return (
        <Link
          to={getLinkToProductPageViaId(pin.Product_id, { Pin_id: pin.id, User_id: pin.User_id })}
          className={className}
          onClick={this.navigateToProduct}
        >
          {el}
        </Link>
      );
    } else {
      return (
        <a
          ref={ref => ref && this.updateLinkRefAttributes(ref)}
          href={affiliateLink}
          target='_blank'
          rel='nofollow noopener noreferrer'
          className={className}
          onClick={this.navigateToProduct}
        >
          {el}
        </a>
      );
    }
  };

  getProductHeartContainer = () => {
    const { user, isShop, pin } = this.props;
    const hasProductDetailPage = isShop && pin?.Product_id;

    const cannotHeartMessage = 'This product has not yet been added to our product graph and cannot yet be favorited.';
    const clickCannotHeart = () => window.ALERT.warn(cannotHeartMessage);

    if (!isShop) return null;

    return (
      <div onClick={dontBubble} className='product-heart-container'>
        {hasProductDetailPage ? (
          <ProductHeart user={user} product={pin.product} attribution={{ Curator_id: pin.User_id }} />
        ) : (
          <Tooltip message={cannotHeartMessage}>
            <FontAwesomeIcon onClick={clickCannotHeart} className='disabled' icon={faHeart} />
          </Tooltip>
        )}
      </div>
    );
  };

  render() {
    const {
      pin,
      index,
      isBeingDuplicated,
      isBeingDeleted,
      collection,
      user,
      isShop,
      adminControlMode,
      showingActionsPanel,
      addScrollSubscriber,
      lastLocation,
      ui,
      analytics
    } = this.props;
    const { pinsBeingEdited } = ui;
    const canEdit = canEditCollection(collection, user);
    const DragHandle = canEdit
      ? sortableHandle(() => (
          <div className={cn('reorder-icn', { active: false })}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        ))
      : null;
    const analyticsOn = _.get(ui, 'inAnalyticsMode', false) === true; // Due to bug with redux setting it to {}
    const isBeingEdited = _.includes(pinsBeingEdited, pin.id);
    const code = getCodeForPin(pin, collection);
    const productHeartContainer = this.getProductHeartContainer();

    return (
      <div className={cn('collection-product-container', collection.skinType)} key={pin.id}>
        {collection.skinType === 'longform' ? (
          <CollectionProductLongform
            user={user}
            index={index}
            collection={collection}
            pin={pin}
            code={code}
            productHeartContainer={productHeartContainer}
            copyCode={this.copyCode}
            canEdit={canEdit}
            adminControlMode={adminControlMode}
            DragHandle={DragHandle}
            isDeleting={isBeingDeleted}
            isDuplicating={isBeingDuplicated}
            isBeingEdited={isBeingEdited}
            lastLocation={lastLocation}
            showingActionsPanel={showingActionsPanel}
            createBonus={this.createBonus}
            deleteProduct={this.deleteProduct}
            toggleVisibility={this.toggleVisibility}
            getActionsPanel={canEdit ? this.getActionsPanel : null}
            copyLink={this.copyLink}
            toggleEditMode={this.toggleEditMode}
            updatePinAttributes={this.updatePinAttributes}
            wrapInLink={this.wrapInLink}
          />
        ) : collection.skinType === 'horizontal' ? (
          <CollectionProductHorizontal
            user={user}
            index={index}
            collection={collection}
            pin={pin}
            code={code}
            copyCode={this.copyCode}
            productHeartContainer={productHeartContainer}
            canEdit={canEdit}
            DragHandle={DragHandle}
            isDeleting={isBeingDeleted}
            isDuplicating={isBeingDuplicated}
            isBeingEdited={isBeingEdited}
            lastLocation={lastLocation}
            addScrollSubscriber={addScrollSubscriber}
            showingActionsPanel={showingActionsPanel}
            updateLinkRefAttributes={this.updateLinkRefAttributes}
            getActionsPanel={canEdit ? this.getActionsPanel : null}
            copyLink={this.copyLink}
            toggleEditMode={this.toggleEditMode}
            navigateToProduct={this.navigateToProduct}
            updatePinAttributes={this.updatePinAttributes}
          />
        ) : collection.skinType === 'compact' ? (
          <>
            <CollectionProductCompact
              user={user}
              index={index}
              collection={collection}
              pin={pin}
              code={code}
              productHeartContainer={productHeartContainer}
              copyCode={this.copyCode}
              canEdit={canEdit}
              DragHandle={DragHandle}
              isDeleting={isBeingDeleted}
              isDuplicating={isBeingDuplicated}
              isBeingEdited={isBeingEdited}
              lastLocation={lastLocation}
              showingActionsPanel={showingActionsPanel}
              getActionsPanel={canEdit ? this.getActionsPanel : null}
              copyLink={this.copyLink}
              toggleEditMode={this.toggleEditMode}
              wrapInLink={this.wrapInLink}
              isMobileOnly
            />
            <CollectionProductClassic
              user={user}
              index={index}
              collection={collection}
              pin={pin}
              code={code}
              copyCode={this.copyCode}
              canEdit={canEdit}
              DragHandle={DragHandle}
              getActionsPanel={canEdit ? this.getActionsPanel : null}
              isDeleting={isBeingDeleted}
              isDuplicating={isBeingDuplicated}
              isBeingEdited={isBeingEdited}
              lastLocation={lastLocation}
              showingActionsPanel={showingActionsPanel}
              wrapInLink={this.wrapInLink}
              copyLink={this.copyLink}
              toggleEditMode={this.toggleEditMode}
              productHeartContainer={productHeartContainer}
              isDesktopOnly
            />
          </>
        ) : collection.skinType === 'grid' || collection.skinType === 'gallery' ? (
          <CollectionProductGrid
            user={user}
            index={index}
            isGallery={collection.skinType === 'gallery'}
            collection={collection}
            pin={pin}
            code={code}
            isShop={isShop}
            copyCode={this.copyCode}
            canEdit={canEdit}
            DragHandle={DragHandle}
            getActionsPanel={canEdit ? this.getActionsPanel : null}
            isDeleting={isBeingDeleted}
            isDuplicating={isBeingDuplicated}
            isBeingEdited={isBeingEdited}
            lastLocation={lastLocation}
            showingActionsPanel={showingActionsPanel}
            copyLink={this.copyLink}
            toggleEditMode={this.toggleEditMode}
            productHeartContainer={productHeartContainer}
            wrapInLink={this.wrapInLink}
          />
        ) : (
          <CollectionProductClassic
            user={user}
            index={index}
            collection={collection}
            pin={pin}
            code={code}
            isShop={isShop}
            copyCode={this.copyCode}
            canEdit={canEdit}
            DragHandle={DragHandle}
            getActionsPanel={canEdit ? this.getActionsPanel : null}
            isDeleting={isBeingDeleted}
            isDuplicating={isBeingDuplicated}
            isBeingEdited={isBeingEdited}
            lastLocation={lastLocation}
            showingActionsPanel={showingActionsPanel}
            copyLink={this.copyLink}
            toggleEditMode={this.toggleEditMode}
            productHeartContainer={productHeartContainer}
            wrapInLink={this.wrapInLink}
          />
        )}
        {canEdit && analyticsOn && (
          <CollectionAnalytics
            user={user}
            collection={collection}
            analytics={analytics}
            fetchingAnalytics={this.props.fetchingAnalytics}
            setAnalyticsMode={this.props.setAnalyticsMode}
            pin={pin}
            adminControlMode={adminControlMode}
          />
        )}
      </div>
    );
  }
}

export default withRouter(CollectionProduct);
