import React from 'react';
import PropTypes from 'prop-types';
import './PublicShopSettingsPanel.scss';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';

import CheckboxButton from '../../../General/Buttons/CheckboxButton';

import { getSettings, getUserTier, isAdmin } from '../../../../Helpers/user_helpers';

const PublicShopSettingsPanel = props => {
  const { user } = props;
  const { isHiddenFromPublicShop, productsVisibleInShop } = getSettings(user) || {};

  const toggleUserSetting = async (setting, value) => {
    await props.updateUserSettings({ [setting]: value });
    await props.syncCurrentUser();
  };

  let sections = [];

  /*
    Searchable in ShopMy.

    Specify whether the creator wants to show up on the publicly searchable ShopMy website.
  */
  const canEnableSearch = getUserTier(user).bestTier <= 2 || isAdmin(user);

  sections.push({
    title: 'Public Shop Configuration',
    subtitle: <>Specify how you want to show up on the publicly searchable ShopMy website.</>,
    isHighlighted: window.location.hash === '#publicshopconfig',
    options: [
      {
        title: 'Findable Via Search',
        isDisabled: !canEnableSearch,
        value: canEnableSearch && !isHiddenFromPublicShop,
        onChange: () => {
          if (!canEnableSearch) return window.ALERT.warn(`You must reach the trendsetter status to be findable via search.`);

          toggleUserSetting('isHiddenFromPublicShop', !isHiddenFromPublicShop);
          if (!isHiddenFromPublicShop) window.ALERT.info('Your shop will no longer be findable via search within 24 hours.');
          else window.ALERT.success('Your shop will be findable via search within 24 hours');
        }
      }
    ]
  });

  /*
    Visible Products.

    Specify which products you want to show up on your "Popular" and "Latest" tabs.

      Option 1: All Products
      Option 2: Only Products I've Added to Visible Collections
  */
  const confirmAndCommitVisibilityChange = newValue => {
    confirmAlert({
      title: 'Just confirming.',
      message:
        newValue === 'all'
          ? `This will permanently reset any visibility changes you've made to your products and will show all links on your public shop.`
          : newValue === 'collections-and-popular'
          ? `This will show products that are in visible collections and quick links that have driven over 10 clicks. Quick links who reach the 10 click threshold may take up to 24 hours to show up.`
          : `This will hide any products that are not in visible collections.`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Make Change',
          onClick: () => {
            toggleUserSetting('productsVisibleInShop', newValue);
            if (newValue === 'collections') window.ALERT.success('Successfully hid products that are not in visible collections.');
            if (newValue === 'collections-and-popular')
              window.ALERT.success('Successfully hid products that are not in collections or do not reach the 10 click threshold.');
            else if (newValue === 'all') window.ALERT.success('All links that are connected to popular products are now visible.');
          }
        }
      ]
    });
  };

  sections.push({
    title: 'Visible Products',
    subtitle: <>Specify which products you want to show up on your "Popular" and "Latest" tabs.</>,
    isHighlighted: window.location.hash === '#visibleproducts',
    options: [
      {
        title: 'Products in Visible Collections and Popular Quick Links',
        tooltip:
          'This option will show products that are in collections and quick links that have driven over 10 clicks. Quick link visibility may take up to 24 hours to show up.',
        value: productsVisibleInShop === 'collections-and-popular',
        onChange: () => confirmAndCommitVisibilityChange('collections-and-popular')
      },
      {
        title: 'Only products in Visible Collections',
        value: productsVisibleInShop === 'collections',
        onChange: () => confirmAndCommitVisibilityChange('collections')
      },
      {
        title: 'All Product Links',
        value: productsVisibleInShop === 'all',
        onChange: () => confirmAndCommitVisibilityChange('all')
      }
    ]
  });

  return (
    <div className='location-data-panel-outer'>
      <div className='location-data-panel-inner'>
        {sections.map(section => {
          const { title, subtitle, isHighlighted, options } = section;

          return (
            <div className='settings-section' key={`location-panel-${title}`}>
              {title && (
                <div
                  className={cn('settings-section-title', {
                    highlighted: isHighlighted
                  })}
                >
                  {title}
                </div>
              )}
              {subtitle && <div className='settings-section-subtitle'>{subtitle}</div>}

              <div className='settings-section-list'>
                {options.map(option => {
                  const { title, userSettingsVariable, value } = option;
                  return (
                    <CheckboxButton
                      key={`location-panel-${userSettingsVariable}`}
                      isChecked={value}
                      isDisabled={option.isDisabled}
                      onChange={option.onChange}
                      text={title}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PublicShopSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  updateUserSettings: PropTypes.func.isRequired
};

export default PublicShopSettingsPanel;
