import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faPencilAlt, faLink, faTag } from '@fortawesome/pro-solid-svg-icons';

import Loader from '../../Loader/Loader';
import { getSmartImage, dismissEvent } from '../../../Helpers/helpers';
import { isShopper } from '../../../Helpers/user_helpers';
import { getPrettyPriceDisplay } from '../../../Helpers/formatting';

const CollectionProductClassic = props => {
  const {
    collection,
    pin,
    code,
    copyCode,
    lastLocation,
    canEdit,
    DragHandle,
    getActionsPanel,
    showingActionsPanel,
    navigateToProduct,
    copyLink,
    toggleEditMode,
    isGallery
  } = props;
  const { title, description, image, isHidden } = pin;

  const [viewingDescription, setViewingDescription] = useState(false);

  const hasDescription = description && description.length > 0;

  return (
    <div className={cn('collection-product', collection.skinType, { hidden: isHidden, 'hide-under-sm': props.isDesktopOnly })}>
      {props.wrapInLink(
        <>
          {!!isHidden && (
            <div className='hidden-cover'>
              <div className='badge'>HIDDEN</div>
            </div>
          )}
          <LazyLoad height={200} offset={window.__IS_SNAP__ ? 2000 : 480}>
            <img
              alt={title || 'Product'}
              onClick={navigateToProduct}
              className={cn('product-image', {
                empty: !image,
                'is-gallery': props.isGallery
              })}
              src={getSmartImage(image)}
            />
          </LazyLoad>
        </>,
        cn('collection-product-img-wrapper', {
          smooth: !canEdit && !!lastLocation,
          'is-gallery': isGallery
        })
      )}
      {canEdit && (
        <div className='action-btn-container'>
          {!showingActionsPanel && !isShopper(props.user) && <FontAwesomeIcon className='hide-on-mobile' onClick={copyLink} icon={faLink} />}
          {!showingActionsPanel && !isShopper(props.user) && (
            <FontAwesomeIcon className='hide-on-mobile' onClick={toggleEditMode} icon={faPencilAlt} />
          )}
          {getActionsPanel()}
        </div>
      )}
      {!props.isGallery && (
        <div className='meta-container'>
          {props.wrapInLink(
            <>
              <h3 className='header'>{title}</h3>
              {code && !!pin.allow_displaying_codes && (
                <div onClick={dismissEvent} className='code-container'>
                  <FontAwesomeIcon onClick={copyCode} icon={faTag} />
                  <div onClick={copyCode} className='code'>
                    USE CODE <span className='display'>{code.displayText}</span> FOR{' '}
                    {code.isPercentage ? `${code.rate}% OFF` : `${getPrettyPriceDisplay(code.rate)} OFF`}
                  </div>
                </div>
              )}
            </>,
            'header-container'
          )}
          {props.wrapInLink(<>SHOP NOW</>, 'link-to-product-btn')}
        </div>
      )}
      {(props.isDeleting || props.isBeingEdited || props.isDuplicating) && (
        <div className='adjusting-product-loader active'>
          <Loader size={100} />
        </div>
      )}
      {!viewingDescription && (
        <div className={cn('show-description-icn', { active: hasDescription })}>
          <FontAwesomeIcon
            onClick={e => {
              e.stopPropagation();
              hasDescription && setViewingDescription(true);
            }}
            icon={faInfoCircle}
          />
        </div>
      )}
      {canEdit && <DragHandle />}
      <div
        className={cn('product-description-overlay', {
          visible: viewingDescription,
          long: description && description.length > 240
        })}
      >
        {description}
        <FontAwesomeIcon className='close-icon' icon={faTimes} onClick={() => setViewingDescription(false)} />
      </div>
      {props.productHeartContainer}
    </div>
  );
};

CollectionProductClassic.propTypes = {
  // Main Data
  user: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  pin: PropTypes.object.isRequired,
  lastLocation: PropTypes.object,

  // Variables
  index: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  isBeingEdited: PropTypes.bool,
  showingActionsPanel: PropTypes.bool,

  // Functions
  wrapInLink: PropTypes.func.isRequired,
  copyLink: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,

  // UI
  isGallery: PropTypes.bool,

  // External Divs
  getActionsPanel: PropTypes.func,
  DragHandle: PropTypes.func
};

export default CollectionProductClassic;
