import React from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';

const ProductMetaTags = props => {
  const { product } = props;
  const { brand } = product;

  const title = `${brand?.name} | ${product.title}`;
  const ogTitle = title;

  const description = product.description;
  const ogDescription = description;

  const ogImage = product.images?.[0]?.url;

  // JSON-LD Schema
  const productSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: title,
    image: ogImage,
    description: description,
    brand: {
      '@type': 'Brand',
      name: brand?.name || 'Unknown Brand'
    }
    // sku: sku || 'N/A',
    // offers: {
    //   '@type': 'Offer',
    //   priceCurrency: currency || 'USD',
    //   price: price || '0.00',
    //   availability: availability === 'in_stock' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
    //   seller: {
    //     '@type': 'Organization',
    //     name: brand?.name || 'Unknown Seller'
    //   }
    // },
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: rating || '4.5',
    //   reviewCount: reviewCount || '10'
    // }
  };

  return (
    <MetaTags>
      {title && <title>{title}</title>}
      {ogTitle && <meta property='og:title' content={ogTitle} />}
      {description && <meta property='description' content={description} />}
      {ogDescription && <meta property='og:description' content={ogDescription} />}
      {ogImage && <meta property='og:image' content={ogImage} />}
      <script type='application/ld+json'>{JSON.stringify(productSchema)}</script>
    </MetaTags>
  );
};

ProductMetaTags.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductMetaTags;
