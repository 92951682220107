import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronsDown, faChevronUp, faChevronsUp, faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { faStar, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import commaNumber from 'comma-number';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityLeaderboardCard.scss';

import CostReasoningOverlay from './CostReasoningOverlay';
import CustomTooltip from '../../General/CustomTooltip';

import {
  getSpendDataOnOpportunityRequest,
  getDisplayStatsOnOpportunityResult,
  getOpportunityRequestAdjustedExpectations
} from '../../../Helpers/opportunity_helpers';
import { getPrettyNumber, getInitialsForUser } from '../../../Helpers/formatting';

import OpportunityExpectations from './OpportunityExpectations';

const OpportunityLeaderboardCard = props => {
  const { request, opportunity, sortFn, rank, isShowingShopMyIntelligence } = props;
  const { id, result } = request;
  const stats = result?.stats ? JSON.parse(result.stats) : {};
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    request
  );
  const hasResult = !!result?.id;

  // Allow toggling
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Get General Data
  const spendData = getSpendDataOnOpportunityRequest(request, opportunity);
  const recommended_cost_data = opportunity?.recommended_cost_data_per_user_id?.[request.user.id];
  const user_stats = opportunity?.user_stats_per_user_id?.[request.user.id];

  // Spend Data relative to engine
  const diffFromRecommendedCost = recommended_cost_data?.cost - spendData.total;
  const overpayRatio = diffFromRecommendedCost / spendData.total;
  const didUnderpay = overpayRatio > 0.15 && overpayRatio < 0.5;
  const didUnderpaySignificantly = overpayRatio >= 0.5;
  const didOverpay = overpayRatio < -0.15 && overpayRatio > -0.5;
  const didOverpaySignificantly = overpayRatio <= -0.5;
  const fairlyPaid = !didUnderpay && !didOverpay && !didUnderpaySignificantly && !didOverpaySignificantly;

  // Get Progress Steps
  let progressSteps = [];
  if (linksExpected) {
    progressSteps.push({
      label: linksExpected === 1 ? 'link' : 'links',
      numCompleted: stats.links + stats.missingLinks,
      numExpected: linksExpected
    });
  }
  if (linkingDaysExpected) {
    progressSteps.push({
      label: linkingDaysExpected === 1 ? 'day with link' : 'days with links',
      numCompleted: stats.linkingDays + stats.missingLinkingDays,
      numExpected: linkingDaysExpected
    });
  }
  if (mentionsExpected) {
    progressSteps.push({
      label: mentionsExpected === 1 ? 'mention' : 'mentions',
      numCompleted: stats.mentions + stats.missingMentions,
      numExpected: mentionsExpected
    });
  }
  if (mentionDaysExpected) {
    progressSteps.push({
      label: mentionDaysExpected === 1 ? 'day with mention' : 'days with mentions',
      numCompleted: stats.mentionDays + stats.missingMentionDays,
      numExpected: mentionDaysExpected
    });
  }

  // Get stats
  let resultStats = getDisplayStatsOnOpportunityResult(result, opportunity);

  // Specify which is the sort, or add it if it's not there
  resultStats = resultStats.map(resultStat => ({
    ...resultStat,
    isSort: resultStat.label === sortFn.display
  }));
  sortFn &&
    !resultStats.find(stat => stat.isSort) &&
    resultStats.push({
      label: sortFn.display,
      display: !hasResult ? null : sortFn.getValueDisplay(request),
      value: !hasResult ? null : sortFn.getValue(request),
      isSort: true
    });

  // Augment with tooltipEl if needed
  if (window.__ADMIN_CONTROL_MODE__ && user_stats) {
    const totalLinksExpected = opportunity.linksExpected + opportunity.linkingDaysExpected;
    const totalLinksPosted = stats.links;
    resultStats = resultStats.map(resultStat => {
      let tooltipMsg, performanceLevel;

      const getPerformanceLevel = (value, average) => {
        const ratioToStandard = value / average;
        if (ratioToStandard > 2.5) return 'highest';
        else if (ratioToStandard > 1.5) return 'high';
        else if (ratioToStandard < 0.4) return 'lowest';
        else if (ratioToStandard < 0.7) return 'low';
        return 'normal';
      };
      const isHighPerformance = level => ['highest', 'high'].includes(level);
      const isLowPerformance = level => ['lowest', 'low'].includes(level);

      // Clicks
      if (resultStat.label === 'Clicks' && totalLinksExpected > 0) {
        const averageClicksLast90Base = user_stats.opportunitiesClicksPerLinkLast90 * totalLinksExpected;
        const averageClicksLast90Posted = user_stats.opportunitiesClicksPerLinkLast90 * totalLinksPosted;
        const isTooSignificantOfADeviationFromClicksExpected = totalLinksPosted / totalLinksExpected >= 2;

        // Performance
        performanceLevel = averageClicksLast90Posted ? getPerformanceLevel(resultStat.value, averageClicksLast90Posted) : null;

        // Tooltip
        tooltipMsg = averageClicksLast90Base
          ? `Typically drives ${commaNumber(averageClicksLast90Base.toFixed(0))} clicks for ${totalLinksExpected} link${
              averageClicksLast90Base === 1 ? '' : 's'
            }${
              totalLinksExpected !== totalLinksPosted
                ? `\n\nShould expect ${commaNumber(averageClicksLast90Posted.toFixed(0))} for ${totalLinksPosted} the click${
                    totalLinksPosted === 1 ? '' : 's'
                  } they posted.`
                : ''
            }\n\nDrove ${resultStat.display} on this one.${
              isTooSignificantOfADeviationFromClicksExpected
                ? '\n\nToo significant of a deviation from the expected number of clicks to measure Audience Fit.'
                : isHighPerformance(performanceLevel)
                ? `\n\nThis may indicate a Strong Fit with their audience.`
                : isLowPerformance(performanceLevel)
                ? `\n\nThis may indicate a Weak Fit with their audience.`
                : ''
            }`
          : 'No recent click data.';
      }

      // Volume
      if (resultStat.label === 'Volume ROI') {
        const numOpps = user_stats.opportunitiesCompletedLast90;
        const averageRoiLast90 = user_stats.opportunitiesROIPerLinkLast90;

        // Performance
        performanceLevel = getPerformanceLevel(resultStat.value, averageRoiLast90);

        // Tooltip
        tooltipMsg = averageRoiLast90
          ? `Drove an average of ${averageRoiLast90?.toFixed(2)}x ROI over last ${numOpps} ${
              numOpps === 1 ? 'opportunity' : 'opportunities'
            }.\n\nDrove ${resultStat.display} on this one.`
          : 'No recent ROI data.';
      }
      return {
        ...resultStat,
        tooltipMsg,
        performanceLevel
      };
    });
  }
  const openProfile = e => {
    e.stopPropagation();
    props.openArtistModal(request.user);
  };

  // Ranking
  const isTopThree = rank <= 3;
  const isFirst = rank === 1;
  const st = isFirst ? 'st' : rank === 2 ? 'nd' : rank === 3 ? 'rd' : 'th';
  const rankSt = `${rank}${st}`;

  const additionalClasses = { [`rank-${props.rank}`]: true };
  return (
    <div key={id} className={cn('opportunity-leaderboard-card', additionalClasses)}>
      <div onClick={toggleExpanded} className='collapsed-card'>
        <div className={cn('ranking-status', additionalClasses)}>
          {isTopThree ? (
            <div className='rank'>
              <span className='number'>{props.rank}</span>
              <span className='st'>{st}</span>
            </div>
          ) : (
            <div className='rank'>{props.rank}</div>
          )}
          <div className={cn('user-image-container', additionalClasses)}>
            <div className='image-badge-wrapper' />
            {request.user.image ? (
              <img onClick={openProfile} src={request.user.image} alt={request.user.name} />
            ) : (
              <div className='empty-image'>
                <div className='initials'>{getInitialsForUser(request.user)}</div>
              </div>
            )}
            {isTopThree && (
              <div className={cn('ribbon-container', additionalClasses)}>
                <div className={cn('ribbon-outer', additionalClasses)}>
                  <div className={cn('ribbon-inner', additionalClasses)}>
                    {isFirst && <FontAwesomeIcon icon={faStar} />}
                    <span className='label'>{rankSt} place</span>
                    {isFirst && <FontAwesomeIcon icon={faStar} />}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={cn('main-container', additionalClasses)}>
          <div className='section user-info'>
            <div className='section-data'>
              <div onClick={openProfile} className='name'>
                {request.user.name}
              </div>
              {!!progressSteps.length && (
                <div className='progress-steps'>
                  {progressSteps.map(step => {
                    const { numExpected, label, numCompleted } = step;
                    const isComplete = numCompleted >= numExpected;
                    return (
                      <div key={label} className={cn('progress-step', { complete: isComplete })}>
                        {isComplete && <FontAwesomeIcon icon={faBadgeCheck} />}
                        <div>
                          {_.min([numCompleted, numExpected])} of {numExpected} {label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className='section spend'>
            <div className='section-stats'>
              <div className='section-stat'>
                {spendData.locked ? <div className='value'>${getPrettyNumber(spendData.locked)}</div> : <div className='value empty'>-</div>}
                <div className={cn('label', { empty: !spendData.total })}>{spendData.status}</div>{' '}
                {!!spendData.locked && recommended_cost_data && isShowingShopMyIntelligence && (
                  <div
                    className={cn('ai-result', {
                      highest: didUnderpaySignificantly,
                      high: didUnderpay,
                      low: didOverpay,
                      lowest: didOverpaySignificantly,
                      normal: fairlyPaid
                    })}
                  >
                    <CustomTooltip tooltipEl={<CostReasoningOverlay reasoning={recommended_cost_data.reasoning} />}>
                      <div className='ai-label'>
                        {!fairlyPaid && (
                          <FontAwesomeIcon
                            icon={
                              didOverpaySignificantly
                                ? faChevronsDown
                                : didOverpay
                                ? faChevronDown
                                : didUnderpaySignificantly
                                ? faChevronsUp
                                : faChevronUp
                            }
                          />
                        )}
                        {didOverpaySignificantly || didOverpay
                          ? `Overpaid ${Math.abs(100 * overpayRatio).toFixed(0)}%`
                          : didUnderpaySignificantly || didUnderpay
                          ? `Underpaid ${Math.abs(100 * overpayRatio).toFixed(0)}%`
                          : 'Paid Fairly'}
                      </div>
                    </CustomTooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='section results'>
            <div className='section-stats'>
              {resultStats.map(({ label, value, display, tooltipMsg, performanceLevel, isSort }) => {
                const additionalClasses = { featured: isSort, empty: !value };

                const performanceIcon = {
                  highest: faChevronsUp,
                  high: faChevronUp,
                  low: faChevronDown,
                  lowest: faChevronsDown
                }[performanceLevel];
                const performanceLabel = {
                  highest: 'Very High',
                  high: 'Above Average',
                  low: 'Below Average',
                  lowest: 'Very Low',
                  normal: 'Expected'
                }[performanceLevel];

                return (
                  <div key={label} className={cn('section-stat', additionalClasses)}>
                    {isSort && <div className='background-highlight' />}
                    {tooltipMsg ? (
                      <CustomTooltip delayBeforeOpening={50} tooltipEl={<div className='debug-tooltip'>{tooltipMsg}</div>}>
                        <div className={cn('value', additionalClasses)}>
                          {value ? display : '-'}
                          {window.__ADMIN_CONTROL_MODE__ && performanceLevel && (
                            <div className={cn('performance-icon show-on-card-hover', performanceLevel)}>
                              <FontAwesomeIcon
                                icon={
                                  {
                                    highest: faChevronsUp,
                                    high: faChevronUp,
                                    low: faChevronDown,
                                    lowest: faChevronsDown,
                                    normal: faGripLines
                                  }[performanceLevel]
                                }
                              />
                            </div>
                          )}
                        </div>
                      </CustomTooltip>
                    ) : (
                      <div className={cn('value', additionalClasses)}>{value ? display : '-'}</div>
                    )}
                    <div className={cn('label', additionalClasses)}>{label}</div>
                    {tooltipMsg && !!value && isShowingShopMyIntelligence && performanceLevel && (
                      <div className={cn('ai-result', performanceLevel)}>
                        <CustomTooltip delayBeforeOpening={50} tooltipEl={<div className='debug-tooltip'>{tooltipMsg}</div>}>
                          <div className={cn('ai-label', performanceLevel)}>
                            {performanceIcon && <FontAwesomeIcon icon={performanceIcon} />}
                            {performanceLabel}
                          </div>
                        </CustomTooltip>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {isExpanded ? (
        <div className='expanded-card'>
          <OpportunityExpectations opportunity={opportunity} request={request} />
        </div>
      ) : (
        <div className='toggle-expanded-overlay'>
          <div>View Expectations</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
    </div>
  );
};

OpportunityLeaderboardCard.propTypes = {
  sortFn: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired,
  isShowingShopMyIntelligence: PropTypes.bool
};

export default OpportunityLeaderboardCard;
