import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './ShopCollectionResults.scss';

import { focusShopSearch } from '../../Actions/UIActions';

import ShopCollectionResult from './Elements/ShopCollectionResult';
import Loader from '../Loader/Loader';
import ShopEmptyResults from '../Curators/ShopEmptyResults';

import { getSections, isShopper } from '../../Helpers/user_helpers';

const ShopCollectionResults = props => {
  const { isFetchingResults, isFetchingFirstPage, page, hadErrorResults, hasMoreResults, Section_id } = props;

  // Establish Infinite Scroll Observer
  const infiniteScrollElementRef = React.useRef(null);
  React.useEffect(() => {
    // If we're already fetching results, don't set up the observer
    if (isFetchingResults || !hasMoreResults || hadErrorResults) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        entry.isIntersecting && entry.isIntersecting && props.setPage(props.page + 1);
      },
      { root: null, rootMargin: '0px', threshold: 0.5 } // 0.5 means when half of the element is visible
    );
    const bottomElement = infiniteScrollElementRef.current;
    if (bottomElement) observer.observe(bottomElement);
    return () => {
      bottomElement && observer.unobserve(bottomElement);
    };
  }, [isFetchingResults, page, hasMoreResults, hadErrorResults]);

  // Filter the collections to only show the ones in the current section in case of transition
  const currentlyActiveSection =
    (props.isYourShop ? getSections(props.user) : props.sectionResults).find(section => section.id === Section_id) || props.sectionResults[0];
  const currentSectionPreviewType = props.sectionType || currentlyActiveSection?.previewType || 'shelves';

  // Break out the results by column
  const numColumns = currentSectionPreviewType?.includes('post') ? 3 : 1;
  let columns = _.times(numColumns, () => []);
  const columnChunks = _.chunk(props.collectionResults, numColumns);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0].push(chunk[0]);
    chunk[1] && columns[1].push(chunk[1]);
    chunk[2] && columns[2].push(chunk[2]);
  });

  // Is Empty in general
  const numCollectionsWithPins = props.collectionResults.filter(collection => collection.total_num_pins).length;
  const showEmptyShopResults = !isFetchingResults && !numCollectionsWithPins && props.isYourShop;

  // Empty and yours
  const showEmptySectionResultsForYou = currentlyActiveSection && showEmptyShopResults && props.isYourShop && !isShopper(props.user);

  return (
    <div className={cn('shop-collection-results-outer-container', currentSectionPreviewType)}>
      <div className='shop-collection-results-inner-container'>
        {props.hadErrorResults && (
          <div className='error-message'>
            <div className='error-message-text'>There was an error fetching collections. Please reload the page to try again.</div>
          </div>
        )}
        {isFetchingFirstPage ? (
          <Loader size={64} />
        ) : showEmptySectionResultsForYou ? (
          <ShopEmptyResults shop={props.shop} user={props.user} isYourEmptySection />
        ) : showEmptyShopResults ? (
          <ShopEmptyResults shop={props.shop} clearAllCurators={props.clearAllCurators} isYourEmptyShop focusShopSearch={props.focusShopSearch} />
        ) : (
          <div className={cn('shop-collection-columns', `columns-${numColumns}`)}>
            {columns.map((column, columnIdx) => {
              return (
                <div key={columnIdx} className='shop-collection-column'>
                  {column.map((collection, idx) => {
                    if (!collection.total_num_pins) return null;
                    return (
                      <ShopCollectionResult
                        key={collection.id}
                        columnIdx={columnIdx}
                        user={props.user}
                        shop={props.shop}
                        collection={collection}
                        previewType={currentSectionPreviewType}
                      />
                    );
                  })}
                  {columnIdx === 0 && <div ref={infiniteScrollElementRef} className='observer-trigger' />}
                  {isFetchingResults && !isFetchingFirstPage && (
                    <div className={cn('load-next-page-container', currentSectionPreviewType)}>
                      <Loader size={64} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

ShopCollectionResults.propTypes = {
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  focusShopSearch: PropTypes.func.isRequired,

  // Outside
  isFetchingFirstPage: PropTypes.bool.isRequired,
  isFetchingResults: PropTypes.bool.isRequired,
  collectionResults: PropTypes.array.isRequired,
  sectionResults: PropTypes.array.isRequired,
  Section_id: PropTypes.number
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  focusShopSearch
})(ShopCollectionResults);
