import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrid2, faHeart } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './ShopEmptyResults.scss';

import { getActiveCurator, getActiveCuratorGroup } from '../../Helpers/shop_helpers';
import { getUsername } from '../../Helpers/user_helpers';

const ShopEmptyResults = props => {
  let header,
    subheader,
    badgeIcon,
    actions = [];

  if (props.isMyCuratorGroups) {
    header = `You don't have any shopping circles`;
    subheader = 'Circles enable you to choose who curates your shopping experience. Create your own or search and heart others.';
    badgeIcon = faGrid2;

    actions = [
      {
        text: 'Create My First Circle',
        // icon: faPlus,
        extraClasses: 'primary',
        onClick: props.createGroup
      },
      {
        text: 'Discover Circles',
        extraClasses: 'secondary',
        to: '/shop/circles'
      }
    ];
  } else if (props.isFavoritedCuratorGroups) {
    header = 'No favorited circles.';
    subheader = 'You can favorite circles to easily access them later. To favorite, click the heart icons.';
    badgeIcon = faHeart;
    actions = [
      {
        text: 'Discover Circles',
        extraClasses: 'secondary',
        to: '/shop/circles'
      }
    ];
  } else if (props.isMyCuratorGroupPage) {
    header = `Add Curators`;
    subheader =
      'You haven’t added any curators to this circle yet. Search for curators to add to this circle. To add a curator, click the heart icons.';
    badgeIcon = faHeart;

    actions = [
      {
        text: 'Search For Specific Curators',
        extraClasses: 'primary',
        onClick: () => props.focusShopSearch()
      },
      {
        text: 'Discover New Curators',
        extraClasses: 'secondary',
        to: '/shop/circles'
      }
    ];
  } else if (props.isSelectingCuratorGroups) {
    header = `You don't have any circles`;
    subheader = 'Create a circle or explore pre-made circles to get started.';
    badgeIcon = faGrid2;

    actions = [
      {
        text: 'Create My First Circle',
        extraClasses: 'primary',
        onClick: props.createGroup
      },
      {
        text: 'Find Curators',
        extraClasses: 'secondary',
        to: '/shop/circles',
        onClick: props.closeModal
      },
      {
        text: 'Search Top Products',
        extraClasses: 'secondary',
        to: '/shop',
        onClick: props.closeModal
      }
    ];
  } else if (props.isEmptyBrandShop) {
    const activeCurator = getActiveCurator(props.shop);
    const activeCuratorGroup = getActiveCuratorGroup(props.shop);
    const isFiltered = !!activeCurator || !!activeCuratorGroup;
    header = `No results found for ${props.brand?.name}`;
    subheader = `${props.brand?.name} has not yet been mentioned by ${activeCurator?.name || activeCuratorGroup?.title || 'ShopMy curators'}.${
      isFiltered ? '' : ` If you work at ${props.brand?.name}, click below to apply to join our platform.`
    }`;
    actions = [
      ...(isFiltered
        ? [
            {
              text: `${props.brand.name} from all ShopMy curators`,
              extraClasses: 'primary',
              onClick: () => props.clearAllCurators()
            }
          ]
        : [
            {
              text: 'Apply To Join',
              extraClasses: 'secondary',
              onClick: () => props.openAuthModal('apply-brands')
            }
          ])
    ];
  } else if (props.isYourEmptyShop) {
    header = `No products added`;
    subheader =
      'Search other curators to find products to add to your wishlist or search for products and click the heart icon to add them to your wishlists.';
    badgeIcon = faHeart;

    actions = [
      {
        text: 'Shop Top Products',
        extraClasses: 'primary',
        to: '/shop?tab=popular'
      },
      { text: 'Search for Products', extraClasses: 'secondary', onClick: props.focusShopSearch }
    ];
  } else if (props.isYourEmptySection) {
    header = `No collections added`;
    subheader = 'Edit your shop to add collections and products.';
    actions = [
      {
        text: 'Edit Your Shop',
        extraClasses: 'primary',
        to: `/${getUsername(props.user)}`
      }
    ];
  } else if (props.isEmptySearchResults) {
    const activeCurator = getActiveCurator(props.shop);
    const activeCuratorGroup = getActiveCuratorGroup(props.shop);
    const hasCuratorFilter = activeCurator || activeCuratorGroup;
    header = `No results found`;
    subheader = hasCuratorFilter
      ? `${activeCurator ? activeCurator.name : activeCuratorGroup.title} does not have any products that match your search.`
      : `No products found for "${props.query}".`;
    actions = [
      {
        text: 'Change Curators',
        extraClasses: 'primary',
        onClick: () =>
          props.openCuratorGroupModal({
            params: {
              type: 'select'
            }
          })
      },
      { text: 'Search Again', extraClasses: 'secondary', onClick: props.focusShopSearch }
    ];
  }
  return (
    <div className='shop-empty-results-container'>
      <div className='header-container'>
        {badgeIcon && <FontAwesomeIcon icon={badgeIcon} />}
        <div className='header'>{header}</div>
        <div className='subheader'>{subheader}</div>
      </div>
      <div className='actions'>
        {actions.map((action, index) =>
          action.to ? (
            <Link key={index} to={action.to} onClick={action.onClick} className={cn('action', action.extraClasses)}>
              {action.icon && <FontAwesomeIcon icon={action.icon} />}
              {action.text}
            </Link>
          ) : (
            <div key={index} className={cn('action', action.extraClasses)} onClick={action.onClick}>
              {action.icon && <FontAwesomeIcon icon={action.icon} />}
              {action.text}
            </div>
          )
        )}
      </div>
    </div>
  );
};

ShopEmptyResults.propTypes = {
  // My Groups (/my/circles, top section)
  isMyCuratorGroups: PropTypes.bool,
  createGroup: PropTypes.func,

  // Favorite Groups (/my/circles, bottom section)
  isFavoritedCuratorGroups: PropTypes.bool,

  // My Curator Group (/my/circles/123)
  isMyCuratorGroupPage: PropTypes.bool,
  focusShopSearch: PropTypes.func,

  // Curator Group Selection Modal
  isSelectingCuratorGroups: PropTypes.bool,
  closeModal: PropTypes.func,

  // Empty Brand Results Page
  isEmptyBrandShop: PropTypes.bool,
  brand: PropTypes.object,
  openAuthModal: PropTypes.func,

  // Empty Shop Results
  isYourEmptyShop: PropTypes.bool,
  // focusShopSearch: PropTypes.func
  user: PropTypes.object,

  // Empty Search Results
  isEmptySearchResults: PropTypes.bool,
  query: PropTypes.string,
  shop: PropTypes.object
};

export default ShopEmptyResults;
