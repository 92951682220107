import _ from 'lodash';
import cogoToast from 'cogo-toast';

import * as ActionTypes from '../Actions/UIActions';
import * as UserActionTypes from '../Actions/UserActions';
import * as StoreActionTypes from '../Actions/StoreActions';

import { updateGlobalThemeStyling, isPinBeingMoved } from '../Helpers/ui_helpers';
import { spotlightKeys } from '../Helpers/spotlight_helpers';
import { setGlobalAdminControlMode, setGlobalNewShopMode } from '../global';

export const ui = (
  state = {
    inAnalyticsMode: false,
    isAdminControlMode: false,
    showAuthPanel: false,
    authPanelCallbackFns: null, // { onRegister, onLogin }
    modalVisible: false,
    pinsBeingEdited: [],
    isSearching: false,
    hasJoinedMailingList: false,

    // Allow us to focus in on certain features to guide the user
    spotlightTasksVisible: false,
    spotlightKey: null,

    // Page Based General UI Storage
    discover: {},
    general: {},
    talent: {
      viewedIntroductionNewPanel: null
    },

    // Header Handling For Homepage
    shopMyLabel: 'shelf',

    // Pin movement
    pinsBeingMoved: [],
    pinMoveCompletionFn: null, // Set this on a page to reload the data upon completion, good example in Links.js

    // Redirect handling
    authRedirectUrl: null,

    // Branding Handling
    brandTheme: null,

    // Bulk Talent Modal Overlay
    isBulkTalentModalVisible: false,
    bulkTalentModalStartingParams: null,
    bulkTalentModalCloseCallback: null,

    // Codes Modal Overlay
    isCodesModalVisible: false,
    codesModalStartingParams: null,
    codesModalCloseCallback: null,

    // Gifting Modal Overlay
    bulkRequestModalIsVisible: false,
    bulkRequestModalStartingParams: null,
    bulkRequestModalCloseCallback: null,
    bulkRequestModalSubmitCallback: null,

    // Fulfillment Modal Overlay
    isFulfillmentModalVisible: false,
    fulfillmentModalStartingParams: null,
    fulfillmentModalCloseCallback: null,
    fulfillmentModalSubmitCallback: null,

    // Bonus Modal Overlay
    isBonusModalVisible: false,
    bonusModalStartingParams: null,
    bonusModalCloseCallback: null,
    bonusModalSubmitCallback: null,

    // Curator Group Modal Overlay
    isCuratorGroupModalVisible: false,
    curatorGroupModalStartingParams: null,
    curatorGroupModalCloseCallback: null,
    curatorGroupModalSubmitCallback: null,

    // Brand Budget Modal
    isBrandBudgetModalVisible: false,
    brandBudgetModalStartingParams: null,

    // Subscription Offer Modal
    isSubscriptionOfferModalVisible: false,
    subscriptionOfferModalStartingParams: null,
    subscriptionOfferModalCloseCallback: null,
    subscriptionOfferModalSubmitCallback: null,

    // Unpaid Invoices Modal
    isUnpaidInvoicesModalVisible: false,
    unpaidInvoicesModalStartingParams: null,

    // Connect Instagram Modal
    isConnectInstagramModalVisible: false,

    // Artist Modal Overlay
    activeArtistModalTalent: null,
    artistModalCloseCallback: null,

    // Brand Modal Overlay
    isBrandModalVisible: false,
    brandModalStartingParams: null,
    brandModalCloseCallback: null,

    // Address Modal Overlay
    addressModalIsVisible: false,

    // Shop Header Search
    isShopHeaderSearchFocused: false,
    shopHeaderSearchVal: '',

    // Overlays
    activeChatOverlayTalent: null,

    // Terms and Conditions/Privacy Policy updates
    termsAndConditionsVisible: false,

    // Catalog Variant Modal
    catalogProductModalVariant: null
  },
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case ActionTypes.PROMPT_USER_LOGIN:
      return {
        ...state,
        showAuthPanel: action.panel,
        authPanelCallbackFns: action.callbackFns,
        modalVisible: true
      };

    case ActionTypes.CLEAR_USER_LOGIN:
      return {
        ...state,
        showAuthPanel: null,
        authPanelCallbackFns: null,
        modalVisible: false
      };

    case UserActionTypes.SYNC_CURRENT_USER:
      setGlobalAdminControlMode(state.isAdminControlMode);
      setGlobalNewShopMode(state.isNewShopMode);
      return {
        ...state
      };

    case UserActionTypes.LOGIN_USER_SUCCESS:
      // Handle basic UI setup for various users
      return {
        ...state,
        showAuthPanel: false,
        authPanelCallbackFns: null,
        modalVisible: false
      };

    case UserActionTypes.LOGOUT_USER_SUCCESS:
      // Handle basic UI setup for various users
      return {
        ...state,
        pinsBeingMoved: [],
        pinMoveCompletionFn: null
      };

    case ActionTypes.CLEAR_EDITING_PINS:
      return {
        ...state,
        pinsBeingEdited: []
      };

    case StoreActionTypes.EDIT_PIN_REQUEST:
      return {
        ...state,
        pinsBeingEdited: [...(state.pinsBeingEdited || []), action.pin?.id]
      };

    case StoreActionTypes.EDIT_PIN_SUCCESS:
    case StoreActionTypes.EDIT_PIN_FAILURE:
      return {
        ...state,
        pinsBeingEdited: _.filter(state.pinsBeingEdited || [], pinId => pinId !== action.pin?.id)
      };

    case UserActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        showAuthPanel: null,
        authPanelCallbackFns: null,
        modalVisible: false
      };

    case ActionTypes.SET_ANALYTICS_MODE:
      return {
        ...state,
        inAnalyticsMode: action.toggleOn
      };

    case ActionTypes.TOGGLE_ADMIN_CONTROL_MODE:
      state.isAdminControlMode
        ? cogoToast.info(`Admin Control Mode Off`, { hideAfter: 1 })
        : cogoToast.success(`Admin Control Mode On`, { hideAfter: 1 });
      setGlobalAdminControlMode(!state.isAdminControlMode);
      return {
        ...state,
        isAdminControlMode: !state.isAdminControlMode
      };

    case ActionTypes.TOGGLE_NEW_SHOP_MODE:
      state.isNewShopMode ? cogoToast.info(`New Shop Mode Mode Off`, { hideAfter: 1 }) : cogoToast.success(`New Shop Mode Mode On`, { hideAfter: 1 });
      setGlobalNewShopMode(!state.isNewShopMode);
      return {
        ...state,
        isNewShopMode: !state.isNewShopMode
      };

    case ActionTypes.TOGGLE_BODY_SCROLL_LOCK:
      if (action.turnOn) {
        document.querySelector('body').className += ' modal-visible';
      } else {
        document.querySelector('body').className = (document.querySelector('body').className || '')
          .split(' modal-visible')
          .join('')
          .split('modal-visible')
          .join('');
        !action.skipDelayedCheck &&
          setTimeout(() => {
            document.querySelector('body').className = (document.querySelector('body').className || '')
              .split(' modal-visible')
              .join('')
              .split('modal-visible')
              .join('');
          }, 150); // just in case
      }
      return {
        ...state
      };
    case ActionTypes.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.value
      };
    case ActionTypes.SET_JOINED_MAILING_LIST:
      return {
        ...state,
        hasJoinedMailingList: action.value
      };

    case ActionTypes.TOGGLE_PIN_TO_MOVE:
      return {
        ...state,
        pinsBeingMoved: isPinBeingMoved(action.pin, state)
          ? (state.pinsBeingMoved || []).filter(p => p.id !== action.pin.id)
          : [...(state.pinsBeingMoved || []), action.pin]
      };

    case ActionTypes.CLEAR_PINS_TO_MOVE:
      return {
        ...state,
        pinsBeingMoved: []
      };

    case ActionTypes.SET_COMPLETE_MOVING_PINS_FN:
      return {
        ...state,
        pinMoveCompletionFn: action.fn
      };

    case ActionTypes.SET_AUTH_REDIRECT_URL:
      return {
        ...state,
        authRedirectUrl: action.url
      };

    case ActionTypes.SET_BRAND_THEME:
      updateGlobalThemeStyling(action.brand);
      return {
        ...state,
        brandTheme: action.brand && {
          ...action.brand,
          allow_theming: !!action.brand,
          allow_advanced_theming: false // Deprecated
        }
      };

    case ActionTypes.SET_SHOP_MY_LABEL:
      return {
        ...state,
        shopMyLabel: action.label
      };

    case ActionTypes.SET_SPOTLIGHT_TASKS_VISIBLE:
      return {
        ...state,
        spotlightTasksVisible: !!action.turnOn
      };

    case ActionTypes.SET_SPOTLIGHT_KEY:
      if (!_.values(spotlightKeys).includes(action.key)) {
        cogoToast.warn(`Could not find the spotlight key ${action.key}`);
        return {
          ...state,
          spotlightKey: null
        };
      }
      return {
        ...state,
        spotlightKey: action.key
      };

    case ActionTypes.CLEAR_SPOTLIGHT_KEY:
      return {
        ...state,
        spotlightKey: null
      };

    case ActionTypes.OPEN_CONNECT_INSTAGRAM_MODAL:
      return {
        ...state,
        isConnectInstagramModalVisible: true
      };

    case ActionTypes.CLOSE_CONNECT_INSTAGRAM_MODAL:
      return {
        ...state,
        isConnectInstagramModalVisible: false
      };

    case ActionTypes.OPEN_ARTIST_MODAL:
      return {
        ...state,
        activeArtistModalTalent: action.talent,
        artistModalCloseCallback: action.closeCallback
      };

    case ActionTypes.CLOSE_ARTIST_MODAL:
      return {
        ...state,
        activeArtistModalTalent: null,
        artistModalCloseCallback: null
      };

    case ActionTypes.OPEN_BRAND_MODAL:
      return {
        ...state,
        isBrandModalVisible: true,
        brandModalStartingParams: action.params,
        brandModalCloseCallback: action.closeCallback
      };

    case ActionTypes.CLOSE_BRAND_MODAL:
      return {
        ...state,
        isBrandModalVisible: false,
        brandModalStartingParams: null,
        brandModalCloseCallback: null
      };

    case ActionTypes.OPEN_BULK_TALENT_MODAL:
      return {
        ...state,
        isBulkTalentModalVisible: true,
        bulkTalentModalStartingParams: action.params,
        bulkTalentModalCloseCallback: action.closeCallback
      };

    case ActionTypes.CLOSE_BULK_TALENT_MODAL:
      return {
        ...state,
        isBulkTalentModalVisible: false,
        bulkTalentModalStartingParams: null,
        bulkTalentModalCloseCallback: null
      };

    case ActionTypes.OPEN_CODES_MODAL:
      return {
        ...state,
        isCodesModalVisible: true,
        codesModalStartingParams: action.params,
        codesModalCloseCallback: action.closeCallback,
        codesModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_CODES_MODAL:
      return {
        ...state,
        isCodesModalVisible: false,
        codesModalStartingParams: null,
        codesModalCloseCallback: null,
        codesModalSubmitCallback: null
      };

    case ActionTypes.OPEN_REQUEST_MODAL:
      return {
        ...state,
        bulkRequestModalIsVisible: true,
        bulkRequestModalStartingParams: action.params,
        bulkRequestModalCloseCallback: action.closeCallback,
        bulkRequestModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_REQUEST_MODAL:
      return {
        ...state,
        bulkRequestModalIsVisible: false,
        bulkRequestModalStartingParams: null,
        bulkRequestModalCloseCallback: null,
        bulkRequestModalSubmitCallback: null
      };

    case ActionTypes.OPEN_FULFILLMENT_MODAL:
      return {
        ...state,
        isFulfillmentModalVisible: true,
        fulfillmentModalStartingParams: action.params,
        fulfillmentModalCloseCallback: action.closeCallback,
        fulfillmentModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_FULFILLMENT_MODAL:
      return {
        ...state,
        isFulfillmentModalVisible: false,
        fulfillmentModalStartingParams: null,
        fulfillmentModalCloseCallback: null,
        fulfillmentModalSubmitCallback: null
      };

    case ActionTypes.OPEN_BONUS_MODAL:
      return {
        ...state,
        isBonusModalVisible: true,
        bonusModalStartingParams: action.params,
        bonusModalCloseCallback: action.closeCallback,
        bonusModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_BONUS_MODAL:
      return {
        ...state,
        isBonusModalVisible: false,
        bonusModalStartingParams: null,
        bonusModalCloseCallback: null,
        bonusModalSubmitCallback: null
      };

    case ActionTypes.OPEN_CURATOR_GROUP_MODAL:
      return {
        ...state,
        isCuratorGroupModalVisible: true,
        curatorGroupModalStartingParams: action.params,
        curatorGroupModalCloseCallback: action.closeCallback,
        curatorGroupModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_CURATOR_GROUP_MODAL:
      return {
        ...state,
        isCuratorGroupModalVisible: false,
        curatorGroupModalStartingParams: null,
        curatorGroupModalCloseCallback: null,
        curatorGroupModalSubmitCallback: null
      };

    case ActionTypes.OPEN_BRAND_BUDGET_MODAL:
      return {
        ...state,
        isBrandBudgetModalVisible: true,
        brandBudgetModalStartingParams: action.params
      };

    case ActionTypes.CLOSE_BRAND_BUDGET_MODAL:
      return {
        ...state,
        isBrandBudgetModalVisible: false,
        brandBudgetModalStartingParams: null
      };

    case ActionTypes.OPEN_SUBSCRIPTION_OFFER_MODAL:
      return {
        ...state,
        isSubscriptionOfferModalVisible: true,
        subscriptionOfferModalStartingParams: action.params,
        subscriptionOfferModalCloseCallback: action.closeCallback,
        subscriptionOfferModalSubmitCallback: action.submitCallback
      };

    case ActionTypes.CLOSE_SUBSCRIPTION_OFFER_MODAL:
      return {
        ...state,
        isSubscriptionOfferModalVisible: false,
        subscriptionOfferModalStartingParams: null,
        subscriptionOfferModalCloseCallback: null,
        subscriptionOfferModalSubmitCallback: null
      };

    case ActionTypes.OPEN_UNPAID_INVOICES_MODAL:
      return {
        ...state,
        isUnpaidInvoicesModalVisible: true,
        unpaidInvoicesModalStartingParams: action.params
      };

    case ActionTypes.CLOSE_UNPAID_INVOICES_MODAL:
      return {
        ...state,
        isUnpaidInvoicesModalVisible: false,
        unpaidInvoicesModalStartingParams: null
      };

    case ActionTypes.OPEN_CHAT_OVERLAY:
      return {
        ...state,
        activeChatOverlayTalent: action.talent
      };

    case ActionTypes.CLOSE_CHAT_OVERLAY:
      return {
        ...state,
        activeChatOverlayTalent: null
      };

    case ActionTypes.OPEN_ADDRESS_MODAL:
      newState.addressModalIsVisible = true;
      return newState;

    case ActionTypes.CLOSE_ADDRESS_MODAL:
      newState.addressModalIsVisible = false;
      return newState;

    case ActionTypes.FOCUS_SHOP_SEARCH:
      return {
        ...state,
        isShopHeaderSearchFocused: true
      };

    case ActionTypes.BLUR_SHOP_SEARCH:
      return {
        ...state,
        isShopHeaderSearchFocused: false
      };

    case ActionTypes.SET_SHOP_HEADER_SEARCH_VAL:
      return {
        ...state,
        shopHeaderSearchVal: action.value
      };

    case ActionTypes.SET_UI_KEY_VALUE:
      return {
        ...state,
        [action.page]: {
          ...(state[action.page] || {}),
          [action.key]: action.value
        }
      };

    case ActionTypes.SET_TERMS_AND_CONDITIONS_VISIBLE:
      return {
        ...state,
        termsAndConditionsVisible: !!action.visible
      };

    case ActionTypes.OPEN_CATALOG_PRODUCT_MODAL:
      return {
        ...state,
        catalogProductModalVariant: action.variant
      };

    case ActionTypes.CLOSE_CATALOG_PRODUCT_MODAL:
      return {
        ...state,
        catalogProductModalVariant: null
      };

    default:
      return state;
  }
};
