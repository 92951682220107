import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ShopResultsDisclaimer.scss';

import { isShopper } from '../../../Helpers/user_helpers';

const ShopResultsDisclaimer = props => {
  /*
    For certain shop results, we need to display a disclaimer at the top of the results.
  */
  const { user, isYourShop } = props;
  let disclaimer;

  if (isYourShop && props.tab === 'latest' && !isShopper(user)) {
    disclaimer = (
      <>
        Recently added products can take up to 24-48 hours to show up on the latest tab. Learn more on why{' '}
        <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/the-shop/why-are-my-products-not-showing-up-on-latest'>
          here
        </a>
        . To configure your settings for what products are shown on the latest tab, go to your{' '}
        <Link to='/settings?tab=Account+Management#visibleproducts'>settings</Link>.
      </>
    );
  }

  if (disclaimer) {
    return (
      <div className='shop-results-disclaimer-container'>
        <div className='shop-results-disclaimer'>
          <div>{disclaimer}</div>
        </div>
      </div>
    );
  }

  return null;
};

ShopResultsDisclaimer.propTypes = {
  user: PropTypes.object.isRequired,
  tab: PropTypes.string
};

export default ShopResultsDisclaimer;
