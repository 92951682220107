import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import './MyCurators.scss';
import icons from '../../static/icons';

import {
  createCuratorGroup,
  updateCuratorGroup,
  deleteCuratorGroup,
  deleteCuratorGroupFavorite,
  createCuratorGroupUser,
  deleteCuratorGroupUser
} from '../../Actions/ShopActions';
import { getOrderedCuratorGroups, getOrderedCuratorGroupFavorites } from '../../Helpers/user_helpers';
import { openCreateGroupModal } from '../../Helpers/shop_helpers';

import CuratorGroupCard from './CuratorGroupCard';
import ShopEmptyResults from './ShopEmptyResults';
import InstagramCSVGroupCreationModal from '../Modals/InstagramCSVGroupCreationModal';

const MyCurators = props => {
  const { user, selectGroup, selectGroupAndEdit } = props;

  // Get curator groups and favorites
  const curatorGroups = getOrderedCuratorGroups(user);
  const curatorGroupFavorites = getOrderedCuratorGroupFavorites(user);

  // Connecting TikTok
  const [viewingInstagramCSVGroupCreationModal, setViewingInstagramCSVGroupCreationModal] = React.useState(false);
  const uploadFromInstagramCSV = () => {
    window.__ADD_EVENT__('Shop - Open Instagram CSV Upload Modal');
    setViewingInstagramCSVGroupCreationModal(true);
  };

  // Actions
  const createGroup = () =>
    openCreateGroupModal({
      user,
      createCuratorGroup: props.createCuratorGroup,
      onSuccessfulGroupAdd: group => selectGroupAndEdit(group)
    });

  return (
    <div className='my-curators-sections'>
      <div className='curators-section'>
        <div className='section-title-container'>
          <div className='section-title'>My Shopping Circles</div>
          <div className='section-controls'>
            <div onClick={uploadFromInstagramCSV} className='action-btn secondary create-group'>
              <img src={icons.social.instagram.dark} alt='Instagram' />
              From Instagram
            </div>
            <div onClick={createGroup} className='action-btn primary create-group'>
              <FontAwesomeIcon icon={faPlus} />
              Create Circle
            </div>
          </div>
        </div>
        {curatorGroups.length ? (
          <div className='section-items'>
            {curatorGroups.map(group => (
              <CuratorGroupCard
                key={group.id}
                group={group}
                user={user}
                selectGroup={selectGroup}
                deleteCuratorGroup={props.deleteCuratorGroup}
                deleteCuratorGroupFavorite={props.deleteCuratorGroupFavorite}
              />
            ))}
          </div>
        ) : (
          <ShopEmptyResults isMyCuratorGroups createGroup={createGroup} />
        )}
      </div>
      <div className='curators-section'>
        <div className='section-title-container'>
          <div className='section-title'>Favorited Circles</div>
        </div>
        {curatorGroupFavorites.length ? (
          <div className='section-items'>
            {curatorGroupFavorites.map(favorite => (
              <CuratorGroupCard
                key={favorite.id}
                favorite={favorite}
                group={favorite.curator_group}
                selectGroup={selectGroup}
                user={user}
                deleteCuratorGroup={props.deleteCuratorGroup}
                deleteCuratorGroupFavorite={props.deleteCuratorGroupFavorite}
              />
            ))}
          </div>
        ) : (
          <ShopEmptyResults isFavoritedCuratorGroups />
        )}
      </div>
      {viewingInstagramCSVGroupCreationModal && <InstagramCSVGroupCreationModal closeModal={() => setViewingInstagramCSVGroupCreationModal(false)} />}
    </div>
  );
};

MyCurators.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  createCuratorGroup: PropTypes.func.isRequired,
  deleteCuratorGroup: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired,

  // From Outside
  selectGroup: PropTypes.func.isRequired,
  selectGroupAndEdit: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  createCuratorGroup,
  updateCuratorGroup,
  deleteCuratorGroup,
  deleteCuratorGroupFavorite,
  createCuratorGroupUser,
  deleteCuratorGroupUser
})(MyCurators);
