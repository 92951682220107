import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './ShopHeaderSearchResult.scss';

import { isCuratorOrGroupFavorited, getFavoriteCuratorGroupWithId } from '../../../Helpers/user_helpers';

const ShopHeaderSearchResult = props => {
  const { user, result, isAutoSubmit } = props;
  const { term, image, type, Curator_id, CuratorGroup_id } = result;
  const highlightedTerm = result._highlightResult?.term?.value || term; // Example Curated by <em>Tiffany</em> <em>Blac</em>kmon

  const partsPartOne = highlightedTerm.split('<em>');
  const parts = _.filter(partsPartOne.map(part => part.split('</em>')).flat());
  const isFirstPartHighlighted = highlightedTerm.startsWith('<em>');
  const isAnyPartHighlighted = highlightedTerm.includes('<em>');
  const isGeneralQuery = type === 'Query';

  // Allow selecting
  const select = () => props.selectResult(result);

  // Allow Favoriting
  const showHeart = !!Curator_id || !!CuratorGroup_id;
  const isFavorited = isCuratorOrGroupFavorited(user, { Curator_id, CuratorGroup_id });
  const [isFavoriting, setIsFavoriting] = React.useState(false);
  const pressHeart = async e => {
    if (isFavoriting) return;
    e.stopPropagation();
    if (Curator_id) {
      props.openCuratorGroupModal({
        params: {
          type: 'add',
          curator: {
            id: Curator_id,
            name: term
          }
        }
      });
    } else if (CuratorGroup_id) {
      const favorite = getFavoriteCuratorGroupWithId(user, CuratorGroup_id);
      if (favorite) {
        props.deleteCuratorGroupFavorite(favorite).then(() => {
          window.ALERT.success(`Unfavorited the shopping circle "${favorite.curatoe_group.title}"`);
        });
      } else {
        setIsFavoriting(true);
        await props.createCuratorGroupFavorite({ id: CuratorGroup_id });
        setIsFavoriting(false);
      }
    }
  };

  return (
    <div onClick={select} key={result.objectID} className={cn('shop-header-search-result', { auto: isAutoSubmit })}>
      <div className='main-result'>
        <div className={cn('image-container', type)}>
          {image ? <img src={image} alt={term} /> : isGeneralQuery ? <FontAwesomeIcon icon={faSearch} /> : <div className='empty'>S</div>}
        </div>
        <div className='term'>
          {parts.map((part, idx) => {
            let isHighlighted = false;
            if (!isAnyPartHighlighted) isHighlighted = false;
            else if (idx === 0 && isFirstPartHighlighted) isHighlighted = true;
            else if (idx % 2 === (isFirstPartHighlighted ? 0 : 1)) isHighlighted = true;
            return (
              <span
                className={cn({
                  highlighted: isHighlighted
                })}
              >
                {part}
              </span>
            );
          })}
        </div>
      </div>
      <div className='actions'>
        {/* {isAutoSubmit ? <div className='action enter'>ENTER TO SHOP</div> : null} */}
        {showHeart && (
          <div className={cn('action favorite', { editing: isFavoriting })} onClick={pressHeart}>
            <FontAwesomeIcon icon={isFavorited ? faHeartSolid : faHeart} />
          </div>
        )}
      </div>
    </div>
  );
};

ShopHeaderSearchResult.propTypes = {
  user: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  isAutoSubmit: PropTypes.bool.isRequired,
  selectResult: PropTypes.func.isRequired,

  // Actions
  openCuratorGroupModal: PropTypes.func.isRequired,
  createCuratorGroupFavorite: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired
};

export default ShopHeaderSearchResult;
