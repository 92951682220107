import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './SettingsSidePanel.scss';

import { selectUserToManage } from '../../Actions/ManagerActions';
import { getCurrentManager } from '../../Helpers/manager_helpers';
import { getProfileFromObject, getBrand, isBrand, isManager, isShopper, getUserTier } from '../../Helpers/user_helpers';
import { getBrandTier } from '../../Helpers/brand_helpers';
import { getBrandTierDisplay } from '../../Helpers/brand_tier_helpers';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';
import { scrollToTop } from '../../Helpers/helpers';

import { Link } from 'react-router-dom';
import Image from '../General/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faRightFromBracket, faSearch } from '@fortawesome/pro-light-svg-icons';

const SettingsSidePanel = props => {
  const { user, manager, sections } = props;
  const isSyncing = user.isSyncing;
  const realUser = getCurrentManager(manager);
  const profile = getProfileFromObject(user);
  const brand = getBrand(user);
  const score = isManager(user) || isShopper(user) ? null : isBrand(user) ? parseInt(getBrandTier(brand).score) : parseInt(getUserTier(user)?.score);
  const tierDisplay = isBrand(user)
    ? getBrandTierDisplay(getBrandTier(brand)?.tier)
    : isManager(user)
    ? 'Manager'
    : isShopper(user)
    ? 'Shopper'
    : getUserTierDisplay(profile.tier?.tier);
  const image = isBrand(user) ? brand.mobileBadgeImage : profile.image;
  const failedImageText = profile.name ? profile.name.split(' ').map(n => n[0]).join('') : 'N/A'; // prettier-ignore

  const profileSubtext = [];
  profileSubtext.push(tierDisplay);
  if (score) profileSubtext.push(`${score}/100`);

  const setSearchValue = e => {
    const searchValue = e.target.value;
    if (searchValue.length > 2 && props.activePanelTitle !== 'All') props.setActivePanelTitle('All');
    props.setSearchValue(e.target.value);
    if (searchValue.length > 2) scrollToTop();
  };

  return (
    <div className='settings-side-panel-outer'>
      <div className='settings-side-panel-inner'>
        <div className='settings-side-panel-top'>
          {isSyncing ? (
            <div className='settings-side-panel-profile skeleton'>
              <Image src={null} failedText={failedImageText} alt='Profile' useGenericFailedStyles />
              <div className='settings-side-panel-profile-info'>
                <div className='settings-side-panel-profile-name skeleton' />
                <div className='settings-side-panel-profile-tier skeleton' />
              </div>
            </div>
          ) : (
            <div className='settings-side-panel-profile'>
              <Image src={image} failedText={failedImageText} alt='Profile' useGenericFailedStyles />
              <div className='settings-side-panel-profile-info'>
                <div className='settings-side-panel-profile-name'>{profile.name}</div>
                <div className='settings-side-panel-profile-tier'>{profileSubtext.join(' • ')}</div>
              </div>
            </div>
          )}

          <div className='settings-side-panel-search'>
            <FontAwesomeIcon icon={faSearch} />
            <input
              className={cn('settings-side-panel-search-input', { skeleton: isSyncing })}
              placeholder='Search'
              value={props.searchValue}
              onChange={setSearchValue}
            />
          </div>

          <div className='settings-side-panel-sections'>
            <div
              className={cn('settings-side-panel-section', { isSelected: 'All' === props.activePanelTitle, skeleton: isSyncing })}
              onClick={() => {
                props.setActivePanelTitle('All');
                scrollToTop();
              }}
            >
              <FontAwesomeIcon icon={faHouse} />
              {isSyncing ? <div className='settings-side-panel-section-title' /> : <div className='settings-side-panel-section-title'>All</div>}
            </div>

            {sections.map(section => {
              const { title, shortTitle, icon, panels } = section;
              const atLeastOnePanelIsVisible = panels.some(panel => panel.canView);
              if (!atLeastOnePanelIsVisible) return null;
              const isSelected = title === props.activePanelTitle;

              return (
                <div
                  key={`side-panel-${title}`}
                  className={cn('settings-side-panel-section', { isSelected, skeleton: isSyncing })}
                  onClick={() => {
                    props.setActivePanelTitle(title);
                    scrollToTop();
                  }}
                >
                  {icon && <FontAwesomeIcon icon={icon} />}
                  {isSyncing ? (
                    <div className='settings-side-panel-section-title' />
                  ) : (
                    <div className='settings-side-panel-section-title'>{shortTitle || title}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className='settings-side-panel-bottom'>
          <div className='settings-side-panel-sections'>
            {!!realUser && (
              <div
                className={cn('settings-side-panel-section', { skeleton: isSyncing })}
                onClick={() => {
                  props.setActivePanelTitle('My Talent');
                  props.selectUserToManage(null);
                  scrollToTop();
                }}
              >
                <Image className='settings-side-panel-manager-image' src={realUser.image} failedText='' alt='Profile' useGenericFailedStyles />

                {isSyncing ? (
                  <div className='settings-side-panel-section-title' />
                ) : (
                  <div className='settings-side-panel-section-title'>Return To My Account</div>
                )}
              </div>
            )}

            <Link to='/logout' className={cn('settings-side-panel-section', { skeleton: isSyncing })}>
              <FontAwesomeIcon icon={faRightFromBracket} />

              {isSyncing ? <div className='settings-side-panel-section-title' /> : <div className='settings-side-panel-section-title'>Logout</div>}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingsSidePanel.propTypes = {
  user: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  activePanelTitle: PropTypes.string.isRequired,
  setActivePanelTitle: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, manager } = state;
  return { user, manager };
};

export default connect(mapStateToProps, { selectUserToManage })(SettingsSidePanel);
