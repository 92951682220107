import React from 'react';
import PropTypes from 'prop-types';
import './ProductErrorPanel.scss';

const ProductErrorPanel = props => {
  return (
    <div className='product-error-panel-container'>
      <div className='header-container'>
        <div className='header'>There was an issue</div>
        <div className='subheader'>We were unable to load this product. Please click below to try again.</div>
      </div>
      <div className='retry-btn' onClick={props.retry}>
        Try again
      </div>
    </div>
  );
};

ProductErrorPanel.propTypes = {
  retry: PropTypes.func.isRequired
};

export default ProductErrorPanel;
