import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OnboardingWizard.scss';

import { closeCuratorGroupModal, blurShopSearch } from '../../../Actions/UIActions';
import { createCuratorGroup } from '../../../Actions/ShopActions';

import { getProductIdsPromoted, getUserId, getCuratorGroups, isShopper, getCuratorGroupsWithUsers } from '../../../Helpers/user_helpers';
import { getLinkToShopForUserId, openCreateGroupModal } from '../../../Helpers/shop_helpers';
import {
  isCuratorGroupModalVisible,
  isShopHeaderSearchFocused,
  getShopHeaderSearchVal,
  isProductHeartOverlayOpen
} from '../../../Helpers/ui_helpers';
import {
  getOnboardingStepsEnum,
  isOnboardingStepComplete,
  onboardingConstants,
  getNumProductsBeforeTasteProfile,
  canBuildTasteProfile,
  getNumOnboardingStepsComplete
} from '../../../Helpers/onboarding_helpers';

const OnboardingWizard = props => {
  /*

    This Wizard can be used for many types of onboarding.

    To add a new onboarding wizard for a new type of user or a new user stage, follow these steps:

      1. Define an ENUM in onboarding_helpers.getOnboardingStepsEnum({user})
      2. Define the steps in the OnboardingWizard component below (search for STEPSHERE)
      3. Handle blocking animations on any open modals (search for ANIMATIONBLOCKS)
        - For example, if a user adds a product via a model and we want to "check off" the step AFTER they close the modal, you can do so here

    That's it!
  */

  const { user, shop, ui } = props;

  // When opening from outside, set the state to visible
  const [isVisible, setIsVisible] = React.useState(props.isVisibleFromOuterClick);
  React.useEffect(() => {
    if (props.isVisibleFromOuterClick) {
      setIsVisible(true);
    }
  }, [props.isVisibleFromOuterClick]);

  // Close should close internally and externally
  const closePanel = () => {
    setIsVisible(false);
    props.closePanel();
  };

  const goToTasteProfile = () => {
    closePanel();
    props.history.push('/shop/my/taste');
    window.scrollTo(0, 0);
  };

  // Create Steps object
  let steps = [],
    wizardHeader,
    wizardSubheader;
  const enums = getOnboardingStepsEnum({ user });
  const isComplete = getNumOnboardingStepsComplete({ user, shop }) === enums.length;

  /*
    Shopper Onboarding:

    There are 3 steps to the shopper onboarding process:

    1. Shopping Circles
    2. Products & Wishlist
    3. Taste Profiles
  */
  if (isShopper(user)) {
    wizardHeader = isComplete ? `You're ready to go!` : 'Personalized Shopping Awaits';
    wizardSubheader = isComplete
      ? 'You have completed all the steps to personalize your shopping experience. Continue hearting products and building out your shopping circles to continue to personalize your experience!'
      : 'Complete the following steps to maximize your personalized shopping experience.';

    // Step #1 - Products & Wishlist
    const numProductsPromoted = getProductIdsPromoted(user).length;
    const hasProductsPromoted = numProductsPromoted > 0;
    steps.push({
      enum: 'FIRST_USER_PRODUCT',
      title: (
        <>
          Add to your <i>Wishlist</i>
        </>
      ),
      subtitle: <>Heart products to begin building your personal curated shop.</>,
      actions: [
        {
          title: 'Shop Now',
          hideIfComplete: true,
          to: '/shop',
          onClick: closePanel
        },
        {
          title: 'View My Shop',
          hideIfIncomplete: true,
          to: getLinkToShopForUserId(getUserId(user)),
          onClick: closePanel
        }
      ],
      isComplete: hasProductsPromoted
    });

    // Step #2 - Shopping Circles
    const curatorGroups = getCuratorGroups(user);
    const curatorGroupsWithUsers = getCuratorGroupsWithUsers(user);
    steps.push({
      enum: 'FIRST_CIRCLE',
      title: (
        <>
          Create your first <i>Shopping Circle</i>
        </>
      ),
      subtitle:
        curatorGroups.length && !curatorGroupsWithUsers.length ? (
          <>You have added a circle, but haven't added any curators yet.</>
        ) : (
          <>Add curators you trust to your circle to customize your shopping experience.</>
        ),
      actions: [
        {
          title: 'Go to My Circle',
          hideAlways: !curatorGroups.length,
          hideIfComplete: true,
          onClick: () => {
            closePanel();
          },
          to: curatorGroups.length && `/shop/my/circles/${curatorGroups[0].id}`
        },
        {
          title: 'Create Circle',
          onClick: () => {
            openCreateGroupModal({
              user,
              shop,
              createCuratorGroup: props.createCuratorGroup,
              onSuccessfulGroupAdd: group => {
                props.history.push(`/shop/my/circles/${group.id}`);
                closePanel();
              }
            });
          },
          hideAlways: curatorGroups.length,
          hideIfComplete: true,
          to: '/shop/my/circles'
        },
        {
          title: 'Discover Curators',
          hideAlways: curatorGroups.length,
          hideIfComplete: true,
          onClick: closePanel,
          to: '/shop/circles'
        },
        {
          title: 'View My Circles',
          hideIfIncomplete: true,
          onClick: closePanel,
          to: '/shop/my/circles'
        }
      ]
    });

    // Step #3 - Taste Profiles
    const canBuildTaste = canBuildTasteProfile(user);
    const numProductsBeforeTasteProfile = getNumProductsBeforeTasteProfile(user);
    steps.push({
      enum: 'TASTE_PROFILE',
      title: (
        <>
          Generate your <i>Taste Profile</i>
        </>
      ),
      subtitle: canBuildTaste ? (
        <>You have hearted enough products to unlock your taste profile. Click below to go to the page where you can build your taste profile.</>
      ) : numProductsPromoted ? (
        <>
          Heart <b>{numProductsBeforeTasteProfile}</b> More Product{numProductsBeforeTasteProfile === 1 ? '' : 's'} to unlock your taste profile.
        </>
      ) : (
        <>Heart at least {onboardingConstants.MIN_PRODUCTS_FOR_TASTE} products to unlock your ability to build a taste profile.</>
      ),
      actions: [
        {
          title: 'Build Taste Profile',
          disabled: !canBuildTaste,
          onClick: () =>
            canBuildTaste
              ? goToTasteProfile()
              : window.ALERT.warn(
                  `You need to heart ${numProductsBeforeTasteProfile} more product${
                    numProductsBeforeTasteProfile === 1 ? '' : 's'
                  } to build your taste profile.`
                )
        }
      ]
    });
  }

  // Newly added steps should be added here - STEPSHERE

  // Make sure we only show the steps in the ENUM
  steps = steps.filter(step => enums.includes(step.enum));

  // Handle Completion
  steps = steps.map(step => {
    return {
      ...step,
      isComplete: isOnboardingStepComplete(step.enum, { user })
    };
  });

  // Animation opening after waiting in some cases
  const [shouldAnimateInForEnum, setShouldAnimateInForEnum] = React.useState(false);
  const [isAnimatingEnumCompletion, setIsAnimatingEnumCompletion] = React.useState(false);

  const curatorGroupModalVisible = isCuratorGroupModalVisible(ui);
  const shopHeaderSearchFocused = isShopHeaderSearchFocused(ui);
  const shopHeaderSearchVal = getShopHeaderSearchVal(ui);
  const productHeartOverlayOpen = isProductHeartOverlayOpen(ui);

  const potentialWaitingOn = [curatorGroupModalVisible, shopHeaderSearchFocused, shopHeaderSearchVal, productHeartOverlayOpen].join('-');
  React.useEffect(() => {
    if (shouldAnimateInForEnum) {
      const animateIn = () => {
        setTimeout(() => {
          // Open the overlay
          setShouldAnimateInForEnum(false);
          setIsVisible(true);

          // Animate the completion
          setIsAnimatingEnumCompletion(shouldAnimateInForEnum);
          setTimeout(() => setIsAnimatingEnumCompletion(null), 250); // 250ms before starting the completion animation
        }, 250); // Wait a little before opening the overlay
      };

      if (shouldAnimateInForEnum === 'FIRST_CIRCLE') {
        const needToWait = curatorGroupModalVisible || shopHeaderSearchFocused || !!shopHeaderSearchVal;
        if (!needToWait) {
          animateIn(shouldAnimateInForEnum);
        }
      } else if (shouldAnimateInForEnum === 'FIRST_USER_PRODUCT') {
        const needToWait = productHeartOverlayOpen;
        if (!needToWait) {
          animateIn(shouldAnimateInForEnum);
        }
      } else {
        // Any animation blocking modals should be added here! (ANIMATIONBLOCKS)
        animateIn(shouldAnimateInForEnum);
      }
    }
  }, [shouldAnimateInForEnum, potentialWaitingOn]);

  // Handle animations on completion of tasks
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const stepEnumsCompleted = steps.filter(step => step.isComplete).map(step => step.enum);
  const stepsCompletedPrior = React.useRef(stepEnumsCompleted);
  React.useEffect(() => {
    if (isFirstLoad) return setIsFirstLoad(false);

    // If we just completed a task
    if (stepEnumsCompleted.length > stepsCompletedPrior.current.length) {
      const completedEnum = stepEnumsCompleted.find(enumVal => !stepsCompletedPrior.current.includes(enumVal));
      setShouldAnimateInForEnum(completedEnum);
    }

    // Save the number of steps completed
    stepsCompletedPrior.current = stepEnumsCompleted;
  }, [stepEnumsCompleted.join('-')]);

  const additionalClasses = { visible: isVisible };
  return (
    <div className={cn('onboarding-wizard-outer-container', additionalClasses)}>
      <div onClick={closePanel} className={cn('onboarding-wizard-cover', additionalClasses)} />
      <div className={cn('onboarding-wizard-inner-container', additionalClasses)}>
        <FontAwesomeIcon onClick={closePanel} className='close-icon' icon={faTimes} />
        <div className='wizard-header-container'>
          <div className='wizard-header'>{wizardHeader}</div>
          <div className='wizard-subheader'>{wizardSubheader}</div>
        </div>
        <div className='steps'>
          {steps.map((step, index) => {
            const { isComplete, title, subtitle, actions } = step;
            const animatingComplete = isAnimatingEnumCompletion === step.enum;
            const additionalClasses = {
              complete: isComplete && !animatingComplete,
              'animating-complete-start': animatingComplete
            };
            const visibleActions = actions.filter(action => {
              if (action.hideIfIncomplete && !isComplete) return false;
              if (action.hideIfComplete && isComplete) return false;
              if (action.hideAlways) return false;
              return true;
            });
            return (
              <div key={index} className={cn('step', additionalClasses)}>
                <div className={cn('status-container', additionalClasses)}>
                  {step.isComplete ? <FontAwesomeIcon icon={faCheck} /> : <div className='number'>{index + 1}</div>}
                </div>
                <div className='main'>
                  <div className={cn('title', additionalClasses)}>{title}</div>
                  {!step.isComplete && <div className={cn('subtitle', additionalClasses)}>{subtitle}</div>}
                  {!step.isComplete && (
                    <div className={cn('actions', additionalClasses)}>
                      {visibleActions.map((action, index) =>
                        action.to ? (
                          <Link to={action.to} key={action.title} className={cn('action', { disabled: action.disabled })} onClick={action.onClick}>
                            {action.title}
                          </Link>
                        ) : (
                          <div key={action.title} className={cn('action', { disabled: action.disabled })} onClick={action.onClick}>
                            {action.title}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

OnboardingWizard.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  createCuratorGroup: PropTypes.func.isRequired,
  closeCuratorGroupModal: PropTypes.func.isRequired,
  blurShopSearch: PropTypes.func.isRequired,

  // From Outside
  isVisibleFromOuterClick: PropTypes.bool.isRequired,
  closePanel: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, shop, ui } = state;
  return { user, shop, ui };
};

export default connect(mapStateToProps, {
  createCuratorGroup,
  closeCuratorGroupModal,
  blurShopSearch
})(withRouter(OnboardingWizard));
