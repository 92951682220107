import helpers from './Helpers/helpers';
import cogoToast from 'cogo-toast';

// Import Fonts
import './static/fonts/MediaSans/mediasans.css';
import './static/fonts/ParaSupreme/parasupreme.css';

window.__IS_PRO__ = helpers.isPro();
window.__IS_SMS__ = helpers.isSMS() || helpers.isSnap();
window.__IS_SNAP__ = helpers.isSnap();
window.__IS_DEV__ = helpers.isDevelopment();
window.__IS_PROD__ = helpers.isProduction();
window.__ROOT_SCSS__ = helpers.getRootSCSSClass();

// Feature Flags
export const setGlobalNewShopMode = turnOn => (window.__NEW_SHOP_MODE__ = turnOn);

// For locking of the catalog for only product id matches
window.LOCK_CATALOG_MATCHES_ONLY = false;

// To make it easier to alert from everywhere
window.ALERT = cogoToast;

// For admin control mode without requiring extensive imports
export const setGlobalAdminControlMode = turnOn => (window.__ADMIN_CONTROL_MODE__ = turnOn);
