import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import QRCode from 'qrcode.react';
import defaultPhoto from '../../static/images/logos/logo.png';
import './LandingPromotionPanel.scss';

// import SearchContentModal from '../Modals/SearchContentModal';

import { IMAGE_OPTIONS_LEFT, IMAGE_OPTIONS_RIGHT } from '../../Helpers/home_helpers';
import { getSmartImage, copyToClipboard, matchScrollHeight, isPromotePage, getUrlParam } from '../../Helpers/helpers';
import { getBrandTheme, isNCEA } from '../../Helpers/ui_helpers';
import { validateUsername, isLoggedIn, getUserId, isYou, getInviteLink, isManager, isShopper } from '../../Helpers/user_helpers';
import { REFERRAL_LENGTH_MONTHS_DISPLAY, REFERRAL_PAYOUT_RATE } from '../../Helpers/referral_helpers';
import { US_STATES, PRO_LICENSE_TYPES, PRO_LICENSE_TYPES_NCEA, DEFAULT_PRO_LICENSE_TYPE } from '../../Helpers/constants';
import { isValidEmail } from '../../Helpers/formatting';

import { sendReferralEmails } from '../../APIClient/referrals';
import { useCodePreRegistration } from '../../APIClient/users';

class LandingPromotionPanel extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    registerUser: PropTypes.func.isRequired,

    // Optional depending on whether it is a brand or user invitation
    referringUser: PropTypes.object
  };

  componentDidMount() {
    setTimeout(this.startMotion, 100);

    const { location, history } = this.props;

    if (location?.hash && location.hash.toUpperCase() === location.hash) {
      this.setState({ code: location.hash.slice(1) });
      const newUrl = location.pathname + (location.search || '');
      history.replace(newUrl);
    }

    const referralCode = getUrlParam('referral');
    if (referralCode) this.setState({ code: referralCode });
  }

  componentWillUnmount() {
    clearInterval(this.motionInterval);
  }

  isReferralPage = () => !!this.props.referringUser;

  isInvitePage = () => {
    const { location } = this.props;
    return location.pathname.includes('/invite/');
  };

  getPromotionImages = () => {
    /*
      1, 4, 8 or 16 images
    */
    let images = [];
    if (this.isReferralPage()) {
      images = this.props.referringUser.example_pins
        ? _.map(this.props.referringUser.example_pins, 'image')
        : [...Array(8).keys()].map(k => this.props.referringUser.image);
    } else {
      const brandTheme = getBrandTheme(this.props.ui);
      if (brandTheme?.allow_theming) {
        images = brandTheme.promotionPageImages ? brandTheme.promotionPageImages.split(',') : [...Array(8).keys()].map(k => brandTheme?.logo);
      } else {
        images = [...IMAGE_OPTIONS_LEFT, ...IMAGE_OPTIONS_RIGHT];
      }
    }

    return images;
  };

  getNumImages = () => {
    const images = this.getPromotionImages();
    if (images.length >= 16) return 16;
    if (images.length >= 8) return 8;
    if (images.length >= 4) return 4;
    if (images.length >= 2) return 2;
    if (images.length >= 1) return 1;
    return 0;
  };

  hasNoImages = () => this.getNumImages() === 0;
  hasSingleImage = () => this.getNumImages() === 1;
  hasTwoImages = () => this.getNumImages() === 2;
  hasBasicImages = () => this.getNumImages() === 4;
  hasStandardImages = () => this.getNumImages() === 8;
  hasAdvancedFadeImages = () => this.getNumImages() === 16;
  promotionalImagesShouldHavePadding = () => (getBrandTheme(this.props.ui) ? getBrandTheme(this.props.ui).promotionPageImagePadding : true);

  getLeftTiles = () => this.getTiles(true);
  getRightTiles = () => this.getTiles(false);
  getTiles = isLeft => {
    const brandTheme = getBrandTheme(this.props.ui);
    const backupImage = this.isReferralPage() ? this.props.referringUser?.image || defaultPhoto : brandTheme?.logo;
    const tiles = this.getPromotionImages();
    const hasNoImages = this.hasNoImages();
    const hasSingleImage = this.hasSingleImage();
    const hasTwoImages = this.hasTwoImages();
    const hasBasicImages = this.hasBasicImages();
    const hasStandardImages = this.hasStandardImages();
    const hasAdvancedFadeImages = this.hasAdvancedFadeImages();
    return hasNoImages
      ? [
          { images: [backupImage] },
          { images: [backupImage] },
          { images: [backupImage] },
          { images: [backupImage] },
          { images: [backupImage] },
          { images: [backupImage] }
        ]
      : hasSingleImage
      ? [
          { images: [tiles[0]] },
          { images: [backupImage || tiles[0]] },
          { images: [tiles[0]] },
          { images: [backupImage || tiles[0]] },
          { images: [tiles[0]] },
          { images: [backupImage || tiles[0]] }
        ]
      : hasTwoImages
      ? [
          { images: [tiles[isLeft ? 0 : 0]] },
          { images: [backupImage || tiles[isLeft ? 0 : 1]] },
          { images: [tiles[isLeft ? 1 : 1]] },
          { images: [backupImage || tiles[isLeft ? 1 : 0]] },
          { images: [tiles[isLeft ? 0 : 0]] },
          { images: [backupImage || tiles[isLeft ? 0 : 1]] }
        ]
      : hasBasicImages
      ? [
          { images: [tiles[isLeft ? 1 : 1]] },
          { images: [tiles[isLeft ? 0 : 0]] },
          { images: [tiles[isLeft ? 3 : 3]] },
          { images: [isLeft ? tiles[2] : backupImage || tiles[2]] },
          { images: [isLeft ? backupImage || tiles[1] : tiles[1]] },
          { images: [tiles[isLeft ? 0 : 0]] }
        ]
      : hasStandardImages
      ? [
          { images: [tiles[isLeft ? 1 : 4]] },
          { images: [tiles[isLeft ? 0 : 5]] },
          { images: [tiles[isLeft ? 3 : 6]] },
          { images: [tiles[isLeft ? 2 : 7]] },
          { images: [tiles[isLeft ? 1 : 4]] },
          { images: [tiles[isLeft ? 0 : 5]] }
        ]
      : hasAdvancedFadeImages
      ? [
          { images: isLeft ? tiles.slice(2, 4) : tiles.slice(2 + 8, 4 + 8) },
          { images: isLeft ? tiles.slice(0, 2) : tiles.slice(0 + 8, 2 + 8) },
          { images: isLeft ? tiles.slice(6, 8) : tiles.slice(6 + 8, 8 + 8) },
          { images: isLeft ? tiles.slice(4, 6) : tiles.slice(4 + 8, 6 + 8) },
          { images: isLeft ? tiles.slice(2, 4) : tiles.slice(2 + 8, 4 + 8) },
          { images: isLeft ? tiles.slice(0, 2) : tiles.slice(0 + 8, 2 + 8) }
        ]
      : [];
  };

  state = {
    tilesLeft: _.map(this.getLeftTiles(), d => ({ ...d, inMotion: false })),
    tilesRight: _.map(this.getRightTiles(), d => ({ ...d, inMotion: false })),
    removePromotionImagePadding: !this.promotionalImagesShouldHavePadding(),
    curImageIndex: 0,

    // Form Info
    name: '',
    email: '',
    username: '',
    password: '',
    code: '',
    licenseNumber: '',
    licenseType: window.__IS_PRO__ ? DEFAULT_PRO_LICENSE_TYPE : null,
    licenseState: '',

    // For Referrals
    invitationEmails: '',
    successfullySentReferralEmails: false,
    sendingReferralEmails: false

    // For testing
    // name: `Test User`,
    // email: `user+${Math.floor(Math.random() * 10000)}@shopmy.us`,
    // username: `user${Math.floor(Math.random() * 10000)}`,
    // password: `CTct23##`
  };

  startMotion = () => {
    this.setState({
      tilesLeft: _.map(this.state.tilesLeft, t => ({ ...t, inMotion: true })),
      tilesRight: _.map(this.state.tilesRight, t => ({ ...t, inMotion: true }))
    });
    this.motionInterval = setInterval(() => {
      this.state.tilesLeft[0]?.images?.length > 1 &&
        this.setState({
          curImageIndex: this.state.curImageIndex < this.state.tilesLeft[0].images.length - 1 ? this.state.curImageIndex + 1 : 0
        });
    }, 5400);
  };

  canSubmit = () => {
    const { email, password, name, username, code, licenseState, licenseNumber, licenseType } = this.state;
    return !!(name && email && password && username && code && (!window.__IS_PRO__ || (licenseState && licenseNumber && licenseType)));
  };

  handleRegister = async event => {
    /*
      This code is used twice, once here and once in the LandingApplyPanel component. If
      we have a third use of it, refactor this.

      If you find a bug here, please make sure to fix it in the LandingApplyPanel as well.
    */
    event.preventDefault();
    const { registerUser, ui, referringUser } = this.props;
    const { email, password, name, username, code, licenseState, licenseNumber, licenseType, isSubmitting } = this.state;

    const userIsBanned = referringUser?.isBanned;
    if (userIsBanned) return window.ALERT.error('This account has been restricted from referring others.');

    const brandTheme = getBrandTheme(ui);
    if (isSubmitting) return;
    if (!name) return window.ALERT.error('Name field cannot be left blank.');
    if (!email) return window.ALERT.error('Email field cannot be left blank.');
    if (!password) return window.ALERT.error('Password field cannot be left blank.');
    if (!code) return window.ALERT.error('Invitation code field cannot be left blank.');

    if (window.__IS_PRO__) {
      if (!licenseState) return window.ALERT.error('Please select the state of your license.');
      if (!licenseNumber) return window.ALERT.error('Please input a valid license number.');
      if (!licenseType) return window.ALERT.error('Please select your license type.');
    }
    if (!validateUsername(username)) return;

    this.setState({ isSubmitting: true });
    try {
      const useCodeResp = await useCodePreRegistration({ email, username, code });
      if (useCodeResp.success) {
        try {
          let newUser = { email, password, username, name, code, licenseState, licenseType: licenseType?.value || licenseType, licenseNumber };
          if (brandTheme) newUser.ReferringBrand_id = brandTheme.id;
          const handleRedirect = user => this.props.history.push(`/${username}`);
          const registrationResp = await registerUser(newUser, handleRedirect);
          if (registrationResp.success) {
            window.__ADD_EVENT__(`General - Successfully Registered`, {
              code,
              email
            });
            window.scrollTo(0, 0);
          } else {
            window.__ADD_EVENT__(`General - Error Registering`, {
              error: registrationResp ? JSON.stringify(registrationResp) : 'Unknown'
            });
          }
        } catch (error) {
          console.error(error);
          window.ALERT.error('There was an issue with registration. Please try again.');
        }
      } else {
        window.ALERT.error(_.get(useCodeResp, 'error', 'There was an issue with this code. Please try again.'));
        window.__ADD_EVENT__(`General - Error Registering`, {
          error: useCodeResp ? JSON.stringify(useCodeResp) + ` Code: ${code}, Email: ${email}` : 'Unknown'
        });
      }
    } catch (error) {
      console.error(error);
      window.ALERT.error('There was an issue with this code. Please try again.');
    }
    this.setState({ isSubmitting: false });
  };

  updateUsername = username => {
    const formattedUsername = username.toLowerCase().replace(/[^A-Z a-z0-9]/, '');
    this.updateFormField('username', formattedUsername);
  };

  updateFormField = (name, value) => {
    if (isLoggedIn(this.props.user)) return window.ALERT.warn(`You are logged in, this is what it will look like for someone you refer!`);
    this.setState({ [name]: value });
  };

  getValidInvitationEmails = () => {
    const { invitationEmails } = this.state;
    const emails = invitationEmails
      .split('\n')
      .join(' ')
      .split(',')
      .join(' ')
      .split(' ');
    const validEmails = _.filter(emails, isValidEmail);
    return validEmails;
  };

  sendReferralEmails = e => {
    e.stopPropagation();
    e.preventDefault();
    const validInvitationEmails = this.getValidInvitationEmails();
    const numEmails = validInvitationEmails.length;
    if (!numEmails) return;
    if (numEmails > 10) return window.ALERT.warn(`Please only enter a maximum of 10 emails at a time.`);

    this.setState({ sendingReferralEmails: true });
    sendReferralEmails(validInvitationEmails, getUserId(this.props.user))
      .then(() => {
        window.ALERT.success(numEmails === 1 ? `Successfully sent email!` : `Successfully sent ${numEmails} emails!`);
        this.setState({ invitationEmails: '', successfullySentReferralEmails: true });
        setTimeout(() => this.setState({ successfullySentReferralEmails: false }), 2400);
      })
      .catch(() => {
        window.ALERT.error(`Had an error sending referral emails, please try again. If this issue persists please share your URL instead.`);
      })
      .finally(() => {
        this.setState({ sendingReferralEmails: false });
      });
  };

  render() {
    const { ui, user, referringUser } = this.props;
    const {
      tilesLeft,
      tilesRight,
      curImageIndex,
      name,
      sendingReferralEmails,
      invitationEmails,
      email,
      password,
      username,
      code,
      licenseNumber,
      isSubmitting
    } = this.state;
    const isReferralPage = this.isReferralPage();
    const isInvitePage = this.isInvitePage();
    const isYourReferralPage = isReferralPage && isYou(referringUser?.username, user);
    const isYourInvitePage = isInvitePage && isYou(referringUser?.username, user);
    const isPromotePage = !isReferralPage && !isInvitePage;
    const brandTheme = getBrandTheme(ui);
    const validInvitationEmails = this.getValidInvitationEmails();
    const userIsBanned = !!referringUser?.isBanned;

    const title =
      userIsBanned && isYourInvitePage
        ? 'Your account has been banned.'
        : isShopper(user)
        ? 'Shopper accounts cannot currently refer others.'
        : userIsBanned
        ? 'This account has been restricted from referring others.'
        : isInvitePage
        ? isManager(user)
          ? 'Invite Your Talent'
          : 'ShopMy Referral Program'
        : isReferralPage
        ? `You've been invited!`
        : `Showcase Your Favorite ${brandTheme?.allow_theming ? `${brandTheme.name} ` : ''}Products`;

    const subtitle =
      userIsBanned && isYourInvitePage
        ? 'Please contact a ShopMy representative for more information. You will not be able to refer others until this is resolved.'
        : isShopper(user)
        ? 'Shopper accounts cannot currently refer others.'
        : userIsBanned
        ? 'Please contact the user who referred you for more information.'
        : isInvitePage
        ? isManager(user)
          ? `Invite your talent and when they create an account you will be automatically assigned as their manager.`
          : `Both you and your referral benefit! Each of you will earn a ${REFERRAL_PAYOUT_RATE}% bonus on the referred creator's commissions and brand collaborations for ${REFERRAL_LENGTH_MONTHS_DISPLAY} months. Paid out weekly.`
        : isReferralPage
        ? isManager(referringUser)
          ? `${referringUser.name} invites you to join ShopMy where you can create and share shoppable product lists with your audience and partner with industry leading brands.`
          : `Join ${referringUser.name} in creating and sharing shoppable product lists with your audience and partner with industry leading brands.`
        : `Build shoppable lists and share links to earn commission on every purchase.`;

    const extraClasses = {
      invite: isInvitePage,
      referral: isReferralPage,
      promote: isPromotePage
    };

    // Don't allow people to access other's invite pages
    if (isInvitePage && !isYourInvitePage) {
      return <Redirect to={{ pathname: `/join/${referringUser?.username}` }} />;
    }

    return (
      <div className='landing-promotion-panel-outer-container'>
        <div className='landing-promotion-panel-container'>
          <div className={cn('landing-promotion-panel-left', extraClasses)}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            {isReferralPage && !isInvitePage && (
              <div className='invitation-container'>
                {isYourReferralPage ? (
                  <Link to={`/invite/${referringUser.username}`}>
                    <FontAwesomeIcon className='back-icn' icon={faChevronLeft} />
                    Send invitations by email
                  </Link>
                ) : (
                  <Link target='_blank' to={`/${referringUser.username}`}>
                    {referringUser.image && <img alt={referringUser.name} src={referringUser.image} />}
                    An invitation from <span className='user'>{referringUser.name}</span>
                  </Link>
                )}
              </div>
            )}
            {isYourInvitePage && !userIsBanned ? (
              <div className='referral-methods-container'>
                <div className='referral-method-container'>
                  <div className='label-container'>
                    <div className='label'>Sharing Method One</div>
                    <div className='sublabel'>Your Invitation Page</div>
                  </div>
                  <div className='copy-container'>
                    <Link className='link' to={`/join/${referringUser.username}`}>
                      {getInviteLink(user)}
                    </Link>
                    <div onClick={() => copyToClipboard(getInviteLink(user), true)} className='copy-btn'>
                      <FontAwesomeIcon icon={faCopy} />
                    </div>
                  </div>
                </div>
                <div className='referral-method-container'>
                  <div className='label-container'>
                    <div className='label'>Sharing Method Two</div>
                    <div className='sublabel'>Send Invitation Email</div>
                  </div>
                  <form onSubmit={this.sendReferralEmails}>
                    <textarea
                      type='text'
                      className='name-input standard-input'
                      placeholder='Email addresses (up to 10)'
                      ref={ref => matchScrollHeight(ref)}
                      rows={3}
                      onChange={({ target }) => this.setState({ invitationEmails: target.value })}
                      value={invitationEmails}
                    />
                    {!!validInvitationEmails.length && (
                      <button onClick={this.sendReferralEmails} type='submit' className='send-emails-btn'>
                        Send{sendingReferralEmails ? 'ing' : ''}{' '}
                        {validInvitationEmails.length === 1 ? 'Email' : `${validInvitationEmails.length} Emails`}
                        {sendingReferralEmails ? '...' : ''}
                      </button>
                    )}
                    {this.state.successfullySentReferralEmails && <div className='successful-sent-msg'>Successfully sent!</div>}
                  </form>
                </div>

                <div className='referral-method-container'>
                  <div className='label-container'>
                    <div className='label'>Sharing Method Three</div>
                    <div className='sublabel'>
                      Scan QR Code <span style={{ fontSize: '.8rem' }}>(click to copy)</span>
                    </div>
                  </div>

                  {/* Copyable QR code */}
                  <div className='qr-holder'>
                    <QRCode
                      value={getInviteLink(user)}
                      onClick={e => {
                        const { ClipboardItem } = window;

                        e.target.toBlob(blob => {
                          const item = new ClipboardItem({ 'image/png': blob });
                          navigator.clipboard.write([item]);
                          window.ALERT.success('Copied QR Code!', { hideAfter: 1 });
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : !userIsBanned && !isShopper(user) ? (
              <form className={cn('register-form', extraClasses)} onSubmit={this.handleRegister}>
                <input
                  type='text'
                  className='name-input standard-input'
                  placeholder='Full Name'
                  onChange={({ target }) => this.updateFormField('name', target.value)}
                  value={name}
                />
                <input
                  type='email'
                  className='email-input standard-input'
                  placeholder='Email Address'
                  onChange={({ target }) => this.updateFormField('email', target.value)}
                  value={email}
                />
                <input
                  type='password'
                  className='password-input standard-input'
                  placeholder='Set a Password'
                  onChange={({ target }) => this.updateFormField('password', target.value)}
                  value={password}
                />
                <input
                  type='text'
                  className='username-input standard-input'
                  placeholder='Username'
                  onChange={({ target }) => this.updateUsername(target.value)}
                  value={username}
                />
                <input
                  type='text'
                  className='code-input standard-input'
                  placeholder='Invitation Code'
                  onChange={({ target }) => this.updateFormField('code', target.value)}
                  value={code}
                />
                {window.__IS_PRO__ ? (
                  <>
                    <Select
                      placeholder='License Type'
                      className='license-select'
                      options={isNCEA(brandTheme) ? PRO_LICENSE_TYPES_NCEA : PRO_LICENSE_TYPES}
                      defaultValue={DEFAULT_PRO_LICENSE_TYPE}
                      onChange={({ value, label }) => this.setState({ licenseType: value })}
                    />
                    <input
                      type='text'
                      className='code-input standard-input'
                      placeholder='License Number'
                      onChange={({ target }) => this.setState({ licenseNumber: target.value })}
                      value={licenseNumber}
                    />
                    <Select
                      placeholder='State of License'
                      className='license-select'
                      options={US_STATES.map(({ abbreviation, name }) => ({ value: abbreviation, label: name }))}
                      onChange={({ value, label }) => this.setState({ licenseState: value })}
                    />
                  </>
                ) : null}
                {this.canSubmit() && (
                  <div className='register-btn-container'>
                    <input type='submit' className='register-btn' value={isSubmitting ? 'CREATING ACCOUNT...' : 'CREATE ACCOUNT'} />
                  </div>
                )}
              </form>
            ) : null}
          </div>
        </div>
        <div className='landing-promotion-panel-floating-products'>
          <div className='floating-products-col left'>
            {tilesLeft.map((tile, idx) => {
              return (
                <div
                  key={idx}
                  className={cn('floating-product', {
                    moving: tile.inMotion
                  })}
                >
                  {tile.images.map((img, idx) => (
                    <img
                      alt={`Popular Product ${idx} Left`}
                      key={idx}
                      className={cn({ active: idx === curImageIndex, removePadding: this.state.removePromotionImagePadding })}
                      src={getSmartImage(tile.images[idx])}
                    />
                  ))}
                </div>
              );
            })}
          </div>
          <div className='floating-products-col right'>
            {tilesRight.map((tile, idx) => {
              return (
                <div
                  key={idx}
                  className={cn('floating-product', {
                    moving: tile.inMotion
                  })}
                >
                  {tile.images.map((img, idx) => (
                    <img
                      alt={`Popular Product ${idx} Right`}
                      key={idx}
                      className={cn({ active: idx === curImageIndex, removePadding: this.state.removePromotionImagePadding })}
                      src={tile.images[idx]}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        {/* {isSearchingForUsers && ( */}
        {/*   <SearchContentModal type='users' closeModal={() => this.setState({ isSearchingForUsers: false })} selectUser={() => {}} /> */}
        {/* )} */}
      </div>
    );
  }
}

export default withRouter(LandingPromotionPanel);
