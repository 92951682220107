import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const { getUsername, isBrand, isShopper } = require('../../Helpers/user_helpers');

const RootRedirect = props => {
  const { loggedInUsername, isBrand, isShopper } = props;
  return <Redirect to={{ pathname: isShopper ? '/shop' : loggedInUsername ? (isBrand ? `/talent` : `/links`) : '/home' }} />;
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    isShopper: isShopper(user),
    loggedInUsername: getUsername(user),
    isBrand: isBrand(user)
  };
};

export default connect(mapStateToProps, {})(RootRedirect);
