import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { withRouter } from 'react-router-dom';
import './ContractContentCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faLink } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ContractPin from './ContractPin';
import ContractCollection from './ContractCollection';
import ContractConsult from './ContractConsult';
import ContractMedia from './ContractMedia';
import SearchContentModal from '../../Modals/SearchContentModal';
import SearchMediaModal from '../../Modals/SearchMediaModal';
import ConfirmPrompt from '../../General/ConfirmPrompt';
import Loader from '../../Loader/Loader';
import FacebookSDK from '../../General/FacebookSDK';

import { editPin } from '../../../APIClient/pins';
import { createCollection, saveCollection, deleteCollection as deleteCollectionAPI } from '../../../APIClient/collections';
import { addConsult as addConsultAPI, deleteConsult as deleteConsultAPI, editConsult } from '../../../APIClient/consults';
import { addMedia as addMediaAPI, updateMedia as updateMediaAPI, deleteMedia as deleteMediaAPI } from '../../../APIClient/media';
import { getCollaborationTypeIcon, getDisplayForCollaborationType } from '../../../Helpers/collaboration_helpers';
import { getSocialAccount } from '../../../Helpers/user_helpers';
import { openInstagramAuthModal, openYoutubeAuthModal, openTikTokAuthModal } from '../../../Helpers/social_helpers';
import { getPrettyNumber, getServerFormattedDate } from '../../../Helpers/formatting';
import { isAdmin } from '../../../Helpers/user_helpers';
import { getCollectionsTitle, getSiteTitle } from '../../../Helpers/helpers';
import { getTitleForMedia } from '../../../Helpers/contract_helpers';

const ContractContentCard = props => {
  const { user, canEdit, isPreviewMode, getContractAnalytics, contract } = props;
  const { id, pins, collections, media, analytics, consults } = contract;

  // const unlinkPin = pin => editPin(pin.id, { Contract_id: null }).then(props.syncContract);
  const unlinkCollection = collection => saveCollection(collection.id, { Contract_id: null }).then(props.syncContract);
  const unlinkConsult = consult => editConsult(consult.id, { Contract_id: null }).then(props.syncContract);

  const linkPin = pin => editPin(pin.id, { Contract_id: contract.id }).then(props.syncContract);
  const linkCollection = collection => saveCollection(collection.id, { Contract_id: contract.id }).then(props.syncContract);
  const linkConsult = consult => editConsult(consult.id, { Contract_id: contract.id }).then(props.syncContract);

  const addPin = () => props.toggleAddOrEditPinMode();

  const [isSearchingContentType, setIsSearchingContentType] = useState(null);
  const [isShowingConnectExisting, setIsShowingConnectExisting] = useState(false);
  const searchContent = type => setIsSearchingContentType(type);

  const [isSearchingMediaType, setIsSearchingMediaType] = useState(null);
  const [isAddingNewMedia, setIsAddingNewMedia] = useState(null);
  const instaAccount = getSocialAccount(user, 'instagram');
  const youtubeAccount = getSocialAccount(user, 'youtube');
  const tiktokAccount = getSocialAccount(user, 'tiktok');
  const hasSocialAccount = instaAccount || youtubeAccount || tiktokAccount;
  const openMediaSearch = type => {
    if (type === 'instagram') {
      instaAccount
        ? setIsSearchingMediaType(type)
        : openInstagramAuthModal(
            authResponse => {
              props
                .connectInstagram(authResponse)
                .then(resp => {})
                .catch(errorMessage => {
                  cogoToast.error(errorMessage);
                });
            },
            () => this.props.openConnectInstagramModal()
          );
    }
    if (type === 'youtube') {
      youtubeAccount ? setIsSearchingMediaType(type) : openYoutubeAuthModal();
    }
    if (type === 'tiktok') {
      tiktokAccount ? setIsSearchingMediaType(type) : openTikTokAuthModal();
    }
  };

  const addCollection = () => {
    createCollection({
      name: `Collaboration With ${contract.brand.name}`,
      User_id: contract.User_id,
      Contract_id: contract.id,
      isArchived: true,
      private: true
    }).then(props.syncContract);
  };

  const addConsult = () => {
    addConsultAPI({
      title: `Collaboration With ${contract.brand.name}`,
      User_id: contract.User_id,
      Contract_id: contract.id,
      isPrivate: true
    }).then(props.syncContract);
  };

  const addMedia = async (media, type) => {
    const socialAccount = getSocialAccount(user, type);
    setIsSearchingMediaType(null);
    setIsAddingNewMedia(true);
    type === 'instagram' &&
      (await addMediaAPI({
        type,
        subtype: media.media_type,
        mediaId: media.id,
        image: media.thumbnail_url || media.image || media.media_url,
        url: media.permalink,
        UserSocialAccount_id: socialAccount.id,
        contentCreatedAt: getServerFormattedDate(media.timestamp),
        User_id: contract.User_id,
        Contract_id: contract.id
      }).then(props.syncContract));

    type === 'youtube' &&
      (await addMediaAPI({
        type,
        title: media.title,
        mediaId: media.videoId,
        image: media.thumbnails?.high?.url,
        url: `https://www.youtube.com/watch?v=${media.videoId}`,
        UserSocialAccount_id: socialAccount.id,
        contentCreatedAt: getServerFormattedDate(media.publishedAt),
        User_id: contract.User_id,
        Contract_id: contract.id
      }).then(props.syncContract));

    type === 'tiktok' &&
      (await addMediaAPI({
        type,
        title: media.title,
        mediaId: media.id,
        image: media.cover_image_url,
        url: media.embed_link,
        UserSocialAccount_id: socialAccount.id,
        contentCreatedAt: getServerFormattedDate(media.create_time),
        User_id: contract.User_id,
        Contract_id: contract.id
      }).then(props.syncContract));

    type === 'custom' &&
      (await addMediaAPI({
        type,
        ...(media.title ? { title: media.title } : {}),
        ...(media.image ? { image: media.image } : {}),
        ...(media.url ? { url: media.url } : {}),
        ...(media.views ? { views: parseFloat(media.views) } : {}),
        ...(media.engagement ? { engagement: parseFloat(media.engagement) } : {}),
        contentCreatedAt: getServerFormattedDate(new Date()),
        User_id: contract.User_id,
        Contract_id: contract.id
      }).then(props.syncContract));
    setIsAddingNewMedia(false);
  };

  const addStaticMedia = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Report your results.'
          subheader='Upload the results of your social content. We highly recommend uploading a screenshot on the Uploads tab to go along with your self-reported data.'
          onCancel={onClose}
          customInputFields={[
            { display: 'Title', placeholder: 'My Instagram Post', value: 'title', isSingleLine: true },
            { display: 'Number of Views', placeholder: '2500', value: 'views', isSingleLine: true },
            { display: 'Engagement (# Likes, # Comments, etc.)', placeholder: '150', value: 'engagement', isSingleLine: true },
            { display: 'Content URL (optional)', placeholder: 'https://www.instagram.com/p/CI3Z4QzATPI/', value: 'url', isSingleLine: true },
            { display: 'Image URL (optional)', placeholder: 'https://content.com/image.png', value: 'image', isSingleLine: true }
          ]}
          onSubmit={responseValues => {
            addMedia(responseValues, 'custom');
          }}
        />
      )
    });
  };

  const editMedia = media => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          onCancel={onClose}
          customInputFields={[
            { display: 'Title', placeholder: getTitleForMedia(media), preloaded: media.title, value: 'title', isSingleLine: true },
            {
              display: 'Number of Views',
              placeholder: '2500',
              preloaded: media.views,
              value: 'views',
              isSingleLine: true,
              disabledMsg:
                !isAdmin(user) && media.type !== 'custom'
                  ? `This value is tracked via your ${getDisplayForCollaborationType(media.type)} integration.`
                  : ''
            },
            {
              display: 'Engagement (# Likes, # Comments, etc.)',
              placeholder: '150',
              preloaded: media.engagement,
              value: 'engagement',
              isSingleLine: true,
              disabledMsg:
                !isAdmin(user) && media.type !== 'custom'
                  ? `This value is tracked via your ${getDisplayForCollaborationType(media.type)} integration.`
                  : ''
            },
            {
              display: 'Content URL (optional)',
              placeholder: 'https://www.instagram.com/p/CI3Z4QzATPI/',
              preloaded: media.url,
              value: 'url',
              isSingleLine: true
            },
            {
              display: 'Image URL (optional)',
              placeholder: 'https://content.com/image.png',
              preloaded: media.image,
              value: 'image',
              isSingleLine: true
            }
          ]}
          onSubmit={responseValues => {
            updateMediaAPI(media.id, responseValues).then(props.syncContract);
          }}
        />
      )
    });
  };

  const deleteCollection = collection => {
    const remove = () => deleteCollectionAPI(collection.id).then(props.syncContract);
    collection.pins.length
      ? confirmAlert({
          title: 'Just confirming',
          message: `Would you like to completely delete this ${getCollectionsTitle()} or just disconnect it? Disconnecting it means you will keep the ${getCollectionsTitle()} but any clicks or orders driven by this ${getCollectionsTitle()} will be removed from the collaboration analytics.`,
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            { label: `Delete Entirely`, className: 'secondary', onClick: remove },
            { label: 'Disconnect', onClick: () => unlinkCollection(collection) }
          ]
        })
      : remove();
  };
  const deleteConsult = consult => {
    const confirmed = () => deleteConsultAPI(consult).then(props.syncContract);
    consult.content
      ? confirmAlert({
          title: 'Just confirming',
          message: `Would you like to completely delete this consultation or just disconnect it? Disconnecting it means you will keep the consultation in your shop but any clicks or orders driven by this consultation will be removed from the collaboration analytics.`,
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            { label: 'Delete Entirely', className: 'secondary', onClick: confirmed },
            { label: 'Disconnect', onClick: () => unlinkConsult(consult) }
          ]
        })
      : confirmed();
  };
  const deletePin = pin => {
    confirmAlert({
      title: 'Just confirming',
      message:
        'Are you sure you want to delete this link? Any clicks or orders driven by this link will be removed from the collaboration analytics.',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Delete', onClick: () => props.deletePin(pin) }
      ]
    });
  };
  const deleteMedia = media => {
    const confirmed = () => deleteMediaAPI(media).then(props.syncContract);
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to remove this content?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Delete', onClick: confirmed }
      ]
    });
  };
  const hasPins = !!pins.length;
  const hasCollections = !!collections.length;
  const hasConsults = !!consults.length;
  const hasMedia = !!media.length;
  const hasContent = hasPins || hasCollections || hasConsults || hasMedia;

  useEffect(() => {
    getContractAnalytics(id);
  }, [getContractAnalytics, id]);

  return (
    <div className='contract-content-card-outer-container card'>
      {canEdit && <FacebookSDK />}
      {isPreviewMode ? (
        <div className='card-header-container'>
          <div>
            <div className='card-header'>Connected Links</div>
            {canEdit ? (
              <div className='card-subheader'>
                Connect links, social content and more on the{' '}
                <span className='link' onClick={() => props.selectStepByType('analytics')}>
                  Links & Analytics
                </span>{' '}
                tab.
              </div>
            ) : (
              <div className='card-subheader'>Clicks, Impact, ROI analytics.</div>
            )}
          </div>
        </div>
      ) : (
        <div className='card-header-container dark'>
          <div>
            <div className='card-header'>Connected Content</div>
            {canEdit ? (
              <div className='card-subheader'>Connect any content associated with this collaboration.</div>
            ) : (
              <div className='card-subheader'>Clicks, Impact, ROI analytics.</div>
            )}
          </div>
          <div className='analytics'>
            <div className='tile'>
              <div className={cn('value', { empty: analytics && !analytics.views })}>
                {analytics ? (analytics.views && getPrettyNumber(analytics.views)) || '-' : <Loader size={32} />}
              </div>
              <div className='label'>Views</div>
            </div>
            <div className='tile'>
              <div className={cn('value', { empty: analytics && !analytics.engagement })}>
                {analytics ? (analytics.engagement && getPrettyNumber(analytics.engagement)) || '-' : <Loader size={32} />}
              </div>
              <div className='label'>Engagements</div>
            </div>
            <div className='tile'>
              <div className={cn('value', { empty: analytics && !analytics.clicks })}>
                {analytics ? (analytics.clicks && getPrettyNumber(analytics.clicks)) || '-' : <Loader size={32} />}
              </div>
              <div className='label'>Clicks</div>
            </div>
            <div className='tile'>
              <div className={cn('value', { empty: analytics && !analytics.volume })}>
                {analytics ? (analytics.volume && `$${getPrettyNumber(analytics.volume)}`) || '-' : <Loader size={32} />}
              </div>
              <div className='label'>Volume</div>
            </div>
          </div>
        </div>
      )}
      <div className='card-body-container'>
        {hasPins && (
          <div className='content-type-section'>
            {(hasCollections || hasConsults || hasMedia) && <div className='type-header'>Links</div>}
            <div className='type-group'>
              {pins.map(pin => (
                <ContractPin
                  contract={contract}
                  pin={pin}
                  key={pin.id}
                  canEdit={canEdit}
                  isPreviewMode={isPreviewMode}
                  deletePin={deletePin}
                  toggleAddOrEditPinMode={props.toggleAddOrEditPinMode}
                />
              ))}
            </div>
          </div>
        )}
        {hasCollections && (
          <div className='content-type-section'>
            {(hasPins || hasConsults || hasMedia) && <div className='type-header'>{getCollectionsTitle({ uppercase: true, plural: true })}</div>}
            <div className='type-group'>
              {collections.map(collection => (
                <ContractCollection
                  contract={contract}
                  collection={collection}
                  key={collection.id}
                  canEdit={canEdit}
                  isPreviewMode={isPreviewMode}
                  deleteCollection={deleteCollection}
                />
              ))}
            </div>
          </div>
        )}
        {hasConsults && (
          <div className='content-type-section'>
            {(hasPins || hasCollections || hasMedia) && <div className='type-header'>Consultations</div>}
            <div className='contract-group'>
              {consults.map(consult => (
                <ContractConsult
                  contract={contract}
                  consult={consult}
                  key={consult.id}
                  canEdit={canEdit}
                  isPreviewMode={isPreviewMode}
                  deleteConsult={deleteConsult}
                />
              ))}
            </div>
          </div>
        )}
        {(hasMedia || isAddingNewMedia) && (
          <div className='content-type-section'>
            {(hasPins || hasCollections || hasConsults) && <div className='type-header'>Media</div>}
            <div className='contract-group'>
              {media.map(media => (
                <ContractMedia
                  contract={contract}
                  media={media}
                  key={media.id}
                  canEdit={canEdit}
                  isPreviewMode={isPreviewMode}
                  deleteMedia={deleteMedia}
                  editMedia={editMedia}
                />
              ))}
              {isAddingNewMedia && (
                <div>
                  <Loader size={80} />
                </div>
              )}
            </div>
          </div>
        )}
        {!hasContent && !canEdit && !isPreviewMode && (
          <div className='content-type-section'>
            <div className='empty-msg'>{contract.user.name} has not yet attached any content</div>
          </div>
        )}
      </div>
      {canEdit && !isPreviewMode && (
        <div className='card-footer-container additional-content-actions'>
          <div className='panel'>
            <div className='panel-header-container'>
              <div className='panel-header'>Create trackable links</div>
              <div className='panel-subheader'>
                Create {getSiteTitle()} trackable links and content that correspond to this collaboration that you plan to share. If you want to
                attach links or {getCollectionsTitle({ plural: true })} you've already created please click "Connect Existing".
              </div>
            </div>
            <div className='actions'>
              <div onClick={addPin} className='action'>
                <FontAwesomeIcon icon={faPlus} />
                New Link
              </div>
              <div onClick={addCollection} className='action'>
                <FontAwesomeIcon icon={faPlus} />
                New {getCollectionsTitle({ uppercase: true })}
              </div>
              <div onClick={addConsult} className='action'>
                <FontAwesomeIcon icon={faPlus} />
                New Consult
              </div>
              {isShowingConnectExisting ? (
                <>
                  <div className='actions'>
                    <div onClick={() => searchContent('pins')} className='action'>
                      <FontAwesomeIcon icon={faLink} />
                      Existing Link
                    </div>
                    <div onClick={() => searchContent('collections')} className='action'>
                      <FontAwesomeIcon icon={faLink} />
                      Existing {getCollectionsTitle({ uppercase: true })}
                    </div>
                    <div onClick={() => searchContent('consults')} className='action'>
                      <FontAwesomeIcon icon={faLink} />
                      Existing Consultation
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div onClick={() => setIsShowingConnectExisting(true)} className='action'>
                    <FontAwesomeIcon icon={faLink} />
                    Connect Existing
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='panel'>
            <div className='panel-header-container'>
              <div className='panel-header'>Connect Social Content</div>
              {hasSocialAccount ? (
                <div className='panel-subheader'>Select posts that correspond with this collaboration.</div>
              ) : (
                <div className='panel-subheader'>
                  Link your accounts first. After linking your accounts, select posts that correspond with this collaboration.
                </div>
              )}
            </div>
            <div className='actions'>
              <div onClick={() => openMediaSearch('instagram')} className='action'>
                <img className='social-icn' src={getCollaborationTypeIcon('instagram')} alt='Instagram Logo' />
                {instaAccount ? `From Instagram` : `Connect Instagram`}
              </div>
              <div onClick={() => openMediaSearch('youtube')} className='action'>
                <img className='social-icn' src={getCollaborationTypeIcon('youtube')} alt='YouTube Logo' />
                {youtubeAccount ? `From YouTube` : `Connect YouTube`}
              </div>
              <div onClick={() => openMediaSearch('tiktok')} className='action'>
                <img className='social-icn' src={getCollaborationTypeIcon('tiktok')} alt='YouTube Logo' />
                {tiktokAccount ? `From TikTok` : `Connect TikTok`}
              </div>
              <div onClick={addStaticMedia} className='action'>
                <img className='social-icn' src={getCollaborationTypeIcon()} alt='ShopMy Logo' />
                Report Custom Results
              </div>
            </div>
          </div>
        </div>
      )}
      {isSearchingContentType && (
        <SearchContentModal
          allowMultiSelect
          type={isSearchingContentType}
          Editing_User_id={contract.user.id}
          closeModal={() => setIsSearchingContentType(null)}
          selectPin={linkPin}
          selectCollection={linkCollection}
          selectConsult={linkConsult}
          contract={contract}
        />
      )}
      {isSearchingMediaType && (
        <SearchMediaModal
          type={isSearchingMediaType}
          Searching_User_id={contract.user.id}
          selectResult={addMedia}
          closeModal={() => setIsSearchingMediaType(null)}
          contract={contract}
        />
      )}
    </div>
  );
};

ContractContentCard.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  getContractAnalytics: PropTypes.func.isRequired,
  selectStepByType: PropTypes.func.isRequired,
  syncContract: PropTypes.func.isRequired,
  deletePin: PropTypes.func.isRequired,
  toggleAddOrEditPinMode: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired,

  // Optional
  isPreviewMode: PropTypes.bool
};

export default withRouter(ContractContentCard);
