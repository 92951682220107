import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './TasteProfileSimilarCurators.scss';

import { openCuratorGroupModal } from '../../Actions/UIActions';

import CuratorCardNew from '../Curators/CuratorCardNew';

const TasteProfileSimilarCurators = props => {
  const { curators, isFetching, page, limit, isFetchingFirstPage, fetchNextPage } = props;

  const maxResults = 250;
  const hasMore = curators.length < maxResults;

  return (
    <div className='taste-profile-similar-curators-container'>
      <div className='similar-curators-header-container'>
        <div className='similar-curators-header'>Curators with Similar Taste</div>
        <div className='similar-curators-subheader'>Based on the recent products you've added.</div>
      </div>

      <div className='similar-curators'>
        {isFetchingFirstPage
          ? _.range(limit * (page + 1)).map(i => {
              return <CuratorCardNew user={props.user} isLoading={true} />;
            })
          : curators.map(curator => {
              return (
                <CuratorCardNew
                  user={props.user}
                  curator={curator}
                  openCuratorGroupModal={props.openCuratorGroupModal}
                  activeFilters={{
                    tab: 'foryou'
                  }}
                />
              );
            })}
      </div>
      {hasMore && (
        <div className='get-next-page-outer'>
          <div className='get-next-page' onClick={fetchNextPage}>
            {isFetching ? 'Getting...' : `Get ${props.limit} more`}
          </div>
        </div>
      )}
    </div>
  );
};

TasteProfileSimilarCurators.propTypes = {
  user: PropTypes.object.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  limit: PropTypes.number,
  page: PropTypes.number,
  isFetching: PropTypes.bool.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openCuratorGroupModal
})(TasteProfileSimilarCurators);
