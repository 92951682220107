import React, { useState } from 'react';
import './CatalogControlBar.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarsFilter, faTimes } from '@fortawesome/pro-regular-svg-icons';
import CatalogControlBarFiltersMenu from './CatalogControlBarFiltersMenu';
import InputActions from '../General/InputActions';
import { getBrandSettings, isSimulatingUser } from '../../Helpers/user_helpers';
import ProfilePhoto from '../Profile/ProfilePhoto';

const CatalogControlBar = props => {
  const {
    user,
    ui,
    curTab,
    setCurTab,
    canSupportCustomRates,
    controlBarSearchTerm,
    setControlBarSearchTerm,
    catalogGroups,
    controlBarActiveFilters,
    setControlBarFilterValue
  } = props;
  const brandSettings = getBrandSettings(user);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [shouldShowCatalogInfoBanner, setShouldShowCatalogInfoBanner] = useState(!brandSettings?.hasDismissedCatalogInfoBanner);

  const tabOptions = [
    { name: 'Products', value: 'products' },
    { name: 'Groups', value: 'groups', hidden: !canSupportCustomRates },
    { name: 'Commission Rates', value: 'rates' }
  ];

  const visibleTabOptions = tabOptions.filter(option => option.hidden !== true);

  const handleTabChange = newTab => {
    if (newTab === curTab) return;
    setCurTab(newTab);
  };

  const isFilterUnavailable = ['rates', 'groups'].includes(curTab);
  const handleOpenFiltersMenu = () => {
    if (isFilterUnavailable) return;
    setIsFilterMenuOpen(true);
  };

  const getFilterValueLabel = (filterKey, filterValue) => {
    if (filterKey === 'CatalogGroup') {
      return filterValue.title;
    }
  };

  const getFilterValueCount = (filterKey, filterValue) => {
    if (filterKey === 'CatalogGroup') {
      return filterValue.variants_count || 0;
    }
  };

  const handleDismissInfoBanner = async () => {
    if (isSimulatingUser(user)) return window.ALERT.warn('You cannot dismiss this banner when simulating a brand.');
    await props.updateBrandSettings({ hasDismissedCatalogInfoBanner: true });
    setShouldShowCatalogInfoBanner(false);
  };

  return (
    <div className='catalog-control-bar-container'>
      <div className='catalog-control-bar'>
        <div className='control-bar-search has-actions'>
          <FontAwesomeIcon icon={faSearch} />
          <input type='text' placeholder='Search...' value={controlBarSearchTerm} onChange={e => setControlBarSearchTerm(e.target.value)} />
          <InputActions searchVal={controlBarSearchTerm} onCancel={() => setControlBarSearchTerm('')} />
        </div>
        <div className='control-bar-filters-container'>
          <div className={cn('filters-button', { disabled: isFilterUnavailable })} onClick={handleOpenFiltersMenu}>
            <FontAwesomeIcon icon={faBarsFilter} />
          </div>
          {isFilterMenuOpen && (
            <CatalogControlBarFiltersMenu
              close={() => setIsFilterMenuOpen(false)}
              active={isFilterMenuOpen}
              catalogGroups={catalogGroups}
              setControlBarFilterValue={setControlBarFilterValue}
              controlBarActiveFilters={controlBarActiveFilters}
            />
          )}
        </div>
        <div className='control-bar-center-container'>
          <div className='control-bar-tabs'>
            {visibleTabOptions.map(option => {
              return (
                <div
                  key={option.value}
                  className={cn('tab-container', { active: curTab === option.value })}
                  onClick={() => handleTabChange(option.value)}
                >
                  <span className='control-bar-tab'>{option.name}</span>
                </div>
              );
            })}
          </div>
          <div className='control-bar-active-filters'>
            {Object.keys(controlBarActiveFilters).map(filterKey => {
              const filterValue = controlBarActiveFilters[filterKey];
              return (
                <div key={filterKey} className='active-filter'>
                  {getFilterValueLabel(filterKey, filterValue)}
                  <span>{getFilterValueCount(filterKey, filterValue)}</span>
                  <div className='clear-btn'>
                    <FontAwesomeIcon icon={faTimes} onClick={() => setControlBarFilterValue(filterKey, null)} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='control-bar-info-banner-container'>
        {shouldShowCatalogInfoBanner && (
          <div className='info-banner'>
            <ProfilePhoto ui={ui} basic={true} />
            Welcome to your Catalog. You can use this tool to create commission rates on specific SKUs to incentivize creators to link to your
            products.
            <div className='close-btn' onClick={handleDismissInfoBanner}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CatalogControlBar.propTypes = {
  curTab: PropTypes.string.isRequired,
  setCurTab: PropTypes.func.isRequired,
  canSupportCustomRates: PropTypes.bool.isRequired,
  controlBarSearchTerm: PropTypes.string.isRequired,
  setControlBarSearchTerm: PropTypes.func.isRequired,
  catalogGroups: PropTypes.array.isRequired,
  controlBarActiveFilters: PropTypes.object.isRequired,
  setControlBarFilterValue: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  updateBrandSettings: PropTypes.func.isRequired
};

export default CatalogControlBar;
