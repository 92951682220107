import { useState, useEffect } from 'react';

// Custom hook to get the current URL and update it on history changes with the browser
const useCurrentUrl = () => {
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  useEffect(() => {
    const updateURL = () => setCurrentUrl(window.location.href);

    const interceptHistory = method => {
      const original = window.history[method];
      window.history[method] = (...args) => {
        original.apply(window.history, args);
        updateURL();
      };
    };

    interceptHistory('pushState');
    interceptHistory('replaceState');
    window.addEventListener('popstate', updateURL);

    return () => {
      window.removeEventListener('popstate', updateURL);
    };
  }, []);

  return currentUrl;
};

export default useCurrentUrl;
