import React from 'react';
import _ from 'lodash';

import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import cogoToast from 'cogo-toast';
import { cognitoSignOut, cognitoSignUp, cognitoLogin, hasAxiosUserHash } from '../Auth';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';
import {
  createUser,
  registerShopper as registerShopperAPI,
  fetchUserByEmail,
  fetchUserThroughUsername,
  updateUser,
  updateAddress,
  applyForWaitlist,
  useCodePreRegistration,
  updateUserSettings as updateUserSettingsAPI,
  doesUserExist as doesUserExistAPI,
  getRequests as getRequestsAPI,
  updateRequest as updateRequestAPI,
  deleteRequest as deleteRequestAPI,
  getUserTier as getUserTierAPI,
  fetchEmailFromUserHash
} from '../APIClient/users';
import { generateTasteProfile as generateTasteProfileAPI } from '../APIClient/user_analysis';
import { updateOpportunityRequest as updateOpportunityRequestAPI } from '../APIClient/requests';
import { addTagsToUser as addTagsToUserAPI, deleteTagsForUser as deleteTagsForUserAPI } from '../APIClient/tags';
import { connectStripeAccount as connectStripeAccountAPI } from '../APIClient/stripe';
import {
  connectInstagram as connectInstagramAPI,
  connectYoutube as connectYoutubeAPI,
  connectTiktok as connectTiktokAPI,
  removeSocialAccount as removeSocialAccountAPI
} from '../APIClient/social';
import {
  updateUserAuthenticationToken as updateUserAuthenticationTokenAPI,
  deleteUserAuthenticationToken as deleteUserAuthenticationTokenAPI
} from '../APIClient/user_authentication_tokens';
import { isBrand, getBrandId, getUserId, getUsername, getEmail, getSettings, getTags } from '../Helpers/user_helpers';
import { getAuthCallbackFunctions } from '../Helpers/ui_helpers';
import { sendDataToApp } from '../Helpers/mobile_helpers';
import { setBrandTheme, toggleBodyScrollLock } from './UIActions';

export const SYNC_CURRENT_USER = 'SYNC_CURRENT_USER';
export const SYNC_CURRENT_USER_FAILURE = 'SYNC_CURRENT_USER_FAILURE';
export const SYNC_CURRENT_USER_SUCCESS = 'SYNC_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const STORE_COGNITO_RESPONSE = 'STORE_COGNITO_RESPONSE';
export const STORE_USER_HASH = 'STORE_USER_HASH';

export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE';

export const SYNC_REQUESTS_SUCCESS = 'SYNC_REQUESTS_SUCCESS';
export const SYNC_REQUESTS_FAILURE = 'SYNC_REQUESTS_FAILURE';

export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILURE = 'UPDATE_REQUEST_FAILURE';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';

export const UPDATE_USER_OPPORTUNITY_REQUEST_REQUEST = 'UPDATE_USER_OPPORTUNITY_REQUEST_REQUEST';
export const UPDATE_USER_OPPORTUNITY_REQUEST_SUCCESS = 'UPDATE_USER_OPPORTUNITY_REQUEST_SUCCESS';
export const UPDATE_USER_OPPORTUNITY_REQUEST_FAILURE = 'UPDATE_USER_OPPORTUNITY_REQUEST_FAILURE';

export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

export const UPDATE_USER_SETTINGS_REQUEST = 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_SUCCESS = 'UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE = 'UPDATE_USER_SETTINGS_FAILURE';

export const ADD_TAGS_TO_USER_REQUEST = 'ADD_TAGS_TO_USER_REQUEST';
export const ADD_TAGS_TO_USER_SUCCESS = 'ADD_TAGS_TO_USER_SUCCESS';
export const ADD_TAGS_TO_USER_FAILURE = 'ADD_TAGS_TO_USER_FAILURE';

export const DELETE_TAGS_FOR_USER_REQUEST = 'DELETE_TAGS_FOR_USER_REQUEST';
export const DELETE_TAGS_FOR_USER_SUCCESS = 'DELETE_TAGS_FOR_USER_SUCCESS';
export const DELETE_TAGS_FOR_USER_FAILURE = 'DELETE_TAGS_FOR_USER_FAILURE';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const WAITLIST_APPLICATION_SUCCESS = 'WAITLIST_APPLICATION_SUCCESS';
export const WAITLIST_APPLICATION_FAILURE = 'WAITLIST_APPLICATION_FAILURE';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_ERROR_USERNAME = 'REGISTER_USER_ERROR_USERNAME';

export const CONNECT_INSTAGRAM_ACCOUNT_SUCCESS = 'CONNECT_INSTAGRAM_ACCOUNT_SUCCESS';
export const CONNECT_BRAND_INSTAGRAM_ACCOUNT_SUCCESS = 'CONNECT_BRAND_INSTAGRAM_ACCOUNT_SUCCESS';
export const CONNECT_INSTAGRAM_ACCOUNT_FAILURE = 'CONNECT_INSTAGRAM_ACCOUNT_FAILURE';

export const CONNECT_YOUTUBE_ACCOUNT_SUCCESS = 'CONNECT_YOUTUBE_ACCOUNT_SUCCESS';
export const CONNECT_BRAND_YOUTUBE_ACCOUNT_SUCCESS = 'CONNECT_BRAND_YOUTUBE_ACCOUNT_SUCCESS';
export const CONNECT_YOUTUBE_ACCOUNT_FAILURE = 'CONNECT_YOUTUBE_ACCOUNT_FAILURE';

export const CONNECT_TIKTOK_ACCOUNT_SUCCESS = 'CONNECT_TIKTOK_ACCOUNT_SUCCESS';
export const CONNECT_BRAND_TIKTOK_ACCOUNT_SUCCESS = 'CONNECT_BRAND_TIKTOK_ACCOUNT_SUCCESS';
export const CONNECT_TIKTOK_ACCOUNT_FAILURE = 'CONNECT_TIKTOK_ACCOUNT_FAILURE';

export const REMOVE_SOCIAL_ACCOUNT_SUCCESS = 'REMOVE_SOCIAL_ACCOUNT_SUCCESS';
export const REMOVE_SOCIAL_ACCOUNT_FAILURE = 'REMOVE_SOCIAL_ACCOUNT_FAILURE';

export const CONNECT_STRIPE_ACCOUNT_SUCCESS = 'CONNECT_STRIPE_ACCOUNT_SUCCESS';
export const CONNECT_STRIPE_ACCOUNT_FAILURE = 'CONNECT_STRIPE_ACCOUNT_FAILURE';

export const CONNECT_SHOPIFY_ACCOUNT_SUCCESS = 'CONNECT_SHOPIFY_ACCOUNT_SUCCESS';
export const CONNECT_SHOPIFY_ACCOUNT_FAILURE = 'CONNECT_SHOPIFY_ACCOUNT_FAILURE';

export const SYNC_USER_TIER_SUCCESS = 'SYNC_USER_TIER_SUCCESS';
export const SYNC_USER_TIER_FAILURE = 'SYNC_USER_TIER_FAILURE';

export const UPDATE_USER_AUTHENTICATION_TOKEN_SUCCESS = 'UPDATE_USER_AUTHENTICATION_TOKEN_SUCCESS';
export const UPDATE_USER_AUTHENTICATION_TOKEN_FAILURE = 'UPDATE_USER_AUTHENTICATION_TOKEN_FAILURE';
export const DELETE_USER_AUTHENTICATION_TOKEN_SUCCESS = 'DELETE_USER_AUTHENTICATION_TOKEN_SUCCESS';
export const DELETE_USER_AUTHENTICATION_TOKEN_FAILURE = 'DELETE_USER_AUTHENTICATION_TOKEN_FAILURE';

export const GENERATE_TASTE_PROFILE_SUCCESS = 'GENERATE_TASTE_PROFILE_SUCCESS';
export const GENERATE_TASTE_PROFILE_FAILURE = 'GENERATE_TASTE_PROFILE_FAILURE';

export const SIMULATE_USER = 'SIMULATE_USER';

export const syncCurrentUser = usernameOverride => async (dispatch, getState) => {
  /*
    We must keep both the user object from our server as well as the cognito tokens
    in sync. To do so, we store the JWT token on the user object, and update it if
    it is going to expire in the next 10 minutes.
  */
  const { user, manager } = getState();
  const { jwtToken, jwtIdToken, simulatedUsername } = user || {};
  const { currentlyManaging } = manager || {};
  dispatch({ type: SYNC_CURRENT_USER });

  const username = usernameOverride || currentlyManaging?.username || simulatedUsername || getUsername(user);

  if (username) {
    try {
      const refreshTokens = async () => {
        const cognitoRes = await Auth.currentSession();
        dispatch({ type: STORE_COGNITO_RESPONSE, cognitoRes });
      };

      // Quickly pass through the value to redux to  ensure componentDidMount calls can use Auth
      dispatch({ type: STORE_COGNITO_RESPONSE, jwtToken, jwtIdToken });
      if (!jwtToken || !jwtIdToken) {
        await refreshTokens();
      }

      // Update the token if close to expiring in the next 5 minutes
      const tokenExpires = jwtToken && _.get(jwt_decode(jwtToken), 'exp', 0);
      const idTokenExpires = jwtIdToken && _.get(jwt_decode(jwtIdToken), 'exp', 0);
      const now = Math.floor(Date.now() / 1000);
      if ((tokenExpires && now + 60 * 5 > tokenExpires) || (idTokenExpires && now + 60 * 5 > idTokenExpires)) {
        await refreshTokens();
      }
    } catch (error) {
      const isAuthenticatedViaHash = hasAxiosUserHash();
      if (!isAuthenticatedViaHash) {
        // Auth session has expired but the user is still logged in, log the user out
        dispatch(logoutUser());
        return dispatch({
          type: SYNC_CURRENT_USER_FAILURE
        });
      }
    }
  }

  const resp = username ? await fetchUserThroughUsername(username, { detailed: 1 }) : {};
  if (!resp.user) {
    return dispatch({
      type: SYNC_CURRENT_USER_FAILURE
    });
  }

  if (!window.location.href.includes('promote/')) dispatch(setBrandTheme(resp.user?.referring_brand));
  return dispatch({
    type: SYNC_CURRENT_USER_SUCCESS,
    user: resp.user
  });
};

export const updateCurrentUser = (newUserInfo, id) => async (dispatch, getState) => {
  const { user } = getState();
  const { email } = newUserInfo;
  const oldEmail = getEmail(user);

  if (email && oldEmail !== email) {
    const currentUser = await Auth.currentAuthenticatedUser();
    const res = await Auth.updateUserAttributes(currentUser, { email });

    const confirmEmail = () => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmPrompt
            header='Please Confirm Your Email'
            subheader={`We just sent you a confirmation code to confirm your email address, please enter it here to complete the update.`}
            placeholder='Verification Code'
            isSingleLine
            onCancel={onClose}
            submitBtnDisplay='Confirm Email'
            onSubmit={async code => {
              if (code) {
                Auth.verifyCurrentUserAttributeSubmit('email', code)
                  .then(async e => {
                    cogoToast.success(`Successfully verified your email!`);

                    // Need to reset API tokens after making changes on cognito
                    const cognitoRes = await Auth.currentSession();
                    dispatch({ type: STORE_COGNITO_RESPONSE, cognitoRes });
                  })
                  .catch(e => {
                    cogoToast.warn('Invalid code, please try again.');
                    confirmEmail();
                  });
              }
            }}
          />
        )
      });
    };
    confirmEmail();

    if (res !== 'SUCCESS') {
      return dispatch({
        error: 'Failure Updating Cognito',
        type: UPDATE_CURRENT_USER_FAILURE
      });
    }
  }

  const resp = await updateUser(newUserInfo, id);

  if (resp.user) {
    return dispatch({
      type: UPDATE_CURRENT_USER_SUCCESS,
      user: resp.user
    });
  } else {
    return dispatch({
      type: UPDATE_CURRENT_USER_FAILURE,
      error: resp.error
    });
  }
};

export const logoutUser = () => async (dispatch, getState) => {
  await cognitoSignOut();
  sendDataToApp('LOGOUT_USER_SUCCESS');
  return dispatch({
    type: LOGOUT_USER_SUCCESS
  });
};

export const setCurrentUser = user => async (dispatch, getState) => {
  /**
   * This is a temporary setup until we move the entirety of the login into this action file.
   */
  return dispatch({
    type: SET_CURRENT_USER,
    user
  });
};

export const registerUser = (
  {
    email,
    password,
    name,
    username,
    code,
    brandWebsite,
    brandLogo,
    brandCommissionRate,
    ReferringBrand_id,
    ReferringUser_id,
    ReferringLookbook_id,
    LookbookInvite_hash,
    licenseState,
    licenseType,
    licenseNumber,
    ReferringOAuthApplication_id
  },
  handleRedirect
) => async (dispatch, getState) => {
  const credentials = {
    username: email,
    password
  };

  if (getUserId(getState().user)) {
    // Edge case where we are registering with a valid user in the redux store.
    await cognitoSignOut();
  }

  // Confirm user does not exist before signing up in Cognito
  const doesExistResp = await doesUserExistAPI({ username, email });
  if (doesExistResp.exists) {
    return dispatch({
      type: REGISTER_USER_FAILURE,
      error: doesExistResp.error
    });
  }

  // Confirm the code was valid and mark it as used
  if (code) {
    const useCodeResp = await useCodePreRegistration({ email, username, code });
    if (useCodeResp.error) {
      return dispatch({
        type: REGISTER_USER_FAILURE,
        success: false,
        error: useCodeResp.error
      });
    }
  }

  // Create User in Cognito
  const cognitoRes = await cognitoSignUp(credentials);
  if (cognitoRes.error) {
    return dispatch({
      type: REGISTER_USER_FAILURE,
      success: false,
      error: cognitoRes.errorMsg
    });
  }

  // Configure axios with login version of authentication token on success.
  const cognitoResLogin = await cognitoLogin(credentials);
  dispatch({
    type: STORE_COGNITO_RESPONSE,
    cognitoRes: cognitoResLogin
  });

  try {
    const createdUser = await createUser({
      email,
      username,
      name,
      code,
      brandWebsite,
      brandLogo,
      brandCommissionRate,
      ...(ReferringBrand_id ? { ReferringBrand_id } : {}),
      ...(ReferringLookbook_id ? { ReferringLookbook_id } : {}),
      ...(LookbookInvite_hash ? { LookbookInvite_hash } : {}),
      ...(ReferringUser_id ? { ReferringUser_id } : {}),
      ...(licenseState ? { licenseState } : {}),
      ...(licenseType ? { licenseType } : {}),
      ...(licenseNumber ? { licenseNumber } : {}),
      ...(ReferringOAuthApplication_id ? { ReferringOAuthApplication_id } : {})
    });

    handleRedirect && setTimeout(() => handleRedirect(createdUser.user), 100); // Let Redux Propagation complete

    // Handle UI callbacks
    const authCallbacks = getAuthCallbackFunctions(getState().ui);
    authCallbacks?.onRegister && authCallbacks.onRegister();

    return dispatch({
      success: true,
      type: REGISTER_USER_SUCCESS,
      cognitoRes,
      user: createdUser.user
    });
  } catch (error) {
    await cognitoSignOut();
    return dispatch({
      success: false,
      type: REGISTER_USER_FAILURE,
      error
    });
  }
};

export const registerShopper = ({ email, password, name }, handleRedirect) => async (dispatch, getState) => {
  // Edge case where we are registering with a valid user in the redux store.
  if (getUserId(getState().user)) {
    await cognitoSignOut();
  }

  // Confirm user does not exist before signing up in Cognito
  const doesExistResp = await doesUserExistAPI({ email });
  if (doesExistResp.exists) {
    return dispatch({ type: REGISTER_USER_FAILURE, error: doesExistResp.error });
  }

  // Create User in Cognito
  const credentials = { username: email, password };
  const cognitoRes = await cognitoSignUp(credentials);
  if (cognitoRes.error) {
    return dispatch({ type: REGISTER_USER_FAILURE, error: cognitoRes.errorMsg });
  }

  // Configure axios with login version of authentication token on success.
  const cognitoResLogin = await cognitoLogin(credentials);
  dispatch({ type: STORE_COGNITO_RESPONSE, cognitoRes: cognitoResLogin });

  // Unlock the body scroll lock
  dispatch(toggleBodyScrollLock(false));

  try {
    const newUser = await registerShopperAPI({ email, name });
    handleRedirect && (await handleRedirect(newUser));

    // Handle UI callbacks
    const authCallbacks = getAuthCallbackFunctions(getState().ui);
    authCallbacks?.onRegister && authCallbacks.onRegister(newUser);

    return dispatch({
      type: REGISTER_USER_SUCCESS,
      cognitoRes,
      user: newUser
    });
  } catch (error) {
    await cognitoSignOut();
    return dispatch({
      type: REGISTER_USER_FAILURE,
      error
    });
  }
};

export const loginUser = ({ email, password, userHash }, handleRedirect) => async (dispatch, getState) => {
  let cognitoRes;

  // First, ensure user exists on cognito
  if (email && password) {
    cognitoRes = await cognitoLogin({ username: email, password });
    if (cognitoRes.error) {
      return dispatch({
        type: LOGIN_USER_FAILURE,
        message: cognitoRes.message
      });
    }

    // Configure axios with authentication token on success.
    dispatch({
      type: STORE_COGNITO_RESPONSE,
      cognitoRes
    });
  } else if (userHash) {
    dispatch({
      type: STORE_USER_HASH,
      userHash
    });
  }

  // Finally, grab user from our server
  const userEmail = email || (await fetchEmailFromUserHash(userHash)).email;
  const resp = await fetchUserByEmail({ email: userEmail });
  if (!resp.user) {
    return dispatch({
      type: LOGIN_USER_FAILURE,
      message: resp.error || 'Incorrect username or password, please try again.'
    });
  }

  const { user } = resp;

  // Ensure the user is allowed on the platform
  const { isAdmin, isPro } = user;

  if (window.__IS_PRO__ && !isPro && !isAdmin) {
    cogoToast.error(`You are attempting to log in with a non-Professional account. Please use the ShopMy Platform.`);
    return dispatch(logoutUser());
  }

  handleRedirect && setTimeout(() => handleRedirect(user), 100); // Let Redux Propagation complete

  setTimeout(() => sendDataToApp('LOGIN_USER_SUCCESS'), 100); // Let Redux Propagation Complete

  // Handle UI callbacks
  const authCallbacks = getAuthCallbackFunctions(getState().ui);
  authCallbacks?.onLogin && authCallbacks.onLogin(user);

  return dispatch({
    type: LOGIN_USER_SUCCESS,
    cognitoRes,
    user: user
  });
};

export const syncRequests = (request, updates) => async (dispatch, getState) => {
  try {
    const { requests, brand_requests } = await getRequestsAPI(getUserId(getState().user));
    return dispatch({
      type: SYNC_REQUESTS_SUCCESS,
      requests,
      brand_requests
    });
  } catch (error) {
    return dispatch({
      type: SYNC_REQUESTS_FAILURE,
      error
    });
  }
};

export const updateRequest = (request, updates) => async (dispatch, getState) => {
  try {
    const newRequest = await updateRequestAPI(request, updates);
    return dispatch({
      type: UPDATE_REQUEST_SUCCESS,
      request: newRequest
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_REQUEST_FAILURE,
      error
    });
  }
};

export const deleteRequest = Request_id => async (dispatch, getState) => {
  await deleteRequestAPI(Request_id);
  return dispatch({
    type: DELETE_REQUEST_SUCCESS,
    Request_id
  });
};

export const updateOpportunityRequest = (request, updates, history) => async (dispatch, getState) => {
  // Use this only for users updating requests, not brands
  dispatch({ type: UPDATE_USER_OPPORTUNITY_REQUEST_REQUEST, request, updates });
  try {
    const newRequest = await updateOpportunityRequestAPI(request, updates);

    // If they just accepted the opportunity and it has an associated lookbook, check to see if they want to navigate there
    if (updates.userAccepted) {
      history && history.push(`/opportunity/${request.Opportunity_id}`);

      request.opportunity.auto_send_lookbook &&
        confirmAlert({
          title: 'New Lookbook Available',
          message: `By accepting this opportunity you received access to the lookbook "${request.opportunity.auto_send_lookbook.title}". Would you like to view it now to make selections?`,
          buttons: [
            { label: 'Not Now', className: 'cancel', onClick: () => {} },
            {
              label: 'View Lookbook',
              onClick: () => {
                // Sync the user object that contains the request and navigate to the lookbook once it propagates to redux
                dispatch(syncCurrentUser()).then(() => {
                  history && history.push(`/lookbooks/${request.opportunity.auto_send_lookbook.id}`);
                });
              }
            }
          ]
        });
    }

    return dispatch({
      success: true,
      type: UPDATE_USER_OPPORTUNITY_REQUEST_SUCCESS,
      request: newRequest
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_OPPORTUNITY_REQUEST_FAILURE,
      request,
      error
    });
  }
};

export const updateUserSettings = updates => async (dispatch, getState) => {
  const curSettings = getSettings(getState().user);
  dispatch({ type: UPDATE_USER_SETTINGS_REQUEST, updates });
  try {
    const newSettings = await updateUserSettingsAPI(updates, curSettings);
    return dispatch({
      type: UPDATE_USER_SETTINGS_SUCCESS,
      settings: newSettings
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_SETTINGS_FAILURE,
      settings: curSettings,
      error
    });
  }
};

export const setAddress = (user, data) => async (dispatch, getState) => {
  try {
    const resp = await updateAddress(user, data);
    cogoToast.success('Successfully updated address');
    return dispatch({ type: UPDATE_ADDRESS_SUCCESS, address: resp.address });
  } catch (error) {
    return dispatch({
      type: UPDATE_ADDRESS_FAILURE,
      error
    });
  }
};

export const addTagsToUser = tags => async (dispatch, getState) => {
  const existingTags = getTags(getState().user);
  dispatch({ type: ADD_TAGS_TO_USER_REQUEST, tags });
  try {
    await addTagsToUserAPI(tags, getUserId(getState().user));
    return dispatch({
      type: ADD_TAGS_TO_USER_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: ADD_TAGS_TO_USER_FAILURE,
      error,
      tags: existingTags
    });
  }
};

export const deleteTagsForUser = tags => async (dispatch, getState) => {
  const existingTags = getTags(getState().user);
  dispatch({ type: DELETE_TAGS_FOR_USER_REQUEST, tags });
  try {
    await deleteTagsForUserAPI(tags, getUserId(getState().user));
    return dispatch({
      type: DELETE_TAGS_FOR_USER_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: DELETE_TAGS_FOR_USER_FAILURE,
      error,
      tags: existingTags
    });
  }
};

export const applyToWaitlist = data => async (dispatch, getState) => {
  const attribution = getState().attribution;
  const source_fields = attribution.params || {};
  try {
    const resp = await applyForWaitlist({ ...data, source_fields });
    return dispatch({
      success: true,
      type: WAITLIST_APPLICATION_SUCCESS,
      data: resp
    });
  } catch (error) {
    return dispatch({
      type: WAITLIST_APPLICATION_FAILURE,
      error: error
    });
  }
};

export const connectInstagram = data => async (dispatch, getState) => {
  const isBrandAccount = isBrand(getState().user);
  try {
    const resp = await connectInstagramAPI({
      ...data,
      ...(isBrandAccount ? { Brand_id: getBrandId(getState().user) } : { User_id: getUserId(getState().user) })
    });
    return dispatch({
      success: true,
      isBrandAccount,
      warning: resp.warning,
      type: isBrandAccount ? CONNECT_BRAND_INSTAGRAM_ACCOUNT_SUCCESS : CONNECT_INSTAGRAM_ACCOUNT_SUCCESS,
      account: resp.account,
      username: resp.username,
      followers_count: resp.followers_count
    });
  } catch (error) {
    return dispatch({
      type: CONNECT_INSTAGRAM_ACCOUNT_FAILURE,
      error
    });
  }
};

export const connectYoutube = data => async (dispatch, getState) => {
  try {
    const resp = await connectYoutubeAPI({ ...data, User_id: getUserId(getState().user) });
    return dispatch({
      success: true,
      type: CONNECT_YOUTUBE_ACCOUNT_SUCCESS,
      account: resp.account
    });
  } catch (error) {
    return dispatch({
      type: CONNECT_YOUTUBE_ACCOUNT_FAILURE,
      error
    });
  }
};

export const connectTiktok = data => async (dispatch, getState) => {
  try {
    const resp = await connectTiktokAPI({ ...data, User_id: getUserId(getState().user) });
    return dispatch({
      success: true,
      type: CONNECT_TIKTOK_ACCOUNT_SUCCESS,
      account: resp.account
    });
  } catch (error) {
    return dispatch({
      type: CONNECT_TIKTOK_ACCOUNT_FAILURE,
      error
    });
  }
};

export const disconnectSocialAccount = account => async (dispatch, getState) => {
  try {
    await removeSocialAccountAPI(account);
    return dispatch({
      type: REMOVE_SOCIAL_ACCOUNT_SUCCESS,
      account
    });
  } catch (error) {
    return dispatch({
      type: REMOVE_SOCIAL_ACCOUNT_FAILURE,
      error
    });
  }
};

export const connectStripeAccount = account => async (dispatch, getState) => {
  try {
    const resp = await connectStripeAccountAPI(getUserId(getState().user));
    return dispatch({
      type: CONNECT_STRIPE_ACCOUNT_SUCCESS,
      stripeAccountId: resp.stripeAccountId
    });
  } catch (error) {
    return dispatch({
      type: CONNECT_STRIPE_ACCOUNT_FAILURE,
      error
    });
  }
};

export const syncUserTier = tier => async (dispatch, getState) => {
  try {
    const resp = await getUserTierAPI(tier);
    return dispatch({
      type: SYNC_USER_TIER_SUCCESS,
      tier: resp.tier
    });
  } catch (error) {
    return dispatch({
      type: SYNC_USER_TIER_FAILURE,
      error
    });
  }
};

export const simulateUser = username => async (dispatch, getState) => {
  return dispatch({
    type: SIMULATE_USER,
    username
  });
};

export const updateUserAuthenticationToken = (id, updates) => async (dispatch, getState) => {
  const initialTokens = getState().user.profile.tokens;
  const targetIdx = initialTokens.findIndex(t => t.id === id);

  try {
    const updatedToken = await updateUserAuthenticationTokenAPI(id, updates);

    return dispatch({
      tokens: [...initialTokens.slice(0, targetIdx), updatedToken, ...initialTokens.slice(targetIdx + 1)],
      type: UPDATE_USER_AUTHENTICATION_TOKEN_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_AUTHENTICATION_TOKEN_FAILURE,
      error
    });
  }
};

export const deleteUserAuthenticationToken = id => async (dispatch, getState) => {
  const initialTokens = getState().user.profile.tokens;
  const targetIdx = initialTokens.findIndex(t => t.id === id);

  try {
    await deleteUserAuthenticationTokenAPI(id);
    let updatedTokens = [...initialTokens.slice(0, targetIdx), ...initialTokens.slice(targetIdx + 1)];

    if (updatedTokens.length === 0) {
      updatedTokens = undefined;
    }

    return dispatch({
      tokens: updatedTokens,
      type: DELETE_USER_AUTHENTICATION_TOKEN_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: DELETE_USER_AUTHENTICATION_TOKEN_FAILURE,
      error
    });
  }
};

export const generateTasteProfile = () => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const taste = await generateTasteProfileAPI({
      User_id: getUserId(user)
    });
    return dispatch({
      type: GENERATE_TASTE_PROFILE_SUCCESS,
      taste
    });
  } catch (error) {
    return dispatch({
      type: GENERATE_TASTE_PROFILE_FAILURE,
      error
    });
  }
};
