import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './ShopControls.scss';

import { getPrettyNumber, getFormattedSectionTitle } from '../../Helpers/formatting';
import { openCuratorGroupModal } from '../../Actions/UIActions';

import { getPreviewImageForGroup } from '../../Helpers/shop_helpers';
import { getSections, isShopper } from '../../Helpers/user_helpers';

import ShopSectionsModal from '../Sections/ShopSectionsModal';

const ShopControls = props => {
  const { isShowingCollections, isForYouSection, isYourShop, activeRefinementObjects } = props;
  const { activeCurator, activeCuratorGroup } = activeRefinementObjects;

  const [showingAllFilters, setShowingAllFilters] = React.useState(false);
  const showAllFilters = () => setShowingAllFilters(true);
  const showLessFilters = () => setShowingAllFilters(false);

  const activeFilters = isShowingCollections
    ? []
    : props.activeProductRefinements
        .filter(i => !i.label.includes('Curated By'))
        .map(item => ({
          label: item.displayValue,
          sublabel: item.label,
          active: true,
          isAdjusting: props.isAdjustingLabel(item.label),
          onClick: () => {
            props.applyUnrefinement(item);
            window.__ADD_EVENT__('Shop - Click Unrefinement', { label: item.label });
          }
        }));

  const potentialFilters = isShowingCollections
    ? isYourShop
      ? getSections(props.user).map(section => ({
          label: getFormattedSectionTitle(section),
          active: props.Section_id ? props.Section_id === section.id : false,
          disableClose: true,
          icon: section.isHidden ? faEyeSlash : null,
          onClick: () => props.goToSectionId(section.id)
        }))
      : props.sectionResults.length === 1
      ? []
      : props.Curator_id || props.Curator_username
      ? props.sectionResults.map((section, idx) => ({
          label: getFormattedSectionTitle(section),
          active: props.Section_id ? props.Section_id === section.id : idx === 0,
          disableClose: true,
          onClick: () => {
            props.goToSectionId(section.id);
            window.__ADD_EVENT__('Shop - Click Shop Section', { title: section.title });
          }
        }))
      : props.sectionResults.map((section, idx) => ({
          label: section.label,
          active: props.sectionType ? section.sectionType === props.sectionType : idx === 0,
          disableClose: true,
          onClick: () => {
            props.goToSectionType(section.sectionType);
            window.__ADD_EVENT__('Shop - Click Group Shop Section', { type: section.sectionType });
          }
        }))
    : _.flatten(
        props.productRefinements.map(refinement =>
          refinement.items.map(item => ({
            label: item.label,
            count: item.count,
            isAdjusting: props.isAdjustingLabel(item.label),
            onClick: () => {
              props.applyRefinement(item, refinement);
              window.__ADD_EVENT__('Shop - Click Refinement', { label: item.label, count: item.count });
            }
          }))
        )
      );

  const filters = _.filter(_.uniqBy([...activeFilters, ...potentialFilters], 'label'), f => f.label);
  const hasSearchQuery = !!props.query;
  const additionalClasses = { adjusting: props.isFetchingResults };

  const filterNamesHash = filters.map(f => f.label).join(',');
  React.useEffect(() => {
    showingAllFilters && setShowingAllFilters(false);
  }, [filterNamesHash]);

  // Show the "Show More" button if it overlaps with the filters
  const showMoreRef = React.useRef();
  const lastFilterRef = React.useRef();
  React.useEffect(() => {
    if (showMoreRef.current && lastFilterRef.current) {
      const filtersRect = lastFilterRef.current.getBoundingClientRect();
      const showMoreRect = showMoreRef.current.getBoundingClientRect();
      if (filtersRect.x + filtersRect.width > showMoreRect.x) {
        showMoreRef.current.style.opacity = 1;
      } else {
        showMoreRef.current.style.opacity = 0;
      }
    }
  }, [filterNamesHash, lastFilterRef]);

  const showCuratorUnfilter = (!!activeCurator || !!activeCuratorGroup) && !isYourShop && !isShowingCollections && !isForYouSection;

  const isRemovingCuratorFilter =
    (props.Curator_id || activeCurator) && activeCurator?.id !== props.Curator_id && activeCurator?.username !== props.Curator_username;
  const removeCuratorFilter = () => {
    props.goToCuratorId(null);
    window.ALERT.success(`You are now shopping all curators.`);
  };

  const isRemovingCuratorGroupFilter = (props.CuratorGroup_id || activeCuratorGroup) && activeCuratorGroup?.id !== props.CuratorGroup_id;
  const removeCuratorGroupFilter = () => {
    props.goToCuratorGroupId(null);
    window.ALERT.success(`You are now shopping all curator groups.`);
  };

  // Sections
  const [isSectionsModalVisible, setIsSectionsModalVisible] = React.useState(false);
  const [shopSectionsModalInitialConfig, setShopSectionsModalInitialConfig] = React.useState({});
  const closeSectionsModal = () => setIsSectionsModalVisible(false);
  const openSectionsModal = () => setIsSectionsModalVisible(true);

  return (
    <>
      <div className={cn('shop-controls-container', additionalClasses)}>
        <div className={cn('filters', { 'showing-all': showingAllFilters })}>
          {showCuratorUnfilter && (
            <>
              {activeCurator && (
                <div
                  className={cn('filter active', {
                    'with-photo': activeCurator.image,
                    adjusting: isRemovingCuratorFilter
                  })}
                  onClick={removeCuratorFilter}
                >
                  {activeCurator.image && (
                    <div className='image-container'>
                      <img className='preview-image' src={activeCurator.image} alt={activeCurator.name} />
                    </div>
                  )}
                  <div className='label'>{activeCurator.name}</div>
                  <div className='remove-icn'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}
              {activeCuratorGroup && (
                <div
                  className={cn('filter active', {
                    'with-photo': getPreviewImageForGroup(activeCuratorGroup),
                    adjusting: isRemovingCuratorGroupFilter
                  })}
                  onClick={removeCuratorGroupFilter}
                >
                  {getPreviewImageForGroup(activeCuratorGroup) && (
                    <div className='image-container'>
                      <img className='preview-image' src={getPreviewImageForGroup(activeCuratorGroup)} alt={activeCuratorGroup.title} />
                    </div>
                  )}
                  <div className='label'>{activeCuratorGroup.title}</div>
                  <div className='remove-icn'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}
            </>
          )}
          {hasSearchQuery && (
            <div className='filter active' onClick={() => props.setQuery('')}>
              <div className='label'>{props.query}</div>
              <div className='remove-icn'>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          )}
          {filters.map((filter, index) => {
            const { label, count, active, isAdjusting, icon, onClick, disableClose } = filter;
            return (
              <div
                ref={index === filters.length - 1 ? lastFilterRef : null}
                className={cn('filter', { active, inactive: !active, adjusting: isAdjusting })}
                onClick={onClick}
                key={label + index}
              >
                <div className='label'>{label}</div>
                {active && !disableClose ? (
                  <div className='remove-icn'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                ) : (
                  count && <div className='count'>{getPrettyNumber(count)}</div>
                )}
                {icon && (
                  <div className='icon'>
                    <FontAwesomeIcon icon={icon} />
                  </div>
                )}
              </div>
            );
          })}
          {isShowingCollections && props.isYourShop && !isShopper(props.user) && (
            <div className='filter edit' onClick={openSectionsModal}>
              <div>Edit</div>
            </div>
          )}
          {showingAllFilters && (
            <div onClick={showLessFilters} className='filter show-less-filter'>
              <div className='label'>Show Less</div>
            </div>
          )}
          {!showingAllFilters && (
            <div ref={showMoreRef} className='show-all-btn-container'>
              <div onClick={showAllFilters} className='show-all-btn'>
                Show All
              </div>
            </div>
          )}
        </div>
      </div>
      {isSectionsModalVisible && (
        <ShopSectionsModal close={closeSectionsModal} sectionsUser={props.user} initConfig={shopSectionsModalInitialConfig} />
      )}
    </>
  );
};

ShopControls.propTypes = {
  user: PropTypes.object.isRequired,

  // Hierarchy Based
  Category_id: PropTypes.number,
  Department_id: PropTypes.number,
  Industry_id: PropTypes.number,
  AllBrand_id: PropTypes.number,
  shoppingHeaderSentence: PropTypes.string,

  // Tab Based
  tab: PropTypes.string,
  goToTab: PropTypes.func.isRequired,
  isForYouSection: PropTypes.bool.isRequired,

  // Search Based
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,

  // Curators Based
  Curator_id: PropTypes.number,
  CuratorGroup_id: PropTypes.number,

  // Loading
  isFetchingRefinements: PropTypes.bool.isRequired,
  isFetchingResults: PropTypes.bool.isRequired,

  // Refinements
  productRefinements: PropTypes.array.isRequired,
  activeRefinementObjects: PropTypes.object.isRequired,
  activeProductRefinements: PropTypes.array.isRequired,
  applyRefinement: PropTypes.func.isRequired,
  applyUnrefinement: PropTypes.func.isRequired,
  isAdjustingLabel: PropTypes.func.isRequired,

  // Collections
  isShowingCollections: PropTypes.bool.isRequired,
  goToSectionId: PropTypes.func.isRequired,
  goToSectionType: PropTypes.func.isRequired,
  sectionType: PropTypes.string,

  // Actions
  openCuratorGroupModal: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openCuratorGroupModal
})(ShopControls);
