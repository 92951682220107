import _ from 'lodash';
import algoliasearch from 'algoliasearch';

const client = algoliasearch('BLB7XGVAAJ', '69dbc6317a3a5f2a8106abf1b73de68a');
export const productIndex = client.initIndex(`sms_products_${process.env.NODE_ENV === 'production' ? 'production' : 'staging'}`);

/** @param {('sms_products_production'|'sms_products_staging'|'production_autocomplete'|'sms_users_production'|'brands_production'|'store_staging'|'store_production')} type */
export const fetchAlgoliaIndex = type => {
  const index = client.initIndex(type);
  return index;
};

export const customSearchResultsRanking = results => {
  /*
    Currently not used, this function allows for a final override
    filter on the client to adjust the final 25 algolia results.
  */
  return results;
};

export const cleanAutoCompleteResults = (results, searchVal) => {
  const isMatch = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim();
  const newResults = results.filter(a => !isMatch(a.query, searchVal));
  if (results.length === 1 && isMatch(searchVal, _.get(results, '0.query'))) return [];

  const searchNotInResults = !newResults.find(a => isMatch(a.query, searchVal));
  if (newResults.length && searchNotInResults) {
    return [...newResults.slice(0, 1), { query: searchVal }, ...newResults.slice(1)];
  }

  return results;
};

export const matchesSearchTerm = (term, searchTerm, options = {}) => {
  if (typeof term !== 'string' || typeof searchTerm !== 'string') return false;
  const { directMatch, perfectMatch } = options;

  if (perfectMatch) {
    return term.includes(searchTerm);
  }

  if (directMatch) {
    return term.toLowerCase().includes(searchTerm.toLowerCase());
  }

  let match = true;
  const parts = searchTerm.split(' ');
  parts.forEach(part => {
    match = match && term.toLowerCase().includes(part.toLowerCase());
  });

  return match;
};

export const getExampleShopSearchTerms = () => {
  return [
    'Nike Air Force Ones',
    'Chanel handbags',
    'pretty summer dresses',
    'oversized denim jacket',
    'Aritzia effortless pants',
    'black combat boots',
    'cozy knit sweaters',
    'wedding guest dresses',
    'gold hoop earrings',
    'minimalist watches',
    'lululemon align leggings',
    'Free People maxi dress',
    'New Balance 550s',
    'vegan leather tote',
    'silk slip dress',
    'statement sunglasses',
    'white platform sneakers',
    'mens linen shirts',
    'wedding dresses',
    'small crossbody bags',
    'crochet beach cover up',
    'sports bras',
    'trench coats for fall',
    'cashmere scarves',
    'vintage graphic tees',
    'high waisted jeans',
    'platform sandals',
    'silver dainty necklaces',
    'Boho festival outfits',
    'puffer jackets',
    'workwear trousers',
    'corset tops',
    'Moncler puffer coats',
    'satin midi skirts',
    'chunky dad sneakers',
    'bridesmaid dresses',
    'leather loafers',
    'basic white tees',
    'Cowboy boots',
    'Hermès Birkin',
    'mesh ballet flats',
    'Versace sunglasses',
    'Skims shapewear',
    'cargo pants',
    'matching loungewear sets',
    'lace bodysuits',
    'tulle skirts',
    'faux fur coats',
    'maxi trench coats',
    'Nike Dunks',
    'bucket hats',
    'colorful beaded necklaces',
    'Tory Burch sandals',
    'embroidered cardigans',
    'quilted shoulder bags',
    'sustainable denim',
    'glitter heels',
    'Prada Re-Edition bag',
    'tie-dye hoodies',
    'Yeezy slides',
    'tailored blazers',
    'Hoka running shoes',
    'wrap dresses',
    'Bottega Veneta clutch',
    'wide leg trousers',
    'cropped leather jackets',
    'oversized flannel shirts',
    'lace-up ankle boots',
    'Asics Gel Kayano',
    'metallic handbags',
    'Nike tech fleece',
    'wool bucket hats',
    'cutout swimsuits',
    'pleated mini skirts',
    'Ganni boots',
    'bralette tops',
    'tie front tops',
    'Celine sunglasses',
    'matching pajama sets',
    'oversized knit cardigans',
    'leather biker jackets',
    'retro track jackets',
    'Adidas Samba',
    'wrap coats',
    'striped turtlenecks',
    'off-shoulder tops',
    'denim midi skirts',
    'quilted vests',
    'smocked dresses',
    'square toe heels',
    'Nike Blazers',
    'designer phone cases',
    'clogs with socks',
    'tennis skirts',
    'ear cuff sets',
    'high neck crop tops',
    'chunky gold rings',
    'linen co-ord sets',
    'oversized sweatshirts',
    'tiered maxi dresses',
    'fleece shackets'
  ];
};
