import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import './AnnouncementModal.scss';

import { markAnnouncementAsRead } from '../../Actions/AnnouncementActions';
import { isSimulatingUser } from '../../Helpers/user_helpers';
import { prepopulateIntercomMessage } from '../../Helpers/chat_helpers';

import MentionsFeedAnnouncement from './Templates/MentionsFeedAnnouncement';
import Discover2Announcement from './Templates/Discover2Announcement';
import LookbooksAnnouncement from './Templates/LookbooksAnnouncement';
import BrandReportAnnouncement from './Templates/BrandReportAnnouncement';
import OpportunitiesAnnouncement from './Templates/OpportunitiesAnnouncement';
import BrandCatalogAnnouncement from './Templates/BrandCatalogAnnouncement';

const TEST_ANNOUNCEMENT = false;
// const TEST_ANNOUNCEMENT = true;

const AnnouncementModal = props => {
  const { announcement } = props;
  const { type } = announcement;

  // Handle closing and updating announcement object
  const [isClosed, setIsClosed] = useState(false);
  const closeModal = () => {
    setIsClosed(true);
    props.markAnnouncementAsRead(announcement);
  };
  const ignoreClick = e => e.stopPropagation();

  const openChat = (msg = '') => prepopulateIntercomMessage(msg);

  // Hide in certain cases
  if (isClosed) return null;
  if (!TEST_ANNOUNCEMENT && isSimulatingUser(props.user)) return null; // Never show for simulated users

  // Grab custom template
  let innerComponent = null;
  let disableOutsideClick = false;
  if (type === 'DISCOVER_2.0') {
    innerComponent = <Discover2Announcement {...props} closeModal={closeModal} openChat={openChat} />;
    disableOutsideClick = true;
  } else if (type === 'SOCIAL_MENTIONS_FEED') {
    innerComponent = <MentionsFeedAnnouncement {...props} closeModal={closeModal} openChat={openChat} />;
  } else if (type === 'LOOKBOOKS') {
    innerComponent = <LookbooksAnnouncement {...props} closeModal={closeModal} openChat={openChat} />;
  } else if (type === 'BRAND_REPORT') {
    innerComponent = <BrandReportAnnouncement {...props} closeModal={closeModal} openChat={openChat} />;
  } else if (type === 'OPPORTUNITIES') {
    innerComponent = <OpportunitiesAnnouncement {...props} closeModal={closeModal} openChat={openChat} />;
  } else if (type === 'BRAND_CATALOG') {
    innerComponent = <BrandCatalogAnnouncement {...props} closeModal={closeModal} openChat={openChat} />;
  }

  return (
    <div onClick={() => (disableOutsideClick ? null : closeModal())} className='announcement-modal-outer-container'>
      <div onClick={ignoreClick} className='announcement-modal-inner-container'>
        {innerComponent || `Unknown Type ${type}`}
        <div onClick={closeModal} className='announcement-modal-close-button-mobile'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

AnnouncementModal.propTypes = {
  // From Outside
  announcement: PropTypes.object.isRequired,
  extra: PropTypes.object,

  // From Redux
  user: PropTypes.object.isRequired,
  markAnnouncementAsRead: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  markAnnouncementAsRead
})(AnnouncementModal);
