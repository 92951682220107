import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import Mousetrap from 'mousetrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Links.scss';

import { getCustomRates } from '../../Actions/StoreActions';
import { togglePinToMove, setCompleteMovingPinsFn, openArtistModal, openBrandModal } from '../../Actions/UIActions';

import { addEvent } from '../../APIClient/events';
import { getPins, deletePin } from '../../APIClient/pins';
import { getUserId, isLoggedIn, isSimulatingUser, isShopper, isAdmin, isBrand, getStats, getBrand } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { downloadCsvFromUrl, getUrlParam, removeUrlParam } from '../../Helpers/helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import LinkControls from '../../Components/Links/LinkControls';
import LinkResults from '../../Components/Links/LinkResults';
import LinkResultsDisclaimer from '../../Components/Links/LinkResultsDisclaimer';
import AddPinModal from '../../Components/AddPinModal/AddPinModal';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';

const RESULTS_PER_PAGE = 25;

class Links extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    getCustomRates: PropTypes.func.isRequired,
    togglePinToMove: PropTypes.func.isRequired,
    setCompleteMovingPinsFn: PropTypes.func.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    openBrandModal: PropTypes.func.isRequired
  };

  getInitialGroupByMode = groupByMode => {
    return groupByMode || (isBrand(this.props.user) ? 'users' : 'mentions');
  };

  constructor(props) {
    super(props);
    const initialGroupByMode = this.getInitialGroupByMode(_.get(this.props, 'match.params.tab'));

    const onlyShowBrandTargets = !!getUrlParam('onlyShowBrandTargets');
    const onlyShowShopMyBrands = !!getUrlParam('onlyShowShopMyBrands');
    onlyShowBrandTargets && removeUrlParam('onlyShowBrandTargets');
    onlyShowShopMyBrands && removeUrlParam('onlyShowShopMyBrands');

    this.state = {
      // API Responses
      fetching: false,
      transitioningTabs: false,
      results: [],
      meta: {},

      // Ui Handling
      showPinAddOrEditModal: false,
      pinBeingEdited: null,

      // Modes
      groupByMode: initialGroupByMode, // users, orders, products, mentions

      // Filtering
      searchVal: '',
      selectedList: null,
      sortOrder: this.getDefaultSortOrder(initialGroupByMode),
      sortDirection: this.getDefaultSortDirection(initialGroupByMode),
      hideOtherRetailers: isSubscribedToFeature(this.props.user, 'CROSS_RETAILER_ANALYTICS') ? false : true,
      onlyShowBrandTargets,
      onlyShowShopMyBrands,
      partnershipStatusFilters: {
        hideWithCodes: false,
        hideWithGifting: false,
        hideWithOpportunities: false,
        hideWithRates: false
      },
      showSpecificRetailerDomains: [],
      startDate: this.getInitialStartDate(),
      endDate: null,
      pageNum: 1,

      // Admin control
      userIdOverrideForAdminTesting: null
    };
  }

  getDefaultSortOrder = groupByMode => {
    if (groupByMode === 'products') return 'views';
    if (groupByMode === 'users') return 'orderVolumeTotal';
    if (groupByMode === 'domains') return 'orderVolumeTotal';
    return 'createdAt';
  };

  getDefaultSortDirection = groupByMode => {
    return 'desc';
  };

  componentDidMount() {
    window.__ADD_EVENT__('Analytics - View Page');
    const { user } = this.props;
    this.loadPins();
    this.searchPinsDebouncer = AwesomeDebouncePromise(this.loadPins, 500);

    // Ensure we refresh on a pin move
    this.props.setCompleteMovingPinsFn(this.loadPins);

    // Ensure we have all custom rates
    this.props.getCustomRates(getUserId(user));

    // All admins to mock being any user
    if (isAdmin(user) || isSimulatingUser(user)) {
      Mousetrap.bind('ctrl+a', () => {
        const resp = window.prompt('Input a User ID to simulate this panel for that user.');
        const userId = resp === '*' ? '*' : parseInt(resp);
        userId &&
          this.setState({ userIdOverrideForAdminTesting: userId }, async () => {
            await this.props.getCustomRates(userId); // Ensure locally we have the rates of the user we selected
            await this.loadPinsAndResetToInitialState();
          });
      });
    }

    addEvent('Links - View', { user: this.props.user });
  }

  componentDidUpdate(prevProps) {
    // react to group by tab changes
    const curTab = _.get(this.props, 'match.params.tab');
    const prevTab = _.get(prevProps, 'match.params.tab');

    if (curTab !== prevTab) {
      const newGroupByMode = this.getInitialGroupByMode(curTab);
      let newSortOrder = this.getDefaultSortOrder(newGroupByMode);
      let newSortDir = this.getDefaultSortDirection(newGroupByMode);
      this.setState(
        {
          groupByMode: newGroupByMode,
          sortOrder: newSortOrder || this.state.sortOrder,
          sortDirection: newSortDir || this.state.sortDirection,
          pageNum: 1,
          transitioningTabs: true
        },
        this.loadPins
      );
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+a');
    this.props.setCompleteMovingPinsFn(null);
  }

  isSuperAdminMode = () => this.state.userIdOverrideForAdminTesting === '*';

  getInitialStartDate = () => {
    const { user } = this.props;

    // For users with a ton of total clicks, we want to default to only showing a month
    const numTotalClicks = _.get(getStats(user), 'totalPinClicks') || 0;
    if (numTotalClicks > 1e6) return moment().subtract(1, 'month');

    return null;
  };

  loadPinsAndResetToInitialState = () => this.loadPins({ resetToInitialState: true });
  loadPinsAndResetToPageOne = () => this.loadPins({ resetToPageOne: true });
  loadPins = ({ resetToInitialState, resetToPageOne } = {}) =>
    new Promise((resolve, reject) => {
      this.setState(
        {
          fetching: true,
          sortOrder: resetToInitialState ? 'createdAt' : this.state.sortOrder,
          sortDirection: resetToInitialState ? 'desc' : this.state.sortDirection,
          searchVal: resetToInitialState ? '' : this.state.searchVal,
          pageNum: resetToPageOne ? 1 : this.state.pageNum
        },
        async () => {
          const {
            pageNum,
            sortOrder,
            hideOtherRetailers,
            onlyShowBrandTargets,
            onlyShowShopMyBrands,
            showSpecificRetailerDomains,
            groupByMode,
            sortDirection,
            searchVal,
            startDate,
            endDate,
            selectedList,
            partnershipStatusFilters
          } = this.state;
          const offsetFromUTC = new Date().getTimezoneOffset();
          const activePartnershipStatusFilters = _.pickBy(partnershipStatusFilters, val => val);
          getPins(
            `User_id=${this.state.userIdOverrideForAdminTesting || getUserId(this.props.user)}` +
              `&limit=${RESULTS_PER_PAGE}` +
              `&offset=${RESULTS_PER_PAGE * (pageNum - 1)}` +
              `&sortOrder=${sortOrder}` +
              `&sortDirection=${sortDirection}` +
              (searchVal ? `&query=${searchVal}` : '') +
              (startDate ? `&startDate=${startDate.format('YYYY-MM-DD')}` : '') +
              (endDate ? `&endDate=${endDate.format('YYYY-MM-DD')}` : '') +
              (endDate || startDate ? `&timezoneOffset=${offsetFromUTC}` : '') +
              (groupByMode ? `&groupByMode=${groupByMode}` : ``) +
              (hideOtherRetailers ? `&hideOtherRetailers=1` : ``) +
              (onlyShowBrandTargets ? `&onlyShowBrandTargets=1` : ``) +
              (onlyShowShopMyBrands ? `&onlyShowShopMyBrands=1` : ``) +
              (selectedList?.BrandList_id ? `&BrandList_id=${selectedList?.BrandList_id}` : ``) +
              (selectedList?.DiscoverList_id ? `&DiscoverList_id=${selectedList?.DiscoverList_id}` : ``) +
              (selectedList?.Lookbook_id ? `&Lookbook_id=${selectedList?.Lookbook_id}` : ``) +
              (selectedList?.Opportunity_id ? `&Opportunity_id=${selectedList?.Opportunity_id}` : ``) +
              (showSpecificRetailerDomains?.length ? `&showSpecificRetailerDomains=${showSpecificRetailerDomains.join(',')}` : '') +
              _.keys(activePartnershipStatusFilters)
                .map(key => `&${key}=1`)
                .join('')
          ).then(
            resp => {
              this.setState({ results: resp.results, meta: resp, fetching: false, transitioningTabs: false });
              resolve();
            },
            err => {
              this.setState({ fetching: false, transitioningTabs: false });
              cogoToast.error(err || 'There was an issue loading analytics.');
              reject();
            }
          );
        }
      );
    });

  downloadCsv = async () => {
    const {
      sortOrder,
      hideOtherRetailers,
      onlyShowBrandTargets,
      onlyShowShopMyBrands,
      showSpecificRetailerDomains,
      partnershipStatusFilters,
      groupByMode,
      sortDirection,
      searchVal,
      startDate,
      endDate,
      selectedList
    } = this.state;
    const offsetFromUTC = new Date().getTimezoneOffset();
    if (this.state.isDownloading) return;
    this.setState({ isDownloading: true });
    window.__ADD_EVENT__('Analytics - Download', { brand: getBrand(this.props.user) });

    const activePartnershipStatusFilters = _.pickBy(partnershipStatusFilters, val => val);
    try {
      const resp = await getPins(
        `downloadAllToCsv=1` +
          `&User_id=${this.state.userIdOverrideForAdminTesting || getUserId(this.props.user)}` +
          `&sortDirection=${sortDirection}` +
          `&sortOrder=${sortOrder}` +
          (searchVal ? `&query=${searchVal}` : '') +
          (startDate ? `&startDate=${startDate.format('YYYY-MM-DD')}` : '') +
          (endDate ? `&endDate=${endDate.format('YYYY-MM-DD')}` : '') +
          (endDate || startDate ? `&timezoneOffset=${offsetFromUTC}` : '') +
          (groupByMode ? `&groupByMode=${groupByMode}` : ``) +
          (hideOtherRetailers ? `&hideOtherRetailers=1` : ``) +
          (onlyShowBrandTargets ? `&onlyShowBrandTargets=1` : ``) +
          (onlyShowShopMyBrands ? `&onlyShowShopMyBrands=1` : ``) +
          (selectedList?.BrandList_id ? `&BrandList_id=${selectedList?.BrandList_id}` : ``) +
          (selectedList?.DiscoverList_id ? `&DiscoverList_id=${selectedList?.DiscoverList_id}` : ``) +
          (selectedList?.Lookbook_id ? `&Lookbook_id=${selectedList?.Lookbook_id}` : ``) +
          (selectedList?.Opportunity_id ? `&Opportunity_id=${selectedList?.Opportunity_id}` : ``) +
          (showSpecificRetailerDomains?.length ? `&showSpecificRetailerDomains=${showSpecificRetailerDomains.join(',')}` : '') +
          _.keys(activePartnershipStatusFilters)
            .map(key => `&${key}=1`)
            .join('')
      );
      if (resp.downloaded_url) {
        downloadCsvFromUrl(resp.downloaded_url);
        window.ALERT.success('Successfully downloaded!');
      } else {
        window.ALERT.error(`There was an error downloading, please try again.`);
      }
    } catch (error) {
      window.ALERT.error(typeof error === 'string' ? error : `Error downloading, please try again.`);
    }
    this.setState({ isDownloading: false });
  };

  editPin = pin => {
    this.setState({
      showPinAddOrEditModal: true,
      pinBeingEdited: pin
    });
  };

  deletePin = async pin => {
    const confirmDelete = async () => {
      try {
        await deletePin(pin.id);
        this.loadPins();
      } catch (error) {
        cogoToast.error('There was an error deleting this link, please try again.');
      }
    };
    if (pin.Collection_id || pin.views) {
      confirmAlert({
        title: 'Just confirming',
        message: `Are you sure you want to delete this link? ${
          pin.Collection_id
            ? `This means it will be removed from the ${window.__IS_SMS__ ? 'collection' : 'list'}: ${pin.Collection_name || 'Unknown'}`
            : ''
        }`,
        buttons: [
          { label: 'No', className: 'cancel', onClick: () => {} },
          { label: 'Yes', onClick: confirmDelete }
        ]
      });
    } else {
      confirmDelete();
    }
  };

  goToPage = newPageNum => {
    this.setState({ pageNum: newPageNum }, this.loadPins);
  };

  setGroupByMode = newGroupByMode => {
    const { history } = this.props;
    history.push(`/links/${newGroupByMode}`);
  };

  setSort = (order, direction) => {
    this.setState({ sortOrder: order, sortDirection: direction, pageNum: 1 }, this.loadPins);
  };

  selectList = selectedList => {
    this.setState({ selectedList }, this.loadPins);
  };

  toggleAddingMode = () => {
    this.setState({ showPinAddOrEditModal: !this.state.showPinAddOrEditModal, pinBeingEdited: null });
  };

  toggleShowOtherRetailersMode = () => {
    if (blockOnRequiringSubscription(this.props.user, 'CROSS_RETAILER_ANALYTICS')) return null;
    this.setState({ hideOtherRetailers: !this.state.hideOtherRetailers }, this.loadPins);
  };

  toggleOnlyShowBrandTargets = () => {
    this.setState({ onlyShowBrandTargets: !this.state.onlyShowBrandTargets, onlyShowShopMyBrands: false }, this.loadPins);
  };

  toggleOnlyShowShopMyBrands = () => {
    this.setState({ onlyShowShopMyBrands: !this.state.onlyShowShopMyBrands, onlyShowBrandTargets: false }, this.loadPins);
  };

  setPartnershipStatusFilters = filters =>
    new Promise(resolve => {
      this.setState({ partnershipStatusFilters: filters }, () => {
        this.loadPins().finally(resolve);
      });
    });

  setShowSpecificRetailerDomains = domains => {
    this.setState({ showSpecificRetailerDomains: domains || [], hideOtherRetailers: false }, this.loadPins);
  };

  updateSearchVal = newVal => {
    this.setState({
      searchVal: newVal,
      isFetchingMerchants: true,
      fetching: true
    });
    this.searchPinsDebouncer();
  };

  updateDates = (startDate, endDate) => {
    this.setState({ startDate, endDate }, () => {
      const searchWithDates = startDate && endDate;
      const searchWithoutDates = !startDate && !endDate;
      if (searchWithDates || searchWithoutDates) {
        this.loadPins();
      }
    });
  };

  render() {
    const { user, ui, analytics } = this.props;
    const {
      results,
      fetching,
      transitioningTabs,
      meta,
      sortOrder,
      hideOtherRetailers,
      onlyShowBrandTargets,
      onlyShowShopMyBrands,
      groupByMode,
      startDate,
      endDate,
      sortDirection,
      searchVal
    } = this.state;

    const superAdminMode = this.isSuperAdminMode();
    if (!isLoggedIn(user)) return <RequiresLoginPanel />;
    if (isShopper(user)) return <RequiresNonShopperLoginPanel />;
    return (
      <>
        <div className='links-outer-container'>
          {insertMetaTags({
            title: isBrand(user) ? `Talent Analytics` : 'My Links',
            description: '',
            image: ''
          })}
          <div className={cn('links-inner-container', { 'super-admin': superAdminMode })}>
            <LinkControls
              analytics={analytics}
              user={user}
              searchVal={searchVal}
              toggleAddingMode={this.toggleAddingMode}
              toggleShowOtherRetailersMode={this.toggleShowOtherRetailersMode}
              downloadCsv={this.downloadCsv}
              updateSearchVal={this.updateSearchVal}
              hideOtherRetailers={hideOtherRetailers}
              showSpecificRetailerDomains={this.state.showSpecificRetailerDomains}
              setShowSpecificRetailerDomains={this.setShowSpecificRetailerDomains}
              startDate={startDate}
              endDate={endDate}
              groupByMode={groupByMode}
              setGroupByMode={this.setGroupByMode}
              updateDates={this.updateDates}
              isSuperAdminMode={superAdminMode}
              selectList={this.selectList}
              selectedList={this.state.selectedList}
            />
            <LinkResultsDisclaimer groupByMode={groupByMode} />
            <LinkResults
              sortOrder={sortOrder}
              sortDirection={sortDirection}
              setSort={this.setSort}
              goToPage={this.goToPage}
              editPin={this.editPin}
              toggleAddingMode={this.toggleAddingMode}
              togglePinToMove={this.props.togglePinToMove}
              deletePin={this.deletePin}
              searchVal={searchVal}
              updateSearchVal={this.updateSearchVal}
              openArtistModal={this.props.openArtistModal}
              selectedList={this.state.selectedList}
              selectList={this.selectList}
              openBrandModal={this.props.openBrandModal}
              onlyShowBrandTargets={onlyShowBrandTargets}
              onlyShowShopMyBrands={onlyShowShopMyBrands}
              toggleOnlyShowBrandTargets={this.toggleOnlyShowBrandTargets}
              toggleOnlyShowShopMyBrands={this.toggleOnlyShowShopMyBrands}
              partnershipStatusFilters={this.state.partnershipStatusFilters}
              setPartnershipStatusFilters={this.setPartnershipStatusFilters}
              meta={meta}
              user={user}
              ui={ui}
              results={results}
              fetching={fetching}
              transitioningTabs={transitioningTabs}
              groupByMode={groupByMode}
              isSuperAdminMode={superAdminMode}
            />
            {!isBrand(user) && (
              <AddPinModal
                inAddMode={this.state.showPinAddOrEditModal}
                pinBeingEdited={this.state.pinBeingEdited}
                toggleAddingMode={this.toggleAddingMode}
                syncExternalState={this.loadPins}
                // syncExternalState={this.loadPinsAndResetToPageOne}{/* Need to get this working */}
                Editing_User_id={
                  // When in admin mode, we must act as if we are the user who owns the pin
                  superAdminMode
                    ? _.get(this.state, 'pinBeingEdited.Pin_id', getUserId(user))
                    : this.state.userIdOverrideForAdminTesting || getUserId(user)
                }
                groupByMode={groupByMode}
                isQuickLinkMode
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user, ui, analytics } = state;
  return { user, ui, analytics };
};

export default connect(mapStateToProps, {
  getCustomRates,
  togglePinToMove,
  openArtistModal,
  openBrandModal,
  setCompleteMovingPinsFn
})(withRouter(Links));
