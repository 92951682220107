import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import './RequiresNonShopperLoginPanel.scss';

import { openAuthModal } from '../../Actions/UIActions';
import { getShopperUpgradeWaitlistApplication } from '../../Helpers/user_helpers';

const RequiresNonShopperLoginPanel = props => {
  const daysUntilReapply = 90;
  const currentApplication = getShopperUpgradeWaitlistApplication(props.user);
  const hasNotYetApplied = !currentApplication;
  const hasAppliedButBeenRejected = currentApplication && !!currentApplication.isRejected;
  const hasAppliedButBeenRejectedAndCannotYetReapply =
    hasAppliedButBeenRejected && moment().diff(moment(currentApplication.createdAt), 'days') <= daysUntilReapply;
  const hasAppliedButBeenRejectedAndCanReapply =
    hasAppliedButBeenRejected && moment().diff(moment(currentApplication.createdAt), 'days') > daysUntilReapply;
  const hasAppliedAndIsPending = currentApplication && !currentApplication.isRejected;
  return (
    <div className='requires-non-shopper-login-outer-container'>
      <div className='requires-non-shopper-login-inner-container'>
        <div className='header'>You must have an upgraded account to view this page.</div>
        {hasNotYetApplied && (
          <>
            <div className='subheader'>
              Your account is currently a shopper account, in order to access this page you must apply to upgrade it to a creator account.
            </div>
            <div onClick={() => props.openAuthModal('apply-upgrade')} className='upgrade-btn'>
              Apply to Upgrade
            </div>
          </>
        )}
        {hasAppliedButBeenRejectedAndCannotYetReapply && (
          <div className='subheader'>
            Unfortunately your application to upgrade your account to a creator account was not accepted. You can reapply after{' '}
            {moment(currentApplication.createdAt)
              .add(daysUntilReapply, 'days')
              .format('MMMM Do, YYYY')}
            .
          </div>
        )}
        {hasAppliedButBeenRejectedAndCanReapply && (
          <>
            <div className='subheader'>
              Unfortunately your application to upgrade your account to a creator account was not accepted. You can reapply now.
            </div>
            <div onClick={() => props.openAuthModal('apply-upgrade')} className='upgrade-btn'>
              Reapply
            </div>
          </>
        )}
        {hasAppliedAndIsPending && (
          <div className='subheader'>
            You applied to upgrade your account on {moment(currentApplication.createdAt).format('MMMM Do, YYYY')}. Your application is currently
            pending review.
          </div>
        )}
      </div>
    </div>
  );
};

RequiresNonShopperLoginPanel.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(RequiresNonShopperLoginPanel);
