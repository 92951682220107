import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBadgeDollar, faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeDollar as faBadgeDollarSolid, faBadgeCheck as faBadgeCheckSolid } from '@fortawesome/pro-solid-svg-icons';

import './LinkResults.scss';

import LinkResult from './LinkResult';
import ShopMyPagination from '../General/ShopMyPagination';
import EmptyLinkResults from './EmptyLinkResults';
import RequiresPermissions from '../Managers/RequiresPermissions';
import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';
import PartnershipStatusFilters from '../PartnershipStatus/PartnershipStatusFilters';

import { getPrettyNumber } from '../../Helpers/formatting';
import { isBrand } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { hasUnlockedIncreasedReferralBonuses } from '../../Helpers/tier_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';

class LinkResults extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    results: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    transitioningTabs: PropTypes.bool.isRequired,
    setSort: PropTypes.func.isRequired,
    sortDirection: PropTypes.string.isRequired,
    searchVal: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    groupByMode: PropTypes.string.isRequired,
    selectedList: PropTypes.object,
    openArtistModal: PropTypes.func.isRequired,
    editPin: PropTypes.func.isRequired,
    togglePinToMove: PropTypes.func.isRequired,
    toggleAddingMode: PropTypes.func.isRequired,
    onlyShowBrandTargets: PropTypes.bool.isRequired,
    onlyShowShopMyBrands: PropTypes.bool.isRequired,
    partnershipStatusFilters: PropTypes.object.isRequired,
    toggleOnlyShowBrandTargets: PropTypes.func.isRequired,
    toggleOnlyShowShopMyBrands: PropTypes.func.isRequired,
    setPartnershipStatusFilters: PropTypes.func.isRequired,
    updateSearchVal: PropTypes.func.isRequired,
    selectList: PropTypes.func.isRequired,
    deletePin: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    openBrandModal: PropTypes.func.isRequired,
    isSuperAdminMode: PropTypes.bool.isRequired
  };

  clickFilter = filter => {
    this.props.setSort(filter, this.props.sortOrder !== filter || this.props.sortDirection === 'asc' ? 'desc' : 'asc');
  };

  getHeaderCell = (name, additionalClasses, sortVar, options = {}) => {
    const { fetching, sortOrder, sortDirection, user, ui } = this.props;
    const {
      requiresFeature,
      requiresPermission,
      metric,
      isMetricDollarValue,
      showMetricAsDollars,
      additionalIconActions,
      showPartnershipStatusFiltering
    } = options;
    const classes = cn('cell', additionalClasses, { clickable: sortVar });
    const isBlocked = isBrand(user) && !!requiresFeature && !isSubscribedToFeature(user, requiresFeature) && !isAdminControlMode(ui);
    const click = () =>
      !sortVar
        ? null
        : isBrand(user)
        ? sortVar && (!isBlocked || !blockOnRequiringSubscription(user, requiresFeature)) && this.clickFilter(sortVar)
        : this.clickFilter(sortVar);
    const isCurSort = sortVar && sortOrder === sortVar;
    const tooltipDisplay = isBlocked || !metric ? '' : `${isMetricDollarValue ? '$' : ''}${commaNumber(metric.toFixed(0))}`;
    return requiresPermission ? (
      <RequiresPermissions permission={requiresPermission} hideMessage>
        <div onClick={click} className={classes}>
          {sortVar && (
            <FontAwesomeIcon
              className={cn('sort', { active: isCurSort })}
              icon={isCurSort ? (sortDirection === 'desc' ? faArrowDown : faArrowUp) : faArrowDown}
            />
          )}
          {name}
          {!!metric && (
            <Tooltip interactive message={tooltipDisplay}>
              <div className={cn('metric', { fetching })}>
                {showMetricAsDollars ? '$' : ''}
                {getPrettyNumber(Math.round(metric))}
                {fetching && <Loader size={24} />}
              </div>
            </Tooltip>
          )}
        </div>
      </RequiresPermissions>
    ) : (
      <div onClick={click} className={classes}>
        {sortVar && (
          <FontAwesomeIcon
            className={cn('sort', { active: isCurSort })}
            icon={isCurSort ? (sortDirection === 'desc' ? faArrowDown : faArrowUp) : faArrowDown}
          />
        )}
        {name}
        {!!metric && (
          <Tooltip interactive message={tooltipDisplay}>
            <div className={cn('metric', { fetching })}>
              {isBlocked ? (
                <>
                  VIEW
                  {fetching && <Loader size={24} />}
                </>
              ) : (
                <>
                  {showMetricAsDollars ? '$' : ''}
                  {getPrettyNumber(Math.round(metric))}
                  {fetching && <Loader size={24} />}
                </>
              )}
            </div>
          </Tooltip>
        )}
        {showPartnershipStatusFiltering && (
          <PartnershipStatusFilters filters={this.props.partnershipStatusFilters} setFilters={this.props.setPartnershipStatusFilters} />
        )}
        {!!additionalIconActions &&
          additionalIconActions.map((action, index) => (
            <Tooltip key={index} message={action.tooltip}>
              <FontAwesomeIcon onClick={action.onClick} icon={action.icon} className={cn('icon-toggle', { active: action.active })} />
            </Tooltip>
          ))}
      </div>
    );
  };

  shouldHideHeaderOnMobile = () => {
    if (this.props.groupByMode === 'domains') return false;
    return true;
  };

  getWarningEl = () => {
    const { user, groupByMode } = this.props;
    const isBlackFridayWindow = moment().isBetween('2024-11-15', '2024-12-04');
    if (!isBrand(user) && isBlackFridayWindow && ['mentions', 'creator-orders'].includes(groupByMode)) {
      return (
        <div className='results-warning'>
          <span>
            <span className='main'>Not seeing your recent sales?</span> Orders can take up to 3 days to populate in your links.
          </span>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      user,
      ui,
      meta,
      results,
      searchVal,
      groupByMode,
      toggleAddingMode,
      updateSearchVal,
      fetching,
      transitioningTabs,
      selectedList,
      toggleOnlyShowShopMyBrands,
      toggleOnlyShowBrandTargets,
      onlyShowShopMyBrands,
      onlyShowBrandTargets,
      isSuperAdminMode
    } = this.props;
    const { summary } = meta || {};
    const { commissionTotal, mentions, orderVolumeTotal, estimatedMediaValue, ordersTotal, views } = summary || {};
    const hideHeaderOnMobile = this.shouldHideHeaderOnMobile();
    const allowShowingTargetUpsell = hasUnlockedIncreasedReferralBonuses(user);
    const warningEl = this.getWarningEl();
    return (
      <div className='link-results-outer-container'>
        <div className={cn('row header', { 'hide-on-mobile': hideHeaderOnMobile })}>
          {groupByMode === 'users' ? (
            isSuperAdminMode ? (
              <>
                {this.getHeaderCell('User', 'xl')}
                {this.getHeaderCell('Links', 'sm', 'mentions', { requiresFeature: 'ANALYTICS', metric: mentions })}
                {this.getHeaderCell('Clicks', 'sm', 'views', { requiresFeature: 'ANALYTICS', metric: views })}
                {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { requiresFeature: 'ANALYTICS', metric: ordersTotal })}
                {this.getHeaderCell('Volume', 'sm', 'orderVolumeTotal', {
                  requiresFeature: 'ANALYTICS',
                  metric: orderVolumeTotal,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('Commission', 'sm', 'commissionTotal', {
                  requiresFeature: 'ANALYTICS',
                  metric: commissionTotal,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('EMV', 'sm', 'estimatedMediaValue', {
                  requiresFeature: 'SOCIAL_MENTIONS_FEED',
                  metric: estimatedMediaValue,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('First Linked', 'md', 'createdAt')}
              </>
            ) : (
              <>
                {this.getHeaderCell('User', 'lg')}
                {this.getHeaderCell('Links', 'sm', 'mentions', { requiresFeature: 'ANALYTICS', metric: mentions })}
                {this.getHeaderCell('Clicks', 'sm', 'views', { requiresFeature: 'ANALYTICS', metric: views })}
                {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { requiresFeature: 'ANALYTICS', metric: ordersTotal })}
                {this.getHeaderCell('Volume', 'sm', 'orderVolumeTotal', {
                  requiresFeature: 'ANALYTICS',
                  metric: orderVolumeTotal,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('Commission', 'sm', 'commissionTotal', {
                  requiresFeature: 'ANALYTICS',
                  metric: commissionTotal,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('EMV', 'sm', 'estimatedMediaValue', {
                  requiresFeature: 'SOCIAL_MENTIONS_FEED',
                  metric: estimatedMediaValue,
                  isMetricDollarValue: true
                })}
                {this.getHeaderCell('First Linked', 'sm', 'createdAt', {
                  requiresFeature: 'ANALYTICS'
                })}
                {this.getHeaderCell('Last Linked', 'sm', 'lastLinkedAt', {
                  requiresFeature: 'ANALYTICS'
                })}
                {this.getHeaderCell('Partnership Status', 'md', null, { showPartnershipStatusFiltering: true })}
              </>
            )
          ) : groupByMode === 'products' ? (
            <>
              {this.getHeaderCell('Product', 'xl')}
              {isSuperAdminMode && this.getHeaderCell('Brand', 'sm')}
              {this.getHeaderCell('Links', 'sm', 'mentions', { requiresFeature: 'ANALYTICS', metric: mentions })}
              {this.getHeaderCell('Clicks', 'sm', 'views', { requiresFeature: 'ANALYTICS', metric: views })}
              {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { requiresFeature: 'ANALYTICS', metric: ordersTotal, isMetricDollarValue: true })}
              {this.getHeaderCell('Volume', 'sm', 'orderVolumeTotal', {
                requiresFeature: 'ANALYTICS',
                metric: orderVolumeTotal,
                isMetricDollarValue: true
              })}
            </>
          ) : groupByMode === 'domains' ? (
            <>
              {this.getHeaderCell('Domain', 'xl', null, {
                additionalIconActions: [
                  {
                    icon: onlyShowShopMyBrands ? faBadgeCheckSolid : faBadgeCheck,
                    onClick: toggleOnlyShowShopMyBrands,
                    active: onlyShowShopMyBrands,
                    tooltip: 'ShopMy Partners Only'
                  },
                  ...(allowShowingTargetUpsell
                    ? [
                        {
                          icon: onlyShowBrandTargets ? faBadgeDollarSolid : faBadgeDollar,
                          onClick: toggleOnlyShowBrandTargets,
                          active: onlyShowBrandTargets,
                          tooltip: 'Icon Referral Program Only'
                        }
                      ]
                    : [])
                ]
              })}
              {this.getHeaderCell('Current Rate', 'sm desktop-only')}
              {this.getHeaderCell('Links', 'sm desktop-only', 'mentions', { metric: mentions })}
              {this.getHeaderCell('Clicks', 'sm', 'views', { metric: views })}
              {this.getHeaderCell('Orders', 'sm desktop-only', 'ordersTotal', { requiresPermission: 'canViewEarnings', metric: ordersTotal })}
              {this.getHeaderCell('Volume', 'sm desktop-only', 'orderVolumeTotal', {
                requiresPermission: 'canViewEarnings',
                metric: orderVolumeTotal,
                isMetricDollarValue: true
              })}
              {this.getHeaderCell('Earned', 'sm', 'commissionTotal', {
                requiresPermission: 'canViewEarnings',
                metric: commissionTotal,
                showMetricAsDollars: true,
                isMetricDollarValue: true
              })}
            </>
          ) : groupByMode === 'orders' ? (
            <>
              {this.getHeaderCell('Date', 'sm', 'createdAt')}
              {this.getHeaderCell('User', 'sm')}
              {this.getHeaderCell('Content', 'md')}
              {this.getHeaderCell('Order #', 'sm')}
              {this.getHeaderCell('Domain', 'sm')}
              {this.getHeaderCell('Network', 'sm')}
              {this.getHeaderCell('Amount', 'sm', 'orderVolumeTotal', {
                requiresFeature: 'ANALYTICS',
                metric: orderVolumeTotal,
                isMetricDollarValue: true
              })}
              {this.getHeaderCell('Commission', 'sm', 'commissionTotal', {
                requiresFeature: 'ANALYTICS',
                metric: commissionTotal,
                isMetricDollarValue: true
              })}
              {this.getHeaderCell('Code Used', 'sm')}
            </>
          ) : groupByMode === 'creator-orders' ? (
            <>
              {this.getHeaderCell('Date', 'sm', 'createdAt')}
              {this.getHeaderCell('Merchant', 'sm')}
              {this.getHeaderCell('Content', 'md desktop-only')}
              {this.getHeaderCell('Amount', 'sm desktop-only', 'orderVolumeTotal', {
                requiresPermission: 'canViewEarnings',
                metric: orderVolumeTotal
              })}
              {this.getHeaderCell('Commission', 'sm', 'commissionTotal', { requiresPermission: 'canViewEarnings', metric: commissionTotal })}
              {this.getHeaderCell('Code Used', 'sm')}
            </>
          ) : isSuperAdminMode ? (
            <>
              {this.getHeaderCell('Created', 'sm', 'createdAt')}
              {this.getHeaderCell('Product', 'xl')}
              {this.getHeaderCell('User', 'md')}
              {this.getHeaderCell('Content', 'md')}
              {this.getHeaderCell('Geolinks', 'sm')}
              {this.getHeaderCell('Clicks', 'sm', 'views', { metric: views })}
              {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { metric: ordersTotal })}
              {this.getHeaderCell('Volume', 'sm', 'orderVolumeTotal', { metric: orderVolumeTotal, isMetricDollarValue: true })}
              {this.getHeaderCell('Commission', 'sm', 'commissionTotal', { metric: commissionTotal, isMetricDollarValue: true })}
              {this.getHeaderCell('Affiliate Link', 'lg')}
            </>
          ) : isBrand(user) ? (
            <>
              {this.getHeaderCell('Created', 'sm', 'createdAt')}
              {this.getHeaderCell('Product', 'xl')}
              {this.getHeaderCell('User', 'md')}
              {this.getHeaderCell('Content', 'md')}
              {this.getHeaderCell('Clicks', 'sm', 'views', { requiresFeature: 'ANALYTICS', metric: views })}
              {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { requiresFeature: 'ANALYTICS', metric: ordersTotal })}
              {this.getHeaderCell('Volume', 'sm', 'orderVolumeTotal', {
                requiresFeature: 'ANALYTICS',
                metric: orderVolumeTotal,
                isMetricDollarValue: true
              })}
              {this.getHeaderCell('Commission', 'sm', 'commissionTotal', {
                requiresFeature: 'ANALYTICS',
                metric: commissionTotal,
                isMetricDollarValue: true
              })}
            </>
          ) : (
            <>
              {this.getHeaderCell('', 'xs')}
              {this.getHeaderCell('Created', 'sm', 'createdAt')}
              {this.getHeaderCell('Product', 'xl')}
              {this.getHeaderCell('Content', 'md')}
              {this.getHeaderCell('Clicks', 'sm', 'views', { metric: views })}
              {this.getHeaderCell('Orders', 'sm', 'ordersTotal', { requiresPermission: 'canViewEarnings', metric: ordersTotal })}
              {this.getHeaderCell('Earned', 'sm', 'commissionTotal', {
                requiresPermission: 'canViewEarnings',
                metric: commissionTotal,
                showMetricAsDollars: true,
                isMetricDollarValue: true
              })}
              {this.getHeaderCell('Affiliate Link', 'lg')}
            </>
          )}
        </div>
        {!!results.length ? (
          <div className={cn('rows body', { fetching, transitioning: transitioningTabs })}>
            {warningEl}
            {results.map(result => (
              <LinkResult
                key={result.id || result.User_id || result.Product_id}
                user={user}
                ui={ui}
                result={result}
                groupByMode={groupByMode}
                deletePin={this.props.deletePin}
                editPin={this.props.editPin}
                openArtistModal={this.props.openArtistModal}
                openBrandModal={this.props.openBrandModal}
                togglePinToMove={this.props.togglePinToMove}
                isSuperAdminMode={isSuperAdminMode}
              />
            ))}
          </div>
        ) : fetching ? (
          <div className='loading-msg'>Loading...</div>
        ) : (
          <EmptyLinkResults
            updateSearchVal={updateSearchVal}
            toggleAddingMode={toggleAddingMode}
            meta={meta}
            searchVal={searchVal}
            selectedList={selectedList}
            onlyShowBrandTargets={onlyShowBrandTargets}
            onlyShowShopMyBrands={onlyShowShopMyBrands}
            groupByMode={this.props.groupByMode}
            selectList={this.props.selectList}
            user={user}
          />
        )}
        {!_.isEmpty(meta) && meta.total_pages > 1 && (
          <div className='pagination-container'>
            <ShopMyPagination goToPage={this.props.goToPage} curPage={meta.cur_page} totalPages={meta.total_pages} />
          </div>
        )}
      </div>
    );
  }
}

export default LinkResults;
