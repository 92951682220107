import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ShopHeaderEmptySearchResultsPanel.scss';

import { getFirstName } from '../../../Helpers/formatting';
import { getUserId } from '../../../Helpers/user_helpers';
import { getExampleShopSearchTerms } from '../../../Helpers/search_helpers';
import { getActiveCurator, getActiveCuratorGroup } from '../../../Helpers/shop_helpers';

const ShopHeaderEmptySearchResultsPanel = props => {
  const { resultTypeDisplay, shop, searchApplyingCuratorId, searchApplyingCuratorGroupId } = props;

  const isCuratorSearch = resultTypeDisplay === 'Curators';
  const activeCurator = getActiveCurator(shop);
  const activeCuratorGroup = getActiveCuratorGroup(shop);
  const isScopedToCurator = searchApplyingCuratorId || searchApplyingCuratorGroupId;

  const isMyShop = getUserId(props.user) && searchApplyingCuratorId === getUserId(props.user);

  const exampleSearchTermsRef = React.useRef(_.shuffle(getExampleShopSearchTerms()).slice(0, 5));
  const exampleSearchTerms = exampleSearchTermsRef.current;

  const clickExampleTerm = term => {
    props.setSearchVal(term);
    window.__ADD_EVENT__('Shop - Click Example Search Term', { term });
  };

  return props.searchVal ? (
    <div className='shop-header-empty-search-results-panel empty'>
      <div className='header'>{isCuratorSearch ? 'No Curators Found' : 'No Circles Found'}</div>
      <div className='explainer'>
        {isCuratorSearch ? (
          <div>
            ShopMy search only returns the top performing curators. If you can't find who you are looking for, you can look for their ShopMy shop via
            their social profile or ask them to share it with you.
          </div>
        ) : (
          <div>
            ShopMy only features a select number of featured circles, you can discover more circles{' '}
            <Link onClick={props.closePanel} to='/shop/circles'>
              here
            </Link>
            .
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className='shop-header-empty-search-results-panel'>
      {isScopedToCurator ? (
        activeCurator ? (
          isMyShop ? (
            <div className='header'>Search Your Products</div>
          ) : (
            <div className='header'>
              Search {getFirstName(activeCurator.name)}
              {getFirstName(activeCurator.name).slice(-1) === 's' ? "'" : "'s"} Recommendations
            </div>
          )
        ) : (
          <div className='header'>Search "{activeCuratorGroup.title}"</div>
        )
      ) : (
        <div className='header'>What are you looking for?</div>
      )}
      {isScopedToCurator ? (
        <div className='explainer-container'>
          <div className='explainer'>Search brands, categories, anything.</div>
        </div>
      ) : (
        <div className='explainer-container'>
          <div className='explainer'>Search for curators, products, brands or categories.</div>
          <div className='example-search-terms'>
            {exampleSearchTerms.map((term, index) => {
              const select = () => clickExampleTerm(term);
              // Need to use onTouchStart and onMouseDown to prevent the input from losing focus
              return (
                <div onTouchStart={select} onMouseDown={select} key={index} className='example-search-term'>
                  {term}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

ShopHeaderEmptySearchResultsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  searchVal: PropTypes.string,
  resultTypeDisplay: PropTypes.string,
  closePanel: PropTypes.func.isRequired,
  searchApplyingCuratorId: PropTypes.string.isRequired,
  setSearchApplyingCuratorId: PropTypes.func.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  searchApplyingCuratorGroupId: PropTypes.string.isRequired,
  setSearchApplyingCuratorGroupId: PropTypes.func.isRequired
};

export default ShopHeaderEmptySearchResultsPanel;
