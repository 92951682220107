import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Chat.scss';

import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import RequiresNonShopperLoginPanel from '../../Components/General/RequiresNonShopperLoginPanel';
import ChatMessages from '../../Components/Chat/ChatMessages';
import ChatSearch from '../../Components/Chat/ChatSearch';
import ChatConnect from '../../Components/Chat/ChatConnect';
import ChatPotentialPartner from '../../Components/Chat/ChatPotentialPartner';
import ConfirmPrompt from '../../Components/General/ConfirmPrompt';
import ScrollToTop from '../../Components/General/ScrollToTop';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';
import AppPromotionBanner from '../../Components/General/AppPromotionBanner';
import Loader from '../../Components/Loader/Loader';

import {
  isLoggedIn,
  getUserId,
  getName,
  getBrandId,
  isShopper,
  getBrandName,
  isBrand,
  isSimulatingUser,
  getAddress
} from '../../Helpers/user_helpers';
import { isUserIdPromoterForOutreachPurposes } from '../../Helpers/talent_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { getCurrentManager } from '../../Helpers/manager_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';
import { setCustomRate } from '../../Helpers/rate_helpers';
import { openArtistModal, openBrandModal, openBonusModal, openCodesModal, openRequestModal, openFulfillmentModal } from '../../Actions/UIActions';
import { updateRequest, setAddress, syncRequests } from '../../Actions/UserActions';
import { createContractWithBrand, createContractWithUser } from '../../Actions/CollaborationsActions';
import { createSamplesRequest, setCustomCode, setCustomCommissionRate } from '../../Actions/AnalyticsActions';
import {
  getChats,
  setActiveChat,
  setActivePotentialPartner,
  receivedMessage,
  editedMessage,
  deletedMessage,
  typedMessage,
  updateChat,
  deleteChat,
  ADD_MESSAGE,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  TYPING_MESSAGE
} from '../../Actions/ChatActions';
import { updateBrandList, deleteBrandList } from '../../Actions/BrandActions';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

class Chat extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    talent: PropTypes.object.isRequired,
    manager: PropTypes.object.isRequired,
    chats: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    getChats: PropTypes.func.isRequired,
    setActiveChat: PropTypes.func.isRequired,
    setActivePotentialPartner: PropTypes.func.isRequired,
    createSamplesRequest: PropTypes.func.isRequired,
    syncRequests: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
    updateChat: PropTypes.func.isRequired,
    deleteChat: PropTypes.func.isRequired,
    updateBrandList: PropTypes.func.isRequired,
    deleteBrandList: PropTypes.func.isRequired,
    receivedMessage: PropTypes.func.isRequired,
    editedMessage: PropTypes.func.isRequired,
    deletedMessage: PropTypes.func.isRequired,
    typedMessage: PropTypes.func.isRequired,
    openBrandModal: PropTypes.func.isRequired,
    openBonusModal: PropTypes.func.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    openCodesModal: PropTypes.func.isRequired,
    openRequestModal: PropTypes.func.isRequired,
    openFulfillmentModal: PropTypes.func.isRequired
  };

  componentDidMount = () => {
    // Super messy, needs cleanup work.
    if (window.innerWidth < 768) {
      document.querySelector('body').style.overflow = 'hidden';
      window.scrollTo(0, 0);
    }
  };
  componentWillUnmount() {
    document.querySelector('body').style.overflow = '';
  }

  state = {
    isSearchExpandedOnMobile: true,
    isConnectedToChat: false,
    failedConnectionToChat: false
  };

  partnersAreBrands = () => !isBrand(this.props.user);
  partnersAreCreators = () => !!isBrand(this.props.user);

  selectInitialChat = chat => this.selectChat(chat);
  selectChat = chat => {
    if (isBrand(this.props.user) ? chat.hasNewMessagesForBrand : chat.hasNewMessagesForUser) {
      !isSimulatingUser(this.props.user) &&
        this.props.updateChat(chat, { [isBrand(this.props.user) ? 'hasNewMessagesForBrand' : 'hasNewMessagesForUser']: false });
    }
    this.props.setActiveChat(chat);
    this.setState({ isSearchExpandedOnMobile: false });
  };
  expandSearchOnMobile = () => this.setState({ isSearchExpandedOnMobile: true });
  selectInitialActivePotentialPartner = partner => this.selectActivePotentialPartner(partner, true);
  selectActivePotentialPartner = (partner, isFirst) => {
    this.props.setActivePotentialPartner(partner);
    !isFirst && this.setState({ isSearchExpandedOnMobile: false });
  };

  /******************************************************************************
   *************************** General Message **********************************
   ******************************************************************************/
  sendMessage = msg => {
    const { chats, user, manager } = this.props;
    const currentManager = getCurrentManager(manager);
    if (!chats.activeChat) return;

    this.ws.send(
      JSON.stringify({
        event: ADD_MESSAGE,
        message: msg,
        User_id: isBrand(user) ? chats.activeChat.User_id : getUserId(user),
        Brand_id: isBrand(user) ? getBrandId(user) : chats.activeChat.Brand_id,
        ...(currentManager ? { Manager_id: currentManager.id } : {})
      })
    );
  };

  editMessage = (msg, newMsg) => {
    const { chats, user } = this.props;
    if (!chats.activeChat) return;

    this.ws.send(
      JSON.stringify({
        event: EDIT_MESSAGE,
        message: msg,
        newMessage: newMsg,
        User_id: isBrand(user) ? chats.activeChat.User_id : getUserId(user),
        Brand_id: isBrand(user) ? getBrandId(user) : chats.activeChat.Brand_id
      })
    );
  };

  deleteMessage = msg => {
    const { chats, user } = this.props;
    if (!chats.activeChat) return;

    this.ws.send(
      JSON.stringify({
        event: DELETE_MESSAGE,
        message: msg,
        User_id: isBrand(user) ? chats.activeChat.User_id : getUserId(user),
        Brand_id: isBrand(user) ? getBrandId(user) : chats.activeChat.Brand_id
      })
    );
  };

  typingMessage = typingData => {
    const { chats, user } = this.props;
    if (!chats.activeChat) return;

    this.ws.send(
      JSON.stringify({
        event: TYPING_MESSAGE,
        User_id: isBrand(user) ? chats.activeChat.User_id : getUserId(user),
        Brand_id: isBrand(user) ? getBrandId(user) : chats.activeChat.Brand_id,
        typingData
      })
    );
  };

  /******************************************************************************
   ************************ Partnership Request **********************************
   ******************************************************************************/
  requestPartnership = type => {
    const { talent, chats, user } = this.props;

    // Brand > User
    if (!this.partnersAreBrands()) {
      const userIsPromoter = isUserIdPromoterForOutreachPurposes(talent, chats.activePotentialPartner?.id);
      if (blockOnRequiringSubscription(user, userIsPromoter ? 'CHAT_WITH_PROMOTERS' : 'UNLIMITED_CHAT')) return null;
      this.ws.send(
        JSON.stringify({
          message: `${getBrandName(user)} started a chat.`,
          User_id: chats.activePotentialPartner?.id,
          Brand_id: getBrandId(user),
          isAutomated: true
        })
      );
      return;
    }

    let promptHeader, promptSubheader, promptPlaceholder, automatedMessage;
    switch (type) {
      case 'gifting':
        promptHeader = 'Add a message with your request.';
        promptSubheader = `Let the brand know if there are specific products you'd like to try or if you are working on a piece of content that you'd like to try their product for.`;
        promptPlaceholder = `Hi there! I've heard great things and I'd love to try your products.`;
        automatedMessage = `${getName(user)} requests gifting.`;
        break;
      case 'code':
        promptHeader = 'Add a message with your request.';
        promptSubheader = `Let the brand know if there is a specific code you'd like and feel free to include any other relevant information to your discount code request.`;
        promptPlaceholder = `Hi there! Could I get a custom discount code to share with my audience? Thank you so much.`;
        automatedMessage = `${getName(user)} requests a custom discount code.`;
        break;
      default:
        promptHeader = 'Write your message';
        promptSubheader = `Have a question or request for this brand? Write your message here to start a chat with them.`;
        automatedMessage = `${getName(user)} started a chat.`;
        break;
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header={promptHeader}
          subheader={promptSubheader}
          placeholder={promptPlaceholder || ''}
          onCancel={onClose}
          onSubmit={additionalMessage => {
            this.ws.send(
              JSON.stringify({
                message: automatedMessage,
                User_id: getUserId(user),
                Brand_id: chats.activePotentialPartner?.id,
                type: 'partnership',
                email_extras: {
                  partnershipMessage: additionalMessage
                },
                isAutomated: true
              })
            );
            additionalMessage &&
              setTimeout(() => {
                this.ws.send(
                  JSON.stringify({
                    message: additionalMessage,
                    User_id: getUserId(user),
                    type: 'partnership',
                    Brand_id: chats.activePotentialPartner?.id
                  })
                );
              }, 200);
          }}
        />
      )
    });
  };

  /******************************************************************************
   ************************** Gifting Request (User) ****************************
   ******************************************************************************/
  acceptGiftingFromBrand = sampleRequest => {
    const { user } = this.props;
    const address = getAddress(user);
    if (!address?.address) {
      this.requestAddress(() => this.acceptGiftingFromBrand(sampleRequest));
      return;
    }

    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to accept samples from ${sampleRequest.brand.name}? If you do, we will notify them and they will mail the samples to: ${address.address}.`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Change Address',
          className: 'cancel',
          onClick: () => {
            setTimeout(() => this.requestAddress(() => this.acceptGiftingFromBrand(sampleRequest)), 150); // For design feel
          }
        },
        {
          label: 'Yes',
          onClick: () => {
            this.props.updateRequest(sampleRequest, { userAccepted: true, userRejected: false });
          }
        }
      ]
    });
  };

  requestAddress = cb => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          onCancel={onClose}
          submitMustReturnTrueToClose={true}
          customInputFields={[
            {
              display: 'Please enter an address to send the gift to.',
              placeholder: '77 Mass Ave, Cambridge MA 02142',
              preloaded: getAddress(this.props.user)?.address || '',
              value: 'address',
              isSingleLine: true
            },
            {
              display: 'Phone Number (Optional)',
              placeholder: '781 307 8404',
              preloaded: getAddress(this.props.user)?.address || '',
              value: 'phone',
              isSingleLine: true
            }
          ]}
          onSubmit={response => {
            const { address, phone } = response;
            if (address) {
              this.props.setAddress(this.props.user, { address, phone }).then(
                resp => {
                  const { error } = resp;

                  if (error) {
                    cogoToast.error(`${error}`);
                    return false;
                  }

                  this.setState({ address: resp.address }, cb);
                  return true;
                },
                err => cogoToast.error('There was an issue saving your address. Please try again.')
              );
            } else {
              cogoToast.error('Please enter an address.');
              return false;
            }
          }}
        />
      )
    });
  };

  /******************************************************************************
   ************************** Gifting Request (Brand) ***************************
   ******************************************************************************/
  requestGiftingToUser = () => {
    const { chats } = this.props;
    this.props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests(chats.activePotentialPartner?.id ? [chats.activePotentialPartner] : [chats.activeChat?.user])
      }
    });
  };

  /******************************************************************************
   ************************** Gifting Request (Brand) ***************************
   ******************************************************************************/
  requestOpportunityToUser = () => {
    const { chats } = this.props;
    this.props.openRequestModal({
      params: {
        type: 'opportunities',
        preselectedUsers: formatUsersForBulkRequests(chats.activePotentialPartner?.id ? [chats.activePotentialPartner] : [chats.activeChat?.user])
      }
    });
  };

  /******************************************************************************
   ***************************** Codes (Brand) **********************************
   ******************************************************************************/
  offerCodeToUser = existingCode => {
    const { chats } = this.props;
    this.props.openCodesModal({
      params: {
        User_id: chats.activePotentialPartner?.id || chats.activeChat?.user?.id,
        name: chats.activePotentialPartner?.name || chats.activeChat?.user?.name,
        showTemplates: true
      }
    });
  };

  /******************************************************************************
   ***************************** Rates (Brand) **********************************
   ******************************************************************************/
  setCustomRate = existingRate => {
    const { chats, user, analytics, setCustomCommissionRate } = this.props;

    setCustomRate({
      user,
      artist: chats.activePotentialPartner || chats.activeChat?.user,
      analytics,
      setCustomCommissionRate
    });
  };

  /******************************************************************************
   ************************** Collaborations (User) *****************************
   ******************************************************************************/
  createCollaborationWithBrand = Brand_id => {
    if (this.state.isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    this.setState({ isCreatingContract: true });
    this.props.createContractWithBrand(Brand_id).then(resp => {
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      } else {
        this.setState({ isCreatingContract: false });
      }
    });
  };

  /******************************************************************************
   ************************** Collaborations (Brand) *****************************
   ******************************************************************************/
  createCollaborationWithUser = User_id => {
    if (this.state.isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    this.setState({ isCreatingContract: true });
    this.props.createContractWithUser(User_id).then(resp => {
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      } else {
        this.setState({ isCreatingContract: false });
      }
    });
    setTimeout(() => this.setState({ isCreatingContract: false }), 1000); // In case they dismiss a modal from the reducer
  };

  render = () => {
    const { user, ui, manager, talent, chats, analytics, getChats } = this.props;
    const { isConnectedToChat, failedConnectionToChat } = this.state;
    if (!isLoggedIn(user)) return <RequiresLoginPanel />;
    if (isShopper(user)) return <RequiresNonShopperLoginPanel />;
    return (
      <RequiresPermissions permission='canAnswerChats'>
        {insertMetaTags({
          title: isBrand(user) ? `Chat with Talent` : 'Chat with Brands',
          description: '',
          image: ''
        })}
        <AppPromotionBanner />
        <ChatConnect
          user={user}
          setWebsocket={ws => (this.ws = ws)}
          receivedMessage={this.props.receivedMessage}
          onEditMessage={this.props.editedMessage}
          onDeleteMessage={this.props.deletedMessage}
          onTypingMessage={this.props.typedMessage}
          onConnect={() => this.setState({ isConnectedToChat: true })}
          onFailedAttemplt={() => this.setState({ isConnectedToChat: true })}
          onFailure={() => this.setState({ failedConnectionToChat: true })}
          onMessage={({ chat, message }) => {
            const { chats } = this.props;

            // If this is our first message, we want to now select that chat
            if (chats?.activePotentialPartner?.id === (this.partnersAreBrands() ? chat.Brand_id : chat.User_id)) {
              this.selectInitialChat(chat);
            }

            // If we just received a gifting email, we need to resync requests
            if (message?.type === 'gifting' && message?.isAutomated) {
              this.props.syncRequests();
            }
          }}
        />
        <div className='chat-outer-container'>
          <ScrollToTop />
          <div className='chat-inner-container'>
            <div
              className={cn('chat-connection-status', {
                connected: isConnectedToChat,
                failed: failedConnectionToChat,
                connecting: !isConnectedToChat && !failedConnectionToChat
              })}
            >
              {failedConnectionToChat ? (
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Connection Failed
                </>
              ) : isConnectedToChat ? (
                <>
                  <FontAwesomeIcon icon={faCheck} /> Connected To Chat Server
                </>
              ) : (
                'Connecting...'
              )}
            </div>
            <ChatSearch
              chats={chats}
              user={user}
              ui={ui}
              getChats={getChats}
              activeChat={chats.activeChat}
              activePotentialPartner={chats.activePotentialPartner}
              isSearchExpandedOnMobile={this.state.isSearchExpandedOnMobile}
              selectChat={this.selectChat}
              selectInitialChat={this.selectInitialChat}
              selectActivePotentialPartner={this.selectActivePotentialPartner}
              selectInitialActivePotentialPartner={this.selectInitialActivePotentialPartner}
              expandSearchOnMobile={this.expandSearchOnMobile}
              updateBrandList={this.props.updateBrandList}
              deleteBrandList={this.props.deleteBrandList}
              updateChat={this.props.updateChat}
              deleteChat={this.props.deleteChat}
            />
            <div className='chat-chat-container container'>
              {chats.activeChat && (
                <ChatMessages
                  user={user}
                  ui={ui}
                  manager={manager}
                  analytics={analytics}
                  talent={talent}
                  activeChat={chats.activeChat}
                  acceptGiftingFromBrand={this.acceptGiftingFromBrand}
                  requestGiftingToUser={this.requestGiftingToUser}
                  requestOpportunityToUser={this.requestOpportunityToUser}
                  offerCodeToUser={this.offerCodeToUser}
                  setCustomRate={this.setCustomRate}
                  sendMessage={this.sendMessage}
                  editMessage={this.editMessage}
                  deleteMessage={this.deleteMessage}
                  typingMessage={this.typingMessage}
                  updateChat={this.props.updateChat}
                  openArtistModal={this.props.openArtistModal}
                  openBrandModal={this.props.openBrandModal}
                  openBonusModal={this.props.openBonusModal}
                  openFulfillmentModal={this.props.openFulfillmentModal}
                  createCollaborationWithBrand={this.createCollaborationWithBrand}
                  createCollaborationWithUser={this.createCollaborationWithUser}
                />
              )}
              {chats.activePotentialPartner && (
                <ChatPotentialPartner
                  user={user}
                  ui={ui}
                  chats={chats}
                  analytics={analytics}
                  talent={talent}
                  activePotentialPartner={chats.activePotentialPartner}
                  openBrandModal={this.props.openBrandModal}
                  requestPartnership={this.requestPartnership}
                  requestGiftingToUser={this.requestGiftingToUser}
                  createCollaborationWithBrand={this.createCollaborationWithBrand}
                  createCollaborationWithUser={this.createCollaborationWithUser}
                  offerCodeToUser={this.offerCodeToUser}
                  setCustomRate={this.setCustomRate}
                />
              )}
              {!chats.activeChat && !chats.activePotentialPartner && (
                <div className='loader-container'>
                  <Loader size={120} />
                </div>
              )}
            </div>
          </div>
        </div>
      </RequiresPermissions>
    );
  };
}

const mapStateToProps = state => {
  const { user, ui, talent, chats, analytics, manager } = state;
  return { user, ui, talent, chats, analytics, manager };
};

export default connect(mapStateToProps, {
  getChats,
  updateChat,
  deleteChat,
  setActiveChat,
  setActivePotentialPartner,
  receivedMessage,
  editedMessage,
  deletedMessage,
  typedMessage,
  setCustomCode,
  setCustomCommissionRate,
  createSamplesRequest,
  updateRequest,
  syncRequests,
  createContractWithBrand,
  createContractWithUser,
  setAddress,
  updateBrandList,
  deleteBrandList,
  openBrandModal,
  openBonusModal,
  openArtistModal,
  openCodesModal,
  openRequestModal,
  openFulfillmentModal
})(Chat);
