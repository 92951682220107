import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Curators.scss';

import {
  createCuratorGroup,
  deleteCuratorGroup,
  deleteCuratorGroupFavorite,
  createCuratorGroupUser,
  deleteCuratorGroupUser
} from '../../Actions/ShopActions';
import { getCuratorGroupWithId } from '../../Helpers/user_helpers';
import { getUrlParam } from '../../Helpers/helpers';
import { getLinkToShopForCuratorGroup } from '../../Helpers/shop_helpers';
import { getCuratorGroupsByIds } from '../../APIClient/shop';

import CuratorGroup from '../../Components/Curators/CuratorGroup';
import MyCurators from '../../Components/Curators/MyCurators';
import DiscoverCurators from '../../Components/Curators/DiscoverCurators';
import Loader from '../../Components/Loader/Loader';

const Curators = props => {
  const { user, isDiscover, match, history } = props;

  // Allow diving into a specific group
  const [activeGroup, setActiveGroup] = React.useState(null);
  const selectGroupAndEdit = group => selectGroup(group, true);
  const selectGroup = (group, isEditing = false) => {
    let urlParams = props.location.search;
    if (isEditing) urlParams += (urlParams.startsWith('?') ? '&' : '?') + 'edit=true';

    const newUrl = isDiscover
      ? isEditing
        ? `/shop/circles/${group.id}${urlParams}`
        : getLinkToShopForCuratorGroup(group)
      : `/shop/my/circles/${group.id}${urlParams}`;
    if (history.location.pathname !== newUrl) {
      history.push(newUrl);
    }
    setActiveGroup(group);
  };
  const backFromActiveGroup = () => {
    history.push(isDiscover ? `/shop/circles` : `/shop/my/circles`);
    setActiveGroup(null);
  };

  // Fetch the group data to display all the curators
  const [augmentedGroupUneditable, setAugmentedGroupUneditable] = React.useState(null);
  const [isAugmentingGroup, setIsAugmentingGroup] = React.useState(false);
  const fetchGroupByIdAndAugment = async groupId => {
    setIsAugmentingGroup(true);
    const additionalParams = {};
    if (getUrlParam('share')) additionalParams.share = getUrlParam('share');
    getCuratorGroupsByIds([groupId], additionalParams)
      .then(resp => setAugmentedGroupUneditable(resp.groups[0]))
      .catch(err => window.ALERT.error(err?.error || `There was an issue fetching the curators for this group.`))
      .finally(() => setIsAugmentingGroup(false));
  };

  // If we are on a specific group via URL, show that group
  let defaultFavorite, defaultStarterGroup;
  if (match.params.id) {
    defaultStarterGroup = defaultFavorite?.curator_group || getCuratorGroupWithId(user, parseInt(match.params.id));
  }
  React.useEffect(() => {
    if (defaultStarterGroup) selectGroup(defaultStarterGroup, getUrlParam('edit') === 'true');
  }, [defaultFavorite, defaultStarterGroup]);

  // Get the group to display from redux so editing works
  let group;
  if (augmentedGroupUneditable) group = augmentedGroupUneditable;
  else if (activeGroup && getCuratorGroupWithId(user, activeGroup.id)) group = getCuratorGroupWithId(user, activeGroup.id);
  else if (activeGroup) group = activeGroup;

  // Allow force reloading when removing from my group
  const forceReloadNewlyPublicGroup = () => {
    setActiveGroup(null);
    setAugmentedGroupUneditable(null);
    fetchGroupByIdAndAugment(match.params.id);
  };

  const forceReloadNewlyPrivateGroup = () => {
    setAugmentedGroupUneditable(null);
  };

  // Fetch the augmented group data to display all the curators
  React.useEffect(() => {
    // If we have an active group with a different ID than the URL, reset the active group
    if (group && group.id !== parseInt(match.params.id)) {
      setAugmentedGroupUneditable(null);
      setActiveGroup(parseInt(match.params.id) === defaultStarterGroup?.id ? defaultStarterGroup : null);
    }

    // Fetch the augmented group if it is not mine
    if (match.params.id && !getCuratorGroupWithId(user, parseInt(match.params.id))) {
      fetchGroupByIdAndAugment(match.params.id);
    }

    // Reset the augmented group if we are no longer on a group
    if (!match.params.id && augmentedGroupUneditable) {
      setAugmentedGroupUneditable(null);
    }
  }, [match.params.id]);

  return (
    <div className={cn('curators-outer-container', { discover: isDiscover })}>
      <div className={cn('curators-inner-container', { discover: isDiscover })}>
        {match.params.id ? (
          group ? (
            <CuratorGroup
              isDiscover={isDiscover}
              goBack={backFromActiveGroup}
              group={group}
              isAugmentingGroup={isAugmentingGroup}
              forceReloadNewlyPublicGroup={forceReloadNewlyPublicGroup}
              forceReloadNewlyPrivateGroup={forceReloadNewlyPrivateGroup}
            />
          ) : (
            <div className='loading-group-container'>
              <Loader size={80} />
            </div>
          )
        ) : isDiscover ? (
          <DiscoverCurators selectGroup={selectGroup} />
        ) : (
          <MyCurators selectGroup={selectGroup} selectGroupAndEdit={selectGroupAndEdit} />
        )}
      </div>
    </div>
  );
};

Curators.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,
  createCuratorGroup: PropTypes.func.isRequired,
  createCuratorGroupUser: PropTypes.func.isRequired,
  deleteCuratorGroup: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired,
  deleteCuratorGroupUser: PropTypes.func.isRequired,

  // From outside
  isDiscover: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  createCuratorGroup,
  createCuratorGroupUser,
  deleteCuratorGroup,
  deleteCuratorGroupFavorite,
  deleteCuratorGroupUser
})(withRouter(Curators));
