import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './ProductOtherProducts.scss';

import { setAttributableCuratorId, setAttributableCuratorGroupId } from '../../Actions/ShopActions';

import { getLinkToProductPageViaId, getRecentlyViewedProducts } from '../../Helpers/shop_helpers';
import { getPrettyNumber } from '../../Helpers/formatting';

import ProductHeart from '../Shop/Elements/ProductHeart';

const ProductOtherProducts = props => {
  const { shop } = props;
  const { isFetchingSimilarProducts, activeProductSimilarProducts } = shop || {};
  const { other_products } = activeProductSimilarProducts || {};

  let header,
    subheader,
    num_loading_visible,
    products = [];
  if (props.similarProducts) {
    header = `You might also like`;
    subheader = `We found a few other options for you to consider.`;
    products = other_products?.similar_products || [];
    num_loading_visible = 8;
  } else if (props.similarProductsSameBrand) {
    products = other_products?.same_brand || [];
    num_loading_visible = 8;
  } else if (props.similarProductsDifferentBrandSameCategory) {
    header = `Explore other brands`;
    subheader = `Discover other brands you might like.`;
    products = other_products?.different_brand_same_category || [];
    num_loading_visible = 8;
  } else if (props.isRecentlyViewed) {
    header = `Recently Viewed`;
    products = getRecentlyViewedProducts(shop)
      .filter(p => p.id !== props.product?.id)
      .slice(0, 8);
  } else {
    header = 'Unknown';
    subheader = 'Unknown';
  }

  if (!isFetchingSimilarProducts && !products.length) return null;

  return (
    <div className={cn('section product-other-products-container', { 'no-margin-top': props.noMarginTop })}>
      {header && (
        <div className='section-header-container'>
          <div className='section-header'>{header}</div>
          {subheader && <div className='section-subheader'>{subheader}</div>}
        </div>
      )}
      <div className='product-other-products'>
        {props.isFetchingFull
          ? _.range(num_loading_visible).map(index => (
              <div className='other-product loading' key={index}>
                <div className='image-container' />
                <div className='data'>
                  <div className='brand loading' />
                  <div className='title loading' />
                </div>
              </div>
            ))
          : products.map(product => {
              // Need to parse since the product can be in different formats depending on the props source
              const Brand_name = product.Brand_name || product.AllBrand_name || product.brand?.name || null;
              const Category_id = product.Category_id || product.category?.id || null;
              const Category_name = product.Category_name || product.category?.name || null;
              const isDifferentCategory = Category_id !== props.product?.Category_id;
              const promoterCount = product.totalMentionCount || 0;

              const clearGlobalAttribution = () => {
                props.setAttributableCuratorId(null);
                props.setAttributableCuratorGroupId(null);
                window.__ADD_EVENT__('Shop - Click ShopMy Recommended Product', { Brand_name, Category_name, product_id: product?.id });
              };

              let metadata = [];
              Category_name && isDifferentCategory && metadata.push(Category_name);
              promoterCount && metadata.push(`${getPrettyNumber(promoterCount)} curator${promoterCount === 1 ? '' : 's'}`);

              return (
                <div className='other-product-outer' key={product.id}>
                  <Link to={getLinkToProductPageViaId(product.id)} onClick={clearGlobalAttribution} className='other-product'>
                    <div className='image-container'>
                      <img loading='lazy' src={product.image} alt={product.title} />
                    </div>
                    <div className='data'>
                      <div className='brand'>{Brand_name || 'Unknown Brand'}</div>
                      <div className='title'>{product.title}</div>
                      {!!metadata.length && <div className='metadata'>{metadata.join(' • ')}</div>}
                    </div>
                  </Link>
                  <div className='favorite-container'>
                    <ProductHeart product={product} user={props.user} attribution={null} />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

ProductOtherProducts.propTypes = {
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  product: PropTypes.object,
  isFetchingFull: PropTypes.bool,
  noMarginTop: PropTypes.bool,

  // Types of Other Products
  similarProducts: PropTypes.bool,
  similarProductsSameBrand: PropTypes.bool,
  similarProductsDifferentBrandSameCategory: PropTypes.bool,
  similarProductsSameBrandNotCategory: PropTypes.bool
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  setAttributableCuratorId,
  setAttributableCuratorGroupId
})(ProductOtherProducts);
