import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ScrollToTop extends Component {
  static propTypes = {
    from: PropTypes.array,
    avoid: PropTypes.array
  };

  componentDidMount() {
    const { from, avoid, lastLocation } = this.props;

    let needsScroll = true;
    if (from) {
      needsScroll = !!_.find(from, loc => lastLocation && lastLocation.pathname.includes(loc));
    }

    if (avoid) {
      needsScroll = !_.find(avoid, loc => lastLocation && lastLocation.pathname.includes(loc));
    }

    const scrolledTo = document.querySelector('.scrolled-to');
    needsScroll && !scrolledTo && setTimeout(() => window.scrollTo(0, 0), 0);
  }

  render() {
    return null;
  }
}

export default withLastLocation(withRouter(ScrollToTop));
