import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ProductBrandPanel.scss';

import { getLinkToBrandPageViaId } from '../../Helpers/shop_helpers';

const ProductBrandPanel = props => {
  const { product, isFetchingFull } = props;
  const { brand } = product || {};

  const [hasBadLogo, setHasBadLogo] = React.useState(false);
  const onLogoLoad = e => {
    // Make sure the logo is big enough
    const img = e.target;
    if (img.naturalWidth < 100 || img.naturalHeight < 100) {
      setHasBadLogo(true);
    }
  };

  const showLogo = brand?.logo && !hasBadLogo && false;
  return (
    <div className={cn('product-brand-panel-container', {})}>
      {showLogo && (
        <div className='logo-container'>
          <div onLoad={onLogoLoad} className='logo'>
            {brand.logo && <img src={brand.logo} alt={brand.name} />}
          </div>
        </div>
      )}
      {isFetchingFull || !brand ? (
        <div className='description-container'>
          <div className='brand loading' />
          <div className='description loading' />
        </div>
      ) : (
        <div className={cn('description-container', { 'full-width': !showLogo })}>
          <div className='brand'>{brand.name}</div>
          <div className='description'>{brand.description || `Shop all products.`}</div>
          <Link to={getLinkToBrandPageViaId(brand.id)} className='shop-all-btn'>
            Shop All {brand.name}
          </Link>
        </div>
      )}
    </div>
  );
};

ProductBrandPanel.propTypes = {
  product: PropTypes.object,
  isFetchingFull: PropTypes.bool.isRequired
};

export default ProductBrandPanel;
