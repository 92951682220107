import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Shop.scss';

import { focusShopSearch } from '../../Actions/UIActions';

import ShopData from '../../Components/Shop/ShopData';
import ShopMetaTags from '../../Components/Shop/Elements/ShopMetaTags';
import ShopPageHeaderNew from '../../Components/Shop/Elements/ShopPageHeaderNew';
import ShopResultsDisclaimer from '../../Components/Shop/Elements/ShopResultsDisclaimer';
import ShopControls from '../../Components/Shop/ShopControls';
import ShopResults from '../../Components/Shop/ShopResults';
import ShopCatalogResults from '../../Components/Shop/ShopCatalogResults';
import ShopCollectionResults from '../../Components/Shop/ShopCollectionResults';
import ShopMainHomeHeader from '../../Components/Shop/ShopMainHomeHeader';

import { getActiveCuratorGroup } from '../../Helpers/shop_helpers';
import { getUrlParam } from '../../Helpers/helpers';
import { getUsername, getUserId } from '../../Helpers/user_helpers';
import usePageLoadEvent from '../../CustomHooks/usePageLoadEvent';

const Shop = props => {
  const { shop } = props;

  const defaultValuesIfNull = {
    tab: 'popular',
    // Curator_id: !getUrlParam('CuratorGroup_id') && getActiveCurator(shop)?.id, // Currently we do not default to individuals
    CuratorGroup_id: !getUrlParam('Curator_id') && getActiveCuratorGroup(shop)?.id
  };

  // Paging
  const [page, setPage] = React.useState(0);
  const [hasMoreResults, setHasMoreResults] = React.useState(true);

  // Tabs
  const [tab, setTab] = React.useState(getUrlParam('tab') || props.match?.params?.tab || defaultValuesIfNull.tab);

  // Search Based
  const [query, setQuery] = React.useState(getUrlParam('query') || null);

  // Catalog Based
  const [Category_id, setCategory_id] = React.useState(+getUrlParam('Category_id') || null);
  const [Department_id, setDepartment_id] = React.useState(+getUrlParam('Department_id') || null);
  const [Industry_id, setIndustry_id] = React.useState(+getUrlParam('Industry_id') || null);
  const [AllBrand_id, setAllBrand_id] = React.useState(+getUrlParam('AllBrand_id') || null);

  // Curators Based
  const [Curator_id, setCurator_id] = React.useState(+getUrlParam('Curator_id') || defaultValuesIfNull.Curator_id || null);
  const [Curator_username, setCurator_username] = React.useState(props.match?.params?.username || null);
  const [CuratorGroup_id, setCuratorGroup_id] = React.useState(+getUrlParam('CuratorGroup_id') || defaultValuesIfNull.CuratorGroup_id || null);

  // Handle url transitions
  const usernameFromUrl = props.match?.params?.username;
  React.useEffect(() => {
    usernameFromUrl && goToCuratorUsername(props.match?.params?.username);
  }, [usernameFromUrl]);

  // Product Results
  const [productResults, setProductResults] = React.useState([]);
  const [productRefinements, setProductRefinements] = React.useState([]);
  const [activeRefinementObjects, setActiveRefinementObjects] = React.useState({});
  const [activeProductRefinements, setActiveProductRefinements] = React.useState([]);
  const locallyUpdateProduct = (product, updates) => setProductResults(productResults.map(p => (p.id === product.id ? { ...p, ...updates } : p)));
  const locallyMoveProductToIndex = (product, index) => {
    const newProductResults = productResults.filter(p => p.id !== product.id);
    newProductResults.splice(index, 0, product);
    setProductResults(newProductResults);
  };

  // Collection Results
  const [Section_id, setSection_id] = React.useState(null);
  const [sectionType, setSectionType] = React.useState(null);
  const [collectionResults, setCollectionResults] = React.useState([]);
  const [sectionResults, setSectionResults] = React.useState([]);
  const isShowingCollections = tab === 'collections' && !query;
  const activeSection = isShowingCollections && (Section_id ? sectionResults.find(s => s.id === Section_id) : sectionResults[0]);

  // For You handling
  const isForYouSection = tab === 'foryou' && !query;
  const isYourShop = !!(Curator_username && getUsername(props.user) === Curator_username) || !!(Curator_id && getUserId(props.user) === Curator_id);
  const isCuratedShop = !!(Curator_id || CuratorGroup_id || Curator_username);

  // Error Handling
  const [hadErrorResults, setHadErrorResults] = React.useState(false);
  const [hadErrorRefinements, setHadErrorRefinements] = React.useState(false);

  // Loading Stats
  const [isFetchingResults, setIsFetchingResults] = React.useState(false);
  const [isFetchingRefinements, setIsFetchingRefinements] = React.useState(false);
  const isFetchingFirstPageFirstTime = page === 0 && isFetchingResults && !productResults.length;
  const isFetchingFirstPage = page === 0 && isFetchingResults;

  // Refinement Actions
  const [currentlyRefiningLabels, setCurrentlyRefiningLabels] = React.useState([]);
  const startRefiningLabel = label => setCurrentlyRefiningLabels([...currentlyRefiningLabels, label]);
  const startRefiningLabels = labels => setCurrentlyRefiningLabels([...currentlyRefiningLabels, ...labels]);
  const isAdjustingLabel = label => {
    // Allow for singular or plural labels to register
    const singularLabel =
      {
        Categories: 'Category',
        Departments: 'Department',
        Industries: 'Industry',
        Brands: 'Brand'
      }[label] || label;
    const pluralLabel =
      {
        Category: 'Categories',
        Department: 'Departments',
        Industry: 'Industries',
        Brand: 'Brands'
      }[label] || label;
    return currentlyRefiningLabels.includes(singularLabel) || currentlyRefiningLabels.includes(pluralLabel);
  };
  React.useEffect(() => {
    !isFetchingRefinements && setCurrentlyRefiningLabels([]);
  }, [isFetchingRefinements]);

  const applyRefinement = ({ label, labels, refinement }, { label: groupLabel } = {}) => {
    const allLabels = [label, ...(labels || []), groupLabel].filter(Boolean);
    allLabels.length && startRefiningLabels(allLabels);
    setQuery('query' in refinement ? refinement.query : query);
    setCategory_id('Category_id' in refinement ? refinement.Category_id : Category_id);
    setDepartment_id('Department_id' in refinement ? refinement.Department_id : Department_id);
    setIndustry_id('Industry_id' in refinement ? refinement.Industry_id : Industry_id);
    setAllBrand_id('AllBrand_id' in refinement ? refinement.AllBrand_id : AllBrand_id);
    'Curator_id' in refinement && setCurator_id(refinement.Curator_id);
    'CuratorGroup_id' in refinement && setCuratorGroup_id(refinement.CuratorGroup_id);
    setPage(0);
  };

  // Apply an unrefinement from the backend
  const applyUnrefinement = ({ label, labels, unrefinement = {} }, { label: groupLabel } = {}) => {
    label ? startRefiningLabel(label) : labels && startRefiningLabels(labels);
    groupLabel && startRefiningLabel(groupLabel);
    setQuery('query' in unrefinement ? unrefinement.query : query);
    setCategory_id('Category_id' in unrefinement ? unrefinement.Category_id : Category_id);
    setDepartment_id('Department_id' in unrefinement ? unrefinement.Department_id : Department_id);
    setIndustry_id('Industry_id' in unrefinement ? unrefinement.Industry_id : Industry_id);
    setAllBrand_id('AllBrand_id' in unrefinement ? unrefinement.AllBrand_id : AllBrand_id);
    'Curator_id' in unrefinement && setCurator_id(unrefinement.Curator_id);
    'CuratorGroup_id' in unrefinement && setCuratorGroup_id(unrefinement.CuratorGroup_id);
    setPage(0);
  };

  // Apply a refinement from the frontend
  const goToCategoryId = Category_id => {
    startRefiningLabel('Category');
    setCategory_id(Category_id);
    setDepartment_id(null);
    setIndustry_id(null);
    setPage(0);
  };

  const clearCategoryId = Category_id => {
    startRefiningLabel('Category');
    setCategory_id(null);
    setPage(0);
  };

  const clearDepartmentId = Department_id => {
    startRefiningLabel('Department');
    setDepartment_id(null);
    setCategory_id(null);
    setPage(0);
  };

  const clearIndustryId = Industry_id => {
    startRefiningLabel('Industry');
    setIndustry_id(null);
    setDepartment_id(null);
    setCategory_id(null);
    setPage(0);
  };

  const clearBrandIds = Brand_ids => {
    startRefiningLabel('Brand');
    setAllBrand_id(null);
    setPage(0);
  };

  const goToCuratorUsername = Curator_username => {
    startRefiningLabel('Curator');
    setCurator_id(null);
    setCuratorGroup_id(null);
    setCurator_username(Curator_username);
    setPage(0);
  };

  const goToCuratorId = Curator_id => {
    startRefiningLabel('Curator');
    setCurator_id(Curator_id);
    setCurator_username(null);
    setCuratorGroup_id(null);
    setPage(0);
  };

  const goToCuratorGroupId = CuratorGroup_id => {
    startRefiningLabel('Curator Group');
    setCurator_id(null);
    setCurator_username(null);
    setCuratorGroup_id(CuratorGroup_id);
    setPage(0);
  };

  const clearAllCurators = () => {
    goToCuratorId(null);
    goToCuratorGroupId(null);
  };

  const goToBrandId = AllBrand_id => {
    startRefiningLabel('Brand');
    setAllBrand_id(AllBrand_id);
    setPage(0);
  };

  const goToSectionId = Section_id => {
    setSection_id(Section_id);
    setSectionType(null);
    setPage(0);
  };

  const goToSectionType = newSectionType => {
    if (newSectionType === sectionType) return;
    setSection_id(null);
    setSectionType(newSectionType);
    setCollectionResults([]);
    setPage(0);
  };

  const goToTab = tab => {
    setTab(tab);
    setCollectionResults([]);
    setPage(0);
  };

  // Pass these into the necessary components
  const filterState = {
    Category_id,
    Department_id,
    Industry_id,
    AllBrand_id,
    CuratorGroup_id,
    Curator_id,
    Curator_username
  };
  // Show the catalog hierarchy if at the department or industry level of the general catalog
  const isUnfilteredHierarchyState = ![Category_id, Department_id, Industry_id, AllBrand_id, query].some(Boolean);
  const isUnfilteredCurationState = ![CuratorGroup_id, Curator_id, Curator_username].some(Boolean);
  const isUnfilteredState = isUnfilteredHierarchyState && isUnfilteredCurationState;

  let [showCatalogHierarchyHeader, showCatalogHierarchyBody] = [false, false];

  // If on general shop and no hierarchy filters are applied, show catalog level
  if (isUnfilteredState && !isForYouSection) {
    showCatalogHierarchyHeader = true;
    showCatalogHierarchyBody = true;
  }

  // If on a curators shop on the popular page, show catalog level
  if (isUnfilteredHierarchyState && !isUnfilteredCurationState && tab === 'popular') {
    // showCatalogHierarchyHeader = false;
    // showCatalogHierarchyBody = true;
  }

  const filterProps = {
    ...filterState,

    setCategory_id,
    setDepartment_id,
    setIndustry_id,
    setAllBrand_id,
    setCuratorGroup_id,
    setCurator_id,
    setCurator_username,
    goToCategoryId,
    goToCuratorId,
    goToCuratorGroupId,
    goToCuratorUsername,
    clearAllCurators,
    goToBrandId,
    applyRefinement,
    applyUnrefinement,
    isAdjustingLabel,
    clearCategoryId,
    clearDepartmentId,
    clearIndustryId,
    clearBrandIds
  };

  const tabProps = {
    tab,
    setTab,
    goToTab,
    isForYouSection,
    isYourShop,
    isCuratedShop
  };

  const queryProps = {
    query,
    setQuery
  };

  const pagingProps = {
    page,
    setPage,
    hasMoreResults,
    setHasMoreResults
  };

  const collectionProps = {
    isShowingCollections,
    Section_id,
    sectionType,
    setSection_id,
    setSectionType,
    goToSectionId,
    goToSectionType,
    collectionResults,
    setCollectionResults,
    activeSection,
    sectionResults,
    setSectionResults
  };

  const errorProps = {
    hadErrorResults,
    setHadErrorResults,
    hadErrorRefinements,
    setHadErrorRefinements
  };

  const loadingProps = {
    isFetchingResults,
    isFetchingRefinements,
    isFetchingFirstPage,
    isFetchingFirstPageFirstTime
  };

  usePageLoadEvent('Shop - View Page', {
    tab,
    query,
    Category_id,
    Department_id,
    Industry_id,
    AllBrand_id,
    CuratorGroup_id,
    Curator_id,
    Curator_username,
    Section_id,
    sectionType,
    page,
    productResults,
    collectionResults,
    activeRefinementObjects
  });

  return (
    <div className='shop-outer-container'>
      <ShopData
        {...filterProps}
        {...loadingProps}
        {...tabProps}
        {...queryProps}
        {...pagingProps}
        {...collectionProps}
        {...errorProps}
        match={props.match}
        defaultValuesIfNull={defaultValuesIfNull}
        productResults={productResults}
        setProductRefinements={setProductRefinements}
        setProductResults={setProductResults}
        setActiveProductRefinements={setActiveProductRefinements}
        setActiveRefinementObjects={setActiveRefinementObjects}
        setIsFetchingResults={setIsFetchingResults}
        setIsFetchingRefinements={setIsFetchingRefinements}
        productRefinements={productRefinements}
        activeProductRefinements={activeProductRefinements}
        activeRefinementObjects={activeRefinementObjects}
      />
      <ShopMetaTags activeRefinementObjects={activeRefinementObjects} />
      <div className='shop-inner-container'>
        <div className='shop-results-page'>
          {/* <ShopPageHeader
            {...filterProps}
            {...loadingProps}
            {...tabProps}
            {...queryProps}
            {...collectionProps}
            showCatalogHierarchy={showCatalogHierarchy}
            isUnfilteredState={isUnfilteredState}
            productRefinements={productRefinements}
            activeProductRefinements={activeProductRefinements}
            activeRefinementObjects={activeRefinementObjects}
            setActiveRefinementObjects={setActiveRefinementObjects}
          /> */}
          {/* <ShopProfileCuratorHeader
            {...filterProps}
            {...loadingProps}
            productRefinements={productRefinements}
            activeProductRefinements={activeProductRefinements}
            activeRefinementObjects={activeRefinementObjects}
          /> */}
          {showCatalogHierarchyHeader ? (
            <ShopMainHomeHeader />
          ) : (
            <>
              <ShopPageHeaderNew
                {...filterProps}
                {...loadingProps}
                {...tabProps}
                {...queryProps}
                {...collectionProps}
                isUnfilteredState={isUnfilteredState}
                productRefinements={productRefinements}
                activeProductRefinements={activeProductRefinements}
                activeRefinementObjects={activeRefinementObjects}
                setActiveRefinementObjects={setActiveRefinementObjects}
              />
              <ShopControls
                {...filterProps}
                {...tabProps}
                {...loadingProps}
                {...queryProps}
                {...collectionProps}
                setPage={setPage}
                productRefinements={productRefinements}
                activeProductRefinements={activeProductRefinements}
                activeRefinementObjects={activeRefinementObjects}
              />
            </>
          )}
          {/* <div className='sidebar-container'>
            <ShopSidebar
              user={props.user}
              {...filterProps}
              {...loadingProps}
              productRefinements={productRefinements}
              activeProductRefinements={activeProductRefinements}
              activeRefinementObjects={activeRefinementObjects}
            />
          </div> */}
          <main className='main-container'>
            <ShopResultsDisclaimer user={props.user} {...tabProps} {...filterProps} />
            {isShowingCollections ? (
              <ShopCollectionResults {...collectionProps} {...loadingProps} {...filterProps} {...tabProps} {...pagingProps} {...errorProps} />
            ) : showCatalogHierarchyBody ? (
              <ShopCatalogResults {...filterProps} {...tabProps} productRefinements={productRefinements} applyRefinement={applyRefinement} />
            ) : (
              <ShopResults
                productResults={productResults}
                {...loadingProps}
                {...filterProps}
                {...tabProps}
                {...pagingProps}
                {...queryProps}
                {...errorProps}
                locallyUpdateProduct={locallyUpdateProduct}
                locallyMoveProductToIndex={locallyMoveProductToIndex}
                productRefinements={productRefinements}
                activeProductRefinements={activeProductRefinements}
                activeRefinementObjects={activeRefinementObjects}
              />
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

Shop.propTypes = {
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { shop, user } = state;
  return { shop, user };
};

export default connect(mapStateToProps, {
  focusShopSearch
})(Shop);
