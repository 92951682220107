import React from 'react';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './DiscoverCurators.scss';

import {
  setDiscoverFilters,
  getCuratorsAndGroupsViaDiscover,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite
} from '../../Actions/ShopActions';
import { openCuratorGroupModal } from '../../Actions/UIActions';
import { getDiscoverFilters, getDiscoverCurators, getDiscoverGroups } from '../../Helpers/shop_helpers';
import { getFavoriteCuratorGroupWithId } from '../../Helpers/user_helpers';

import DiscoverCuratorFilters from './DiscoverCuratorFilters';
import FeaturedCuratorGroup from './FeaturedCuratorGroup';
import CuratorGroupCard from './CuratorGroupCard';
import CuratorCardNew from './CuratorCardNew';

const DiscoverCurators = props => {
  const { shop, user } = props;

  const discoverFilters = getDiscoverFilters(shop);
  const curators = getDiscoverCurators(shop);
  const groups = getDiscoverGroups(shop);

  // Curators
  const [isFetching, setIsFetching] = React.useState(false);

  // Error Handling
  const [errorCount, setErrorCount] = React.useState(0);
  const [hadErrorFetching, setHadErrorFetching] = React.useState(false);

  // When filters change, re-fetch the data
  const [isFirstFetch, setIsFirstFetch] = React.useState(true);
  const activeFiltersString = JSON.stringify(discoverFilters);
  React.useEffect(() => {
    !isFirstFetch && setIsFetching(true);
    props
      .getCuratorsAndGroupsViaDiscover(_.omitBy({ ...discoverFilters, showHidden: !!window.__ADMIN_CONTROL_MODE__ || null }, _.isNil))
      .then(resp => {
        resp.success ? setHadErrorFetching(false) : setHadErrorFetching(true);
        setIsFetching(false);
        setIsFirstFetch(false);
      });
  }, [activeFiltersString, errorCount]);

  // const featuredGroup = groups[0];
  const featuredGroup = null;
  const groupResults = groups.map(group => ({ type: 'group', data: group }));
  const curatorResults = curators.map(curator => ({ type: 'curator', data: curator }));

  // Sort it by 1 group, 2 people, etc.
  const results = [];
  let [groupIdx, curatorIdx] = [0, 0];
  let [nextGroups, nextCurators] = [groupResults.slice(0, 1), curatorResults.slice(0, 2)];
  while (nextGroups.length > 0 || nextCurators.length > 0) {
    results.push(...nextGroups, ...nextCurators);
    groupIdx += 1;
    curatorIdx += 2;
    nextGroups = groupResults.slice(groupIdx, groupIdx + 1) || [];
    nextCurators = curatorResults.slice(curatorIdx, curatorIdx + 2) || [];
  }

  const seoTitle = "Discover Curators & Shops You'll Love | ShopMy";
  const seoDescription = 'Discover curators and groups that match your interests.';

  return (
    <div className='discover-curators-container'>
      <MetaTags>
        <title>{seoTitle}</title>
        <meta property='og:title' content={seoTitle} />
        <meta property='description' content={seoDescription} />
        <meta property='og:description' content={seoDescription} />
      </MetaTags>
      <div className='discover-curators-header'>
        <h1>
          Create a shop <i>that fits you.</i>
        </h1>
        <h2>Heart your favorite curators and groups to build your personalized shop.</h2>
      </div>
      {featuredGroup && (
        <div className='discover-featured-shops'>
          <FeaturedCuratorGroup
            user={user}
            group={featuredGroup}
            favorite={getFavoriteCuratorGroupWithId(user, featuredGroup.id)}
            isLoading={isFetching}
            selectGroup={props.selectGroup}
            createCuratorGroupFavorite={props.createCuratorGroupFavorite}
            deleteCuratorGroupFavorite={props.deleteCuratorGroupFavorite}
          />
        </div>
      )}
      <DiscoverCuratorFilters shop={shop} activeFilters={discoverFilters} setActiveFilters={props.setDiscoverFilters} />
      {hadErrorFetching ? (
        <div className='discover-results-error-container'>
          <div className='discover-results-error'>
            <div className='error-msg'>There was an issue fetching all of the stores, please click below to try again.</div>
            <div className='retry-btn' onClick={() => setErrorCount(errorCount + 1)}>
              {isFetching ? 'Trying...' : 'Try Again'}
            </div>
          </div>
        </div>
      ) : (
        <div className='discover-results'>
          {results.map(result => {
            const isGroup = result.type === 'group';
            const additionalClasses = { 'is-fetching': isFetching };
            if (isGroup) {
              const group = result.data;
              const favorite = getFavoriteCuratorGroupWithId(user, group.id);
              return (
                <div key={'group-' + group.id} className={cn('discover-curator-group', additionalClasses)}>
                  <CuratorGroupCard
                    user={user}
                    group={group}
                    favorite={favorite}
                    isLoading={isFetching}
                    selectGroup={props.selectGroup}
                    createCuratorGroupFavorite={props.createCuratorGroupFavorite}
                    deleteCuratorGroupFavorite={props.deleteCuratorGroupFavorite}
                  />
                </div>
              );
            } else {
              const curator = result.data;
              return (
                <div key={'curator-' + curator.id} className={cn('discover-curator', additionalClasses)}>
                  <CuratorCardNew
                    hideSocials
                    shop={shop}
                    key={curator.id}
                    user={props.user}
                    curator={curator}
                    isLoading={isFetching}
                    activeFilters={discoverFilters}
                    openCuratorGroupModal={props.openCuratorGroupModal}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

DiscoverCurators.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,

  setDiscoverFilters: PropTypes.func.isRequired,
  getCuratorsAndGroupsViaDiscover: PropTypes.func.isRequired,

  createCuratorGroupFavorite: PropTypes.func.isRequired,
  deleteCuratorGroupFavorite: PropTypes.func.isRequired,
  openCuratorGroupModal: PropTypes.func.isRequired,

  // From Outside
  selectGroup: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  setDiscoverFilters,
  getCuratorsAndGroupsViaDiscover,
  createCuratorGroupFavorite,
  deleteCuratorGroupFavorite,
  openCuratorGroupModal
})(DiscoverCurators);
