import icons from '../static/icons';

export const HIGHER_RATE_ALERT = 'higher_rate_alert';

export const CUSTOM_RATE_ICON_DATA = {
  [HIGHER_RATE_ALERT]: {
    icon: icons.misc.higher_rate_alert,
    iconTip: 'This item has a higher commission rate than the brand typically offers.'
  }
};

export const DEFAULT_NUM_VISIBLE_VARIANT_RATES = 10;
