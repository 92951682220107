import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ShopResults.scss';

import { openAuthModal, openCuratorGroupModal, focusShopSearch } from '../../Actions/UIActions';
import {
  setDiscoverFilters,
  createUserProductByProductId,
  updateUserProduct,
  deleteUserProductByProductId,
  setActiveProduct,
  setAttributableCuratorId
} from '../../Actions/ShopActions';
import { getUserId, getUsername } from '../../Helpers/user_helpers';

import ShopProductResult from './ShopProductResult';
import ShopResultsShowMoreOptions from './Elements/ShopResultsShowMoreOptions';
import ShopEmptyResults from '../Curators/ShopEmptyResults';

const ShopResults = props => {
  const { isFetchingResults, activeProductRefinements, activeRefinementObjects, hasMoreResults } = props;
  const infiniteScrollElementRef = React.useRef(null);

  const showLoaderCards = isFetchingResults && (props.page > 0 || !props.productResults.length);

  React.useEffect(() => {
    // If we're already fetching results, don't set up the observer
    if (isFetchingResults) return;
    if (!hasMoreResults) return;
    if (props.hadErrorResults) return;

    // Create the observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          props.setPage(props.page + 1);
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 } // Trigger when fully visible
    );

    // Set up observer
    const bottomElement = infiniteScrollElementRef.current;
    if (bottomElement) observer.observe(bottomElement);
    return () => bottomElement && observer.unobserve(bottomElement);
  }, [isFetchingResults, props.page, props.hadErrorResults]);

  const hasNoResults = !isFetchingResults && props.productResults.length === 0;
  const showNoBrandResults =
    hasNoResults &&
    activeProductRefinements.length === 1 &&
    activeProductRefinements[0].label === 'Brand' &&
    props.activeRefinementObjects.activeBrands?.[0];
  const showYourEmptyShop =
    hasNoResults &&
    !activeProductRefinements.length &&
    getUserId(props.user) &&
    (getUserId(props.user) === props.Curator_id || getUsername(props.user) === props.Curator_username);
  const showNoSearchResults = hasNoResults && !activeProductRefinements.length;

  return (
    <div className='shop-results-container'>
      <div className='product-results'>
        {props.productResults.map((result, idx) => {
          return <ShopProductResult key={result.id} idx={idx} result={result} user={props.user} {...props} />;
        })}
        {showLoaderCards &&
          _.range(6).map(idx => {
            return <ShopProductResult key={idx + 'empty'} idx={idx} emptyResult={{}} user={props.user} {...props} />;
          })}
      </div>
      {/* Element for infinite scroll observer */}
      <div ref={infiniteScrollElementRef} className='observer-trigger' />
      {props.productResults.length ? (
        !hasMoreResults &&
        !isFetchingResults && (
          <ShopResultsShowMoreOptions
            activeRefinementObjects={props.activeRefinementObjects}
            activeProductRefinements={props.activeProductRefinements}
            productRefinements={props.productRefinements}
            shop={props.shop}
            applyRefinement={props.applyRefinement}
            applyUnrefinement={props.applyUnrefinement}
            setDiscoverFilters={props.setDiscoverFilters}
          />
        )
      ) : showNoBrandResults ? (
        <ShopEmptyResults
          isEmptyBrandShop
          shop={props.shop}
          clearAllCurators={props.clearAllCurators}
          brand={props.activeRefinementObjects.activeBrands[0]}
          openAuthModal={props.openAuthModal}
        />
      ) : showYourEmptyShop ? (
        <ShopEmptyResults shop={props.shop} clearAllCurators={props.clearAllCurators} isYourEmptyShop focusShopSearch={props.focusShopSearch} />
      ) : (
        showNoSearchResults && (
          <ShopEmptyResults
            shop={props.shop}
            clearAllCurators={props.clearAllCurators}
            query={props.query}
            isEmptySearchResults
            openCuratorGroupModal={props.openCuratorGroupModal}
            focusShopSearch={props.focusShopSearch}
          />
        )
      )}
      {props.hadErrorResults && (
        <div className='error-message'>
          <div className='error-message-text'>There was an error fetching products. Please try again later.</div>
        </div>
      )}
    </div>
  );
};

ShopResults.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  focusShopSearch: PropTypes.func.isRequired,
  setActiveProduct: PropTypes.func.isRequired,
  setAttributableCuratorId: PropTypes.func.isRequired,
  createUserProductByProductId: PropTypes.func.isRequired,
  deleteUserProductByProductId: PropTypes.func.isRequired,

  // From Shop.js
  productResults: PropTypes.array,
  productRefinements: PropTypes.array.isRequired,
  activeProductRefinements: PropTypes.array.isRequired,
  activeRefinementObjects: PropTypes.object.isRequired,
  query: PropTypes.string,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  isFetchingResults: PropTypes.bool.isRequired,
  applyRefinement: PropTypes.func.isRequired,
  applyUnrefinement: PropTypes.func.isRequired,
  setDiscoverFilters: PropTypes.func.isRequired,
  isYourShop: PropTypes.bool.isRequired
};
const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  openAuthModal,
  openCuratorGroupModal,
  setActiveProduct,
  setAttributableCuratorId,
  focusShopSearch,
  setDiscoverFilters,
  createUserProductByProductId,
  updateUserProduct,
  deleteUserProductByProductId
})(ShopResults);
