import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { faHeart, faCheck, faPaperPlane, faUsers, faBagShopping, faTrash } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import cn from 'classnames';
import './CuratorGroupCard.scss';

import {
  getPreviewImageForGroup,
  getCoverImageForGroup,
  getLinkToShopForCuratorGroup,
  getLinkToPublicCuratorGroupPage,
  getLinkToPrivateCuratorGroupPage
} from '../../Helpers/shop_helpers';
import { getUserId } from '../../Helpers/user_helpers';
import { copyToClipboard, dontBubble } from '../../Helpers/helpers';

import ConfirmDelete from '../General/ConfirmDelete';
import Tooltip from '../General/Tooltip';
import Loader from '../Loader/Loader';

const CuratorGroupCard = props => {
  const { user, favorite, group } = props;
  const { id, curated_by, isSearchable } = group;

  const isCuratedByYou = curated_by?.id === getUserId(user);
  const isCuratedByShopMy = !curated_by;
  const isHiddenButVisibleToAdmins = !isSearchable && isCuratedByShopMy;

  // Get data from the group
  const previewImage = getPreviewImageForGroup(group);
  const coverImage = getCoverImageForGroup(group);
  const num_curators = favorite || group.preview_group_users?.length ? group.num_curators : group.group_users?.length;

  // Curated By Message
  let curatedByMessage;
  if (group.industry) {
    curatedByMessage = `${group.industry.name}, curated by`;
  } else if (group.department) {
    curatedByMessage = `${group.department.name}, curated by`;
  } else if (group.category) {
    curatedByMessage = `${group.category.name}, curated by`;
  } else {
    curatedByMessage = 'Curated by';
  }

  // Actions
  const viewGroup = () => {
    props.selectGroup(group);
    window.__ADD_EVENT__('Shop - View Curator Group From Group Card', { Group_id: group.id, title: group.title });
  };

  // Share Group
  const [isCopyingUrl, setIsCopyingUrl] = React.useState(false);
  const shareGroup = async () => {
    setIsCopyingUrl(true);
    copyToClipboard(window.location.origin + getLinkToPublicCuratorGroupPage(group));
    window.ALERT.success(`Copied shoppable URL to clipboard`);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsCopyingUrl(false);
  };

  // Delete Group
  const canRemoveGroup = isCuratedByYou && !!props.deleteCuratorGroup;
  const removeGroup = () => {
    const handleDelete = () => props.deleteCuratorGroup(group);
    if (!num_curators) handleDelete();
    else
      confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmDelete
            header='Are you sure?'
            subheader='This will permanently delete this shopping circle from your account as well as for anyone you have shared it with.'
            onCancel={onClose}
            hideCancel={false}
            onDelete={() => {
              onClose();
              handleDelete();
              window.__ADD_EVENT__('Shop - Delete Curator Group', { Group_id: group.id, title: group.title });
            }}
          />
        )
      });
  };

  // Unfavorite Group
  const canUnfavoriteGroup = favorite && !!props.deleteCuratorGroupFavorite;
  const unfavoriteGroup = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Are you sure?'
          subheader='This will remove this shopping circle from your favorites.'
          deleteBtnText='Yes, remove'
          onCancel={onClose}
          hideCancel={false}
          onDelete={() => {
            onClose();
            props.deleteCuratorGroupFavorite(favorite);
            window.__ADD_EVENT__('Shop - Remove Curator Group Favorite', { Group_id: group.id, title: group.title });
          }}
        />
      )
    });
  };

  // Favorite Group
  const [isFavoritingGroup, setIsFavoritingGroup] = React.useState(false);
  const canFavoriteGroup = !favorite && !isCuratedByYou && props.createCuratorGroupFavorite;
  const favoriteGroup = () => {
    if (isFavoritingGroup) return;
    setIsFavoritingGroup(true);
    props.createCuratorGroupFavorite(group).then(resp => {
      setIsFavoritingGroup(false);
      resp.groupFavorite && window.ALERT.success('Added this Shopping Circle to your favorites');
      resp.groupFavorite && window.__ADD_EVENT__('Shop - Favorite Curator Group', { Group_id: group.id, title: group.title });
    });
  };

  // Badges
  let badges = [];

  // Badges - Focus
  const mainFocus = group?.category?.name || group?.department?.name || group?.industry?.name || 'All Categories';
  if (mainFocus) badges.push(mainFocus);

  // Badges - Curated By
  if (isCuratedByYou) badges.push('By You');
  else if (isCuratedByShopMy) badges.push('By ShopMy');
  else if (group.curated_by) badges.push(`By ${group.curated_by.name}`);

  // UI Classes
  const additionalClasses = {
    'has-image': !!coverImage,
    'is-loading': props.isLoading,
    'hidden-except-admins': isHiddenButVisibleToAdmins
  };

  const card = (
    <div className='curator-group-card-container'>
      <div className={cn('curator-group-card', additionalClasses)} key={id}>
        {!!coverImage && (
          <div className={cn('background-image', additionalClasses)}>
            <img src={coverImage} alt={group.title} />
            {isHiddenButVisibleToAdmins && <div className='hidden-except-admins-overlay'>Hidden</div>}
          </div>
        )}
        <div onClick={dontBubble} className={cn('actions', additionalClasses)}>
          {!props.hideActions && (
            <>
              <Link
                to={isCuratedByYou ? getLinkToPrivateCuratorGroupPage(group) : getLinkToPublicCuratorGroupPage(group)}
                className={cn('action show-action-on-card-hover', additionalClasses)}
              >
                <FontAwesomeIcon icon={faUsers} />
                <div className='explainer'>View Curators</div>
              </Link>
              <div onClick={shareGroup} className={cn('action show-action-on-card-hover', additionalClasses, { 'is-check': isCopyingUrl })}>
                <FontAwesomeIcon icon={isCopyingUrl ? faCheck : faPaperPlane} />
                <div className='explainer'>Share</div>
              </div>
              {canRemoveGroup && (
                <div onClick={removeGroup} className={cn('action show-action-on-card-hover', additionalClasses)}>
                  <FontAwesomeIcon icon={faTrash} />
                  <div className='explainer'>Delete Circle</div>
                </div>
              )}
              {num_curators ? (
                <Link to={getLinkToShopForCuratorGroup(group)} className={cn('action', additionalClasses)}>
                  <FontAwesomeIcon icon={faBagShopping} />
                  <div className='explainer'>Shop</div>
                </Link>
              ) : (
                <Tooltip message='You must have at least one curator in the group to shop.'>
                  <div className={cn('action disabled', additionalClasses)}>
                    <FontAwesomeIcon icon={faBagShopping} />
                  </div>
                </Tooltip>
              )}
              {isCuratedByYou ? null : canUnfavoriteGroup ? (
                <div onClick={unfavoriteGroup} className={cn('action', additionalClasses)}>
                  <FontAwesomeIcon icon={faHeartSolid} />
                  <div className='explainer'>Unfavorite</div>
                </div>
              ) : (
                canFavoriteGroup && (
                  <div onClick={favoriteGroup} className={cn('action', additionalClasses)}>
                    {isFavoritingGroup ? <Loader size={24} /> : <FontAwesomeIcon icon={faHeart} />}
                    <div className='explainer'>Favorite</div>
                  </div>
                )
              )}
            </>
          )}
        </div>
        <div onClick={viewGroup} className={cn('content', { clickable: props.selectGroup }, additionalClasses)}>
          <div className='curated-by'>{curatedByMessage}</div>
          <div className='title'>{group.title || 'No Title'}</div>
          <div className='metadata'>{`${num_curators} ${pluralize('curator', num_curators)}`}</div>
        </div>
      </div>
    </div>
  );
  return num_curators ? (
    <Link className='curator-group-card-outer-link' to={getLinkToShopForCuratorGroup(group)}>
      {card}
    </Link>
  ) : (
    card
  );
};

CuratorGroupCard.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  favorite: PropTypes.object, // If it's a Favorite, this is the outer object

  // If you want the whole thing to be a link
  isLinkToShop: PropTypes.bool,

  // UI Adits
  hideActions: PropTypes.bool,
  isLoading: PropTypes.bool,

  // Actions (if enabled)
  selectGroup: PropTypes.func,
  deleteCuratorGroup: PropTypes.func,
  deleteCuratorGroupFavorite: PropTypes.func,
  createCuratorGroupFavorite: PropTypes.func
};

export default CuratorGroupCard;
