import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import './CuratorGroupModalHeader.scss';

import Select from '../../General/Select';
import InputActions from '../../General/InputActions';

import { getCuratorGroups, getCuratorGroupFavorites } from '../../../Helpers/user_helpers';

const CuratorGroupModalHeader = props => {
  const {
    user,
    curatorToAdd,
    isAddMode,
    createNewCuratorGroupAndAdd,
    showingCuratorsFromGroup,
    backToGroupsPage,
    curatedByFilter,
    setCuratedByFilter,
    searchVal,
    setSearchVal,
    isSearching,
    setIsSearching
  } = props;

  const curatorGroups = getCuratorGroups(user);
  const curatorGroupFavorites = getCuratorGroupFavorites(user);
  const needsToAddFirstGroup = !curatorGroups.length;

  let curatedByFilterOptions = [{ value: 'all', label: 'All Shopping Circles' }];
  if (curatorGroups.length) curatedByFilterOptions.push({ value: 'me', label: 'My Circles' });
  if (curatorGroupFavorites.length) curatedByFilterOptions.push({ value: 'others', label: "Other's Circles" });

  let title, subtitle;
  if (isAddMode) {
    title = `Add to your Circle`;
    subtitle = !needsToAddFirstGroup ? (
      <div className='modal-subtitle'>
        Add {curatorToAdd.name} to one or your existing circle, or{' '}
        <span onClick={createNewCuratorGroupAndAdd} className='clickable'>
          create a new circle
        </span>
        .
      </div>
    ) : (
      <div className='modal-subtitle'>
        Shopping circles allow you to specifically shop the recommendations of <span className='emphasize'>people you trust</span>.
      </div>
    );
  } else {
    title = `Change your curators`;
    subtitle = (
      <div className='modal-subtitle'>
        Shopping circles allow you to specifically shop the recommendations of <span className='emphasize'>people you trust</span>.
      </div>
    );
  }

  const searchInput = (
    <div className='search-container with-search has-actions'>
      <input
        autoFocus
        type='text'
        className='search-input'
        placeholder={isAddMode ? 'Search Your Shopping Circles' : 'Search Circles or Curators'}
        value={searchVal}
        onBlur={() => !searchVal && setIsSearching(false)}
        onChange={e => {
          setSearchVal(e.target.value);
          if (curatedByFilter !== 'all') setCuratedByFilter('all');
        }}
      />
      <InputActions
        onCancel={() => {
          setSearchVal('');
          setIsSearching(false);
        }}
        searchVal={searchVal}
        showSearchIcon
      />
    </div>
  );

  return (
    <div className='curator-group-modal-header'>
      {showingCuratorsFromGroup ? (
        <>
          <div className='head-back' onClick={backToGroupsPage}>
            <FontAwesomeIcon icon={faChevronLeft} />
            Back to Groups
          </div>
          <div className='modal-controls-container'>
            <Link onClick={props.closeModal} to={`/shop/my/circles/${showingCuratorsFromGroup.id}`} className='edit-external'>
              Edit This Group
              <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className='modal-title-container'>
            <div className='modal-title'>{title}</div>
            <div className='modal-subtitle'>{subtitle}</div>
          </div>
          <div className='modal-controls-container'>
            {!isAddMode && !isSearching && (
              <Select
                isNewSmallerStyling
                disableSearch
                options={curatedByFilterOptions}
                value={curatedByFilter}
                placeholder='Curated By'
                onChangeValue={newValue => setCuratedByFilter(newValue)}
              />
            )}
            {isSearching ? (
              searchInput
            ) : (
              <div onClick={() => setIsSearching(true)} className='search-icon'>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CuratorGroupModalHeader.propTypes = {
  user: PropTypes.object.isRequired,
  curatorToAdd: PropTypes.object,
  isAddMode: PropTypes.bool.isRequired,
  showingCuratorsFromGroup: PropTypes.object,
  backToGroupsPage: PropTypes.func.isRequired,
  curatedByFilter: PropTypes.string.isRequired,
  setCuratedByFilter: PropTypes.func.isRequired,
  createNewCuratorGroupAndAdd: PropTypes.func.isRequired,
  searchVal: PropTypes.string.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default CuratorGroupModalHeader;
