import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { fetchAlgoliaIndex } from '../../../Helpers/search_helpers';

const ShopSearchResultsData = props => {
  const { searchVal, resultTypes, setResultTypeDisplay, setResults, setIsSearching, activeCurator, activeCuratorGroup } = props;

  const storeSearchIndex = fetchAlgoliaIndex(`store_${window.__IS_PROD__ ? 'production' : 'staging'}`);

  // Add debouncer
  const debounceSearch = React.useRef(null);
  const DEBOUNCE_DURATION = 250;

  React.useEffect(() => {
    if (searchVal) {
      setIsSearching(true);

      debounceSearch.current && clearTimeout(debounceSearch.current);
      debounceSearch.current = setTimeout(() => {
        window.__ADD_EVENT__('Shop - Search', { searchVal });

        storeSearchIndex
          .search(searchVal, {
            page: 0,
            ...(resultTypes ? { facetFilters: [resultTypes.map(type => `type:${type}`)] } : {}),
            hitsPerPage: 25
          })
          .then(({ hits }) => {
            let results = hits;

            // If we have enough non product results, do not show product results
            const numNonProductResults = hits.filter(hit => hit.type !== 'Product').length;
            if (numNonProductResults >= 2) {
              results = hits.filter(hit => hit.type !== 'Product');
            }

            const isFilteredToShop = activeCurator || activeCuratorGroup;

            // Make Double Search for scoped AND unscoped
            const scopedGeneralQueryResult = isFilteredToShop && {
              type: 'Query',
              term: `Search "${searchVal}"`,
              objectID: 'query-value',
              _highlightResult: { term: { value: `Search "<em>${searchVal}</em>" from ${activeCurator?.name || activeCuratorGroup?.title}` } }
            };

            const generalQueryResult = {
              type: 'Query',
              term: `Search "${searchVal}"`,
              objectID: 'query-value',
              _highlightResult: { term: { value: `Search "<em>${searchVal}</em>"${isFilteredToShop ? ' from All ShopMy Curators' : ''}` } },
              searchAllCurators: true
            };

            const isPreciseStringMatch = result => {
              // A precise match is an exact match of the search value, with the match required at the start for everything but curators
              return result.term.toLowerCase()[result.type === 'Curator' ? 'includes' : 'startsWith'](searchVal.toLowerCase());
            };
            // Show the precise matches
            const preciseMatchResults = _.flatten(
              results
                .filter(result => isPreciseStringMatch(result))
                .map(result => {
                  /*
                    Return a scoped AND unscoped version.
                  */
                  if (!isFilteredToShop) return [result];
                  if (result.type === 'Curator' || result.type === 'CuratorGroup') return [result];

                  return [
                    {
                      ...result,
                      term: `${result.term} from ${activeCurator?.name || activeCuratorGroup?.title}`,
                      _highlightResult: {
                        term: {
                          value: `<em>${result.term}</em> from ${activeCurator?.name || activeCuratorGroup?.title}`
                        }
                      }
                    },
                    {
                      ...result,
                      term: `${result.term} from All ShopMy Curators`,
                      _highlightResult: {
                        term: {
                          value: `<em>${result.term}</em> from All ShopMy Curators`
                        }
                      },
                      searchAllCurators: true
                    }
                  ];
                })
            );

            // Show the other result options
            const inpreciseMatchResults = results.filter(result => !isPreciseStringMatch(result));

            // Rank and sort the results
            results = [
              ...preciseMatchResults.slice(0, 2), // Only show 2 precise match before showing query options
              ...(scopedGeneralQueryResult ? [scopedGeneralQueryResult] : []),
              generalQueryResult,
              ...preciseMatchResults.slice(2),
              ...inpreciseMatchResults
            ];

            setResults(results);
          })
          .catch(() => {
            window.ALERT.error('There was an error, please try again.');
          })
          .finally(() => {
            setIsSearching(false);
          });
      }, DEBOUNCE_DURATION);
    } else {
      setResults([]);
      setResultTypeDisplay('All');
    }
  }, [searchVal, resultTypes?.join('-')]);
  return null;
};

ShopSearchResultsData.propTypes = {
  // Data
  searchVal: PropTypes.string.isRequired,
  resultTypes: PropTypes.string,
  setResultTypeDisplay: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  activeCurator: PropTypes.object.isRequired,
  activeCuratorGroup: PropTypes.object.isRequired,

  // Setters
  setResults: PropTypes.func.isRequired,
  setIsSearching: PropTypes.func.isRequired
};

export default ShopSearchResultsData;
