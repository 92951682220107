import _ from 'lodash';
import * as ActionTypes from '../Actions/ShopActions';
import * as UserActionTypes from '../Actions/UserActions';
import moment from 'moment';

export const shop = (
  state = {
    // Home Page
    featuredGroups: [],

    // Catalog Navigation
    industries: [],
    lastFetchedIndustries: null,

    // Recently Shopped Circles
    activeCurator: null,
    activeCuratorGroup: null,

    // Recently Viewed Products
    recentlyViewedProductsWithTimestamp: [], // { product: {}, timestamp: Date }

    // Used to ensure that the correct curator is attributed when navigating to a product page
    attributableCuratorId: null,
    attributableCuratorGroupId: null,

    // Discover
    discover: {
      filters: {},
      groups: [],
      curators: []
    },

    // Search Results
    searchResultsCache: {}, // Stores a hash to result set in order to more quickly load past results

    // Products
    isFetchingFullProduct: false,
    activeProduct: null,
    isFetchingSimilarProducts: false,
    activeProductSimilarProducts: null,

    // Adding to products
    simpleCollectionsForAdding: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_FEATURED_CURATOR_GROUPS_SUCCESS:
      return {
        ...state,
        featuredGroups: action.groups
      };

    case ActionTypes.GET_FEATURED_CURATOR_GROUPS_ERROR:
      window.ALERT.error('Had an issue fetching the featured circles');
      return {
        ...state
      };

    case ActionTypes.GET_SHOP_HIERARCHY_SUCCESS:
      return {
        ...state,
        industries: action.industries,
        lastFetchedIndustries: new Date()
      };

    case ActionTypes.SET_ACTIVE_CURATOR:
      return {
        ...state,
        activeCurator: action.curator,
        activeCuratorGroup: null
      };

    case ActionTypes.SET_ACTIVE_CURATOR_GROUP:
      return {
        ...state,
        activeCurator: null,
        activeCuratorGroup: action.group
      };

    case ActionTypes.SET_ATTRIBUTABLE_CURATOR_ID:
      return {
        ...state,
        attributableCuratorId: action.Curator_id,
        attributableCuratorGroupId: null
      };

    case ActionTypes.SET_ATTRIBUTABLE_CURATOR_GROUP_ID:
      return {
        ...state,
        attributableCuratorId: null,
        attributableCuratorGroupId: action.CuratorGroup_id
      };

    case ActionTypes.SET_DISCOVER_FILTERS:
      return {
        ...state,
        discover: {
          ...state.discover,
          filters: action.filters
        }
      };

    case ActionTypes.GET_DISCOVER_CURATORS_AND_GROUPS_SUCCESS:
      return {
        ...state,
        discover: {
          ...state.discover,
          groups: action.groups,
          curators: action.curators
        }
      };

    // Recently Viewed
    case ActionTypes.ADD_RECENTLY_VIEWED_PRODUCT:
      const isStillRecent = ({ timestamp }) => moment().diff(moment(timestamp), 'days') < 7;
      return {
        ...state,
        recentlyViewedProductsWithTimestamp: _.uniqBy(
          _.filter(
            [
              {
                product: action.product,
                timestamp: new Date()
              },
              ...(state?.recentlyViewedProductsWithTimestamp || [])
            ],
            isStillRecent
          ),
          d => d.product.id
        ).slice(0, 10)
      };

    // Search Results
    case ActionTypes.SAVE_SEARCH_RESULTS_CACHE:
      return {
        ...state,
        searchResultsCache: {
          key: action.key,
          clearAfter: moment()
            .add(5, 'minutes')
            .toDate(),
          data: action.data // Only store a single value of results
        }
      };

    // Products
    case ActionTypes.SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.product
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetchingFullProduct: true,
        activeProduct: state.activeProduct?.id === action.Product_id ? state.activeProduct : null
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetchingFullProduct: false,
        activeProduct: action.product
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_ERROR:
      return {
        ...state,
        isFetchingFullProduct: false,
        activeProduct: null
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetchingSimilarProducts: true,
        activeProductSimilarProducts: state.activeProduct?.id === action.Product_id ? state.activeProductSimilarProducts : null
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetchingSimilarProducts: false,
        activeProductSimilarProducts: action.similarProducts
      };

    case ActionTypes.GET_ACTIVE_PRODUCT_SIMILAR_PRODUCTS_ERROR:
      return {
        ...state,
        isFetchingSimilarProducts: false,
        activeProductSimilarProducts: null
      };

    case UserActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        activeCurator: null,
        activeCuratorGroup: null,
        attributableCuratorId: null,
        attributableCuratorGroupId: null,
        simpleCollectionsForAdding: []
      };

    case ActionTypes.GET_SIMPLE_COLLECTIONS_FOR_ADDING_SUCCESS:
      return {
        ...state,
        simpleCollectionsForAdding: action.collections
      };

    case ActionTypes.GET_SIMPLE_COLLECTIONS_FOR_ADDING_ERROR:
      window.ALERT.error('Had an issue syncing your most recent collections, please try again.');
      return {
        ...state
      };

    default:
      // Handle clearing the cache after a certain amount of time to reduce payload size while still allowing other parts of the shop to use react-persist
      const cacheNeedsClearingAfter = state.searchResultsCache?.clearAfter;
      if (cacheNeedsClearingAfter) {
        const secondsUntilClear = moment(cacheNeedsClearingAfter).diff(moment(), 'seconds');
        if (secondsUntilClear < 0) return { ...state, searchResultsCache: {} };
      }
      return state;
  }
};
