import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import _ from 'lodash'
// import cn from 'classnames'
import './ProductBrandUpsell.scss';

const ProductBrandUpsell = props => {
  return null; // Currently Disabled
  // const { product } = props;
  // const { brand } = product;
  // return (
  //   <div className='product-brand-upsell-container'>
  //     <div className='header'>Do you work at {brand.name}?</div>
  //     <div className='subheader'>ShopMy can help you get your products in the hands of the top curators in the world.</div>
  //   </div>
  // );
};

ProductBrandUpsell.propTypes = {
  user: PropTypes.object.isRequired,

  // From outside
  product: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(ProductBrandUpsell);
