import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import './CuratorGroupModal.scss';

import { closeCuratorGroupModal } from '../../Actions/UIActions';
import { getCuratorGroupsByIds } from '../../APIClient/shop';
import {
  createCuratorGroup,
  updateCuratorGroup,
  deleteCuratorGroup,
  createCuratorGroupUser,
  deleteCuratorGroupUser
} from '../../Actions/ShopActions';
import { getCuratorGroupModalStartingParams } from '../../Helpers/ui_helpers';
import { getLinkToPrivateCuratorGroupPage, getLinkToShopForCuratorGroupId } from '../../Helpers/shop_helpers';
import { getOrderedCuratorGroups, getOrderedCuratorGroupFavorites, getUserId } from '../../Helpers/user_helpers';
import { setUrlParam, removeUrlParam } from '../../Helpers/helpers';

import CuratorGroupModalCurators from './Elements/CuratorGroupModalCurators';
import CuratorGroupModalCuratorGroups from './Elements/CuratorGroupModalCuratorGroups';
import CuratorGroupModalHeader from './Elements/CuratorGroupModalHeader';
import CuratorGroupModalEmptySearchResults from './Elements/CuratorGroupModalEmptySearchResults';
import Modal from '../General/Modal';
import ConfirmPrompt from '../General/ConfirmPrompt';

const CuratorGroupModal = props => {
  const { user } = props;

  // Initialize State
  const startingParams = getCuratorGroupModalStartingParams(props.ui);
  const [modalMode] = React.useState(startingParams.type);
  const [curatorToAdd] = React.useState(startingParams.curator);
  const isAddMode = modalMode === 'add';
  const isSelectMode = modalMode === 'select';

  // Handle showing specific group curators
  const [showingCuratorsFromGroup, setShowingCuratorsFromGroup] = React.useState(null);

  // Get Curator Groups
  const curatorGroups = getOrderedCuratorGroups(props.user);
  const curatorGroupFavorites = getOrderedCuratorGroupFavorites(props.user);
  const curatorGroupFavoritesGroups = curatorGroupFavorites.map(cgf => cgf.curator_group);
  let allCuratorGroups = [];
  let visibleCuratorGroups = [];

  // Augment results with full user data in order to power search
  const [augmentedFavoriteGroupsById, setAugmentedFavoriteGroupsById] = React.useState({});
  React.useEffect(() => {
    const favoriteGroupIdsNeedingAugmentation = _.map(curatorGroupFavorites, cgf => cgf.CuratorGroup_id);
    if (favoriteGroupIdsNeedingAugmentation.length) {
      getCuratorGroupsByIds(favoriteGroupIdsNeedingAugmentation)
        .then(resp => setAugmentedFavoriteGroupsById(_.keyBy(resp.groups, 'id')))
        .catch(err => console.error(err));
    }
  }, []);
  const augmentGroup = group => {
    const augmentedGroup = augmentedFavoriteGroupsById[group.id];
    return augmentedGroup || group;
  };

  // Handle filtering the results
  const [curatedByFilter, setCuratedByFilter] = React.useState('all'); // me, others, all

  if (isAddMode) {
    visibleCuratorGroups = curatorGroups.map(augmentGroup);
    allCuratorGroups = curatorGroups.map(augmentGroup);
  } else {
    const favoriteGroupsWithTimestamp = curatorGroupFavorites.map(cgf => ({
      ...augmentGroup(cgf.curator_group),
      timestampForSorting: cgf.createdAt
    }));
    const myGroupsWithTimestamp = curatorGroups.map(g => ({
      ...augmentGroup(g),
      timestampForSorting: _.max(g.group_users.map(gu => gu.createdAt))
    }));
    allCuratorGroups = [...favoriteGroupsWithTimestamp, ...myGroupsWithTimestamp];
    visibleCuratorGroups = _.orderBy(
      curatedByFilter === 'me'
        ? myGroupsWithTimestamp
        : curatedByFilter === 'others'
        ? favoriteGroupsWithTimestamp
        : [...favoriteGroupsWithTimestamp, ...myGroupsWithTimestamp],
      'timestampForSorting',
      'desc'
    );
  }

  // Apply Search
  const [searchVal, setSearchVal] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  if (searchVal) {
    visibleCuratorGroups = visibleCuratorGroups.filter(g => {
      const titleMatch = g.title.toLowerCase().includes(searchVal.toLowerCase());
      return titleMatch;
    });
  }
  const modalRef = React.useRef();
  const scrollAndSearch = () => {
    modalRef.current.scrollTop = 0;
    setIsSearching(true);
  };

  // Navigation
  const selectGroupId = groupId => {
    if (window.location.pathname !== '/shop') {
      props.history.push(getLinkToShopForCuratorGroupId(groupId));
      props.closeCuratorGroupModal();
      return;
    } else {
      removeUrlParam('Curator_id');
      setUrlParam('CuratorGroup_id', groupId);
      props.closeCuratorGroupModal();
    }
  };

  const selectCuratorId = curatorId => {
    if (window.location.pathname !== '/shop') {
      window.location.href = '/shop?Curator_id=' + curatorId;
      props.closeCuratorGroupModal();
      return;
    } else {
      removeUrlParam('CuratorGroup_id');
      setUrlParam('Curator_id', curatorId);
      props.closeCuratorGroupModal();
    }
  };

  // Actions
  const closeWithoutUpdate = () => props.closeCuratorGroupModal();
  const [isCreatingNewGroup, setIsCreatingNewGroup] = React.useState(false);
  const [isAddingCuratorToGroupIds, setIsAddingCuratorToGroupIds] = React.useState([]);
  const createNewCuratorGroupAndGoToGroup = () => createNewCuratorGroup({ goToGroupOnceCreated: true });
  const createNewCuratorGroupAndAdd = () => createNewCuratorGroup({ addOnceCreated: true });
  const createNewCuratorGroup = async (options = {}) => {
    const { addOnceCreated, goToGroupOnceCreated } = options;
    if (isCreatingNewGroup) return;

    const defaultTitle = !curatorGroups.length ? 'My Curators' : curatorToAdd ? `${curatorToAdd.name} Group` : '';

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='What do you want to call this shopping circle?'
          subheader={addOnceCreated ? `Once you create this circle, we will add ${curatorToAdd.name} to it.` : ``}
          placeholder='My Curators'
          isSingleLine
          preloaded={defaultTitle}
          onCancel={onClose}
          submitBtnDisplay='Create Circle'
          submitMustReturnTrueToClose
          onSubmitAwait={async title => {
            if (!title) return window.ALERT.warn('Please enter a title for your group');
            setIsCreatingNewGroup(true);
            const resp = await props.createCuratorGroup({
              previewImage: null,
              title
            });
            if (addOnceCreated) {
              await addCuratorToGroup(resp.group);
            }
            setIsCreatingNewGroup(false);

            if (goToGroupOnceCreated) {
              props.history.push(getLinkToPrivateCuratorGroupPage(resp.group));
              props.closeCuratorGroupModal();
            }

            return true;
          }}
        />
      )
    });
  };
  const addCuratorToGroup = async group => {
    setIsAddingCuratorToGroupIds(_.union(isAddingCuratorToGroupIds, [group.id]));
    await props.createCuratorGroupUser({ CuratorGroup_id: group.id, User_id: getUserId(curatorToAdd) });
    setIsAddingCuratorToGroupIds(_.without(isAddingCuratorToGroupIds, group.id));
  };

  if (!isAddMode && !isSelectMode) {
    window.ALERT.warn(`Unimplemented modal type: ${modalMode}`);
    props.closeCuratorGroupModal();
    return null;
  }

  let groups = [];
  if (showingCuratorsFromGroup) {
    groups = [];
  } else {
    groups = visibleCuratorGroups;
  }
  const groupResults = (
    <CuratorGroupModalCuratorGroups
      user={user}
      groups={groups}
      curatorToAdd={curatorToAdd}
      isAddMode={isAddMode}
      isSelectMode={isSelectMode}
      closeModal={closeWithoutUpdate}
      selectGroupId={selectGroupId}
      isAddingCuratorToGroupIds={isAddingCuratorToGroupIds}
      setShowingCuratorsFromGroup={setShowingCuratorsFromGroup}
      addCuratorToGroup={addCuratorToGroup}
      createNewCuratorGroupAndAdd={createNewCuratorGroupAndAdd}
      createNewCuratorGroupAndGoToGroup={createNewCuratorGroupAndGoToGroup}
      deleteCuratorGroupUser={props.deleteCuratorGroupUser}
      deleteCuratorGroup={props.deleteCuratorGroup}
    />
  );

  let curators = [],
    hasMoreHiddenResults = false;
  if (showingCuratorsFromGroup) {
    curators = showingCuratorsFromGroup.group_users;
  } else {
    const curatorGroupsToConsider =
      curatedByFilter === 'me' ? curatorGroups : curatedByFilter === 'others' ? curatorGroupFavoritesGroups : allCuratorGroups;

    // Store the User ID to group ID map for sorting purposes
    let userIdToGroupIdMap = {};
    curatorGroupsToConsider.forEach(group => {
      group.group_users?.forEach(groupUser => {
        userIdToGroupIdMap[groupUser.User_id] = userIdToGroupIdMap[groupUser.User_id] || [];
        userIdToGroupIdMap[groupUser.User_id].push(group.id);
      });
    });

    // Grab unique users from all groups
    const allCuratorGroupUsers = _.filter(_.flatten(curatorGroupsToConsider.map(g => g.group_users)));
    const visibleCuratorGroupUsers = _.uniqBy(allCuratorGroupUsers, gu => gu.user.name + gu.user.image).filter(groupUser => {
      if (!searchVal) return true;
      return groupUser.user?.name.toLowerCase().includes(searchVal.toLowerCase());
    });

    // Sort by number of my groups the user is in, and then by total number of groups
    const myGroupIds = curatorGroups.map(g => g.id);
    const sortedCuratorGroupUsers = _.orderBy(
      visibleCuratorGroupUsers,
      groupUser => {
        const groupIds = userIdToGroupIdMap[groupUser.User_id];
        const numberOfMyGroups = _.intersection(groupIds, myGroupIds).length;
        const numberOfTotalGroups = groupIds.length;
        return numberOfMyGroups * 1000 + numberOfTotalGroups;
      },
      'desc'
    );
    const MAX_TO_SHOW = 48; // Rows of 4
    hasMoreHiddenResults = isSelectMode && sortedCuratorGroupUsers.length > MAX_TO_SHOW;
    curators = sortedCuratorGroupUsers.slice(0, MAX_TO_SHOW);
  }
  const curatorResults = (
    <CuratorGroupModalCurators group={showingCuratorsFromGroup} curators={curators} selectCuratorId={selectCuratorId} selectGroupId={selectGroupId} />
  );

  const hasNoSearchResults = !!searchVal && !groups.length && !curators.length;

  return (
    <Modal
      visible
      scrollRef={modalRef}
      close={closeWithoutUpdate}
      className='curator-group-modal-outer'
      innerClassName='curator-group-modal-inner'
      contentClassName='curator-group-modal-content'
    >
      <div className='curator-group-modal-actions'>
        {isAddMode && (
          <div className='done-btn' onClick={closeWithoutUpdate}>
            Done
          </div>
        )}
        <FontAwesomeIcon icon={faTimes} className='close-icon' onClick={closeWithoutUpdate} />
      </div>
      <CuratorGroupModalHeader
        user={user}
        curatorToAdd={curatorToAdd}
        isAddMode={isAddMode}
        showingCuratorsFromGroup={showingCuratorsFromGroup}
        backToGroupsPage={() => setShowingCuratorsFromGroup(null)}
        curatedByFilter={curatedByFilter}
        setCuratedByFilter={setCuratedByFilter}
        searchVal={searchVal}
        setSearchVal={setSearchVal}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        createNewCuratorGroupAndAdd={createNewCuratorGroupAndAdd}
        closeModal={closeWithoutUpdate}
      />
      <>
        {groupResults}
        {!!curators.length && !isAddMode && (
          <>
            {!!groups.length && <div className='curator-group-modal-divider'>All Curators</div>}
            {curatorResults}
          </>
        )}
        {hasMoreHiddenResults && (
          <div className='curator-group-modal-more-results'>
            To see more curators, please use the search functionality by clicking{` `}
            <span className='clickable' onClick={scrollAndSearch}>
              here
            </span>
            .
          </div>
        )}
        {hasNoSearchResults && <CuratorGroupModalEmptySearchResults closeModal={closeWithoutUpdate} searchVal={searchVal} />}
      </>
    </Modal>
  );
};

CuratorGroupModal.propTypes = {
  shop: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { ui, user, shop } = state;
  return { ui, user, shop };
};

export default connect(mapStateToProps, {
  closeCuratorGroupModal,
  createCuratorGroup,
  updateCuratorGroup,
  deleteCuratorGroup,
  createCuratorGroupUser,
  deleteCuratorGroupUser
})(withRouter(CuratorGroupModal));
