import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faShoppingBag } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import './CuratorCardNew.scss';

import { getLinkToShopForUserId, getLinkToShopForUsername, getCategoryById, getDepartmentById, getIndustryById } from '../../Helpers/shop_helpers';
import { dontBubble } from '../../Helpers/helpers';
import { isCuratorGroupIdFavoritedSomewhere } from '../../Helpers/user_helpers';
// import { split_db_social_links_into_individual_links } from '../../Helpers/social_helpers';

import ConfirmDelete from '../General/ConfirmDelete';

const CuratorCard = props => {
  const { shop, groupUser, curator, isEditing, activeFilters, isCuratedByMe } = props;

  // Pull out the User_id and image
  let User_id, image, name, username, social_links;
  if (groupUser) {
    User_id = groupUser.User_id;
    image = groupUser.user.image;
    name = groupUser.user.name;
    username = groupUser.user.username;
    social_links = groupUser.user.social_links;
  } else if (curator) {
    User_id = curator.id;
    image = curator.image;
    name = curator.name;
    username = curator.username;
    social_links = curator.social_links;
  }

  // Content
  // Curated By Message
  let curatedByMessage;
  if (activeFilters?.Category_id) {
    curatedByMessage = `${getCategoryById(shop, activeFilters.Category_id)?.name}, curated by`;
  } else if (activeFilters?.Department_id) {
    curatedByMessage = `${getDepartmentById(shop, activeFilters.Department_id)?.name}, curated by`;
  } else if (activeFilters?.Industry_id) {
    curatedByMessage = `${getIndustryById(shop, activeFilters.Industry_id)?.name}, curated by`;
  } else {
    curatedByMessage = 'Curated by';
  }

  // const { instagram_url, tiktok_url, youtube_url } = split_db_social_links_into_individual_links(social_links);

  // Allow Deleting if this is your list
  const removeGroupUser = e => {
    e.preventDefault();
    e.stopPropagation();
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Are you sure?'
          subheader={`This will permanently remove ${name} from this group.`}
          onCancel={onClose}
          hideCancel={false}
          onDelete={() => {
            props.deleteCuratorGroupUser(groupUser);
            onClose();
          }}
        />
      )
    });
  };

  // Allow Favoriting if this is not your list
  const isFavorite = isCuratorGroupIdFavoritedSomewhere(props.user, User_id);
  const canClickFavorite = !isCuratedByMe && props.openCuratorGroupModal;
  const clickFavorite = e => {
    dontBubble(e);
    props.openCuratorGroupModal({
      params: {
        type: 'add',
        curator: {
          id: User_id,
          name: name,
          image: image
        }
      }
    });
  };

  // Links
  const linkToShop = username
    ? getLinkToShopForUsername(username, props.activeFilters)
    : User_id && getLinkToShopForUserId(User_id, props.activeFilters);
  const goToShop = e => {
    dontBubble(e);
    props.history.push(linkToShop);
  };

  // Actions
  const additionalClasses = { 'on-dark': props.onDark, 'is-loading': props.isLoading };
  return props.isEmpty ? (
    <div className={cn('new-curator-card-container empty', additionalClasses)}>
      <div className={cn('image-container empty', additionalClasses)}>
        <div className='empty-image empty' />
      </div>
      <div className={cn('data-container empty', additionalClasses)}>
        <div className='name empty'>Loading...</div>
        <div className={cn('icons', additionalClasses)}>
          <div className='social-icon empty' />
          <div className='social-icon empty' />
        </div>
      </div>
    </div>
  ) : (
    <Link to={linkToShop} className='new-curator-card-container'>
      {!!image && (
        <div className={cn('background-image', additionalClasses)}>
          {image ? <img src={image} alt={name} loading={props.lazyLoadImages ? 'lazy' : 'eager'} /> : <div className='empty-image' />}
        </div>
      )}
      <div onClick={dontBubble} className={cn('actions', additionalClasses)}>
        {!props.hideActions && (
          <>
            {!isEditing && (
              <div onClick={goToShop} className={cn('action shop show-action-on-card-hover', additionalClasses)}>
                <FontAwesomeIcon icon={faShoppingBag} />
                <div className='explainer'>Shop</div>
              </div>
            )}
            {canClickFavorite && (
              <div className='action favorite show-action-on-card-hover' onClick={clickFavorite}>
                <FontAwesomeIcon icon={isFavorite ? faHeartSolid : faHeart} />
                <div className='explainer'>Favorite</div>
              </div>
            )}
            {isEditing && (
              <div onClick={removeGroupUser} className={cn('action delete', additionalClasses)}>
                <FontAwesomeIcon icon={faTrash} />
              </div>
            )}
            {isEditing && (
              <div className={cn('action reorder', additionalClasses)}>
                {props.rearrangeHandle}
                <div className='explainer'>Rearrange</div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={cn('content', additionalClasses)}>
        <div className='curated-by'>{curatedByMessage}</div>
        <div className='title'>{name}</div>
        {/* <div className='metadata'>{`${num_curators} ${pluralize('curator', num_curators)}`}</div> */}
      </div>
    </Link>
  );
};

CuratorCard.propTypes = {
  user: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,

  // Group User (from list), Curator (from search), or Empty
  groupUser: PropTypes.object,
  curator: PropTypes.object,
  isEmpty: PropTypes.bool,

  // Optional if we want to open them to a certain page
  activeFilters: PropTypes.object,

  // UI
  onDark: PropTypes.bool,
  hideSocials: PropTypes.bool, // Not Used
  hideActions: PropTypes.bool, // Not Used
  isLoading: PropTypes.bool,
  large: PropTypes.bool, // Not Used
  medium: PropTypes.bool, // Not Used
  lazyLoadImages: PropTypes.bool,

  // If Reordable
  rearrangeHandle: PropTypes.object,

  // Actions
  deleteCuratorGroupUser: PropTypes.func,
  openCuratorGroupModal: PropTypes.func // If you want to allow them to heart
};

export default withRouter(CuratorCard);
