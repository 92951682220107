import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './ShopMainHomeHeader.scss';

import { focusShopSearch } from '../../Actions/UIActions';

import { getExampleShopSearchTerms } from '../../Helpers/search_helpers';

const ShopMainHomeHeader = props => {
  const clickedMockSearch = () => {
    props.focusShopSearch();
    window.__ADD_EVENT__('Shop - Clicked Mock Search');
  };

  // Rotating Search Input
  const searchInputPlaceholderOptionsRef = React.useRef(_.shuffle(getExampleShopSearchTerms()).slice(0, 10));
  const searchInputPlaceholderOptions = searchInputPlaceholderOptionsRef.current;
  const [currentInputPlaceholder, setCurrentInputPlaceholder] = React.useState(searchInputPlaceholderOptions[0]);
  const [isTransitioningPlaceholder, setIsTransitioningPlaceholder] = React.useState(false);
  const SWITCH_PLACEHOLDER_TIMEOUT = 5000;
  React.useEffect(() => {
    const rotate = setInterval(() => {
      const currentIndex = searchInputPlaceholderOptions.indexOf(currentInputPlaceholder);
      const next = searchInputPlaceholderOptions[(currentIndex + 1) % searchInputPlaceholderOptions.length];
      setIsTransitioningPlaceholder(true);
      next &&
        setTimeout(() => {
          setIsTransitioningPlaceholder(false);
          setCurrentInputPlaceholder(next);
        }, 300); // Keep this the same as the SCSS transition duration
    }, SWITCH_PLACEHOLDER_TIMEOUT);
    return () => {
      clearInterval(rotate);
    };
  }, [currentInputPlaceholder]);

  return (
    <div className='shop-main-home-header'>
      <h1>
        Search less, <i>discover more.</i>
      </h1>
      <h2>Shop the products recommended by the world's most trusted curators.</h2>
      <div className='mock-search-container'>
        <div onClick={clickedMockSearch} className='mock-search-input'>
          <FontAwesomeIcon icon={faSearch} />
          <div
            className={cn('placeholder', {
              transitioning: isTransitioningPlaceholder
            })}
          >
            {currentInputPlaceholder}
          </div>
        </div>
      </div>
    </div>
  );
};

ShopMainHomeHeader.propTypes = {
  user: PropTypes.object.isRequired,
  focusShopSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  focusShopSearch
})(ShopMainHomeHeader);
