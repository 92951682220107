import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Auth } from 'aws-amplify';
import cogoToast from 'cogo-toast';

import Loader from '../Loader/Loader';

import { fetchUserByEmail } from '../../APIClient/users';
import { getAndRemoveUrlParam } from '../../Helpers/helpers';

class ForgotPassword extends Component {
  static propTypes = {
    loginUser: PropTypes.func.isRequired,
    backToLogin: PropTypes.func.isRequired
  };

  state = {
    email: '',
    password: '',
    verificationCode: '',
    isLoading: false,
    isCodeSent: false,
    isPasswordUpdated: false,
    isDeepLinkedFromEmail: false
  };

  componentDidMount() {
    const email = getAndRemoveUrlParam('email');
    const code = getAndRemoveUrlParam('pw_code');
    email && this.setState({ email, isCodeSent: true, isDeepLinkedFromEmail: true, verificationCode: code });
  }

  handleResetRequest = async event => {
    event.preventDefault();

    const { email } = this.state;

    // Confirm the Email exists in our system
    const resp = await fetchUserByEmail({ email });
    if (!resp.user) {
      console.error(resp);
      return cogoToast.error(
        `We do not have a user with the email ${email}, please check the spelling or try another email. If you cannot locate the email you used, please reach out to us at team@shopmy.us!`,
        { hideAfter: 12 }
      );
    }

    this.setState({ isLoading: true, isCodeSent: false });
    try {
      await Auth.forgotPassword(email);
      this.setState({ isCodeSent: true });
    } catch (error) {
      cogoToast.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleUpdatePassword = async event => {
    event.preventDefault();

    const { email, password, verificationCode } = this.state;
    const { loginUser } = this.props;

    this.setState({ isLoading: true });

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
      this.setState({ isPasswordUpdated: true });

      loginUser({ email, password }).then(
        ({ user }) => {
          this.setState({ isLoading: false });
        },
        error => {
          cogoToast.error(('Login Failed: ', error.message));
          this.setState({ isLoading: false });
        }
      );
    } catch (error) {
      cogoToast.error(error.message);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, isCodeSent, isPasswordUpdated, email, password, verificationCode } = this.state;

    return (
      <div className='auth-section'>
        {isPasswordUpdated ? (
          <Fragment>
            <div className='auth-header'>Password Updated. Logging in ...</div>
            <div className='login-btn-container'>{isLoading && <Loader size={40} />}</div>
          </Fragment>
        ) : isCodeSent ? (
          <Fragment>
            <div className='auth-header'>
              {this.state.isDeepLinkedFromEmail
                ? `Please reset your password below.`
                : `An email should be delivered shortly. If you do not receive an email please reach out to team@shopmy.us for assistance.`}
            </div>
            <form onSubmit={this.handleUpdatePassword}>
              {!this.state.isDeepLinkedFromEmail && (
                <input
                  placeholder='Verification Code (6 digits)'
                  className='standard-input'
                  type='number'
                  onChange={({ target }) => this.setState({ verificationCode: target.value.slice(0, 6) })}
                  value={verificationCode}
                />
              )}
              <input
                type='email'
                className='standard-input'
                placeholder='Email'
                onChange={({ target }) => this.setState({ email: target.value })}
                value={email}
                disabled
              />
              <input
                type='password'
                className='standard-input'
                placeholder='New Password'
                onChange={({ target }) => this.setState({ password: target.value })}
                value={password}
              />

              <div className='login-btn-container'>
                <input type='submit' className={classnames('login-btn', { loading: isLoading })} value='SET NEW PASSWORD' />
                {isLoading && <Loader size={40} />}
              </div>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <div className='auth-header'>Enter email to reset password</div>
            <form onSubmit={this.handleResetRequest}>
              <input
                type='email'
                className='standard-input'
                placeholder='Email'
                onChange={({ target }) => this.setState({ email: target.value })}
                value={email}
                disabled={isLoading}
              />
              <div className='login-btn-container'>
                <input type='submit' className={classnames('login-btn', { loading: isLoading })} value='REQUEST PASSWORD RESET' />
                {isLoading && <Loader size={40} />}
              </div>
              <div onClick={this.props.backToLogin} className='back-to-login'>
                Back to Login
              </div>
            </form>
          </Fragment>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
