import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import storeData from '../configureStore';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import { isBrand } from './user_helpers';

import instagramIcon from '../static/images/social_icons/instagram_dark.png';
import instagramIconLight from '../static/images/social_icons/instagram_light.png';
import instagramIconWhite from '../static/images/social_icons/instagram_white.png';
import youtubeIcon from '../static/images/social_icons/youtube_dark.png';
import youtubeIconWhite from '../static/images/social_icons/youtube_white.png';
import tiktokIcon from '../static/images/social_icons/tiktok_dark.png';
import tiktokIconLight from '../static/images/social_icons/tiktok_light.png';
import tiktokIconWhite from '../static/images/social_icons/tiktok_white.png';
import twitterIcon from '../static/images/social_icons/twitter_dark.png';
import twitterIconLight from '../static/images/social_icons/twitter_light.png';
import twitterIconWhite from '../static/images/social_icons/twitter_white.png';
import snapchatIcon from '../static/images/social_icons/snapchat_dark.png';
import snapchatIconLight from '../static/images/social_icons/snapchat_light.png';
import snapchatIconWhite from '../static/images/social_icons/snapchat_white.png';
import spotifyIcon from '../static/images/social_icons/spotify_dark.png';
import spotifyIconLight from '../static/images/social_icons/spotify_light.png';
import spotifyIconWhite from '../static/images/social_icons/spotify_white.png';
import substackIcon from '../static/images/social_icons/substack_dark.png';
import substackIconLight from '../static/images/social_icons/substack_light.png';
import substackIconWhite from '../static/images/social_icons/substack_white.png';
import pinterestIcon from '../static/images/social_icons/pinterest_dark.png';
import pinterestIconLight from '../static/images/social_icons/pinterest_light.png';
import pinterestIconWhite from '../static/images/social_icons/pinterest_white.png';
import facebookIcon from '../static/images/social_icons/facebook_dark.png';
import facebookIconLight from '../static/images/social_icons/facebook_light.png';
import facebookIconWhite from '../static/images/social_icons/facebook_white.png';
import hoobeIcon from '../static/images/social_icons/hoobe_dark.png';
import hoobeIconLight from '../static/images/social_icons/hoobe_light.png';
import hoobeIconWhite from '../static/images/social_icons/hoobe_white.png';
import komiIcon from '../static/images/social_icons/komi_dark.png';
import komiIconLight from '../static/images/social_icons/komi_light.png';
import komiIconWhite from '../static/images/social_icons/komi_white.png';
import linkedInIcon from '../static/images/social_icons/linkedin_dark.png';
import linkedInIconLight from '../static/images/social_icons/linkedin_light.png';
import linkedInIconWhite from '../static/images/social_icons/linkedin_white.png';
import { setUIAuthRedirectUrl } from '../Actions/UIActions';
import { getYoutubeAuthUrl, getTiktokAuthUrl } from '../APIClient/social';

export const YOUTUBE_ASPECT_RATIO = 1.78; // From youtube.com (if changing, search for it in scss)
export const TIKTOK_ASPECT_RATIO = 0.754; // From tiktok.com (if changing, search for it in scss)

export const getSocialPlatformForUrl = url => {
  if (isPlatformUrl(url, 'youtube')) return 'YouTube';
  if (isPlatformUrl(url, 'instagram')) return 'Instagram';
  if (isPlatformUrl(url, 'tiktok')) return 'TikTok';
  if (isPlatformUrl(url, 'twitter')) return 'Twitter';
  if (isPlatformUrl(url, 'snapchat')) return 'Snapchat';
  if (isPlatformUrl(url, 'spotify')) return 'Spotify';
  if (isPlatformUrl(url, 'substack')) return 'Substack';
  if (isPlatformUrl(url, 'pinterest')) return 'Pinterest';
  if (isPlatformUrl(url, 'facebook')) return 'Facebook';
  if (isPlatformUrl(url, 'hoobe')) return 'Hoobe';
  if (isPlatformUrl(url, 'komi')) return 'Komi';
  if (isPlatformUrl(url, 'linkedin')) return 'LinkedIn';
  return null;
};
export const getDisplayForSocialPlatform = platform => {
  return (
    {
      youtube: 'YouTube',
      instagram: 'Instagram',
      tiktok: 'TikTok',
      twitter: 'Twitter',
      snapchat: 'Snapchat',
      spotify: 'Spotify',
      substack: 'Substack',
      pinterest: 'Pinterest',
      facebook: 'Facebook',
      linkedin: 'LinkedIn'
    }[platform] || 'Unknown'
  );
};

export const isPlatformUrl = (url, type) => {
  if (typeof url !== 'string') return false;

  switch (type) {
    case 'youtube':
      return url.includes('youtube') || url.includes('youtu.be');
    case 'instagram':
      return url.includes('instagram');
    case 'tiktok':
      return url.includes('tiktok.');
    case 'twitter':
      return url.includes('twitter.');
    case 'snapchat':
      return url.includes('snapchat.');
    case 'spotify':
      return url.includes('spotify.');
    case 'substack':
      return url.includes('substack.');
    case 'pinterest':
      return url.includes('pinterest.');
    case 'facebook':
      return url.includes('facebook.');
    case 'hoobe':
      return url.includes('hoo.be');
    case 'komi':
      return url.includes('komi.io');
    case 'linkedin':
      return url.includes('linkedin.com') || url.includes('lnkd.in');
    case 'other':
      return !_.find(
        [
          'youtube',
          'youtu.be',
          'instagram',
          'tiktok',
          'twitter',
          'snapchat',
          'pinterest',
          'spotify',
          'substack',
          'facebook',
          'hoo.be',
          'komi.io',
          'linkedin.com',
          'lnkd.in'
        ],
        stub => url.includes(stub)
      );
    default:
      console.error('Passed invalid platform type');
      return false;
  }
};

export const getSocialIconForUrl = (url, iconStyle = 'dark') => {
  const type = getSocialPlatformForUrl(url);
  return {
    Twitter: iconStyle === 'white' ? twitterIconWhite : iconStyle === 'light' ? twitterIconLight : twitterIcon,
    Instagram: iconStyle === 'white' ? instagramIconWhite : iconStyle === 'light' ? instagramIconLight : instagramIcon,
    TikTok: iconStyle === 'white' ? tiktokIconWhite : iconStyle === 'light' ? tiktokIconLight : tiktokIcon,
    YouTube: iconStyle === 'white' ? youtubeIconWhite : youtubeIcon,
    Snapchat: iconStyle === 'white' ? snapchatIconWhite : iconStyle === 'light' ? snapchatIconLight : snapchatIcon,
    Spotify: iconStyle === 'white' ? spotifyIconWhite : iconStyle === 'light' ? spotifyIconLight : spotifyIcon,
    Substack: iconStyle === 'white' ? substackIconWhite : iconStyle === 'light' ? substackIconLight : substackIcon,
    Pinterest: iconStyle === 'white' ? pinterestIconWhite : iconStyle === 'light' ? pinterestIconLight : pinterestIcon,
    Facebook: iconStyle === 'white' ? facebookIconWhite : iconStyle === 'light' ? facebookIconLight : facebookIcon,
    Hoobe: iconStyle === 'white' ? hoobeIconWhite : iconStyle === 'light' ? hoobeIconLight : hoobeIcon,
    Komi: iconStyle === 'white' ? komiIconWhite : iconStyle === 'light' ? komiIconLight : komiIcon,
    LinkedIn: iconStyle === 'white' ? linkedInIconWhite : iconStyle === 'light' ? linkedInIconLight : linkedInIcon
  }[type];
};

export const getSocialIconForType = (type, iconStyle = 'dark') => {
  return {
    instagram: getSocialIconForUrl('instagram.com', iconStyle),
    youtube: getSocialIconForUrl('youtube.com', iconStyle),
    tiktok: getSocialIconForUrl('tiktok.com', iconStyle),
    twitter: getSocialIconForUrl('twitter.com', iconStyle),
    facebook: getSocialIconForUrl('facebook.com', iconStyle),
    hoobe: getSocialIconForUrl('hoo.be', iconStyle),
    komi: getSocialIconForUrl('komi.io', iconStyle),
    linkedin: getSocialIconForUrl('linkedin.com', iconStyle)
  }[type];
};

export const getSocialIconsForArtist = (artist, options) => {
  const { iconStyle } = options || {};
  const { social_links } = artist;
  const socialLinks = _.orderBy(
    _.filter((social_links || '').split(',')).map(url => ({
      url,
      type: getSocialPlatformForUrl(url),
      icon: getSocialIconForUrl(url, iconStyle || 'dark')
    })),
    'type'
  );

  return (
    <div className='social-links'>
      {socialLinks?.map(({ icon, type, url }, idx) => (
        <a
          href={url}
          onClick={e => {
            // For some unknow-able reason the artist modal does not allow clicking
            e.preventDefault();
            window.open(url, '_blank', 'noopener,noreferrer');
          }}
          target='_blank'
          rel='noopener noreferrer'
          key={idx + url}
          className='social-link'
        >
          {icon ? <img alt={type} src={icon} /> : <FontAwesomeIcon icon={faLink} />}
        </a>
      ))}
    </div>
  );
};

export const getYoutubeVideoIdFromUrl = url => {
  let ID = '';
  if (!url.includes('youtu')) return null;
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
};

export const getImageFromYoutubeUrl = url => {
  const videoId = getYoutubeVideoIdFromUrl(url);
  return videoId && `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const getTiktokVideoIdFromUrl = url => {
  if (!url.includes('tiktok')) return null;
  const isValidId = id => id?.length > 16 && !isNaN(id);

  // https://www.tiktok.com/@austimarincovich/video/7246569374469950762
  const endOfUrlVideoId = _.last(url.split('?')[0].split('/'));
  if (isValidId(endOfUrlVideoId)) return endOfUrlVideoId;

  // https://www.tiktok.com/static/profile-video?id=7246745837139741994&hide_author=1
  const urlParamId = new URLSearchParams(url.split('?')[1])?.get('id');
  if (isValidId(urlParamId)) return urlParamId;

  return null;
};

/** Resolves multiple sources of Instagram handles for a user and returns in priority order.
 *
 * Sources Instagram handles from `Users.social_links` and `UserSocialAccounts.instagramUsername` fields:
 * * If both fields have overlaps, these are returned first.
 * * If there are no overlaps, handles from `Users.social_links` are returned.
 * * If there are no handles in `Users.social_links`, handles from `UserSocialAccounts.instagramUsername` are returned.
 * * Otherwise, the user does not have an Instagram handle.
 *
 * @param {object} socialSources.social_links - a User.social_links field containing a comma separated list of social links
 * @param {object} socialSources.social_accounts - a UserSocialAccounts.instagramUsername field
 * @returns {array<string>} a priority list of Instragram usernames
 */
export const getInstagramUsernames = ({ social_links, social_accounts }) => {
  const socialLinksIgHandles = _.uniq(
    social_links
      ?.split(',')
      .filter(link => link.includes('instagram'))
      .map(
        link =>
          link
            .toLowerCase()
            .split('?')
            .map(s => s.split('/'))
            .flat()
            .filter(s => s)
            .reduce((acc, s) => {
              if (acc === true) {
                return s;
              }
              return acc || s.includes('instagram');
            }, false) || ''
      ) || []
  );
  const userSocialAccountsIgHandles = _.uniq(social_accounts.map(r => r.instagramUsername?.toLowerCase()).filter(u => u));

  const overlappingHandles = _.intersection(socialLinksIgHandles, userSocialAccountsIgHandles);

  return _.uniq([...overlappingHandles, ...socialLinksIgHandles, ...userSocialAccountsIgHandles]);
};

export const makeInstagramProfileLink = username => (username ? `https://www.instagram.com/${username}` : '');

export const openInstagramAuthModal = (cb, failureCb) => {
  if (!window.FB) {
    console.error(`Need to make sure wherever we call this we add the <FacebookSDK/> object.`);
    return cogoToast.error(`Could not load the Facebook SDK, please reload and try again.`);
  }
  const isBrandAccount = isBrand(storeData.store.getState().user);
  const scopes = isBrandAccount
    ? [
        'instagram_basic',
        'pages_show_list',
        'instagram_manage_insights',
        'pages_read_engagement',
        'business_management',
        'instagram_manage_comments' // Needed for brands to fetch mentions
      ]
    : ['instagram_basic', 'pages_show_list', 'instagram_manage_insights', 'pages_read_engagement', 'business_management'];
  const scope = scopes.join(',');

  window.FB.login(
    response => {
      const missingScopes = scopes.filter(scope => !response?.authResponse?.grantedScopes?.includes(scope));
      if (missingScopes) {
        if (missingScopes.includes('instagram_basic')) {
          cogoToast.warn(
            `We could not find any Instagram pages associated with this Facebook profile, you must select the page associated with your Instagram account. If you cannot find the page, please make sure your Instagram account is connected to your Facebook profile in Accounts Center on the Instagram App.`
          );
          return failureCb && failureCb();
        } else if (missingScopes.includes('instagram_manage_insights')) {
          cogoToast.warn(
            `It looks like we are unable to view your Instagram analytics, you will have to report the results manually in your brand collaborations.`
          );
        }
      }
      response.status === 'connected'
        ? cb({
            ...response.authResponse,
            scope
          })
        : failureCb
        ? failureCb()
        : cogoToast.warn('Could not authenticate, please try again.');
    },
    { scope: scope, auth_type: 'rerequest', return_scopes: true }
  );
};

export const getRedirectUrlForType = (type, forceDomain) =>
  `${(forceDomain ? `https://${forceDomain}` : window.location.href)
    .split('/')
    .slice(0, 3)
    .join('/')}/social_auth/${type}`;

export const openYoutubeAuthModal = (options = {}) => {
  const { useFullUrl } = options;
  const yt_redirect_url = getRedirectUrlForType('youtube', window.__IS_DEV__ ? 'shopmy.us' : null); // Requires this redirect for testing purposes
  const redirect_url = useFullUrl ? window.location.pathname + window.location.search : window.location.pathname;
  storeData.store.dispatch(setUIAuthRedirectUrl(redirect_url)).then(resp => {
    getYoutubeAuthUrl(yt_redirect_url).then(url => {
      window.location.href = url;
    });
  });
};

export const openTikTokAuthModal = (options = {}) => {
  const { useFullUrl } = options;
  const tt_redirect_url = getRedirectUrlForType('tiktok', window.__IS_DEV__ ? 'shopmy.us' : null); // Requires this redirect for testing purposes
  const redirect_url = useFullUrl ? window.location.pathname + window.location.search : window.location.pathname;
  storeData.store.dispatch(setUIAuthRedirectUrl(redirect_url)).then(resp => {
    getTiktokAuthUrl(tt_redirect_url).then(url => {
      window.location.href = url;
    });
  });
};

/**
 * function that will parse a string and search for a username in a social
 * url. i.e. youtube.com/c/HarryRein => HarryRein or
 * no_username_in_url.com => null or tiktok.com/@HarryRein => HarryRein
 *
 * important to note that the username must be at the end of the url. if
 * the username is in the middle (social.com/HarryRein/account), it will
 * not successfully return HarryRein, it will instead return "account"
 *
 * @param {string=} social_url the link to a user's social account i.e. youtube, tiktok, instagram, etc....
 * @return a standalone username that is unique to the user or null if a username wasn't found
 */
export const get_username_from_social_url = social_url => {
  if (typeof social_url !== 'string') return null;

  const username_regex = /\/@?([\w.]+)(\/)?(\?.*)?$/gim;
  const matches = username_regex.exec(social_url);

  if (!matches) return null;

  const [, username] = matches;
  return username ? username : null;
};

/**
 * function that parses the db user table "social_links" column and searches for common
 * social media links inside of it. then it turns the links found into an object that holds
 * each link individually listed
 *
 * @param {string} social_link comma separated list of a user's social links
 * @returns object with each social individually listed
 */
export const split_db_social_links_into_individual_links = social_link => {
  const links = {
    youtube_url: null,
    instagram_url: null,
    tiktok_url: null,
    twitter_url: null,
    facebook_url: null,
    website_url: null
  };

  const youtube_regex = /.*(youtube|youtu\.be).*/gi;
  const instagram_regex = /.*(instagram).*/gi;
  const tiktok_regex = /.*(tiktok).*/gi;
  const twitter_regex = /.*(twitter).*/gi;
  const facebook_regex = /.*(facebook).*/gi;

  if (!social_link) return links;
  const split_links = social_link.split(',');

  for (let link of split_links) {
    switch (true) {
      case link.match(youtube_regex) !== null:
        links.youtube_url = link;
        break;
      case link.match(instagram_regex) !== null:
        links.instagram_url = link;
        break;
      case link.match(tiktok_regex) !== null:
        links.tiktok_url = link;
        break;
      case link.match(twitter_regex) !== null:
        links.twitter_url = link;
        break;
      case link.match(facebook_regex) !== null:
        links.facebook_url = link;
        break;
      default:
        // because user could have multiple other websites, append to array and add all
        if (!links.website_url) links.website_url = [link];
        else links.website_url.push(link);
    }
  }

  return links;
};
