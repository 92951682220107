import React from 'react';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';

import { getDisplayInfoFromFilters, getCatalogObjectsFromProduct } from './shop_helpers';
import { getVariantAffiliateLink } from './attribution_helpers';

export const insertMetaTags = ({ title, description, image }, { addIfNull = true } = {}) => {
  const addTitleTag = addIfNull || !!title;
  const addDescriptionTag = addIfNull || !!description;
  const addImageTag = addIfNull || !image;
  return (
    <MetaTags>
      {addTitleTag && <title>{title}</title>}
      {addDescriptionTag && <meta name='description' content={description}></meta>}
      {addTitleTag && <meta property='og:title' content={title} />}
      {addDescriptionTag && <meta property='og:description' content={description} />}
      {addImageTag && <meta property='og:image' content={image} />}
    </MetaTags>
  );
};

export const getAmpScriptTagForBlogPost = post => {
  const { titleStub, elements, title, coverImage, publishedAt, updatedAt } = post;

  const names = elements?.length
    ? _.uniq(_.filter(_.map(elements, el => _.get(el, 'collection.user.name') || _.get(el, 'pin.user.name') || _.get(el, 'user.name'))))
    : [];

  return (
    <script type='application/ld+json'>
      {`
       {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://shopmy.us/blog/${titleStub}"
        },
        "headline": "${title}",
        "image": [
          "${coverImage}"
         ],
        "datePublished": "${publishedAt}",
        "dateModified": "${updatedAt}",
        "author": {
          "@type": "${names.length === 1 ? 'Person' : 'Organization'}",
          "name": "${names.length === 1 ? names[0] : 'ShopMy'}"
        },
         "publisher": {
          "@type": "Organization",
          "name": "ShopMy",
          "logo": {
            "@type": "ImageObject",
            "url": "https://askemma-static-public.s3.us-east-2.amazonaws.com/Logo/amp_logo.png"
          }
        }
      }
  `}
    </script>
  );
};

export const getSeoSchemaForSearchResultsPage = (shop, products, activeFilters) => {
  const { seoTitle, subtitle, createdAt, updatedAt } = getDisplayInfoFromFilters(activeFilters);
  const categoryIdToNameMap = _.reduce(_.get(shop, 'categories', []), (r, c) => ({ ...r, [c.id]: c.name }), {});
  return (
    <script type='application/ld+json'>
      {`{
        "@context": "https://schema.org/",
        "@type": "Collection",
        "name": "${seoTitle}",
        "headline": "${seoTitle}",
        "description": "${subtitle}",
        ${createdAt ? `"dateCreated": "${createdAt}",` : ''}
        ${updatedAt ? `"dateModified": "${updatedAt}",` : ''}
        "keywords": ["${_.uniq(_.map(products, 'title')).join(`", "`)}"],
        "hasPart": [
          ${products
            .map(
              product => `
              {
                "@type": "Product",
                "brand": "${product.brand}",
                "category": "${categoryIdToNameMap[product.Category_id]}",
                "name": "${product.title}",
                "image": "${product.image}",
                "review":  {
                  "@type": "Review",
                  "reviewBody": "${getSEODescriptionForProduct(product, shop)}",
                  "author": {
                    "@type": "Person",
                    "name": "${getSEOAuthorForProduct(product, shop)}"
                  }
                }
              }
            `
            )
            .join(', ')}
        ]
      }`}
    </script>
  );
};
export const getSeoSchemaForProductPage = (product, shop) => {
  const { variants } = product;
  return (
    <script type='application/ld+json'>
      {`
      {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": "${product.title}",
        "brand": "${product.brand}",
        "image": [
          "${product.image}"
        ],
        "review":  {
          "@type": "Review",
          "reviewBody": "${getSEODescriptionForProduct(product, shop)}",
          "author": {
            "@type": "Person",
            "name": "${getSEOAuthorForProduct(product, shop)}"
          }
        },
        "description": "${getSEODescriptionForProduct(product, shop)}",
        "offers": [
          ${variants
            .filter(v => !!v.price)
            .map(
              variant => `
              {
                "@type": "Offer",
                "price": "${variant.price}",
                "availability": "InStock",
                "priceCurrency": "USD",
                "name": "${product.title}",
                "url": "${getVariantAffiliateLink(variant, shop)}"
              }
            `
            )}
        ]
      }
    `}
    </script>
  );
};

export const getSEOAuthorForProduct = (product, shop) => {
  const { reviews } = product || {};
  const topReviewUser = _.get(reviews, '0.user');
  return !topReviewUser || topReviewUser.isAdmin ? 'ShopMy' : topReviewUser.name;
};

export const getSEODescriptionForProduct = product => {
  const { reviews, brand, title, rank } = product || {};
  const { departmentRank, categoryRank, totalMentionCount } = rank || product;
  const { category, department } = getCatalogObjectsFromProduct(product);
  const topReview = _.get(reviews, '0.review');
  const backupReview = `${title} from ${brand} is ranked #${categoryRank} in ${category && category.name} and #${departmentRank} in ${department &&
    department.name} and has been recommended by ${totalMentionCount} creator${totalMentionCount === 1 ? '' : 's'} on ShopMy.`;
  return topReview || backupReview;
};
